import React from "react";
import { Route } from "react-router-dom";
import WeeklyKpisTeam from "./WeeklyKpisTeam";
import WeeklyKpisTeams from "./WeeklyKpisTeams";

export default function WeeklyKpisRoutes() {
  return (
    <div>
      <Route
        exact
        path={[`/app/forms/department-teams/*`]}
        component={WeeklyKpisTeams}
      />
      <Route
        path={[`/app/forms/team-performance/teams/:id/*`]}
        component={WeeklyKpisTeam}
      />
    </div>
  );
}
