import React from "react";
import { Box, Card, Grid, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { loadMilestones } from "../../../actions/milestone";
import VerifiedIcon from "../../../Icons/VerifiedIcon";
import QuarterMonthProgress from "./QuarterMonthProgress";

function QuarterMonth({
  index,
  quarter_month,
  isSelected = false,
  onSelectMonth = () => {},
  loadMilestones,
}) {
  const {
    month_start_date,
    month_end_date,
    percentage_completion = 0,
  } = quarter_month;

  const [isLoaded, setIsLoaded] = React.useState(false);

  React.useEffect(() => {
    if (isSelected && !isLoaded) {
      loadMilestones(
        index,
        new Date(month_start_date).toISOString(),
        new Date(month_end_date).toISOString()
      ).then(() => {
        setIsLoaded(true);
      });
    }
  }, [
    loadMilestones,
    isSelected,
    index,
    month_start_date,
    month_end_date,
    isLoaded,
  ]);

  const month = new Date(month_start_date).toLocaleDateString("en-GB", {
    month: "short",
  });
  const year = new Date(month_start_date).toLocaleDateString("en-GB", {
    year: "2-digit",
  });
  const percentageCompleted = Math.ceil(percentage_completion * 100) + "%";

  return (
    <Grid item xs={6} md={4} lg={2}>
      <Card elevation={8} style={{ cursor: "pointer", userSelect: "none" }}>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            position: "relative",
          }}
          onClick={() => onSelectMonth()}
        >
          <QuarterMonthProgress
            percentageCompleted={percentageCompleted}
            start_date={month_start_date}
          />
          <div
            style={{
              zIndex: 2,
              padding: 16,
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "baseline",
                borderBottom: `2px solid ${
                  isSelected ? "white" : "rgba(255,255,255,0.2)"
                }`,
                transition: "all 0.3s ease",
              }}
            >
              <Typography
                style={{ marginRight: 4, fontWeight: "bold", color: "white" }}
                variant="h6"
              >
                {month}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body2"
                style={{ color: "white" }}
              >{`'${year}`}</Typography>
            </div>
            {percentage_completion === 1 && (
              <VerifiedIcon
                dimension={24}
                primary_fill="transparent"
                secondary_fill="white"
              />
            )}
          </div>
        </Box>
      </Card>
    </Grid>
  );
}

export default connect(null, { loadMilestones })(QuarterMonth);
