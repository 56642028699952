import React from "react";
import Collapse from "@material-ui/core/Collapse";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";

export default function CustomCircularProgress({ open = true }) {
  return (
    <Collapse in={open}>
      <Box style={{ textAlign: "center" }}>
        <CircularProgress size={28} />
      </Box>
    </Collapse>
  );
}
