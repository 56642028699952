import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  InputLabel,
  FormControl,
  TextField,
  Button,
  Typography,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
  Divider,
  Checkbox,
  CardActionArea,
  Paper,
  makeStyles,
  FormHelperText,
} from "@material-ui/core";
import "date-fns";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { update_time_off_request_form } from "../../../../actions/personal";
import ImageUpload from "../../ImageUpload";
import InfoPopover from "../../Infopopover";

var weekday = new Array(7);
weekday[0] = "Sunday";
weekday[1] = "Monday";
weekday[2] = "Tuesday";
weekday[3] = "Wednesday";
weekday[4] = "Thursday";
weekday[5] = "Friday";
weekday[6] = "Saturday";

var getDaysArray = function (s, e) {
  for (var a = {}, d = new Date(s); d <= e; d.setDate(d.getDate() + 1)) {
    a[d.toLocaleDateString()] = ![5, 6].includes(new Date(d).getDay());
  }
  return a;
};

var getDaysArrayAlternate = function (s, e, daysOff) {
  for (var a = {}, d = new Date(s); d <= e; d.setDate(d.getDate() + 1)) {
    a[d.toLocaleDateString()] = daysOff.includes(d.toLocaleDateString());
  }
  return a;
};

const useStyles = makeStyles((theme) => ({
  middle: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 0,
    "& .MuiPickersDay-day": {
      color: "white !important",
    },
  },
  start: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 64,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    "& .MuiPickersDay-day": {
      color: "white !important",
    },
  },
  end: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 64,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    "& .MuiPickersDay-day": {
      color: "white !important",
    },
  },
  one_day_off: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 64,
    "& .MuiPickersDay-day": {
      color: "white !important",
    },
  },
}));

const RequestInput = ({
  data,
  current_user,
  TMO,
  update_time_off_request_form,
}) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    date_start: null,
    date_end: null,
    days_off: {},
    time_off_type: "",
    note: "",
    doctor_note: null,
  });
  const [edit, setEdit] = useState(false);
  useEffect(() => {
    setFormData({
      date_start: new Date(data.date_start),
      date_end: new Date(data.date_end),
      days_off: getDaysArrayAlternate(
        new Date(data.date_start),
        new Date(data.date_end),
        data.days_off
      ),
      time_off_type: data.time_off_type,
      note: data.note,
      doctor_note: data.doctor_note,
    });
  }, [data, edit]);

  const handleChange = (prop) => (event) => {
    setFormData({ ...formData, [prop]: event.target.value });
  };

  const onChangeDate = (name, date) => {
    var range = {};
    if (name === "date_start" && formData.date_end) {
      range = getDaysArray(date, formData.date_end);
    }
    if (name === "date_end" && formData.date_start) {
      range = getDaysArray(formData.date_start, date);
    }
    setFormData({ ...formData, [name]: date, days_off: range });
  };

  const renderDayFn = (day, b, c, props) => {
    if (
      daysOff[0] === day.toLocaleDateString() &&
      daysOff[daysOff.length - 1] === day.toLocaleDateString()
    ) {
      return <div className={classes.one_day_off}>{props}</div>;
    } else if (daysOff[0] === day.toLocaleDateString()) {
      return <div className={classes.start}>{props}</div>;
    } else if (daysOff[numDaysOff - 1] === day.toLocaleDateString()) {
      return <div className={classes.end}>{props}</div>;
    } else {
      return props["props"]["hidden"] ||
        !daysOff.includes(day.toLocaleDateString()) ? (
        <div>{props}</div>
      ) : (
        <div className={classes.middle}>{props}</div>
      );
    }
  };

  const daysOff = Object.entries(formData.days_off)
    .filter(([k, v]) => v)
    .map(([k, v]) => k);

  const numDaysOff = Object.values(daysOff).length;
  const subheader =
    formData.date_start && formData.date_end && numDaysOff === 0
      ? "Please select at least 1 weekday"
      : null;

  const isAllowedToEdit =
    ["pending", "returned"].includes(data.approval_status.status) &&
    current_user === data.requester;

  const onSubmit = (e) => {
    e.preventDefault();
    update_time_off_request_form(
      {
        ...formData,
        doctor_note:
          formData.time_off_type.toLowerCase() === "sick leave" &&
          numDaysOff > 2
            ? formData.doctor_note
            : null,
        date_start: new Date(daysOff[0]).toLocaleDateString("fr-CA"),
        date_end: new Date(daysOff[numDaysOff - 1]).toLocaleDateString("fr-CA"),
        days_off: daysOff,
      },
      TMO
    );
  };

  return (
    <Fragment>
      {data.approval_status.note && (
        <TextField
          fullWidth
          label="Approver's note"
          multiline
          InputProps={{ disableUnderline: true, readOnly: true }}
          value={data.approval_status.note}
        />
      )}

      <Typography
        style={{ marginBottom: 20, fontWeight: "bold", alignSelf: "start" }}
        variant="h5"
      >
        Time off information
        {isAllowedToEdit && (
          <Button
            color="primary"
            style={{ fontWeight: "bold" }}
            onClick={() => setEdit(!edit)}
          >
            {edit ? "Cancel" : "Edit"}
          </Button>
        )}
      </Typography>
      {isAllowedToEdit && edit ? (
        <form onSubmit={(e) => onSubmit(e)}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <KeyboardDatePicker
                  inputVariant="outlined"
                  label="Starting date"
                  format="dd/MM/yyyy"
                  value={formData.date_start}
                  onChange={(date) => onChangeDate("date_start", date)}
                  autoOk
                  okLabel={null}
                  cancelLabel={null}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <KeyboardDatePicker
                  inputVariant="outlined"
                  label="Ending date"
                  format="dd/MM/yyyy"
                  value={formData.date_end}
                  onChange={(date) => onChangeDate("date_end", date)}
                  minDate={formData.date_start && new Date(formData.date_start)}
                  autoOk
                  okLabel={null}
                  cancelLabel={null}
                  fullWidth
                />
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>

          <Paper elevation={3}>
            {Object.keys(formData.days_off).length !== 0 && (
              <List dense disablePadding>
                {Object.entries(formData.days_off).map(
                  ([day, isChecked], index) => {
                    const isWeekend = [5, 6].includes(new Date(day).getDay());
                    const position = daysOff.indexOf(day) + 1;
                    return (
                      <CardActionArea key={day}>
                        {index !== 0 && <Divider />}
                        <ListItem
                          onClick={() =>
                            setFormData({
                              ...formData,
                              days_off: {
                                ...formData.days_off,
                                [day]: !isChecked,
                              },
                            })
                          }
                        >
                          <ListItemIcon>
                            <Typography>{position || "-"}</Typography>
                          </ListItemIcon>

                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={isChecked}
                              disableRipple
                              color="primary"
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  {weekday[new Date(day).getDay()]}
                                </Grid>
                                <Grid item xs={6}>
                                  {new Date(day).toLocaleDateString("en-GB")}
                                </Grid>
                              </Grid>
                            }
                            secondary={isWeekend && "weekend"}
                          />
                        </ListItem>
                      </CardActionArea>
                    );
                  }
                )}
              </List>
            )}
          </Paper>
          {subheader && (
            <FormHelperText style={{ marginTop: 20 }} error>
              {subheader}
            </FormHelperText>
          )}
          <FormControl
            fullWidth
            variant="outlined"
            required
            style={{
              marginTop:
                formData.date_start && formData.date_end
                  ? subheader
                    ? 20
                    : 30
                  : 0,
            }}
          >
            <InputLabel>Time-off type</InputLabel>
            <Select
              value={formData.time_off_type}
              onChange={handleChange("time_off_type")}
              label="Time off type *"
            >
              <MenuItem value={"Annual paid leave"}>Annual paid leave</MenuItem>
              <MenuItem value={"Sick leave"}>Sick leave</MenuItem>
              <MenuItem value={"Parental leave"}>Parental leave</MenuItem>
              <MenuItem value={"Bereavement leave"}>Bereavement leave</MenuItem>
              <MenuItem value={"Unpaid leave"}>Unpaid leave</MenuItem>
            </Select>
          </FormControl>
          {formData.time_off_type.toLowerCase() === "sick leave" && (
            <Grid container spacing={1}>
              <Grid item xs={11}>
                <FormControl fullWidth>
                  <ImageUpload
                    label="Doctor's note"
                    allowedToEdit={true}
                    passReceipt={(file) =>
                      setFormData({ ...formData, doctor_note: file })
                    }
                    receipt_image={formData.doctor_note}
                    required={!Boolean(formData.note)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={1} style={{ margin: "auto" }}>
                <InfoPopover
                  info="Sick leave lasting over two working days is subject to the
                    employee providing a medical certificate as evidence of
                    their sickness by a medical doctor."
                />
              </Grid>
            </Grid>
          )}
          <TextField
            fullWidth
            variant="outlined"
            label="Note"
            multiline
            onChange={handleChange("note")}
            value={formData.note}
            rows={4}
          />
          <FormControl fullWidth>
            <Button
              disabled={formData.time_off_type.length === 0 || numDaysOff === 0}
              variant="contained"
              color="primary"
              type="submit"
            >
              Update
            </Button>
          </FormControl>
        </form>
      ) : (
        <Fragment>
          <TextField
            fullWidth
            label="Requester"
            inputProps={{ readOnly: true }}
            InputProps={{ disableUnderline: true }}
            value={data.requester_full_name}
          />

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Paper elevation={7} style={{ padding: 24, width: "fit-content" }}>
              <KeyboardDatePicker
                variant="static"
                onChange={(date) => {}}
                renderDay={renderDayFn}
                disableToolbar
                minDate={formData.date_start}
                maxDate={formData.date_end}
                value={formData.date_start}
                fullWidth
              />
            </Paper>
          </MuiPickersUtilsProvider>

          <TextField
            style={{ marginTop: 40 }}
            fullWidth
            label="Time off type"
            inputProps={{ readOnly: true }}
            InputProps={{ disableUnderline: true }}
            value={formData.time_off_type}
          />
          {formData.doctor_note && (
            <FormControl fullWidth>
              <ImageUpload
                label="Doctor's note"
                allowedToEdit={false}
                receipt_image={formData.doctor_note}
              />
            </FormControl>
          )}
          {formData.note && (
            <FormControl fullWidth>
              <TextField
                label="Note"
                inputProps={{ readOnly: true }}
                multiline
                InputProps={{ disableUnderline: true }}
                value={formData.note}
              />
            </FormControl>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default connect(null, {
  update_time_off_request_form,
})(RequestInput);
