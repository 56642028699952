import {
  WEEKLY_KPIS_LOAD_DEPARTMENT_TEAM,
  WEEKLY_KPIS_RESET_DEPARTMENT_TEAM,
  WEEKLY_KPIS_UPDATE_ACTIVE_EVALUATION,
  WEEKLY_KPIS_LOAD_ACTIVE_EVALUATIONS,
  WEEKLY_KPIS_LOAD_PAST_EVALUATIONS,
  WEEKLY_KPIS_SELECT_TAGS,
  WEEKLY_KPIS_SELECT_DURATION,
} from "../actions/weeklyKpisTypes";

const initialPaginatedData = {
  results: [],
  count: 0,
  next: null,
  page: 1,
};

const now = new Date();

const initialState = {
  department_team: null,
  active_evaluations: initialPaginatedData,
  past_evaluations: initialPaginatedData,
  tags: [],

  duration: {
    option: "Last month",
    start_date: new Date(now.getFullYear(), now.getMonth() - 1, 1),
    end_date: new Date(),
  },
};

const updatePaginatedData = ({ payload, prev_results = [] }) => ({
  results:
    payload.page === 1
      ? payload.results
      : [...prev_results, ...payload.results],
  count: payload.count,
  next: payload.next ? payload.page + 1 : null,
});

export default function weekly_kpis(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case WEEKLY_KPIS_SELECT_DURATION:
      return {
        ...state,
        duration: payload,
      };

    case WEEKLY_KPIS_SELECT_TAGS:
      return {
        ...state,
        tags: payload,
      };

    case WEEKLY_KPIS_UPDATE_ACTIVE_EVALUATION:
      return {
        ...state,
        active_evaluations: {
          ...state.active_evaluations,
          results: state.active_evaluations.results.map((evaluation) =>
            evaluation.id === payload.id ? payload : evaluation
          ),
        },
      };

    case WEEKLY_KPIS_RESET_DEPARTMENT_TEAM:
      return {
        ...state,
        department_team: null,
        tags: [],
      };

    case WEEKLY_KPIS_LOAD_DEPARTMENT_TEAM:
      return {
        ...state,
        department_team: payload,
      };

    case WEEKLY_KPIS_LOAD_PAST_EVALUATIONS:
      return {
        ...state,
        past_evaluations: updatePaginatedData({
          payload: payload,
          prev_results: state.past_evaluations.results,
        }),
      };

    case WEEKLY_KPIS_LOAD_ACTIVE_EVALUATIONS:
      return {
        ...state,
        active_evaluations: updatePaginatedData({
          payload: payload,
          prev_results: state.active_evaluations.results,
        }),
      };

    default:
      return state;
  }
}
