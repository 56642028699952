import axios from "axios";
import global from "../../global";
const { API_URL } = global;
// import { AUTHENTICATED_FAIL } from "../../actions/authTypes";

const instance = axios.create();

instance.interceptors.request.use(
  (config) => {
    const access = localStorage.getItem("access");
    if (access) {
      config.headers["Authorization"] = `JWT ${access}`;
      config.headers["Content-Type"] = "application/json";
      config.headers["Accept"] = "application/json";
    } else {
      // dispatch({
      //   type: AUTHENTICATED_FAIL,
      // });
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

//GET ALL PERSPECTIVES
const perspectives = async () => {
  return await instance.get(`${API_URL}/analytics/perspectives/`);
};

//GET ALL METRICS
const metrics = async () => {
  return await instance.get(`${API_URL}/analytics/metrics/`);
};

//GET ALL METRICS IN A GIVEN PERSPECTIVE
const metricsInPerspective = async (perspectiveName) => {
  return await instance.get(
    `${API_URL}/analytics/metrics/?perspective=${perspectiveName}`
  );
};

//GET METRIC GIVEN A METRIC ID
const metric = async (metricid) => {
  return await instance.get(`${API_URL}/analytics/metrics/${metricid}/`);
};

//GET METRIC GIVEN METRIC ID, PERIOD, GRANULARITY
const getMetricWithPeriod = async (metricid, period, granularity) => {
  return await instance.get(
    `${API_URL}/analytics/metrics/${metricid}/stats/?period=${period}&granularity=${granularity}`
  );
};

//GET METRIC GIVEN METRIC ID, FROM DATE, TO DATE, GRANULARITY
const getMetricWithDates = async (metricid, fromdate, todate, granularity) => {
  return await instance.get(
    `${API_URL}/analytics/metrics/${metricid}/stats/?from_date=${fromdate}&to_date=${todate}&granularity=${granularity}`
  );
};

const object = {
  instance: instance,
  perspectives: perspectives,
  metrics: metrics,
  metricsInPerspective: metricsInPerspective,
  metric: metric,
  getMetricWithPeriod: getMetricWithPeriod,
  getMetricWithDates: getMetricWithDates,
};

export default object;
