import React from "react";
import { TextField, Card, CircularProgress } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";

export default function CustomSearchField({
  value = "",
  setValue = () => {},
  placeholder = "",
  InputProps = {},
  isLoading = false,
  ...other
}) {
  return (
    <TextField
      component={Card}
      elevation={8}
      variant="standard"
      placeholder={placeholder}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      InputProps={{
        ...InputProps,
        disableUnderline: true,
        startAdornment: (
          <>
            <SearchIcon dimension={18} style={{ marginRight: 6 }} />
            {InputProps.startAdornment}
          </>
        ),
        endAdornment: isLoading ? (
          <CircularProgress size={18} />
        ) : (
          InputProps.endAdornment
        ),
      }}
      style={{
        padding: "8px 16px",
        width: "100%",
        minHeight: 55,
        display: "grid",
        alignItems: "center",
      }}
      {...other}
    />
  );
}
