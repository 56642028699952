export const BIRTHDAYS_CALENDAR_SUCCESS = "BIRTHDAYS_CALENDAR_SUCCESS";
export const BIRTHDAYS_CALENDAR_FAILURE = "BIRTHDAYS_CALENDAR_FAILURE";
export const NEW_TO_RIMADS_CALENDAR_SUCCESS = "NEW_TO_RIMADS_CALENDAR_SUCCESS";
export const NEW_TO_RIMADS_CALENDAR_FAILURE = "NEW_TO_RIMADS_CALENDAR_FAILURE";
export const UPCOMING_EVENTS_CALENDAR_SUCCESS =
  "UPCOMING_EVENTS_CALENDAR_SUCCESS";
export const UPCOMING_EVENTS_CALENDAR_FAILURE =
  "UPCOMING_EVENTS_CALENDAR_FAILURE";
export const WHOS_OUT_CALENDAR_SUCCESS = "WHOS_OUT_CALENDAR_SUCCESS";
export const WHOS_OUT_CALENDAR_FAILURE = "WHOS_OUT_CALENDAR_FAILURE";
