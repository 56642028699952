import axios from "axios";
import { SNACKBAR_WRITE } from "./procedureType";

import {
  CLEAR_TIME_OFF_FORMDATA,
  GET_TIME_OFF_FORMDATA,
  UPDATE_TIME_OFF_FORMSTATUS,
  PROCESSING_TIME_OFF_REQUEST,
} from "./personalTypes";
import { AUTHENTICATED_FAIL } from "./authTypes";

import global from "../global";
const { API_URL } = global;

export const post_time_off_request = (data) => async (dispatch) => {
  dispatch({
    type: PROCESSING_TIME_OFF_REQUEST,
    payload: true,
  });
  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    let formData = new FormData();
    formData.append("date_start", data.date_start);
    formData.append("date_end", data.date_end);
    formData.append("days_off", JSON.stringify(data.days_off));
    formData.append("time_off_type", data.time_off_type);
    data.doctor_note && formData.append("doctor_note", data.doctor_note);
    formData.append("note", data.note);

    try {
      const response = await axios.post(
        `${API_URL}/personal/timeoff/`,
        formData,
        config
      );
      dispatch({
        type: SNACKBAR_WRITE,
        payload: response.data,
      });
      dispatch({
        type: PROCESSING_TIME_OFF_REQUEST,
        payload: false,
      });

      return response.data.TMO;
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "An error has occured! Please try again later.",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const get_time_off_request = (TMO) => async (dispatch) => {
  dispatch({
    type: CLEAR_TIME_OFF_FORMDATA,
  });
  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    try {
      const response = await axios.get(
        `${API_URL}/personal/timeoff/${TMO}/`,
        config
      );
      dispatch({
        type: GET_TIME_OFF_FORMDATA,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "Unable to load request",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const update_time_off_request_form = (data, TMO) => async (dispatch) => {
  dispatch({
    type: PROCESSING_TIME_OFF_REQUEST,
    payload: true,
  });
  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    let formData = new FormData();
    formData.append("date_start", data.date_start);
    formData.append("date_end", data.date_end);
    formData.append("days_off", JSON.stringify(data.days_off));
    formData.append("time_off_type", data.time_off_type);
    data.doctor_note && formData.append("doctor_note", data.doctor_note);
    formData.append("note", data.note);

    try {
      const response = await axios.put(
        `${API_URL}/personal/timeoff/${TMO}/`,
        formData,
        config
      );
      dispatch({
        type: UPDATE_TIME_OFF_FORMSTATUS,
        payload: response.data,
      });
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: response.data.message,
          severity: response.data.severity,
        },
      });
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "An error has occured! Please try again later.",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const update_time_off_request_status =
  (status, TMO, note) => async (dispatch) => {
    const access = localStorage.getItem("access");
    if (access) {
      dispatch({
        type: PROCESSING_TIME_OFF_REQUEST,
        payload: true,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `JWT ${access}`,
        },
      };

      const body = JSON.stringify({ status: status, note: note });

      try {
        const response = await axios.post(
          `${API_URL}/personal/timeoff/${TMO}/`,
          body,
          config
        );
        dispatch({
          type: UPDATE_TIME_OFF_FORMSTATUS,
          payload: response.data,
        });
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: response.data.message,
            severity: response.data.severity,
          },
        });
      } catch (error) {
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: "An error has occured! Please try again later.",
            severity: "error",
          },
        });
      }
    } else {
      dispatch({
        type: AUTHENTICATED_FAIL,
      });
    }
  };
