import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { FormControl, InputLabel } from "@material-ui/core";
import { add_supplier } from "../../../actions/procedure";
import { connect } from "react-redux";

import DialogTitle from "../../../components/MuiDialogTitle";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function AddSupplier({ open, setOpen, add_supplier }) {
  const handleClose = () => {
    setOpen(false);
    setFormData({
      name: "",
      supplier_type: "",
      store_location: "",
    });
  };

  const [formData, setFormData] = useState({
    name: "",
    supplier_type: "",
    store_location: "",
  });

  const handleChange = (prop) => (event) => {
    setFormData({ ...formData, [prop]: event.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    add_supplier(formData);
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        fullWidth
      >
        <DialogTitle
          style={{ cursor: "move" }}
          id="draggable-dialog-title"
          onClose={handleClose}
        >
          Add a new supplier
        </DialogTitle>
        <form onSubmit={(e) => onSubmit(e)}>
          <DialogContent>
            <FormControl fullWidth>
              <TextField
                required
                onChange={handleChange("name")}
                value={formData.name}
                autoFocus
                variant="outlined"
                label="Name"
                type="text"
              />
            </FormControl>
            <FormControl fullWidth variant="outlined" required>
              <InputLabel>Type</InputLabel>
              <Select
                value={formData.supplier_type}
                onChange={handleChange("supplier_type")}
                label="Type *"
                variant="outlined"
                fullWidth
              >
                <MenuItem value={"partner"}>Partner</MenuItem>
                <MenuItem value={"non partner"}>Non partner</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth variant="outlined" required>
              <InputLabel>Store location</InputLabel>
              <Select
                value={formData.store_location}
                onChange={handleChange("store_location")}
                label="Store location *"
                variant="outlined"
                fullWidth
              >
                <MenuItem value={"online store"}>Online store</MenuItem>
                <MenuItem value={"physical store"}>Physical store</MenuItem>
                <MenuItem value={"both"}>Both</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions style={{ padding: 24 }}>
            <Button
              onClick={handleClose}
              fullWidth
              color="primary"
              variant="outlined"
              style={{ borderRadius: 24 }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              fullWidth
              color="primary"
              variant="contained"
              style={{ borderRadius: 24 }}
            >
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  processing: state.procedures.processing,
});

export default connect(mapStateToProps, { add_supplier })(AddSupplier);
