import React from "react";
import VerifiedIcon from "../../../Icons/VerifiedIcon";
import PendingIcon from "../../../Icons/PendingIcon";

export default function MilestoneStatusIcon({ is_completed, ...other }) {
  return is_completed ? (
    <VerifiedIcon dimension={24} {...other} />
  ) : (
    <PendingIcon dimension={24} {...other} />
  );
}
