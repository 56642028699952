import {
  SNACKBAR_WRITE,
  SNACKBAR_DELETE,
  SNACKBAR_CUSTOM,
  PROCESSING,
  LOAD_SUPPLIERS_SUCCESS,
  LOAD_SUPPLIER_PRODUCTS_SUCCESS,
  CLEAR_SUPPLIER_PRODUCTS,
  DELETE_ALL_SUPPLIER_PRODUCTS,
  DELETE_MULTIPLE_SUPPLIER_PRODUCTS,
  DELETE_SUPPLIER_PRODUCT,
  REMOVE_SUPPLIER_PRODUCT,
  CREATE_NEW_SUPPLIER,
  CREATE_NEW_PRODUCT,
  DELETE_SUPPLIER,
  EDIT_SUPPLIER,
  EDIT_PRODUCT,
  LOAD_WEBAPPS,
  CLEAR_WEBAPP_ACCOUNTS,
  LOAD_WEBAPP_ACCOUNTS,
  CREATE_WEBAPP,
  DELETE_WEBAPP,
  CREATE_WEBAPP_ACCOUNT,
  DELETE_WEBAPP_ACCOUNT,
  DELETE_MULTIPLE_WEBAPPS,
  DELETE_ALL_WEBAPPS,
  EDIT_WEBAPP,
  UPLOAD_PRODUCTS,
  EDIT_WEBAPP_ACCOUNT,
  DELETE_ALL_WEBAPP_ACCOUNTS,
  DELETE_MULTIPLE_WEBAPP_ACCOUNTS,
  SAVE_PROCUREMENT_DATA,
  DISCARD_PROCUREMENT_DATA,
  GET_GROUPS,
  DELETE_MULTIPLE_SUPPLIERS,
  DELETE_ALL_SUPPLIERS,
  GET_USER_ACCOUNTS,
} from "../actions/procedureType";

import { LOGOUT } from "../actions/authTypes";

const initialState = {
  processing: false,
  message: null,
  severity: null,
  suppliers: [],
  supplier_products: [],
  webapps: [],
  webapp_accounts: [],
  groups: null,
  user_accounts: [],
};

export default function procedures(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_USER_ACCOUNTS:
      return {
        ...state,
        user_accounts: payload,
      };

    case DELETE_ALL_WEBAPPS:
      return {
        ...state,
        message: payload.message,
        severity: payload.severity,
        webapps: [],
      };

    case DELETE_MULTIPLE_WEBAPPS:
      return {
        ...state,
        webapps: state.webapps.filter(
          (webapp) => !payload.webapp_pks.includes(webapp.id)
        ),
        message: payload.message,
        severity: payload.severity,
      };

    case DELETE_MULTIPLE_SUPPLIERS:
      return {
        ...state,
        suppliers: state.suppliers.filter(
          (supplier) => !payload.supplier_pks.includes(supplier.id)
        ),
        message: payload.message,
        severity: payload.severity,
        processing: false,
      };

    case DELETE_ALL_SUPPLIERS:
      return {
        ...state,
        message: payload.message,
        severity: payload.severity,
        suppliers: [],
        processing: false,
      };

    case GET_GROUPS:
      return {
        ...state,
        groups: payload,
      };

    case DISCARD_PROCUREMENT_DATA:
      localStorage.removeItem("procurementData");
      return {
        ...state,
        procurementData: {},
      };

    case SAVE_PROCUREMENT_DATA:
      localStorage.setItem("procurementData", JSON.stringify(payload));

      return {
        ...state,
        procurementData: payload,
      };

    case UPLOAD_PRODUCTS:
      return {
        ...state,
        supplier_products: [...state.supplier_products, ...payload],
      };

    case EDIT_WEBAPP_ACCOUNT:
      return {
        ...state,
        webapp_accounts: state.webapp_accounts.map((account) =>
          account.id !== payload.accountId ? account : payload.account
        ),
      };

    case DELETE_MULTIPLE_WEBAPP_ACCOUNTS:
      return {
        ...state,
        webapp_accounts: state.webapp_accounts.filter(
          (account) => !payload.account_pks.includes(account.id)
        ),
        message: payload.message,
        severity: payload.severity,
      };

    case DELETE_ALL_WEBAPP_ACCOUNTS:
      return {
        ...state,
        message: payload.message,
        severity: payload.severity,
        webapp_accounts: [],
      };

    case DELETE_WEBAPP_ACCOUNT:
      return {
        ...state,
        webapp_accounts: state.webapp_accounts.filter(
          (account) => account.id !== payload.account_pk
        ),
        message: payload.message,
        severity: payload.severity,
      };

    case CREATE_WEBAPP_ACCOUNT:
      return {
        ...state,
        webapp_accounts: [...state.webapp_accounts, payload.account],
      };

    case EDIT_WEBAPP:
      return {
        ...state,
        webapps: state.webapps.map((webapp) =>
          webapp.id !== payload.webappId ? webapp : payload.webapp
        ),
      };

    case DELETE_WEBAPP:
      return {
        ...state,
        webapps: state.webapps.filter(
          (webapp) => webapp.id !== payload.webapp_pk
        ),
        message: payload.message,
        severity: payload.severity,
      };

    case CREATE_WEBAPP:
      return {
        ...state,
        webapps: [...state.webapps, payload.webapp],
      };

    case LOAD_WEBAPP_ACCOUNTS:
      return {
        ...state,
        webapp_accounts: payload,
      };

    case CLEAR_WEBAPP_ACCOUNTS:
      return {
        ...state,
        webapp_accounts: [],
      };

    case LOAD_WEBAPPS:
      return {
        ...state,
        webapps: payload,
      };

    case EDIT_PRODUCT:
      return {
        ...state,
        supplier_products: state.supplier_products.map((product) =>
          product.id !== payload.productId ? product : payload.product
        ),
      };

    case EDIT_SUPPLIER:
      return {
        ...state,
        suppliers: state.suppliers.map((supplier) =>
          supplier.id !== payload.supplierId ? supplier : payload.supplier
        ),
      };

    case DELETE_SUPPLIER:
      return {
        ...state,
        suppliers: state.suppliers.filter(
          (supplier) => supplier.id !== payload.supplier_pk
        ),
        message: payload.message,
        severity: payload.severity,
        processing: false,
      };

    case CREATE_NEW_PRODUCT:
      return {
        ...state,
        supplier_products: !state.supplier_products
          .map((product) => product.id)
          .includes(payload.product.id)
          ? [...state.supplier_products, payload.product]
          : [...state.supplier_products],
        processing: false,
      };

    case CREATE_NEW_SUPPLIER:
      return {
        ...state,
        suppliers: [...state.suppliers, payload.supplier],
        processing: false,
      };

    case REMOVE_SUPPLIER_PRODUCT:
      return {
        ...state,
        supplier_products: state.supplier_products.filter(
          (product) => product.id !== payload.product_pk
        ),
      };

    case DELETE_SUPPLIER_PRODUCT:
      return {
        ...state,
        supplier_products: state.supplier_products.filter(
          (product) => product.id !== payload.product_pk
        ),
        message: payload.message,
        severity: payload.severity,
        processing: false,
      };

    case DELETE_MULTIPLE_SUPPLIER_PRODUCTS:
      return {
        ...state,
        supplier_products: state.supplier_products.filter(
          (product) => !payload.product_pks.includes(product.id)
        ),
        message: payload.message,
        severity: payload.severity,
        processing: false,
      };

    case DELETE_ALL_SUPPLIER_PRODUCTS:
      return {
        ...state,
        message: payload.message,
        severity: payload.severity,
        supplier_products: [],
        processing: false,
      };

    case CLEAR_SUPPLIER_PRODUCTS:
      return {
        ...state,
        supplier_products: [],
      };

    case LOAD_SUPPLIER_PRODUCTS_SUCCESS:
      return {
        ...state,
        supplier_products: payload,
        processing: false,
      };

    case LOAD_SUPPLIERS_SUCCESS:
      return {
        ...state,
        suppliers: payload,
      };

    case SNACKBAR_WRITE:
      return {
        ...state,
        processing: false,
        message: payload.message,
        severity: payload.severity,
      };

    case PROCESSING:
      return {
        ...state,
        processing: payload,
      };

    case LOGOUT:
      return {
        ...state,
        processing: false,
        message: null,
        severity: null,
      };

    case SNACKBAR_DELETE:
      return {
        ...state,
        message: null,
        severity: null,
      };

    case SNACKBAR_CUSTOM:
      return {
        ...state,
        processing: false,
        message: payload.message,
        severity: payload.severity,
      };

    default:
      return state;
  }
}
