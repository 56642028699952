import React from "react";
import { Card, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { weeklyKpisUpdateActiveEvaluation } from "../../../actions/weeklyKpis";
import WeeklyKpisEvaluationFormData from "./WeeklyEvaluationFormData";
import WeeklyKpisEvaluationCardHeader from "./WeeklyEvaluationCardHeader";

const getData = (formData, perspective) => {
  const {
    id,
    department_team,
    utilization_percent,
    performance_level_percent,
    num_days_physical_attendance,
    note,
    time_is_not_applicable,
    tasks,
  } = formData;

  switch (perspective) {
    case "from":
      return {
        id: id,
        department_team: department_team,
        utilization_percent_from: utilization_percent,
        performance_level_percent_from: performance_level_percent,
        num_days_physical_attendance_from: num_days_physical_attendance,
        note_from: note,
        time_is_not_applicable: time_is_not_applicable,
        tasks_from: tasks,
      };

    case "to":
      return {
        id: id,
        department_team: department_team,
        utilization_percent_to: utilization_percent,
        performance_level_percent_to: performance_level_percent,
        num_days_physical_attendance_to: num_days_physical_attendance,
        note_to: note,
        time_is_not_applicable: time_is_not_applicable,
        tasks_to: tasks,
      };

    default:
      return {};
  }
};

const getFormData = (evaluation, perspective) => {
  const {
    id,
    department_team,
    utilization_percent_from,
    utilization_percent_to,
    performance_level_percent_from,
    performance_level_percent_to,
    num_days_physical_attendance_from,
    num_days_physical_attendance_to,
    note_from,
    note_to,
    time_last_saved_from,
    time_last_saved_to,
    time_is_not_applicable,
    tasks_from,
    tasks_to,
  } = evaluation;

  switch (perspective) {
    case "from":
      return {
        id: id,
        department_team: department_team,

        utilization_percent: utilization_percent_from || "",
        utilization_percent_initial: utilization_percent_from || 0,

        performance_level_percent: performance_level_percent_from || "",
        performance_level_percent_initial: performance_level_percent_from || 0,

        num_days_physical_attendance: num_days_physical_attendance_from || "",
        num_days_physical_attendance_initial:
          num_days_physical_attendance_from || 0,

        note: note_from || "",
        note_initial: note_from || "-",

        time_is_not_applicable: time_is_not_applicable,
        time_last_saved: time_last_saved_from,

        tasks: tasks_from || "",
        tasks_initial: tasks_from || "-",
      };

    case "to":
      return {
        id: id,
        department_team: department_team,

        utilization_percent: utilization_percent_to || "",
        utilization_percent_initial: utilization_percent_to || 0,

        performance_level_percent: performance_level_percent_to || "",
        performance_level_percent_initial: performance_level_percent_to || 0,

        num_days_physical_attendance: num_days_physical_attendance_to || "",
        num_days_physical_attendance_initial:
          num_days_physical_attendance_to || 0,

        note: note_to || "",
        note_initial: note_to || "-",

        time_is_not_applicable: time_is_not_applicable,
        time_last_saved: time_last_saved_to,

        tasks: tasks_to || "",
        tasks_initial: tasks_to || "-",
      };

    default:
      return {
        utilization_percent: "",
        utilization_percent_initial: 0,

        performance_level_percent: "",
        performance_level_percent_initial: 0,

        num_days_physical_attendance: "",
        num_days_physical_attendance_initial: 0,

        note: "",
        note_initial: "-",

        time_is_not_applicable: null,
        time_last_saved: null,

        tasks: "",
        tasks_initial: "-",
      };
  }
};

const useEffectWeeklyKpiEvaluations = ({ evaluation, perspective }) => {
  const initialData = getFormData(evaluation, perspective);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [formData, setFormData] = React.useState(initialData);
  const [isEditMode, setIsEditMode] = React.useState(false);

  const handleChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleChangeIsApplicable = (e) =>
    setFormData({
      ...(e.target.checked ? formData : initialData),
      time_is_not_applicable: e.target.checked ? null : new Date(),
    });

  React.useEffect(() => {
    setFormData(getFormData(evaluation, perspective));
  }, [isEditMode, evaluation, perspective]);

  const { time_last_saved } = initialData;

  return {
    time_last_saved,
    formData,
    isEditMode,
    isProcessing,
    handleChange,
    handleChangeIsApplicable,
    setIsEditMode,
    setIsProcessing,
  };
};

const WeeklyKpisEvaluation = ({
  evaluation = {},
  weeklyKpisUpdateActiveEvaluation,
  perspective, // from, to, null
}) => {
  const {
    time_last_saved,
    formData,
    isEditMode,
    isProcessing,
    handleChange,
    handleChangeIsApplicable,
    setIsEditMode,
    setIsProcessing,
  } = useEffectWeeklyKpiEvaluations({ evaluation, perspective });

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsProcessing(true);
    weeklyKpisUpdateActiveEvaluation(getData(formData, perspective)).then(
      (noErrors) => {
        setIsProcessing(false);
        noErrors && setIsEditMode(false);
      }
    );
  };

  if (!["from", "to"].includes(perspective)) {
    return null;
  }

  return (
    <Card
      elevation={8}
      component="form"
      onSubmit={handleSubmit}
      style={{
        padding: 26,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid container spacing={1} style={{ textAlign: "start" }}>
        <WeeklyKpisEvaluationCardHeader
          evaluation={evaluation}
          time_last_saved={time_last_saved}
          isEditMode={isEditMode}
          isProcessing={isProcessing}
          setIsEditMode={setIsEditMode}
        />
        <WeeklyKpisEvaluationFormData
          num_workdays={evaluation.num_workdays}
          perspective={perspective}
          isEditMode={isEditMode}
          formData={formData}
          handleChange={handleChange}
          handleChangeIsApplicable={handleChangeIsApplicable}
        />
      </Grid>
    </Card>
  );
};

export default connect(null, { weeklyKpisUpdateActiveEvaluation })(
  WeeklyKpisEvaluation
);
