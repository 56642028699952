import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { connect } from "react-redux";
const destinations = ["The office", "The warehouse", "The pharmacy"];

const useStyles = makeStyles((theme) => ({
  number: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
      {
        display: "none",
      },
  },
}));

const ProcurementDetails = ({
  minDate,
  formData,
  setFormData,
  setNext,
  user_accounts,
}) => {
  const classes = useStyles();

  const handleExpectedDeliveryCostChange = () => (event) => {
    setFormData({
      ...formData,
      expected_delivery_cost: event.target.value
        ? parseFloat(event.target.value)
        : "",
    });
  };
  const handleDateChange = (date) => {
    setFormData({ ...formData, expected_delivery_date: date });
    date === null || date.toString() === "Invalid Date"
      ? setNext(false)
      : formData.expected_receiver_staff.length !== 0 &&
        formData.delivery_destination !== null &&
        setNext(true);
  };
  const handleDestinationChange = (destination) => {
    setFormData({ ...formData, delivery_destination: destination || null });
    destination === ""
      ? setNext(false)
      : formData.expected_receiver_staff.length !== 0 &&
        formData.expected_delivery_date !== null &&
        setNext(true);
  };
  const handleReceiverChange = (staff) => {
    setFormData({ ...formData, expected_receiver_staff: staff });
    staff.length === 0
      ? setNext(false)
      : formData.expected_delivery_date !== null &&
        formData.delivery_destination !== null &&
        setNext(true);
  };

  return (
    <div>
      <Autocomplete
        required
        multiple
        options={user_accounts}
        getOptionLabel={(option) => option.full_name}
        onChange={(event, newValue) => {
          handleReceiverChange(newValue);
        }}
        getOptionSelected={(option, value) =>
          option.full_name === value.full_name ? option : null
        }
        fullWidth
        filterSelectedOptions
        value={formData.expected_receiver_staff}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            InputLabelProps={{ shrink: true }}
            label="Receiver staffs"
            variant="outlined"
          />
        )}
      />
      <Autocomplete
        required
        options={destinations}
        getOptionLabel={(option) => option}
        onInputChange={(event, newValue) => {
          handleDestinationChange(newValue);
        }}
        getOptionSelected={(option, value) =>
          option === value ? option : value
        }
        fullWidth
        value={formData.delivery_destination}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            InputLabelProps={{ shrink: true }}
            label="Delivery destination"
            variant="outlined"
            placeholder="Type in a new destination or select from this list"
          />
        )}
      />
      <FormControl fullWidth variant="outlined">
        <InputLabel>Expected delivery cost</InputLabel>
        <OutlinedInput
          label="Expected delivery cost"
          type="number"
          value={formData.expected_delivery_cost || ""}
          onChange={handleExpectedDeliveryCostChange()}
          startAdornment={<InputAdornment position="start">QAR</InputAdornment>}
          className={classes.number}
          inputProps={{ min: 0, step: 0.01 }}
        />
      </FormControl>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          fullWidth
          required
          inputVariant="outlined"
          label="Expected delivery date"
          format="dd / MM / yyyy"
          value={formData.expected_delivery_date}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          minDate={minDate}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user_accounts: state.procedures.user_accounts,
});

export default connect(mapStateToProps, {})(ProcurementDetails);
