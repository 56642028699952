import React, {useState} from "react";
import {
  Dialog,
  Avatar,
  makeStyles,
  DialogContent,
  AppBar,
  Tabs,
  Tab,
  Card,
  CardContent,
  IconButton,
} from "@material-ui/core";
import AccountContent from "./MyAccount/AccountContent";
import {Close as CloseIcon} from "@material-ui/icons";
import {CircularProgress} from "@material-ui/core";
import TimeOffHistoryTable from "./MyAccount/TimeOff/TimeOffHistoryTable";
import RimadsterEmergencyContacts from "./MyAccount/EmergencyContacts/RimadsterEmergencyContacts";

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  largeAvatar: {
    margin: "auto",
    marginBottom: 40,
    width: theme.spacing(22),
    height: theme.spacing(22),
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 4px 0px, rgb(145 158 171 / 24%) 0px 24px 48px 0px",
  },
}));

const Rimadster = ({rimadster, setRimadster}) => {
  const classes = useStyles();
  const open = Boolean(rimadster);
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => setValue(newValue);
  const onClose = () => setRimadster(false);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      style={{
        height: "100%",
        // backgroundColor: "rgba(0,0,0,0.5)",
      }}
    >
      <div>
        <IconButton onClick={onClose}>
          <CloseIcon/>
        </IconButton>
      </div>

      <DialogContent
        style={{
          margin: 0,
          padding: 80,
          textAlign: "center",
          minHeight: 1000,
          height: "100%",
          display: typeof rimadster === "boolean" && "flex",
          justifyContent: "center",
        }}
      >
        {typeof rimadster !== "boolean" ? (
          <div>
            <Avatar
              className={classes.largeAvatar}
              alt={rimadster.full_name}
              src={rimadster.picture}
            />
            <AppBar
              position="static"
              elevation={0}
              style={{backgroundColor: "transparent", marginBottom: 40}}
            >
              <Tabs
                variant="scrollable"
                scrollButtons="off"
                value={value}
                onChange={handleChange}
                style={{
                  color: "black",
                  backgroundColor: "transparent",
                  alignSelf: "center",
                  margin: "auto",
                  maxWidth: 600,
                }}
                TabIndicatorProps={{
                  style: {backgroundColor: "#77E0FF"},
                }}
              >
                <Tab
                  disableRipple
                  label="Information"
                  style={{fontSize: 20}}
                />
                <Tab disableRipple label="Time off" style={{fontSize: 20}}/>
                <Tab disableRipple label="Emergency" style={{fontSize: 20}}/>
              </Tabs>
            </AppBar>

            <div style={{margin: "auto", maxWidth: 600, minHeight: 550}}>
              <TabPanel value={value} index={0}>
                <Card elevation={12}>
                  <CardContent style={{padding: 40, paddingBottom: 5}}>
                    <AccountContent user={rimadster} showPhone={true}/>
                  </CardContent>
                </Card>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Card elevation={12}>
                  <CardContent style={{padding: 40}}>
                    <TimeOffHistoryTable
                      rows={rimadster.time_offs || []}
                      hide_title={true}
                    />
                  </CardContent>
                </Card>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Card elevation={12}>
                  <CardContent style={{padding: 40}}>
                    <RimadsterEmergencyContacts
                      emergency_contacts={rimadster.contacts || []}
                    />
                  </CardContent>
                </Card>
              </TabPanel>
            </div>
          </div>
        ) : (
          <CircularProgress style={{margin: "auto"}}/>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default Rimadster;
