import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";
import {
  delete_webapp,
  delete_multiple_webapps,
} from "../../../actions/procedure";
import { Fragment } from "react";
import AddWebApp from "./AddWebApp";
import EditWebApp from "./EditWebApp";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "../../../components/MuiDialogTitle";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Website/App",
  },
  {
    id: "manager_groups",
    numeric: true,
    disablePadding: false,
    label: "Manager Groups",
  },
];

function EnhancedTableHead({
  classes,
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            style={{ fontWeight: "bold" }}
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.primary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = ({
  selected,
  numSelected,
  numWebapps,
  delete_webapp,
  delete_multiple_webapps,
  setSelected,
  webapps,
}) => {
  const classes = useToolbarStyles();
  const [openNewWebapp, setOpenNewWebapp] = useState(false);
  const [openEditWebapp, setOpenEditWebapp] = useState(false);
  const first = webapps.find((webapp) => webapp.id === selected[0]);

  const [openDeleteWebapp, setOpenDeleteWebapp] = useState(false);

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        numSelected === 1 && first ? (
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            component="div"
            style={{ textTransform: "capitalize" }}
          >
            {first.name}
          </Typography>
        ) : (
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        )
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Add a website / app
        </Typography>
      )}

      {numSelected > 0 ? (
        numSelected === 1 ? (
          <div style={{ display: "flex" }}>
            <Tooltip title="Edit website / app">
              <IconButton onClick={() => setOpenEditWebapp(!openEditWebapp)}>
                <CreateIcon />
              </IconButton>
            </Tooltip>
            <EditWebApp
              open={openEditWebapp}
              setOpen={setOpenEditWebapp}
              webapp={first}
              setSelected={setSelected}
            />
            <Tooltip title="Delete selected website / app">
              <IconButton onClick={() => setOpenDeleteWebapp(true)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        ) : (
          <Tooltip
            title={
              numSelected !== numWebapps
                ? "Delete selected websites / apps"
                : "Delete all websites / apps"
            }
          >
            <IconButton onClick={() => setOpenDeleteWebapp(true)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )
      ) : (
        <div>
          <Tooltip title="Add website / app">
            <IconButton onClick={() => setOpenNewWebapp(!openNewWebapp)}>
              <AddIcon />
            </IconButton>
          </Tooltip>
          <AddWebApp open={openNewWebapp} setOpen={setOpenNewWebapp} />
        </div>
      )}

      <Dialog
        open={openDeleteWebapp}
        onClose={() => setOpenDeleteWebapp(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle onClose={() => setOpenDeleteWebapp(false)}>
          {numSelected > 1
            ? numSelected !== numWebapps
              ? "Are you sure that you would like to delete these websites / apps? This action cannot be undone."
              : "Are you sure that you would like to delete all websites / apps? This action cannot be undone."
            : "Are you sure that you would like to delete this website / app? This action cannot be undone."}
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => setOpenDeleteWebapp(false)}
            type="submit"
            fullWidth
            color="primary"
            variant="outlined"
            style={{
              borderRadius: 24,
              fontWeight: "bold",
            }}
          >
            No, go back
          </Button>
          <Button
            onClick={() => {
              numSelected > 1
                ? numSelected !== numWebapps
                  ? delete_multiple_webapps(selected)
                  : delete_multiple_webapps([])
                : delete_webapp(selected[0]);
              setSelected([]);
              setOpenDeleteWebapp(false);
            }}
            type="submit"
            fullWidth
            color="primary"
            variant="contained"
            style={{ borderRadius: 24 }}
          >
            Yes, I'm sure
          </Button>
        </DialogActions>
      </Dialog>
    </Toolbar>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },

  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

function EnhancedTable({ webapps, delete_webapp, delete_multiple_webapps }) {
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(1);
  const [rowValues, setRowValues] = useState([]);

  useEffect(() => {
    setRowValues([
      1,
      5 < webapps.length && 5,
      10 < webapps.length && 10,
      25 < webapps.length && 25,
      50 < webapps.length && 50,
      100 < webapps.length && 100,
      webapps.length,
    ]);
    setRowsPerPage(webapps.length > 5 ? 5 : webapps.length);
    setSelected([]);
  }, [setSelected, setRowsPerPage, webapps]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = webapps.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, webapps.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          selected={selected}
          webapps={webapps}
          numSelected={selected.length}
          numWebapps={webapps.length}
          delete_webapp={delete_webapp}
          delete_multiple_webapps={delete_multiple_webapps}
          setSelected={setSelected}
        />
        <Fragment>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={webapps.length}
              />
              <TableBody>
                {stableSort(webapps, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell color="primary" padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.name}
                        </TableCell>
                        <TableCell align="right">
                          {row.manager_groups.join(", ")}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 33 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={rowValues.filter((value) => value)}
            component="div"
            count={webapps.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Fragment>
      </Paper>
    </div>
  );
}

const mapStateToProps = (state) => ({
  webapps: state.procedures.webapps,
});

export default connect(mapStateToProps, {
  delete_webapp,
  delete_multiple_webapps,
})(EnhancedTable);
