import {
  GET_FORM_DATA_SUCCESS,
  UPDATE_FORM_STATUS_SUCCESS,
  CLEAR_FORMDATA,
  PROCESSING,
  UPDATE_PROCUREMENT_PRODUCT,
  PROCESSING_PRODUCT,
  GET_RCRN,
  CLEAR_RCRN_DATA,
  PROCESSING_RCRN,
  UPDATING_RCRN,
  FETCHED_ALL_IR,
} from "../actions/procurementTypes";

const initialState = {
  fetched_all_IR: false,
  fetched: false,
  submitting: false,
  data: null,
  submitting_order_information: false,
  fetched_RCRN: false,
  submitting_RCRN: false,
  RCRN_data: null,
};

export default function procurement(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case FETCHED_ALL_IR:
      return {
        ...state,
        fetched_all_IR: payload,
      };

    case UPDATING_RCRN:
      return {
        ...state,
        RCRN_data: {
          ...state.RCRN_data,
          inventory_requests: state.RCRN_data.inventory_requests.map(
            (inventory_request) =>
              inventory_request.IRN !== payload.IRN
                ? inventory_request
                : payload
          ),
        },
      };

    case PROCESSING_RCRN:
      return {
        ...state,
        submitting_RCRN: payload,
      };

    case CLEAR_RCRN_DATA:
      return {
        ...state,
        fetched_RCRN: false,
        RCRN_data: null,
      };

    case GET_RCRN:
      return {
        ...state,
        RCRN_data: payload,
        fetched_RCRN: true,
      };

    case UPDATE_PROCUREMENT_PRODUCT:
      return {
        ...state,
        data: {
          ...state.data,
          inventory_request_products: state.data.inventory_request_products.map(
            (product) =>
              product.id === payload.procurement_product.id
                ? payload.procurement_product
                : product
          ),
        },
        submitting_order_information: false,
      };

    case GET_FORM_DATA_SUCCESS:
      return {
        ...state,
        data: payload,
        fetched: true,
      };

    case UPDATE_FORM_STATUS_SUCCESS:
      return {
        ...state,
        submitting: false,
        data: payload.formData,
      };

    case CLEAR_FORMDATA:
      return {
        ...state,
        data: null,
        fetched: false,
      };

    case PROCESSING:
      return {
        ...state,
        submitting: payload,
      };

    case PROCESSING_PRODUCT:
      return {
        ...state,
        submitting_order_information: payload,
      };

    default:
      return state;
  }
}
