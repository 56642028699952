import React from "react";
import { Grid, Typography } from "@material-ui/core";
import CardLink from "../../components/CardLink";

const PersonalDirectory = () => {
  return (
    <div style={{ width: 655 }}>
      <Typography variant="h3" style={{ paddingBottom: 48 }}>
        Personal Forms
      </Typography>
      <Typography variant="h6" style={{ marginBottom: 36 }}>
        Choose between the available procedures.
      </Typography>
      <Grid
        container
        spacing={4}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid item>
          <CardLink
            title={"Time Off"}
            link="/app/forms/personal/time-off"
            // disabled={true}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default PersonalDirectory;
