import Api from "./_shared";
import { SNACKBAR_WRITE } from "./procedureType";
import {
  WEEKLY_KPIS_LOAD_DEPARTMENT_TEAM,
  WEEKLY_KPIS_RESET_DEPARTMENT_TEAM,
  WEEKLY_KPIS_UPDATE_ACTIVE_EVALUATION,
  WEEKLY_KPIS_LOAD_ACTIVE_EVALUATIONS,
  WEEKLY_KPIS_LOAD_PAST_EVALUATIONS,
  WEEKLY_KPIS_SELECT_TAGS,
  WEEKLY_KPIS_SELECT_DURATION,
} from "./weeklyKpisTypes";

const memberTypeMapper = {
  supervisor: "is_supervisor",
  supervisee: "is_supervisee",
  manager: "is_manager",
};

const errorMessageMapper = {
  supervisor: "Unable to load teams you supervise!",
  supervisee: "Unable to load teams you're in!",
  manager: "Unable to load teams you manage!",
};

export const weeklyKpisLoadDepartmentTeams =
  ({ page_number = 1, variant }) =>
  async (dispatch) => {
    try {
      const queryParams = memberTypeMapper[variant];
      const response = await Api().get(
        `/weekly_kpis/teams/?page=${page_number}&${queryParams}`
      );
      return response.data;
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: errorMessageMapper[variant],
          severity: "error",
        },
      });
      return false;
    }
  };

export const weeklyKpisLoadDepartmentTeam = (id) => async (dispatch) => {
  try {
    const response = await Api().get(`/weekly_kpis/teams/${id}/`);
    dispatch({
      type: WEEKLY_KPIS_LOAD_DEPARTMENT_TEAM,
      payload: response.data,
    });
    return true;
  } catch (error) {
    dispatch({
      type: SNACKBAR_WRITE,
      payload: {
        message: "Unable to load team!",
        severity: "error",
      },
    });
    return false;
  }
};

export const weeklyKpisResetDepartmentTeam = () => async (dispatch) => {
  dispatch({
    type: WEEKLY_KPIS_RESET_DEPARTMENT_TEAM,
  });
};

export const weeklyKpisUpdateActiveEvaluation = (data) => async (dispatch) => {
  try {
    const response = await Api().put(
      `/weekly_kpis/evaluations/${data.id}/?department_team__id=${data.department_team}`,
      data
    );
    dispatch({
      type: WEEKLY_KPIS_UPDATE_ACTIVE_EVALUATION,
      payload: response.data,
    });
    dispatch({
      type: SNACKBAR_WRITE,
      payload: {
        message: "Employee evaluation updated!",
        severity: "success",
      },
    });
    return true;
  } catch (error) {
    dispatch({
      type: SNACKBAR_WRITE,
      payload: {
        message: "Unable to update employee evaluation!",
        severity: "error",
      },
    });
    return false;
  }
};

const statusDispatchMapper = {
  A: WEEKLY_KPIS_LOAD_ACTIVE_EVALUATIONS,
  L: WEEKLY_KPIS_LOAD_PAST_EVALUATIONS,
};

const errorMessageMapper2 = {
  A: "Unable to load active evaluations!",
  L: "Unable to load active evaluations!",
};

export const weeklyKpisLoadEvaluations =
  ({ page_number = 1, filterQuery = "", status }) =>
  async (dispatch) => {
    try {
      const response = await Api().get(
        `/weekly_kpis/evaluations/?page=${page_number}${filterQuery}&status=${status}`
      );
      dispatch({
        type: statusDispatchMapper[status],
        payload: response.data,
      });
      return true;
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: errorMessageMapper2[status],
          severity: "error",
        },
      });
      return false;
    }
  };

export const weeklyKpisCompanyMetric = (data) => async (dispatch) => {
  try {
    const response = await Api().post(
      `/weekly_kpis/evaluations/company_metrics/`,
      data
    );
    return response.data;
  } catch (error) {
    dispatch({
      type: SNACKBAR_WRITE,
      payload: {
        message: "Unable to load department team metrics!",
        severity: "error",
      },
    });
    return false;
  }
};

export const weeklyKpisDepartmentTeamMetric = (data) => async (dispatch) => {
  try {
    const response = await Api().post(
      `/weekly_kpis/evaluations/department_team_metrics/`,
      data
    );
    return response.data;
  } catch (error) {
    dispatch({
      type: SNACKBAR_WRITE,
      payload: {
        message: "Unable to load department team metrics!",
        severity: "error",
      },
    });
    return false;
  }
};

const tagListItems = (list, tag) =>
  list.map((obj) => ({ ...obj, bnakyEngine: tag }));

export const weeklyKpisSearchTags =
  ({ searchQuery = "", AcceptableEngines = [], department_team__id = null }) =>
  async () => {
    const loadEmployees = AcceptableEngines.includes("Employees");
    const loadDepartmentTeams = AcceptableEngines.includes("Department teams");
    const loadDepartments = AcceptableEngines.includes("Departments");

    const searchQueryStr = searchQuery ? `&search=${searchQuery}` : "";
    const departmentTeamStr = department_team__id
      ? `&teams_as_supervisee__id=${department_team__id}`
      : "";

    const [employeesResult, departmentTeamsResult, departmentsResult] =
      await Promise.allSettled([
        loadEmployees &&
          Api().get(
            `/weekly_kpis/employees/?page=1${searchQueryStr}${departmentTeamStr}`
          ),
        loadDepartmentTeams &&
          Api().get(`/weekly_kpis/teams/?page=1${searchQueryStr}`),
        loadDepartments &&
          Api().get(`/weekly_kpis/departments/?page=1${searchQueryStr}`),
      ]);

    let employees = employeesResult?.value?.data?.results || [];
    let departmentTeams = departmentTeamsResult?.value?.data?.results || [];
    let departments = departmentsResult?.value?.data?.results || [];

    return [
      loadDepartments && {
        name: "All departments",
        bnakyEngine: "Departments",
      },
      ...tagListItems(departments, "Departments"),
      ...tagListItems(departmentTeams, "Department teams"),
      ...tagListItems(employees, "Employees"),
    ];
  };

export const weeklyKpisSelectTags = (data) => async (dispatch) => {
  dispatch({
    type: WEEKLY_KPIS_SELECT_TAGS,
    payload: data,
  });
};

export const weeklyKpisSelectDuration = (data) => async (dispatch) => {
  dispatch({
    type: WEEKLY_KPIS_SELECT_DURATION,
    payload: data,
  });
};
