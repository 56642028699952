import React, {useState} from "react";
import {connect} from "react-redux";
import {
  Typography,
  Card,
  Fab,
  CardContent,
  TextField,
  Badge,
  Button,
  IconButton,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Avatar from "@material-ui/core/Avatar";
import ChangePassword from "../../Forms/Account/ChangePassword";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import {
  update_profile_picture,
  update_user_information,
} from "../../../actions/auth";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DividerWithText from "../../../components/DividerWithRightText";
import EditIcon from "@material-ui/icons/Edit";
import TimeOff from "./TimeOff/TimeOff";
import EmergencyContacts from "./EmergencyContacts/EmergencyContacts";
import AccountContent from "./AccountContent";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  largeAvatar: {
    width: theme.spacing(22),
    height: theme.spacing(22),
    position: "absolute",
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 4px 0px, rgb(145 158 171 / 24%) 0px 24px 48px 0px",
  },
  opacityOnHover: {
    display: "flex",
    flexDirection: "column",
    width: theme.spacing(22),
    height: theme.spacing(22),
    opacity: 0,
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 4px 0px, rgb(145 158 171 / 24%) 0px 24px 48px 0px",
    "&:hover": {
      cursor: "pointer",
      opacity: 1,
      transition: "opacity .2s ease",
    },
  },
  largeIcon: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  number: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
      {
        display: "none",
      },
  },
}));

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const MyAccount = ({
                     user,
                     update_profile_picture,
                     update_user_information,
                     processingProfilePictureUpdate,
                   }) => {
  const classes = useStyles();
  const smspace = <div style={{padding: 8, width: "100%"}}/>;

  const [pictureFile, setPictureFile] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const handleUploadClick = (event) => {
    var file = event.target.files[0];
    if (typeof file !== "undefined") {
      setPictureFile(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        setSelectedFile(reader.result);
      };
    }
  };

  const setImageToNull = () => {
    setSelectedFile(null);
  };
  const submitImage = () => {
    setSelectedFile(null);
    update_profile_picture(pictureFile);
  };

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [information, setInformation] = useState({
    phone_number: user.phone_number,
    get_emails: user.get_emails,
  });

  const handleChangeInformation = (prop) => (event) => {
    prop === "get_emails" &&
    setInformation({...information, get_emails: !information.get_emails});
    prop === "phone_number" &&
    event.target.value.length < 16 &&
    setInformation({...information, [prop]: event.target.value});
  };

  const onSubmit = (e) => {
    e.preventDefault();
    update_user_information(
      information.get_emails,
      information.phone_number
    ).then(() => setEdit(false));
  };

  const [edit, setEdit] = useState(false);

  return (
    <div>
      <Typography variant="h3">My Account</Typography>

      <div
        style={{
          margin: "auto",
          textAlign: "center",
          marginTop: 24,
          marginBottom: 24,
        }}
      >
        {selectedFile ? (
          <Badge
            overlap="circle"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            badgeContent={
              <Fab
                onClick={submitImage}
                size="small"
                style={{
                  color: "white",
                  backgroundColor: "#77E0FF",
                  // border: "2px solid white",
                  boxShadow:
                    "rgb(145 158 171 / 24%) 0px 0px 4px 0px, rgb(145 158 171 / 24%) 0px 24px 48px 0px",
                }}
              >
                <CheckIcon/>
              </Fab>
            }
          >
            <Badge
              overlap="circle"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              badgeContent={
                <Fab
                  onClick={setImageToNull}
                  size="small"
                  style={{
                    color: "#77E0FF",
                    backgroundColor: "white",
                    // border: "2px solid #ed7273",
                    boxShadow:
                      "rgb(145 158 171 / 24%) 0px 0px 4px 0px, rgb(145 158 171 / 24%) 0px 24px 48px 0px",
                  }}
                >
                  <CloseIcon/>
                </Fab>
              }
            >
              <label htmlFor="profile_picture" style={{margin: 0}}>
                <Avatar
                  alt={user.full_name}
                  src={selectedFile}
                  className={classes.largeAvatar}
                />
                <Avatar className={classes.opacityOnHover}>
                  <AddPhotoAlternateIcon className={classes.largeIcon}/>
                  <Typography variant="h6">Update Photo</Typography>
                </Avatar>
              </label>
            </Badge>
          </Badge>
        ) : (
          <label htmlFor="profile_picture" style={{margin: 0}}>
            <Avatar
              alt={user.full_name}
              src={!processingProfilePictureUpdate && user.picture}
              className={classes.largeAvatar}
            >
              {processingProfilePictureUpdate ? (
                <CircularProgress style={{color: "white"}}/>
              ) : null}
            </Avatar>
            <Avatar className={classes.opacityOnHover}>
              <AddPhotoAlternateIcon className={classes.largeIcon}/>
              <Typography variant="h6">Update Photo</Typography>
            </Avatar>
          </label>
        )}
      </div>

      <input
        required
        accept="image/*"
        style={{display: "none"}}
        id="profile_picture"
        name="profile_picture"
        type="file"
        onChange={handleUploadClick}
      />

      <AppBar
        position="static"
        elevation={0}
        style={{backgroundColor: "transparent"}}
      >
        <Tabs
          variant="scrollable"
          scrollButtons="off"
          value={value}
          onChange={handleChange}
          style={{
            color: "black",
            backgroundColor: "transparent",
            alignSelf: "center",
            margin: "auto",
            maxWidth: 600,
          }}
          TabIndicatorProps={{
            style: {backgroundColor: "#77E0FF"},
          }}
        >
          <Tab disableRipple label="My information" style={{fontSize: 20}}/>
          <Tab disableRipple label="Time off" style={{fontSize: 20}}/>
          <Tab disableRipple label="Emergency" style={{fontSize: 20}}/>
          <Tab disableRipple label="Change password" style={{fontSize: 20}}/>
        </Tabs>
      </AppBar>
      {smspace}
      <div style={{margin: "auto", maxWidth: 600}}>
        <TabPanel value={value} index={0}>
          <Card elevation={12}>
            <CardContent style={{padding: 24}}>
              <AccountContent user={user} showPhone={false}/>
              <div style={{marginBottom: edit && 20}}>
                <DividerWithText>
                  <Typography
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                    }}
                    color="textSecondary"
                    variant="caption"
                  >
                    <IconButton
                      color="primary"
                      onClick={() => {
                        edit &&
                        setInformation({
                          phone_number: user.phone_number,
                          get_emails: user.get_emails,
                        });
                        setEdit(!edit);
                      }}
                    >
                      <EditIcon/>
                    </IconButton>
                  </Typography>
                </DividerWithText>
              </div>
              {edit ? (
                <form onSubmit={(e) => onSubmit(e)}>
                  <TextField
                    fullWidth
                    required
                    label="Phone number"
                    value={information.phone_number}
                    className={classes.number}
                    onChange={handleChangeInformation("phone_number")}
                    variant="outlined"
                    InputProps={{pattern: "[+][0-9]*"}}
                    placeholder="Include area code: +97466748668"
                  />
                  <FormControlLabel
                    style={{marginBottom: 20}}
                    onChange={handleChangeInformation("get_emails")}
                    control={
                      <Checkbox
                        color="primary"
                        checked={information.get_emails}
                      />
                    }
                    label="Receive emails"
                  />
                  <Button
                    fullWidth
                    color="primary"
                    type="submit"
                    variant="contained"
                  >
                    Save
                  </Button>
                </form>
              ) : (
                <div>
                  <TextField
                    fullWidth
                    label="Phone number"
                    value={user.phone_number}
                    InputProps={{disableUnderline: true, readOnly: true}}
                  />
                  <FormControlLabel
                    style={{marginBottom: 0}}
                    control={
                      <Checkbox
                        style={{paddingTop: 0, paddingBottom: 0}}
                        color="primary"
                        checked={user.get_emails}
                        disabled
                      />
                    }
                    label="Receive emails"
                  />
                </div>
              )}
            </CardContent>
          </Card>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Card elevation={12} style={{margin: "auto", maxWidth: 600}}>
            <CardContent style={{padding: 24}}>
              <TimeOff/>
            </CardContent>
          </Card>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Card elevation={12} style={{margin: "auto", maxWidth: 600}}>
            <CardContent style={{padding: 24}}>
              <EmergencyContacts/>
            </CardContent>
          </Card>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Card elevation={12} style={{margin: "auto", maxWidth: 600}}>
            <CardContent style={{padding: 24}}>
              <ChangePassword/>
            </CardContent>
          </Card>
        </TabPanel>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  processingProfilePictureUpdate: state.auth.processingProfilePictureUpdate,
});

export default connect(mapStateToProps, {
  update_user_information,
  update_profile_picture,
})(MyAccount);
