import {
  CLEAR_UNPAID_FORMDATA,
  CLEAR_PAID_FORMDATA,
  GET_UNPAID_FORMDATA,
  GET_PAID_FORMDATA,
  UPDATE_UNPAID_FORMSTATUS,
  UPDATE_PAID_FORMSTATUS,
  PROCESSING_UNPAID_REQUEST,
  PROCESSING_PAID_REQUEST,
  GET_MARKETING_SUPPLIES_REQUESTS,
} from "../actions/marketingTypes";

const initialState = {
  fetchedPaid: false,
  fetchedUnpaid: false,
  submittingPaid: false,
  submittingUnpaid: false,
  dataPaid: null,
  dataUnpaid: null,
  marketing_supplies_requests: null,
};

export default function marketing_expenses(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_MARKETING_SUPPLIES_REQUESTS:
      return {
        ...state,
        marketing_supplies_requests: payload,
      };

    case GET_UNPAID_FORMDATA:
      return {
        ...state,
        dataUnpaid: payload,
        fetchedUnpaid: true,
      };

    case GET_PAID_FORMDATA:
      return {
        ...state,
        dataPaid: payload,
        fetchedPaid: true,
      };

    case UPDATE_UNPAID_FORMSTATUS:
      return {
        ...state,
        submittingUnpaid: false,
        dataUnpaid: payload.formData,
      };

    case UPDATE_PAID_FORMSTATUS:
      return {
        ...state,
        submittingPaid: false,
        dataPaid: payload.formData,
      };

    case CLEAR_UNPAID_FORMDATA:
      return {
        ...state,
        fetchedUnpaid: false,
        dataUnpaid: null,
      };

    case CLEAR_PAID_FORMDATA:
      return {
        ...state,
        fetchedPaid: false,
        dataPaid: null,
      };

    case PROCESSING_UNPAID_REQUEST:
      return {
        ...state,
        submittingUnpaid: payload,
      };

    case PROCESSING_PAID_REQUEST:
      return {
        ...state,
        submittingPaid: payload,
      };

    default:
      return state;
  }
}
