export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const USER_LOADED_SUCCESS = "USER_LOADED_SUCCESS";
export const USER_LOADED_FAIL = "USER_LOADED_FAIL";

export const AUTHENTICATED_SUCCESS = "AUTHENTICATED_SUCCESS";
export const AUTHENTICATED_FAIL = "AUTHENTICATED_FAIL";
export const LOGOUT = "LOGOUT";

export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_FAIL = "PASSWORD_RESET_FAIL";
export const PASSWORD_RESET_CONFIRM_SUCCESS = "PASSWORD_RESET_CONFIRM_SUCCESS";
export const PASSWORD_RESET_CONFIRM_FAIL = "PASSWORD_RESET_CONFIRM_FAIL";

export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";
export const ACTIVATION_SUCCESS = "ACTIVATION_SUCCESS";
export const ACTIVATION_FAIL = "ACTIVATION_FAIL";

export const EMAIL_NOT_FOUND = "User with given email does not exist.";

export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";
export const LOAD_NOTIFICATION_SUCCESS = "LOAD_NOTIFICATION_SUCCESS";
export const LOAD_NOTIFICATION_FAILURE = "LOAD_NOTIFICATION_FAILURE";
export const MARK_NOTIFICAITON_AS_READ = "MARK_NOTIFICAITON_AS_READ";
export const MARK_NOTIFICATIONS_AS_READ = "MARK_NOTIFICATIONS_AS_READ";

export const SNACKBAR_DELETE = "SNACKBAR_DELETE";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";
export const PROCESSING_PASSWORD_RESET = "PROCESSING_PASSWORD_RESET";
export const PROCESSING_PROFILE_PICTURE_UPDATE =
  "PROCESSING_PROFILE_PICTURE_UPDATE";
export const CHANGE_PROFILE_PICTURE = "CHANGE_PROFILE_PICTURE";

export const INCOMING_NOTIFICATION = "INCOMING_NOTIFICATION";

export const CLEAR_ANALYTICS_TABLE = "CLEAR_ANALYTICS_TABLE";
export const LOAD_ANALYTICS_TABLE_SUCCESS = "LOAD_ANALYTICS_TABLE_SUCCESS";
export const LOAD_ANALYTICS_TABLE_FAILURE = "LOAD_ANALYTICS_TABLE_FAILURE";

export const UPDATE_USER_INFORMATION = "UPDATE_USER_INFORMATION";

export const LOAD_USER_TIME_OFFS_SUCCESS = "LOAD_USER_TIME_OFFS_SUCCESS";
export const LOAD_USER_TIME_OFFS_FAILURE = "LOAD_USER_TIME_OFFS_FAILURE";

export const LOAD_EMERGENCY_CONTACTS = "LOAD_EMERGENCY_CONTACTS";
export const CREATE_EMERGENCY_CONTACT = "CREATE_EMERGENCY_CONTACT";
export const UPDATE_EMERGENCY_CONTACT = "UPDATE_EMERGENCY_CONTACT";
export const DELETE_EMERGENCY_CONTACT = "DELETE_EMERGENCY_CONTACT";
