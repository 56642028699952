import axios from "axios";
import { SNACKBAR_WRITE } from "./procedureType";

import {
  CLEAR_FORMDATA,
  GET_FORMDATA,
  UPDATE_FORM_STATUS,
  PROCESSING,
  PROCESSING_OE_IE,
  FETCHED_ALL_OE,
  NEW_OE_IE,
  DELETE_OE_IE,
  EDIT_OE_IE,
  GET_RCRN,
  CLEAR_RCRN_DATA,
  PROCESSING_RCRN,
  UPDATING_RCRN,
} from "./operationTypes";
import { AUTHENTICATED_FAIL } from "./authTypes";

import global from "../global";
const { API_URL } = global;

export const post_operation_expense_request = (data) => async (dispatch) => {
  dispatch({
    type: PROCESSING,
    payload: true,
  });
  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };
    let formData = new FormData();
    formData.append(
      "payment_start_date",
      new Date(data.payment_start_date).toLocaleDateString("en-GB")
    );
    formData.append(
      "payment_end_date",
      data.payment_end_date !== null &&
        new Date(data.payment_end_date).toLocaleDateString("en-GB")
    );
    data.cash_requested_amount &&
      formData.append("cash_requested_amount", data.cash_requested_amount);
    data.cash_justification &&
      formData.append("cash_justification", data.cash_justification);
    data.credit_requested_amount &&
      formData.append("credit_requested_amount", data.credit_requested_amount);
    data.credit_justification &&
      formData.append("credit_justification", data.credit_justification);

    try {
      const response = await axios.post(
        `${API_URL}/operation_expenses/operation_expense/`,
        formData,
        config
      );
      dispatch({
        type: SNACKBAR_WRITE,
        payload: response.data,
      });
      dispatch({
        type: PROCESSING,
        payload: false,
      });

      return response.data.OERN;
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "An error has occured! Please try again later.",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const get_operation_expense_request = (OERN) => async (dispatch) => {
  dispatch({
    type: CLEAR_FORMDATA,
  });

  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    try {
      const response = await axios.get(
        `${API_URL}/operation_expenses/operation_expense/${OERN}/`,
        config
      );
      dispatch({
        type: GET_FORMDATA,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "Unable to load request",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const update_operation_expense_request_form =
  (data, OERN) => async (dispatch) => {
    dispatch({
      type: PROCESSING,
      payload: true,
    });
    const access = localStorage.getItem("access");
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `JWT ${access}`,
        },
      };

      let formData = new FormData();
      formData.append(
        "payment_start_date",
        new Date(data.payment_start_date).toLocaleDateString("en-GB")
      );
      formData.append(
        "payment_end_date",
        data.payment_end_date !== null &&
          new Date(data.payment_end_date).toLocaleDateString("en-GB")
      );
      data.cash_requested_amount &&
        formData.append("cash_requested_amount", data.cash_requested_amount);
      data.cash_justification &&
        formData.append("cash_justification", data.cash_justification);
      data.credit_requested_amount &&
        formData.append(
          "credit_requested_amount",
          data.credit_requested_amount
        );
      data.credit_justification &&
        formData.append("credit_justification", data.credit_justification);

      try {
        const response = await axios.put(
          `${API_URL}/operation_expenses/operation_expense/${OERN}/`,
          formData,
          config
        );
        dispatch({
          type: UPDATE_FORM_STATUS,
          payload: response.data,
        });
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: response.data.message,
            severity: response.data.severity,
          },
        });
      } catch (error) {
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: "An error has occured! Please try again later.",
            severity: "error",
          },
        });
      }
    } else {
      dispatch({
        type: AUTHENTICATED_FAIL,
      });
    }
  };

export const update_operation_expense_request_approval_status =
  (status, OERN, note, payment_type) => async (dispatch) => {
    const access = localStorage.getItem("access");
    if (localStorage.getItem("access")) {
      dispatch({
        type: PROCESSING,
        payload: true,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `JWT ${access}`,
        },
      };

      const body = JSON.stringify({
        status: status,
        note: note,
        payment_type: payment_type,
      });

      try {
        const response = await axios.post(
          `${API_URL}/operation_expenses/operation_expense/${OERN}/`,
          body,
          config
        );
        dispatch({
          type: UPDATE_FORM_STATUS,
          payload: response.data,
        });
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: response.data.message,
            severity: response.data.severity,
          },
        });
      } catch (error) {
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: "An error has occured! Please try again later.",
            severity: "error",
          },
        });
      }
    } else {
      dispatch({
        type: AUTHENTICATED_FAIL,
      });
    }
  };

export const submit_operation_expense_input_details =
  (payment_type, OERN) => async (dispatch) => {
    dispatch({
      type: PROCESSING,
      payload: true,
    });
    const access = localStorage.getItem("access");
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `JWT ${access}`,
        },
      };

      const body = JSON.stringify({
        status: "to collect",
        payment_type: payment_type,
      });

      try {
        const response = await axios.post(
          `${API_URL}/operation_expenses/operation_expense/${OERN}/`,
          body,
          config
        );
        dispatch({
          type: UPDATE_FORM_STATUS,
          payload: response.data,
        });
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: response.data.message,
            severity: response.data.severity,
          },
        });
      } catch (error) {
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: "An error has occured! Please try again later.",
            severity: "error",
          },
        });
      }
    } else {
      dispatch({
        type: AUTHENTICATED_FAIL,
      });
    }
  };

export const get_unreturned_OERNS = () => async (dispatch) => {
  dispatch({
    type: FETCHED_ALL_OE,
    payload: false,
  });
  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    try {
      const response = await axios.get(
        `${API_URL}/operation_expenses/return_cash_request/`,
        config
      );

      dispatch({
        type: FETCHED_ALL_OE,
        payload: true,
      });

      return response.data;
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "Unable to load unreturned operation expense requests",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
  dispatch({
    type: FETCHED_ALL_OE,
    payload: true,
  });
};

export const get_approved_OERNS = () => async (dispatch) => {
  dispatch({
    type: FETCHED_ALL_OE,
    payload: false,
  });
  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    try {
      const response = await axios.get(
        `${API_URL}/operation_expenses/operation_expense/`,
        config
      );

      dispatch({
        type: FETCHED_ALL_OE,
        payload: true,
      });

      return response.data;
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "Unable to load unreturned operation expense requests",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
  dispatch({
    type: FETCHED_ALL_OE,
    payload: true,
  });
};

export const post_return_cash_request =
  (operation_expense_requests) => async (dispatch) => {
    dispatch({
      type: PROCESSING,
      payload: true,
    });
    const access = localStorage.getItem("access");
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `JWT ${access}`,
        },
      };
      let formData = new FormData();
      formData.append(
        "operation_expense_requests",
        JSON.stringify(operation_expense_requests)
      );

      try {
        const response = await axios.post(
          `${API_URL}/operation_expenses/return_cash_request/`,
          formData,
          config
        );
        dispatch({
          type: SNACKBAR_WRITE,
          payload: response.data,
        });
        dispatch({
          type: PROCESSING,
          payload: false,
        });

        return response.data.RCRN;
      } catch (error) {
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: "An error has occured! Please try again later.",
            severity: "error",
          },
        });
      }
    } else {
      dispatch({
        type: AUTHENTICATED_FAIL,
      });
    }
  };

export const get_RCRN = (RCRN) => async (dispatch) => {
  dispatch({
    type: CLEAR_RCRN_DATA,
  });
  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    try {
      const response = await axios.get(
        `${API_URL}/operation_expenses/return_cash_request/${RCRN}/`,
        config
      );

      dispatch({
        type: GET_RCRN,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "Unable to load return cash request",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const post_input_expense = (OERN, data) => async (dispatch) => {
  dispatch({
    type: PROCESSING_OE_IE,
    payload: true,
  });
  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };
    let formData = new FormData();
    formData.append("category", data.category);
    formData.append("amount_paid", data.amount_paid);
    formData.append("payment_type", data.payment_type);
    formData.append("receipt_image", data.receipt_image);
    formData.append(
      "submission_date",
      new Date(data.submission_date).toISOString()
    );

    try {
      const response = await axios.post(
        `${API_URL}/operation_expenses/input_expense/${OERN}/`,
        formData,
        config
      );
      dispatch({
        type: SNACKBAR_WRITE,
        payload: response.data,
      });
      dispatch({
        type: PROCESSING_OE_IE,
        payload: false,
      });
      dispatch({
        type: NEW_OE_IE,
        payload: response.data.input_expense,
      });
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "An error has occured! Please try again later.",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
  dispatch({
    type: PROCESSING_OE_IE,
    payload: false,
  });
};

export const delete_input_expense =
  (OERN, input_expenses_pks) => async (dispatch) => {
    dispatch({
      type: PROCESSING_OE_IE,
      payload: true,
    });

    const access = localStorage.getItem("access");
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `JWT ${access}`,
        },
        data: { input_expenses_pks: input_expenses_pks },
      };

      try {
        const response = await axios.delete(
          `${API_URL}/operation_expenses/input_expenses/${OERN}/`,
          config
        );
        dispatch({
          type: SNACKBAR_WRITE,
          payload: response.data,
        });
        dispatch({
          type: PROCESSING_OE_IE,
          payload: false,
        });
        dispatch({
          type: DELETE_OE_IE,
          payload: input_expenses_pks,
        });
      } catch (error) {
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: "An error has occured! Please try again later.",
            severity: "error",
          },
        });
      }
    } else {
      dispatch({
        type: AUTHENTICATED_FAIL,
      });
    }
    dispatch({
      type: PROCESSING_OE_IE,
      payload: false,
    });
  };

export const update_input_expense = (OERN, data) => async (dispatch) => {
  dispatch({
    type: PROCESSING_OE_IE,
    payload: true,
  });
  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };
    let formData = new FormData();
    formData.append("id", data.id);
    formData.append("category", data.category);
    formData.append("amount_paid", data.amount_paid);
    formData.append("payment_type", data.payment_type);
    // formData.append("receipt_image", data.receipt_image);
    formData.append(
      "submission_date",
      new Date(data.submission_date).toISOString()
    );

    try {
      const response = await axios.put(
        `${API_URL}/operation_expenses/input_expense/${OERN}/`,
        formData,
        config
      );
      dispatch({
        type: SNACKBAR_WRITE,
        payload: response.data,
      });
      dispatch({
        type: PROCESSING_OE_IE,
        payload: false,
      });
      dispatch({
        type: EDIT_OE_IE,
        payload: response.data.input_expense,
      });
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "An error has occured! Please try again later.",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
  dispatch({
    type: PROCESSING_OE_IE,
    payload: false,
  });
};

export const OE_cash_returned = (OERN, amount_returned) => async (dispatch) => {
  dispatch({
    type: PROCESSING_RCRN,
    payload: true,
  });
  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };
    let formData = new FormData();
    formData.append("OERN", OERN);
    formData.append("amount_returned", amount_returned);

    try {
      const response = await axios.put(
        `${API_URL}/operation_expenses/return_cash_request/`,
        formData,
        config
      );

      dispatch({
        type: SNACKBAR_WRITE,
        payload: response.data,
      });
      dispatch({
        type: PROCESSING_RCRN,
        payload: false,
      });
      dispatch({
        type: UPDATING_RCRN,
        payload: response.data.operation_expense_request,
      });
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "An error has occured! Please try again later.",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
  dispatch({
    type: PROCESSING_RCRN,
    payload: false,
  });
};

export const create_operation_expense_request_status =
  (OERN, data) => async (dispatch) => {
    const access = localStorage.getItem("access");
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `JWT ${access}`,
        },
      };

      try {
        const response = await axios.post(
          `${API_URL}/operation_expenses/oe_accounting_status/${OERN}/`,
          data,
          config
        );

        response.data.formData &&
          dispatch({
            type: UPDATE_FORM_STATUS,
            payload: response.data,
          });
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: response.data.message,
            severity: response.data.severity,
          },
        });
        return true;
      } catch (error) {
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: "An error has occured! Please try again later.",
            severity: "error",
          },
        });
      }
    } else {
      dispatch({
        type: AUTHENTICATED_FAIL,
      });
    }
  };

export const update_operation_expense_request_status =
  (OERN, data) => async (dispatch) => {
    const access = localStorage.getItem("access");
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `JWT ${access}`,
        },
      };

      try {
        const response = await axios.put(
          `${API_URL}/operation_expenses/oe_accounting_status/${OERN}/`,
          data,
          config
        );

        response.data.formData &&
          dispatch({
            type: UPDATE_FORM_STATUS,
            payload: response.data,
          });
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: response.data.message,
            severity: response.data.severity,
          },
        });
        return true;
      } catch (error) {
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: "An error has occured! Please try again later.",
            severity: "error",
          },
        });
      }
    } else {
      dispatch({
        type: AUTHENTICATED_FAIL,
      });
    }
  };

export const delete_operation_expense_request_status =
  (OERN, data) => async (dispatch) => {
    const access = localStorage.getItem("access");
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `JWT ${access}`,
        },
      };

      try {
        const response = await axios.patch(
          `${API_URL}/operation_expenses/oe_accounting_status/${OERN}/`,
          data,
          config
        );

        response.data.formData &&
          dispatch({
            type: UPDATE_FORM_STATUS,
            payload: response.data,
          });
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: response.data.message,
            severity: response.data.severity,
          },
        });
        return true;
      } catch (error) {
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: "An error has occured! Please try again later.",
            severity: "error",
          },
        });
      }
    } else {
      dispatch({
        type: AUTHENTICATED_FAIL,
      });
    }
  };
