import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  update_emergency_contact,
  delete_emergency_contact,
} from "../../../../actions/auth";
import {
  Typography,
  TextField,
  Button,
  IconButton,
  makeStyles,
  Avatar,
  ListItem,
  ListItemText,
  Collapse,
  Grid,
  ListItemIcon,
  Backdrop,
  CircularProgress,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import {
  Add as AddIcon,
  Remove as RemoveIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@material-ui/icons";
import EmergencyContactContent from "./EmergencyContactContent";
import DividerWithText from "../../../../components/DividerWithRightText";
import DraggableDialog from "../../../../components/DraggableDialog";
import DialogTitle from "../../../../components/MuiDialogTitle";

const useStyles = makeStyles((theme) => ({
  padding: {
    paddingTop: 14,
    paddingBottom: 14,
  },
  marginBottom: {
    width: "100%",
    marginBottom: 48,
  },
  yellow: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: "#FBE773",
    color: "black",
    "& > *": {
      height: 16,
    },
  },
  blue: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: "#77E0FF",
    color: "black",
    "& > *": {
      height: 16,
    },
  },
}));

const EmergencyContacts = ({
  editable,
  contact,
  update_emergency_contact,
  delete_emergency_contact,
}) => {
  const classes = useStyles();
  const [formData, setFormData] = useState(contact);
  const [collapse, setCollapse] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [warnDelete, setWarnDelete] = useState(false);

  useEffect(() => {
    if (editMode) {
      setFormData(contact);
    }
  }, [editMode, contact]);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onWarnDelete = () => {
    setWarnDelete(!warnDelete);
  };

  const onDelete = () => {
    setProcessing(true);
    delete_emergency_contact(contact.id).then(() => {
      setProcessing(false);
    });
  };

  const onCollapse = () => {
    setEditMode(false);
    setWarnDelete(false);
    setCollapse(!collapse);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setProcessing(true);
    update_emergency_contact(formData).then((updated) => {
      setProcessing(false);
      updated && setEditMode(false);
    });
  };

  return (
    <div>
      <ListItem button onClick={() => onCollapse()}>
        <ListItemIcon className={classes.padding}>
          {processing ? (
            <CircularProgress size={20} />
          ) : collapse ? (
            <Avatar className={classes.blue}>
              <RemoveIcon />
            </Avatar>
          ) : (
            <Avatar className={classes.yellow}>
              <AddIcon />
            </Avatar>
          )}
        </ListItemIcon>
        <ListItemText
          primary={contact.name}
          //   secondary={contact.relationship}
          primaryTypographyProps={{ variant: "h6" }}
        />
      </ListItem>
      <Collapse in={collapse}>
        <ListItem>
          <ListItemIcon />
          <Backdrop
            style={{ position: "absolute", zIndex: 1 }}
            open={processing}
          >
            <CircularProgress style={{ margin: "auto" }} />
          </Backdrop>
          {editMode ? (
            <form onSubmit={(e) => onSubmit(e)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <DividerWithText>
                    <IconButton
                      color="primary"
                      onClick={() => setWarnDelete(true)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </DividerWithText>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    label="Name"
                    name="name"
                    value={formData.name}
                    onChange={(e) => onChange(e)}
                    fullWidth
                    required
                    style={{ marginBottom: 12 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    label="Relationship"
                    name="relationship"
                    value={formData.relationship}
                    onChange={(e) => onChange(e)}
                    fullWidth
                    required
                    style={{ marginBottom: 12 }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    label="Phone number (with prefix)"
                    name="phone_number"
                    value={formData.phone_number}
                    onChange={(e) => onChange(e)}
                    fullWidth
                    required
                    style={{ marginBottom: 12 }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    label="Email"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={(e) => onChange(e)}
                    fullWidth
                    style={{ marginBottom: 12 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    label="Country of residence"
                    name="country"
                    value={formData.country}
                    onChange={(e) => onChange(e)}
                    fullWidth
                    required
                    style={{ marginBottom: 12 }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setEditMode(false)}
                    fullWidth
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    fullWidth
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </form>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {editable && (
                  <DividerWithText>
                    <IconButton
                      color="secondary"
                      onClick={() => setEditMode(true)}
                    >
                      <EditIcon />
                    </IconButton>
                  </DividerWithText>
                )}
              </Grid>
              <EmergencyContactContent contact={contact} />
            </Grid>
          )}
        </ListItem>
      </Collapse>
      <DraggableDialog open={warnDelete} onClose={onWarnDelete} fullWidth>
        <DialogTitle onClose={onWarnDelete}>
          <Typography variant="h5">Delete emergency contact?</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure that you want to remove <b>{contact.name}</b> from your
            emergency contacts?
          </Typography>
        </DialogContent>

        <DialogActions>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                fullWidth
                color="secondary"
                variant="contained"
                onClick={() => onWarnDelete()}
              >
                No, go back!
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => onDelete()}
                fullWidth
                color="primary"
                variant="contained"
              >
                Yes, I'm sure!
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </DraggableDialog>
    </div>
  );
};

export default connect(null, {
  update_emergency_contact,
  delete_emergency_contact,
})(EmergencyContacts);
