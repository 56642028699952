export const CLEAR_FORMDATA = "CLEAR_OPERATION_EXPENSE_FORMDATA";
export const GET_FORMDATA = "GET_OPERATION_EXPENSE_FORMDATA";
export const PROCESSING = "PROCESSING_OPERATION_EXPENSE_REQUEST";
export const UPDATE_FORM_STATUS = "UPDATE_OPERATION_EXPENSE_FORM_STATUS";

export const PROCESSING_OE_IE = "PROCESSING_OE_IE";
export const FETCHED_ALL_OE = "FETCHED_ALL_OE";
export const NEW_OE_IE = "NEW_OE_IE";
export const EDIT_OE_IE = "EDIT_OE_IE";
export const DELETE_OE_IE = "DELETE_OE_IE";

export const CLEAR_RCRN_DATA = "CLEAR_RCRN_DATA";
export const GET_RCRN = "GET_RCRN";
export const UPDATING_RCRN = "UPDATING_RCRN";
export const PROCESSING_RCRN = "PROCESSING_RCRN";

export const NEW_OE_ACCOUNTING_STATUS = "NEW_OE_ACCOUNTING_STATUS";
export const DELETE_OE_ACCOUNTING_STATUS = "DELETE_OE_ACCOUNTING_STATUS";
export const EDIT_OE_ACCOUNTING_STATUS = "EDIT_OE_ACCOUNTING_STATUS";
