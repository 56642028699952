import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import { FormControl } from "@material-ui/core";
import { edit_webapp } from "../../../actions/procedure";
import { connect } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";

import DialogTitle from "../../../components/MuiDialogTitle";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function EditWebApp({
  open,
  setOpen,
  webapp,
  edit_webapp,
  setWebApp,
  setSelected,
  groups,
}) {
  const handleClose = () => {
    setOpen(false);
    setFormData({
      name: webapp.name,
      manager_groups: webapp.manager_groups,
    });
  };

  const [formData, setFormData] = useState({});

  useEffect(() => {
    setFormData({
      name: webapp.name,
      manager_groups: webapp.manager_groups,
    });
  }, [setFormData, webapp]);

  const handleChange = (prop) => (event) => {
    setFormData({ ...formData, [prop]: event.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    edit_webapp(formData, webapp.id).then((editedWebapp) => {
      if (editedWebapp) {
        setSelected ? setSelected([editedWebapp.id]) : setWebApp(editedWebapp);
      }
    });
    handleClose();
  };

  const handleAccountManagersChange = (manager_groups) => {
    setFormData({
      ...formData,
      manager_groups: manager_groups,
    });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        fullWidth
      >
        <DialogTitle
          style={{ cursor: "move" }}
          id="draggable-dialog-title"
          onClose={handleClose}
        >
          Edit Website / App
        </DialogTitle>
        <form onSubmit={(e) => onSubmit(e)}>
          <DialogContent>
            <FormControl fullWidth required>
              <TextField
                onChange={handleChange("name")}
                value={formData.name}
                autoFocus
                variant="outlined"
                label="Name *"
                type="text"
              />
            </FormControl>
            <Autocomplete
              multiple
              id="tags-outlined"
              options={groups}
              getOptionLabel={(option) => option}
              value={formData.manager_groups}
              onChange={(event, newValue) => {
                handleAccountManagersChange(newValue);
              }}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Website / App account managers"
                  placeholder="Search for groups"
                />
              )}
            />
          </DialogContent>
          <DialogActions style={{ padding: 24 }}>
            <Button
              onClick={handleClose}
              fullWidth
              color="primary"
              variant="outlined"
              style={{ borderRadius: 24 }}
            >
              Cancel
            </Button>
            <Button
              disabled={
                formData.name === webapp.name &&
                JSON.stringify(formData.manager_groups.sort()) ===
                  JSON.stringify(webapp.manager_groups.sort())
              }
              type="submit"
              fullWidth
              color="primary"
              variant="contained"
              style={{ borderRadius: 24 }}
            >
              Edit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  groups: state.procedures.groups,
});

export default connect(mapStateToProps, { edit_webapp })(EditWebApp);
