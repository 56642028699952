import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
// import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";

import Suppliers from "./Suppliers";
import Products from "./Products";

import {
  get_suppliers,
  get_supplier_products,
  clear_supplier_products,
} from "../../../actions/procedure";

import Poppup from "../../../components/Poppup";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
  },
}));

const SuppliersForm = ({
  get_suppliers,
  suppliers,
  get_supplier_products,
  clear_supplier_products,
}) => {
  const classes = useStyles();

  const [supplier, setSupplier] = useState(null);

  useEffect(() => {
    get_suppliers();
  }, [get_suppliers]);

  const handleChange = (supplier) => {
    if (supplier !== null) {
      get_supplier_products(supplier.id);
      setSupplier(supplier);
    } else {
      clear_supplier_products();
      setSupplier(null);
    }
  };

  return (
    <div className={classes.paper}>
      <Typography style={{ marginBottom: 40 }} component="h1" variant="h4">
        Suppliers
        <Poppup
          content={
            <Typography variant="body1">
              This page allows you to add and edit suppliers, and products that
              belong to these suppliers. You can always also add suppliers from
              the inventory stocking request, but you can only duplicate
              products across suppliers from this page.
            </Typography>
          }
        />
      </Typography>
      <Suppliers />
      <Typography
        style={{ marginTop: 40, marginBottom: 40 }}
        component="h1"
        variant="h4"
      >
        Products
      </Typography>
      <Autocomplete
        id="combo-box-demo"
        // style={{ marginBottom: 20 }}
        options={suppliers}
        getOptionLabel={(option) => `${option.name} (${option.supplier_type})`}
        value={supplier}
        getOptionSelected={(option, value) =>
          option.id === value.id ? option : null
        }
        onChange={(event, newValue) => {
          handleChange(newValue);
        }}
        fullWidth
        renderInput={(params) => (
          <TextField
            {...params}
            InputLabelProps={{ shrink: true }}
            label="Supplier"
            variant="outlined"
            placeholder="Search for supplier"
          />
        )}
      />
      <Products supplier={supplier} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  suppliers: state.procedures.suppliers,
});

export default connect(mapStateToProps, {
  get_suppliers,
  get_supplier_products,
  clear_supplier_products,
})(SuppliersForm);
