import React, {useState, Fragment} from "react";
import {connect} from "react-redux";
import {NavLink} from "react-router-dom";
import {
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  Collapse,
  CardActionArea,
  IconButton,
  Typography,
  Avatar,
  makeStyles,
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import {logout} from "../../actions/auth";

const useStyles = makeStyles((theme) => ({
  drawerContainer: {
    overflow: "auto",
  },
  listitem: {
    height: 0,
    padding: 32,
    paddingRight: 16,
    color: "#333",
  },
  active: {
    height: 0,
    color: "#77E0FF",
    fontWeight: "bold",
    borderRight: "4px solid #77E0FF",
  },
  activeAccount: {
    color: "#77E0FF",
    fontWeight: "bold",
  },
}));

const Subdirectory = ({parentDirectory, handleNavDrawerClick}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  return (
    <Fragment>
      <ListItem
        button
        className={classes.listitem}
        onClick={() => setOpen(!open)}
      >
        <ListItemText primary={parentDirectory.title}/>
        <IconButton>{open ? <ExpandMore/> : <ExpandLess/>}</IconButton>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List disablePadding>
          {parentDirectory.paths.map(
            (directory) =>
              directory &&
              directory.map((subdirectory, index) => (
                <ListItem
                  style={{paddingLeft: 48}}
                  key={index}
                  button
                  disabled={subdirectory.isDisabled}
                  onClick={handleNavDrawerClick}
                  component={NavLink}
                  to={subdirectory.href}
                  className={classes.listitem}
                  activeClassName={classes.active}
                >
                  <div>
                    <div>{subdirectory.title}</div>
                    <Typography variant="subtitle2">
                      {subdirectory.subtitle}
                    </Typography>
                  </div>
                </ListItem>
              ))
          )}
        </List>
      </Collapse>
    </Fragment>
  );
};

const NavDrawer = ({handleNavDrawerClick, current_user, logout}) => {
  const classes = useStyles();

  const directories = (groups) => {
    return [
      {
        title: "Home",
        href: "/app/Home",
      },
      {
        title: "Introduction",
        href: "/app/introduction",
      },
      {
        title: "Mission & Vision",
        href: "/app/mission&vision",
      },
      {
        title: "Strategy",
        href: "/app/strategy",
      },
      {
        title: "Culture",
        href: "/app/culture",
      },
      {
        title: "Policies",
        href: "/app/policies",
      },
      {
        title: "Aveyators",
        paths: [
          [
            {
              href: "/app/aveyators",
              title: "Aveyators",
            },
            {
              href: "/app/mailing-lists",
              title: "Mailing lists",
            },
          ],
        ],
      },
      {
        title: "Departments",
        paths: [
          [
            {
              href: "/app/departments/design",
              title: "Design",
            },
            {
              href: "/app/departments/engineering",
              title: "Engineering",
            },
            {
              href: "/app/departments/finance",
              title: "Finance",
            },
            {
              href: "/app/departments/health-content",
              title: "Health Content",
            },
            {
              href: "/app/departments/human-resources",
              title: "Human Resources",
            },
            {
              href: "/app/departments/marketing",
              title: "Marketing",
            },
            {
              href: "/app/departments/operations",
              title: "Operations",
            },
            {
              href: "/app/departments/research",
              title: "Research",
            },
          ],
        ],
      },
      {
        title: "Procedures",
        paths: [
          ["procurement"].some((group) => groups.includes(group)) && [
            {
              href: "/app/forms/procurement",
              title: "Procurement",
            },
          ],
          ["reimbursement"].some((group) => groups.includes(group)) && [
            {
              href: "/app/forms/reimbursement",
              title: "Reimbursement",
            },
          ],
          ["marketing"].some((group) => groups.includes(group)) && [
            {
              href: "/app/forms/marketing-expenses",
              title: "Marketing Expenses",
            },
          ],
          ["operations"].some((group) => groups.includes(group)) && [
            {
              href: "/app/forms/operation-expenses",
              title: "Operation Expenses",
              subtitle: "Coming Soon",
            },
          ],
          ["personal"].some((group) => groups.includes(group)) && [
            {
              href: "/app/forms/personal",
              title: "Personal",
            },
            // {
            //   href: "/app/forms/payments",
            //   title: "Payments",
            //   subtitle: "Coming Soon",
            // },
          ],
        ],
      },
      ["accounting"].some((group) => groups.includes(group)) && {
        title: "Analytics Table",
        href: "/app/analytics-table",
      },
      ["performance"].some((group) => groups.includes(group)) && {
        title: "Performance evaluation",
        href: "/app/forms/department-teams/your-teams",
      },
      current_user.perspectives &&
      current_user.perspectives.length > 0 && {
        title: "Analytics",
        paths: [
          current_user.perspectives.map((pers) => {
            return {
              href:
                "/app/analytics/" + pers.name.toLowerCase().replace(" ", "_"),
              title: pers.name,
            };
          }),
        ],
      },
    ];
  };

  return (
    <div className={classes.drawerContainer}>
      <List disablePadding>
        <ListSubheader
          style={{
            padding: 20,
            backgroundColor: "white",
          }}
        >
          <CardActionArea
            elevation={0}
            style={{
              padding: 12,
              borderRadius: 10,
              textDecoration: "none",
            }}
            component={NavLink}
            onClick={handleNavDrawerClick}
            to="/app/account"
          >
            <div style={{display: "flex"}}>
              <Avatar
                alt={current_user.full_name}
                src={current_user.picture}
                style={{
                  margin: "auto",
                  marginRight: 16,
                  marginLeft: 0,
                }}
              />
              <div>
                <Typography
                  style={{
                    color: "#333333",
                    fontWeight: "bold",
                  }}
                >
                  {current_user.full_name}
                </Typography>
                <Typography style={{color: "#333333", fontSize: 14}}>
                  {current_user.job_title}
                </Typography>
              </div>
            </div>
          </CardActionArea>
        </ListSubheader>
        {directories(current_user.groups).map(
          (parentDirectory, index) =>
            parentDirectory &&
            (parentDirectory.href ? (
              <ListItem
                key={index}
                disabled={parentDirectory.isDisabled}
                button
                onClick={handleNavDrawerClick}
                component={NavLink}
                to={parentDirectory.href}
                className={classes.listitem}
                activeClassName={classes.active}
              >
                <div>
                  <div>{parentDirectory.title}</div>
                  <Typography variant="subtitle2">
                    {parentDirectory.subtitle}
                  </Typography>
                </div>
              </ListItem>
            ) : (
              <Subdirectory
                key={index}
                parentDirectory={parentDirectory}
                handleNavDrawerClick={handleNavDrawerClick}
              />
            ))
        )}
        <ListItem
          button
          component={NavLink}
          to="/app/account"
          onClick={handleNavDrawerClick}
          className={classes.listitem}
          activeClassName={classes.activeAccount}
          style={{marginTop: 64}}
        >
          My Account
        </ListItem>
        <ListItem
          button
          onClick={logout}
          className={classes.listitem}
          style={{
            color: "black",
            fontWeight: "bold",
          }}
        >
          Logout
        </ListItem>
        <ListItem
          style={{
            padding: 32,
            paddingBottom: 32,
            fontSize: 12,
            color: "#949494",
          }}
        >
          {`Copyright © ${new Date().getFullYear()} Avey`}
        </ListItem>
      </List>
    </div>
  );
};

const mapStateToProps = (state) => ({
  current_user: state.auth.user,
});

export default connect(mapStateToProps, {logout})(NavDrawer);
