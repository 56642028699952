import React, { useRef } from "react";
import { Dialog } from "@material-ui/core";
import Draggable from "react-draggable";

const DraggableDialog = (props) => {
  const { children, handleClick, open, ...other } = props;
  const nodeRef = useRef(null);

  return (
    <Draggable
      nodeRef={nodeRef}
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Dialog ref={nodeRef} open={open} onClose={handleClick} {...other}>
        {children}
      </Dialog>
    </Draggable>
  );
};

export default DraggableDialog;
