export const BIRTHDAYS_SUCCESS = "BIRTHDAYS_SUCCESS";
export const BIRTHDAYS_FAILURE = "BIRTHDAYS_FAILURE";

export const NEW_TO_RIMADS_SUCCESS = "NEW_TO_RIMADS_SUCCESS";
export const NEW_TO_RIMADS_FAILURE = "NEW_TO_RIMADS_FAILURE";

export const UPCOMING_EVENTS_SUCCESS = "UPCOMING_EVENTS_SUCCESS";
export const UPCOMING_EVENTS_FAILURE = "UPCOMING_EVENTS_FAILURE";

export const WHOS_OUT_SUCCESS = "WHOS_OUT_SUCCESS";
export const WHOS_OUT_FAILURE = "WHOS_OUT_FAILURE";

export const ANNIVERSARIES_SUCCESS = "ANNIVERSARIES_SUCCESS";
export const ANNIVERSARIES_FAILURE = "ANNIVERSARIES_FAILURE";
