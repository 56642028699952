import React from "react";
import {Card, Typography} from "@material-ui/core";
import mascot from "../../the-original-mascot.jpg";

const MissionAndVision = () => {
  const mdspace = <div style={{padding: 24, width: "100%"}}/>;
  const smspace = <div style={{padding: 8, width: "100%"}}/>;

  return (
    <div style={{maxWidth: 600}}>
      <Typography variant="h3">Mission & Vision</Typography>
      {mdspace}
      <Typography variant="body1">
        When the founder’s six-year-old son suddenly fell sick with a serious
        chronic condition, Avey was born.
      </Typography>
      {smspace}
      <Typography variant="body1">
        Here is the original mascot (Avey) which was drawn by the founder’s son.
        The son drew this hoping for a hero with a cure.
      </Typography>
      {mdspace}
      <Card elevation={8}>
        <img
          src={mascot}
          alt="mascot"
          height="100%"
          width="100%"
          style={{transition: "opacity 400ms ease 0ms"}}
        />
      </Card>
      {mdspace}
      <Typography variant="h6">Our mission</Typography>
      {smspace}
      <Typography variant="body1">
        To reshape healthcare into a safer, faster, and widely accessible
        ecosystem via the endless means of technology.
      </Typography>
      {mdspace}
      <Typography variant="h6">Our vision</Typography>
      {smspace}
      <Typography variant="body1">
        To offer the world an innovative healthcare solution that changes lives.
      </Typography>
      {mdspace}
      <Typography variant="h6">The Avey ecosystem</Typography>
      {smspace}
      <Typography variant="body1">
        <b style={{backgroundColor: "#FBE773"}}>Assess:</b> When a person
        feels unwell, they can talk to Avey, a medical AI-based diagnostic
        engine to assess their case immediately & accurately.
      </Typography>
      {smspace}
      <Typography variant="body1">
        <b style={{backgroundColor: "#FBE773"}}>Connect:</b> When a person
        needs a doctor, they can ask Avey, who constantly learns about doctors
        and can connect them quickly with the best based on their case.
      </Typography>
      {smspace}
      <Typography variant="body1">
        <b style={{backgroundColor: "#FBE773"}}>Cure:</b> When a person needs
        medicine, they can get it at a click of a button through the Avey
        health & wellness e-commerce platform.
      </Typography>
      {smspace}
      <Typography variant="body1">
        To this end, medical assessment becomes safe & instant, identifying and
        seeing the right doctor for your case become easy & quick, and obtaining
        medicine becomes convenient & fast.
      </Typography>
    </div>
  );
};

export default MissionAndVision;
