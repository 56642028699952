import React from "react";
import { Grid, Typography } from "@material-ui/core";
import CardLink from "../../components/CardLink";

const MarketingExpenseDirectory = () => {
  return (
    <div style={{ width: 655 }}>
      <Typography variant="h3" style={{ paddingBottom: 48 }}>
        Marketing Expenses
      </Typography>
      <Typography variant="h6" style={{ marginBottom: 36 }}>
        The purpose of this module is to manage all marketing requests (barring
        marketing supplies requests, which are present in the procurement
        module). 
      </Typography>
      <Grid
        container
        spacing={4}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid item>
          <CardLink
            title={"Paid Marketing"}
            link="/app/forms/marketing-expenses/paid-form"
          />
        </Grid>
        <Grid item>
          <CardLink
            title={"Unpaid Marketing"}
            link="/app/forms/marketing-expenses/unpaid-form"
          />
        </Grid>
        <Grid item>
          <CardLink
            title={"Marketing Supplies Requests"}
            link="/app/forms/procurement/form"
          />
        </Grid>
        <Grid item>
          <CardLink
            title={"Social Media Accounts"}
            link="/app/records/webapps"
          />
        </Grid>
        <Grid item>
          <CardLink title={"Suppliers"} link="/app/records/suppliers" />
        </Grid>
      </Grid>
    </div>
  );
};

export default MarketingExpenseDirectory;
