import axios from "axios";
import {
  BIRTHDAYS_SUCCESS,
  BIRTHDAYS_FAILURE,
  NEW_TO_RIMADS_SUCCESS,
  NEW_TO_RIMADS_FAILURE,
  UPCOMING_EVENTS_SUCCESS,
  UPCOMING_EVENTS_FAILURE,
  WHOS_OUT_SUCCESS,
  WHOS_OUT_FAILURE,
  ANNIVERSARIES_SUCCESS,
  ANNIVERSARIES_FAILURE,
} from "./homeTypes";

import {
  BIRTHDAYS_CALENDAR_SUCCESS,
  BIRTHDAYS_CALENDAR_FAILURE,
  NEW_TO_RIMADS_CALENDAR_SUCCESS,
  NEW_TO_RIMADS_CALENDAR_FAILURE,
  UPCOMING_EVENTS_CALENDAR_SUCCESS,
  UPCOMING_EVENTS_CALENDAR_FAILURE,
  WHOS_OUT_CALENDAR_SUCCESS,
  WHOS_OUT_CALENDAR_FAILURE,
} from "../actions/calendarTypes";

import { AUTHENTICATED_FAIL } from "./authTypes";

import { SNACKBAR_WRITE } from "./procedureType";

import global from "../global";
const { API_URL } = global;

export const load_anniversaries = (data) => async (dispatch) => {
  const access = localStorage.getItem("access");
  if (access) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    try {
      const response = await axios.get(
        `${API_URL}/accounts/rimadsters/?joined_at__month__gte=${data.start}&joined_at__month__lte=${data.end}&joined_at__day__gte=${data.day}`,
        config
      );

      dispatch({
        type: ANNIVERSARIES_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: ANNIVERSARIES_FAILURE,
      });

      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "Unable to load anniversaries!",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const load_new_to_rimads = (data, isCalendar) => async (dispatch) => {
  const access = localStorage.getItem("access");
  if (access) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    try {
      const response = isCalendar
        ? await axios.get(
            `${API_URL}/accounts/rimadsters/?${
              data.start < data.end && `joined_at__month__gte=${data.start}`
            }&joined_at__month__lte=${data.end}`,
            config
          )
        : await axios.get(
            `${API_URL}/accounts/rimadsters/?joined_at__gte=${data.start}&joined_at__lte=${data.end}`,
            config
          );

      isCalendar
        ? dispatch({
            type: NEW_TO_RIMADS_CALENDAR_SUCCESS,
            payload: response.data,
          })
        : dispatch({
            type: NEW_TO_RIMADS_SUCCESS,
            payload: response.data,
          });
    } catch (error) {
      isCalendar
        ? dispatch({
            type: NEW_TO_RIMADS_CALENDAR_FAILURE,
          })
        : dispatch({
            type: NEW_TO_RIMADS_FAILURE,
          });

      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "Unable to load new employees!",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const load_birthdays = (data, isCalendar) => async (dispatch) => {
  const access = localStorage.getItem("access");
  if (access) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    try {
      const response = isCalendar
        ? await axios.get(
            `${API_URL}/accounts/rimadsters/?${
              data.start < data.end && `dob__month__gte=${data.start}`
            }&dob__month__lte=${data.end}`,
            config
          )
        : await axios.get(
            `${API_URL}/accounts/rimadsters/?${
              data.start <= data.end && `dob__month__gte=${data.start}`
            }&dob__month__lte=${data.end}&dob__day__gte=${data.day}`,
            config
          );

      // const response = await axios.get(
      //   `${API_URL}/accounts/rimadsters/?dob__month=${month}`,
      //   config
      // );

      isCalendar
        ? dispatch({
            type: BIRTHDAYS_CALENDAR_SUCCESS,
            payload: response.data,
          })
        : dispatch({
            type: BIRTHDAYS_SUCCESS,
            payload: response.data,
          });
    } catch (error) {
      isCalendar
        ? dispatch({
            type: BIRTHDAYS_CALENDAR_FAILURE,
          })
        : dispatch({
            type: BIRTHDAYS_FAILURE,
          });

      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "Unable to load employee birthdays!",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const load_upcoming_events = (data, isCalendar) => async (dispatch) => {
  const access = localStorage.getItem("access");
  if (access) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    try {
      const response = await axios.get(
        `${API_URL}/miscellaneous/events/?date_end__gte=${data.start}&date_start__lte=${data.end}`,
        config
      );
      isCalendar
        ? dispatch({
            type: UPCOMING_EVENTS_CALENDAR_SUCCESS,
            payload: response.data,
          })
        : dispatch({
            type: UPCOMING_EVENTS_SUCCESS,
            payload: response.data,
          });
    } catch (error) {
      isCalendar
        ? dispatch({
            type: UPCOMING_EVENTS_CALENDAR_FAILURE,
          })
        : dispatch({
            type: UPCOMING_EVENTS_FAILURE,
          });

      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "Unable to load upcoming events!",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const load_whos_out = (data, isCalendar) => async (dispatch) => {
  const access = localStorage.getItem("access");
  if (access) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    try {
      const response = await axios.get(
        `${API_URL}/miscellaneous/time_offs/?date_end__gte=${data.start}&date_start__lte=${data.end}&approval_status__status=approved`,
        config
      );

      isCalendar
        ? dispatch({
            type: WHOS_OUT_CALENDAR_SUCCESS,
            payload: response.data,
          })
        : dispatch({
            type: WHOS_OUT_SUCCESS,
            payload: response.data,
          });
    } catch (error) {
      isCalendar
        ? dispatch({
            type: WHOS_OUT_CALENDAR_FAILURE,
          })
        : dispatch({
            type: WHOS_OUT_FAILURE,
          });
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "Unable to load employees time off!",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};
