AveyLogo.displayName = "AveyLogo";

const mapFillToHex = {
  primary: "#77E0FF",
  secondary: "#ED7273",
  tertiary: "#FFF",
};

export default function AveyLogo({
  fill = "primary", // secondary, tertiary
  ...other
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 471 196"
      height="40px"
      {...other}
    >
      <path
        d="M82.423 61.785c-6.649-10.333-19.887-16.203-33.574-16.203C20.606 45.581 0 67.45 0 97.01c0 30.381 21.384 51.574 48.13 51.574a40.946 40.946 0 0 0 19.49-4.619 40.005 40.005 0 0 0 14.803-13.257v15.293h24.799V48.165h-24.8v13.62zM53.31 125.952a29.294 29.294 0 0 1-11.074-2.325 28.821 28.821 0 0 1-9.304-6.328 28.177 28.177 0 0 1-6.097-9.355 27.734 27.734 0 0 1-1.947-10.934 27.536 27.536 0 0 1 1.93-10.857 27.97 27.97 0 0 1 6.052-9.287 28.622 28.622 0 0 1 9.237-6.284 29.098 29.098 0 0 1 10.994-2.31c7.643.21 14.903 3.328 20.244 8.692 5.34 5.364 8.341 12.554 8.368 20.049.028 7.494-2.921 14.705-8.222 20.106-5.302 5.401-12.539 8.569-20.18 8.833zM182.307 115.239l-26.506-67.074h-26.746l41.721 97.896h22.672l42.14-97.896h-26.955l-26.326 67.074zM301.029 45.581c-28.662 0-52.412 20.137-52.412 51.37 0 30.616 22.702 51.575 52.412 51.575 18.18 0 35.941-7.985 45.135-22.221l-16.712-12.329a33.042 33.042 0 0 1-11.949 9.439 33.73 33.73 0 0 1-15.006 3.154c-14.975 0-25.667-7.339-28.453-20.959h77.451c.21-3.581.42-6.722.42-9.452.03-30.587-22.463-50.577-50.886-50.577zm-26.955 42.975c2.995-14.883 13.927-21.37 27.165-21.37 13.927 0 24.619 7.955 26.117 21.37h-53.282zM443.263 48.165l-25.877 63.933-26.087-63.933h-26.116l38.755 92.23-5.78 14.266c-4.912 11.536-9.195 18.669-20.306 18.669a39.646 39.646 0 0 1-11.351-1.262v21.164a46.102 46.102 0 0 0 13.268 2.084c21.174 0 31.028-9.217 40.642-31.438l48.968-115.713h-26.116z"
        fill={mapFillToHex[fill]}
      />
    </svg>
  );
}
