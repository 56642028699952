import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { Toolbar, Container, makeStyles } from "@material-ui/core";

import PermissionDenied from "../PermissionDenied";
import Home from "./Home";
import Account from "../../containers/StaticPages/MyAccount/Account";

import ReimbursementRequest from "../../containers/Forms/Reimbursement/Form";
import ReimbursementView from "../../containers/Forms/Reimbursement/View";

import ProcurementRequest from "../../containers/Forms/Procurement/Form";
import ProcurementView from "../../containers/Forms/Procurement/View";

import SupplierForm from "../../containers/Forms/Suppliers/Form";
import WebAppForm from "../../containers/Forms/WebApps/Form";

import UnpaidCampaignRequest from "../../containers/Forms/MarketingExpenses/Unpaid/UnpaidForm";
import UnpaidCampaignView from "../../containers/Forms/MarketingExpenses/Unpaid/View";

import MarketingExpenseRequest from "../../containers/Forms/MarketingExpenses/Paid/PaidForm";
import MarketingExpenseView from "../../containers/Forms/MarketingExpenses/Paid/View";

import OperationExpenseRequest from "../../containers/Forms/OperationExpenses/OperationExpense/Form";
import OperationExpenseView from "../../containers/Forms/OperationExpenses/OperationExpense/View";

import ReturnCashRequest from "../../containers/Forms/OperationExpenses/ReturnCash/Form";
import ReturnCashView from "../../containers/Forms/OperationExpenses/ReturnCash/View";

import ReturnCashRequestProcurement from "../../containers/Forms/Procurement/ReturnCash/Form";
import ReturnCashViewProcurement from "../../containers/Forms/Procurement/ReturnCash/View";

import OEInputExpense from "../../containers/Forms/OperationExpenses/OEInputExpense/View";

import TimeOffRequest from "../../containers/Forms/Personal/TimeOff/Form";
import TimeOffView from "../../containers/Forms/Personal/TimeOff/View";

import AnalyticsTable from "../../containers/Forms/Analytics/View";

import Introduction from "../../containers/StaticPages/Introduction";
import MissionAndVision from "../../containers/StaticPages/MissionAndVision";
import Strategy from "../../containers/StaticPages/Strategy";
import Culture from "../../containers/StaticPages/Culture";
import Policies from "../../containers/StaticPages/Policies";
import Rimadsters from "../../containers/StaticPages/Rimadsters";

// import Design from "../../containers/StaticPages/Departments/Design";
// import Engineering from "../../containers/StaticPages/Departments/Engineering";
// import HealthContent from "../../containers/StaticPages/Departments/HealthContent";
// import HumanResources from "../../containers/StaticPages/Departments/HumanResources";
// import Finance from "../../containers/StaticPages/Departments/Finance";
// import Marketing from "../../containers/StaticPages/Departments/Marketing";
// import Operations from "../../containers/StaticPages/Departments/Operations";
// import Research from "../../containers/StaticPages/Departments/Research";

import ProcurementDirectory from "../../containers/Directories/ProcurementDirectory";
import ReimbursementDirectory from "../../containers/Directories/ReimbursementDirectory";
import MarketingExpenseDirectory from "../../containers/Directories/MarketingExpenseDirectory";
import RecordDirectory from "../../containers/Directories/RecordDirectory";
import OperationExpensesDirectory from "../../containers/Directories/OperationExpensesDirectory";
import PaymentsDirectory from "../../containers/Directories/PaymentsDirectory";
import PersonalDirectory from "../../containers/Directories/PersonalDirectory";
import AnalyticsDirectory from "../../containers/Directories/AnalyticsDirectory";
import Perspective from "../../containers/Analytics/Perspective";

import DepartmentTemplate from "../../containers/StaticPages/Departments/Template";
import WeeklyKpisRoutes from "../WeeklyKpis/WeeklyKpisRoutes";
import MailingLists from "../../containers/StaticPages/MailingList/MailingLists";

const useStyles = makeStyles((theme) => ({
  containerWidth: {
    maxWidth: "100%",
    [theme.breakpoints.up("md")]: {
      maxWidth: 920,
    },
  },
  toolbar: {
    [theme.breakpoints.up("xs")]: {
      paddingBottom: 90,
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 32,
      paddingRight: 32,
    },
  },
}));

const NavRoutes = ({ groups, perspectives, navbarHeight }) => {
  const classes = useStyles();
  const perspectiveNames = perspectives
    ? perspectives.map((per) => per.name)
    : [];

  return (
    <div>
      <Toolbar
        className={classes.toolbar}
        style={{ minHeight: navbarHeight }}
      />
      <Container className={classes.containerWidth}>
        <Route exact path={[`/app`, ``]}>
          <Redirect to="/app/home" />
        </Route>
        <Route exact path={[`/app/home`]} component={Home} />
        <Route exact path={[`/app/account`]} component={Account} />
        <Route exact path={[`/app/introduction`]} component={Introduction} />
        <Route
          exact
          path={[`/app/mission&vision`]}
          component={MissionAndVision}
        />
        <Route exact path={[`/app/strategy`]} component={Strategy} />
        <Route exact path={[`/app/culture`]} component={Culture} />
        <Route exact path={[`/app/policies`]} component={Policies} />

        {/* Departments */}
        <Route
          exact
          path={[`/app/departments/:identifier`]}
          component={DepartmentTemplate}
        />
        {/* <Route exact path={[`/app/departments/design`]} component={Design} />
        <Route exact path={[`/app/departments/finance`]} component={Finance} />
        <Route
          exact
          path={[`/app/departments/marketing`]}
          component={Marketing}
        />
        <Route
          exact
          path={[`/app/departments/operations`]}
          component={Operations}
        />
        <Route
          exact
          path={[`/app/departments/research`]}
          component={Research}
        />
        <Route
          exact
          path={[`/app/departments/health-content`]}
          component={HealthContent}
        />
        <Route
          exact
          path={[`/app/departments/human-resources`]}
          component={HumanResources}
        />
        <Route
          exact
          path={[`/app/departments/engineering`]}
          component={Engineering}
        /> */}
        {/* */}

        <Route exact path={[`/app/aveyators`]} component={Rimadsters} />

        <Route exact path={[`/app/mailing-lists`]} component={MailingLists} />

        {/* WeeklyKpis */}
        <Route
          path={[`/app/forms/department-teams`, `/app/forms/team-performance`]}
          component={WeeklyKpisRoutes}
        />

        {/* Analytics*/}

        <Route
          exact
          path={[`/app/analytics-table`]}
          component={
            ["accounting"].some((group) => groups.includes(group))
              ? AnalyticsTable
              : PermissionDenied
          }
        />
        {/* Personal */}
        <Route
          exact
          path={[`/app/forms/personal`]}
          component={
            ["personal"].some((group) => groups.includes(group))
              ? PersonalDirectory
              : PermissionDenied
          }
        />
        <Route
          exact
          path={[`/app/forms/personal/time-off`]}
          component={
            ["personal"].some((group) => groups.includes(group))
              ? TimeOffRequest
              : PermissionDenied
          }
        />
        <Route
          exact
          path={[`/app/forms/personal/time-off/:TMO`]}
          component={
            ["personal"].some((group) => groups.includes(group))
              ? TimeOffView
              : PermissionDenied
          }
        />
        <Route
          exact
          path={[`/app/analytics/`]}
          component={AnalyticsDirectory}
        />
        <Route
          exact
          path={`/app/analytics/procurement/`}
          render={() =>
            perspectiveNames.includes("Procurement") ? (
              <Perspective name="Procurement" />
            ) : (
              <PermissionDenied />
            )
          }
        />
        <Route
          exact
          path={`/app/analytics/reimbursement/`}
          render={() =>
            perspectiveNames.includes("Reimbursement") ? (
              <Perspective name="Reimbursement" />
            ) : (
              <PermissionDenied />
            )
          }
        />
        <Route
          exact
          path={`/app/analytics/marketing_expenses/`}
          render={() =>
            perspectiveNames.includes("Marketing Expenses") ? (
              <Perspective name="Marketing Expenses" />
            ) : (
              <PermissionDenied />
            )
          }
        />
        <Route
          exact
          path={`/app/analytics/operations/`}
          render={() =>
            perspectiveNames.includes("Operations") ? (
              <Perspective name="Operations" />
            ) : (
              <PermissionDenied />
            )
          }
        />
        <Route
          exact
          path={`/app/analytics/performance/`}
          render={() =>
            perspectiveNames.includes("Performance") ? (
              <Perspective name="Performance" />
            ) : (
              <PermissionDenied />
            )
          }
        />

        {/* Payments */}
        <Route
          exact
          path={[`/app/forms/payments`]}
          component={
            ["personal"].some((group) => groups.includes(group))
              ? PaymentsDirectory
              : PermissionDenied
          }
        />
        {/* Records */}
        <Route
          exact
          path={[`/app/records`]}
          component={
            ["procurement", "marketing"].some((group) => groups.includes(group))
              ? RecordDirectory
              : PermissionDenied
          }
        />
        <Route
          exact
          path={[`/app/records/suppliers`]}
          component={
            ["procurement", "marketing"].some((group) => groups.includes(group))
              ? SupplierForm
              : PermissionDenied
          }
        />
        <Route
          exact
          path={[`/app/records/webapps`]}
          component={
            ["procurement", "marketing"].some((group) => groups.includes(group))
              ? WebAppForm
              : PermissionDenied
          }
        />
        {/* Procurement */}
        <Route
          exact
          path={[`/app/forms/procurement`]}
          component={
            ["procurement"].some((group) => groups.includes(group))
              ? ProcurementDirectory
              : PermissionDenied
          }
        />
        <Route
          exact
          path={[`/app/forms/procurement/form`]}
          component={
            ["procurement"].some((group) => groups.includes(group))
              ? ProcurementRequest
              : PermissionDenied
          }
        />
        <Route
          exact
          path={[`/app/forms/procurement/form/:IRN`]}
          component={
            ["procurement"].some((group) => groups.includes(group))
              ? ProcurementView
              : PermissionDenied
          }
        />
        <Route
          exact
          path={[`/app/forms/procurement/return-cash/form`]}
          component={
            ["procurement"].some((group) => groups.includes(group))
              ? ReturnCashRequestProcurement
              : PermissionDenied
          }
        />
        <Route
          exact
          path={[`/app/forms/procurement/return-cash/form/:RCRN`]}
          component={
            ["procurement"].some((group) => groups.includes(group))
              ? ReturnCashViewProcurement
              : PermissionDenied
          }
        />
        {/* Reimbursement */}
        <Route
          exact
          path={[`/app/forms/reimbursement`]}
          component={
            ["reimbursement"].some((group) => groups.includes(group))
              ? ReimbursementDirectory
              : PermissionDenied
          }
        />
        <Route
          exact
          path={[`/app/forms/reimbursement/form`]}
          component={
            ["reimbursement"].some((group) => groups.includes(group))
              ? ReimbursementRequest
              : PermissionDenied
          }
        />
        <Route
          exact
          path={[`/app/forms/reimbursement/form/:RRN`]}
          component={
            ["reimbursement"].some((group) => groups.includes(group))
              ? ReimbursementView
              : PermissionDenied
          }
        />
        {/* Operation expenses */}
        <Route
          exact
          path={[`/app/forms/operation-expenses`]}
          component={
            ["operations"].some((group) => groups.includes(group))
              ? OperationExpensesDirectory
              : PermissionDenied
          }
        />
        <Route
          exact
          path={[`/app/forms/operation-expenses/operation-expense/form`]}
          component={
            ["operations"].some((group) => groups.includes(group))
              ? OperationExpenseRequest
              : PermissionDenied
          }
        />
        <Route
          exact
          path={[`/app/forms/operation-expenses/operation-expense/form/:OERN`]}
          component={
            ["operations"].some((group) => groups.includes(group))
              ? OperationExpenseView
              : PermissionDenied
          }
        />
        <Route
          exact
          path={[`/app/forms/operation-expenses/return-cash/form`]}
          component={
            ["operations"].some((group) => groups.includes(group))
              ? ReturnCashRequest
              : PermissionDenied
          }
        />
        <Route
          exact
          path={[`/app/forms/operation-expenses/input-expense`]}
          component={
            ["operations"].some((group) => groups.includes(group))
              ? OEInputExpense
              : PermissionDenied
          }
        />
        <Route
          exact
          path={[`/app/forms/operation-expenses/return-cash/form/:RCRN`]}
          component={
            ["operations"].some((group) => groups.includes(group))
              ? ReturnCashView
              : PermissionDenied
          }
        />
        {/* Marketing Expenses */}
        <Route
          exact
          path={[`/app/forms/marketing-expenses`]}
          component={
            ["marketing"].some((group) => groups.includes(group))
              ? MarketingExpenseDirectory
              : PermissionDenied
          }
        />
        <Route
          exact
          path={[`/app/forms/marketing-expenses/unpaid-form`]}
          component={
            ["marketing"].some((group) => groups.includes(group))
              ? UnpaidCampaignRequest
              : PermissionDenied
          }
        />
        <Route
          exact
          path={[`/app/forms/marketing-expenses/unpaid-form/:UCN`]}
          component={
            ["marketing"].some((group) => groups.includes(group))
              ? UnpaidCampaignView
              : PermissionDenied
          }
        />
        <Route
          exact
          path={[`/app/forms/marketing-expenses/paid-form`]}
          component={
            ["marketing"].some((group) => groups.includes(group))
              ? MarketingExpenseRequest
              : PermissionDenied
          }
        />
        <Route
          exact
          path={[`/app/forms/marketing-expenses/paid-form/:MERN`]}
          component={
            ["marketing"].some((group) => groups.includes(group))
              ? MarketingExpenseView
              : PermissionDenied
          }
        />
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  groups: state.auth.user.groups,
  perspectives: state.auth.user.perspectives,
});

export default connect(mapStateToProps, {})(NavRoutes);
