import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Typography, CircularProgress } from "@material-ui/core";

import {
  get_approved_OERNS,
  get_operation_expense_request,
} from "../../../../actions/operation_expenses";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputExpenses from "./InputExpenses";

import Poppup from "../../../../components/Poppup";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
  },
}));

const OEinputExpense = ({
  fetched,
  get_approved_OERNS,
  get_operation_expense_request,
}) => {
  const classes = useStyles();
  const [selectedOERN, setSelectedOERN] = useState(null);
  const [operationExpenses, setOperationExpenses] = useState(null);

  useEffect(() => {
    get_approved_OERNS().then((operation_expenses) =>
      setOperationExpenses(operation_expenses)
    );
  }, [get_approved_OERNS]);

  const handleOERNChange = (OERN) => {
    setSelectedOERN(OERN);
    OERN && get_operation_expense_request(OERN);
  };

  return (
    <div className={classes.paper}>
      <Typography style={{ marginBottom: 40 }} variant="h3">
        Input Expenses
        <Poppup
          content={
            <Typography variant="body1">
              This request form is meant to be used to note and categorize
              expenditure from approved Operations Expense requests. Logistics
              Managers are requested to input their expenditure from approved
              Operations Expense requests as soon as this expenditure occurs. 
            </Typography>
          }
        />
      </Typography>

      <Autocomplete
        options={operationExpenses || []}
        getOptionLabel={(option) => option}
        value={selectedOERN}
        onChange={(event, newValue) => {
          handleOERNChange(newValue);
        }}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Unpaid operation expense requests *"
            placeholder="Search by OERN"
          />
        )}
      />

      {!fetched ? (
        <div className={classes.center}>
          <CircularProgress style={{ margin: "auto" }} />
        </div>
      ) : (
        selectedOERN && <InputExpenses OERN={selectedOERN} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  fetched: state.operation_expenses.fetched_all_OE,
});

export default connect(mapStateToProps, {
  get_approved_OERNS,
  get_operation_expense_request,
})(OEinputExpense);
