import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { connect } from "react-redux";
import { update_time_off_request_status } from "../../../../actions/personal";
import DialogTitle from "../../../../components/MuiDialogTitle";

const CancelRequest = ({
  TMO,
  current_user,
  data,
  update_time_off_request_status,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    current_user === data.requester &&
    data.approval_status.status === "pending" && (
      <div>
        <Button color="primary" type="submit" onClick={handleClick}>
          Cancel request
        </Button>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
        >
          <DialogTitle
            style={{ cursor: "move" }}
            id="draggable-dialog-title"
            onClose={handleClick}
          >
            Are you sure that you want to cancel your reimbursement request?
            This action cannot be undone.
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={handleClick}
              fullWidth
              color="primary"
              variant="outlined"
              style={{ borderRadius: 24 }}
            >
              Keep It
            </Button>
            <Button
              onClick={() => {
                update_time_off_request_status("cancel", TMO);
                handleClick();
              }}
              type="submit"
              fullWidth
              color="primary"
              variant="contained"
              style={{ borderRadius: 24 }}
            >
              Yes, cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  );
};

export default connect(null, { update_time_off_request_status })(CancelRequest);
