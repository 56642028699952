import React, {useState, useEffect} from "react";
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";
import {checkAuthenticated, login, remove_snackbar} from "../../actions/auth";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Paper} from "@material-ui/core";
import AveyLogo from "../../aveyLogo";

import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const useStyles = makeStyles((theme) => ({
  body: {
    backgroundColor: "#77E0FF",
    height: "inherit",
    display: "grid",
    placeItems: "center",
  },
  paper: {
    padding: theme.spacing(6),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(8),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(10),
    },
    [theme.breakpoints.up("lr")]: {
      padding: theme.spacing(12),
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: 12,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  center: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
  },
}));

const Login = ({
                 remove_snackbar,
                 checkAuthenticated,
                 isAuthenticated,
                 isLoading,
                 login,
                 message,
                 severity,
               }) => {
  useEffect(() => {
    checkAuthenticated();
  }, [checkAuthenticated]);

  const smspace = <div style={{padding: 16, width: "100%"}}/>;

  const [showPassword, setShowPassword] = useState(false);
  const [processing, setProcessing] = useState(false);
  const classes = useStyles();
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const {username, password} = formData;
  const onChange = (e) =>
    setFormData({...formData, [e.target.name]: e.target.value});

  const onSubmit = (e) => {
    e.preventDefault();
    setProcessing(true);
    login(username, password).then(() => setProcessing(false));
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    remove_snackbar();
  };

  if (isLoading) {
    return (
      <div className={classes.center}>
        <CircularProgress style={{margin: "auto"}}/>
      </div>
    );
  }

  return isAuthenticated ? (
    <Redirect to="/app/home"/>
  ) : (
    <div className={classes.body}>
      <Container component="main" maxWidth="sm">
        <Paper className={classes.paper}>
          {/* <img
            src={logo}
            alt="Avey"
            height={60}
            style={{ backgroundColor: "black", fill: "#ED7273" }}
          /> */}
          <AveyLogo fill="primary" style={{minHeight: 60}}/>
          {smspace}
          <form className={classes.form} onSubmit={(e) => onSubmit(e)}>
            <TextField
              fullWidth
              required
              variant="outlined"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              value={username}
              onChange={(e) => onChange(e)}
            />

            <TextField
              required
              fullWidth
              autoComplete="current-password"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              label="Password"
              name="password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <Visibility/> : <VisibilityOff/>}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => onChange(e)}
              value={password}
            />

            {processing ? (
              <div style={{textAlign: "center"}}>
                <CircularProgress/>
              </div>
            ) : (
              <Button
                disabled={processing}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                Log in
              </Button>
            )}
          </form>
        </Paper>
        {message && (
          <Snackbar
            open={true}
            autoHideDuration={8000}
            onClose={handleAlertClose}
          >
            <MuiAlert
              onClose={handleAlertClose}
              elevation={6}
              variant="filled"
              severity={severity}
            >
              {message}
            </MuiAlert>
          </Snackbar>
        )}
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isLoading: state.auth.isLoading,
  message: state.auth.message,
  severity: state.auth.severity,
});

export default connect(mapStateToProps, {
  login,
  checkAuthenticated,
  remove_snackbar,
})(Login);
