import {
  BIRTHDAYS_SUCCESS,
  BIRTHDAYS_FAILURE,
  NEW_TO_RIMADS_SUCCESS,
  NEW_TO_RIMADS_FAILURE,
  UPCOMING_EVENTS_SUCCESS,
  UPCOMING_EVENTS_FAILURE,
  WHOS_OUT_SUCCESS,
  WHOS_OUT_FAILURE,
  ANNIVERSARIES_SUCCESS,
  ANNIVERSARIES_FAILURE,
} from "../actions/homeTypes";

const initialState = {
  anniversaries: [],
  birthdays: [],
  new_to_rimads: [],
  upcoming_events: [],
  whos_out: [],
};

export default function home(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ANNIVERSARIES_SUCCESS:
      return {
        ...state,
        anniversaries: payload,
      };
    case ANNIVERSARIES_FAILURE:
      return {
        ...state,
        anniversaries: [],
      };

    case BIRTHDAYS_SUCCESS:
      return {
        ...state,
        birthdays: payload,
      };
    case BIRTHDAYS_FAILURE:
      return {
        ...state,
        birthdays: [],
      };

    case NEW_TO_RIMADS_SUCCESS:
      return {
        ...state,
        new_to_rimads: payload,
      };
    case NEW_TO_RIMADS_FAILURE:
      return {
        ...state,
        new_to_rimads: [],
      };
    case UPCOMING_EVENTS_SUCCESS:
      return {
        ...state,
        upcoming_events: payload,
      };
    case UPCOMING_EVENTS_FAILURE:
      return {
        ...state,
        upcoming_events: [],
      };
    case WHOS_OUT_SUCCESS:
      return {
        ...state,
        whos_out: payload,
      };
    case WHOS_OUT_FAILURE:
      return {
        ...state,
        whos_out: [],
      };

    default:
      return state;
  }
}
