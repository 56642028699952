import React from "react";
import {
  Card,
  CardContent,
  Avatar,
  ListItemText,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    borderRadius: 64,
    margin: "auto",
    marginRight: 24,
    "& .MuiAvatar-fallback": {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
  },
}));

const Event = ({ employee, image, title, subtitle }) => {
  const classes = useStyles();
  return (
    <Card elevation={8}>
      <CardContent
        style={{
          padding: 12,
          paddingLeft: 24,
          paddingRight: 24,
          display: "flex",
          flexDirection: "row",
        }}
      >
        {
          <Avatar
            src={image}
            alt={employee ? employee.full_name : "avatar"}
            className={classes.large}
          />
        }
        <ListItemText primary={title} secondary={subtitle} />
      </CardContent>
    </Card>
  );
};

export default Event;
