import React from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import DepartmentTeamEvaluationMetrics from "./DepartmentTeamEvaluationMetrics";
import WeeklyKpisPastEvaluations from "./WeeklyKpisPastEvaluations";
import WeeklyKpisActiveEvaluations from "./WeeklyKpisActiveEvaluations";

const WeeklyKpisTeamRoutes = ({ department_team }) => {
  const { is_manager, is_supervisor, is_supervisee, num_workdays } =
    department_team;
  const ActiveEvaluationsRoutes = (
    <Route
      exact
      path={[
        "/app/forms/team-performance/teams/:id/weekly-team-evaluations/*",
        `/app/forms/team-performance/teams/:id/weekly-team-evaluations`,
      ]}
      component={WeeklyKpisActiveEvaluations}
    />
  );
  const PastEvaluationsRoutes = (
    <Route
      exact
      path={[`/app/forms/team-performance/teams/:id/history`]}
      component={WeeklyKpisPastEvaluations}
    />
  );
  const MetricsRoutes = (
    <Route
      exact
      path={[`/app/forms/team-performance/teams/:id/metrics`]}
      render={() => (
        <DepartmentTeamEvaluationMetrics
          scope="department_team"
          name={department_team.slug}
          num_workdays={num_workdays}
        />
      )}
    />
  );

  if (is_supervisor) {
    return (
      <div>
        {ActiveEvaluationsRoutes}
        {PastEvaluationsRoutes}
        {MetricsRoutes}
      </div>
    );
  } else if (is_manager) {
    return (
      <div>
        {PastEvaluationsRoutes}
        {MetricsRoutes}
      </div>
    );
  } else if (is_supervisee) {
    return (
      <div>
        {ActiveEvaluationsRoutes}
        {PastEvaluationsRoutes}
      </div>
    );
  }

  return null;
};

const mapStateToProps = (state) => ({
  department_team: state.weekly_kpis.department_team,
});

export default connect(mapStateToProps, {})(WeeklyKpisTeamRoutes);
