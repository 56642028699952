import React, { Fragment, useState } from "react";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import CreateIcon from "@material-ui/icons/Create";
import EditOrderInformation from "./EditOrderInformation";
import LinearProgress from "@material-ui/core/LinearProgress";
import { CSVLink } from "react-csv";
import GetAppIcon from "@material-ui/icons/GetApp";

import {
  get_supplier_products,
  clear_supplier_products,
  remove_product,
  add_back_product,
  add_back_products,
} from "../../../actions/procedure";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function Row({ row, labelId, AllowedToEdit }) {
  const classes = useRowStyles();
  const [open, setOpen] = React.useState(false);
  const [openEditProduct, setOpenEditProduct] = useState(false);
  return (
    <Fragment>
      <TableRow
        hover
        className={classes.root}
        // onClick={(event) => handleClickRow(event, row.id)}
        role="checkbox"
        tabIndex={-1}
        key={row.id}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" id={labelId} scope="row" padding="none">
          {row.name}
        </TableCell>
        <TableCell align="right">{row.product_type}</TableCell>
        <TableCell align="right">
          {(row.cost_unit_price * row.requested_quantity).toFixed(2)}
        </TableCell>
        <TableCell align="right">
          {row.selling_unit_price
            ? (row.selling_unit_price * row.requested_quantity).toFixed(2)
            : null}
        </TableCell>
        <TableCell align="right">
          {row.margin && row.margin.toFixed(2)}
        </TableCell>
        <TableCell align="right">{row.requested_quantity}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Order Information
                {AllowedToEdit && (
                  <Tooltip title="Edit order information">
                    <IconButton
                      onClick={() => setOpenEditProduct(!openEditProduct)}
                    >
                      <CreateIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Typography>
              {openEditProduct && (
                <EditOrderInformation
                  order={row}
                  open={openEditProduct}
                  setOpen={setOpenEditProduct}
                />
              )}

              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold" }}>
                      # Requested
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align="right">
                      # Received
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align="right">
                      # Unfit
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align="right">
                      Unfit reason
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align="right">
                      Delivery date
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align="right">
                      Collector Staff
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow className={classes.root}>
                    <TableCell>{row.requested_quantity}</TableCell>
                    <TableCell align="right">{row.received_quantity}</TableCell>
                    <TableCell align="right">{row.unfit_quantity}</TableCell>
                    <TableCell align="right">{row.reason_for_unfit}</TableCell>
                    <TableCell align="right">
                      {row.delivery_date
                        ? new Date(row.delivery_date).toLocaleDateString(
                            "en-GB"
                          )
                        : ""}
                    </TableCell>
                    <TableCell align="right">{row.responsible_staff}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Product",
  },
  {
    id: "product_type",
    numeric: true,
    disablePadding: false,
    label: "Product type",
  },
  {
    id: "cost_unit_price",
    numeric: true,
    disablePadding: false,
    label: "Total Cost (QAR)",
  },
  {
    id: "selling_unit_price",
    numeric: true,
    disablePadding: false,
    label: "Total Retail (QAR)",
  },
  { id: "margin", numeric: true, disablePadding: false, label: "Margin (%)" },
  {
    id: "quantity",
    numeric: true,
    disablePadding: false,
    label: "Quantity (#)",
  },
];

const headers = [
  {
    key: "name",
    label: "Product",
  },
  {
    key: "cost_unit_price",
    label: "Cost (QAR)",
  },
  {
    key: "selling_unit_price",
    label: "Retail (QAR)",
  },
  {
    key: "requested_quantity",
    label: "Quantity",
  },
];

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 600,
    marginBottom: 20,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  number: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
      {
        display: "none",
      },
  },
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.primary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

function EnhancedTable({
  supplier_products,
  submitting_order_information,
  approval_status,
  current_user,
  expected_receiver_staff_username,
  supplier_name,
  IRN,
}) {
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const AllowedToEdit =
    approval_status === "approved" &&
    (expected_receiver_staff_username === current_user.username ||
      current_user.groups.includes("procurement"));

  const createSortHandler = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const csvReport = {
    data: Object.values(supplier_products),
    headers: headers,
    filename: `${supplier_name}_${IRN}`,
  };

  return (
    <Paper className={classes.paper}>
      {submitting_order_information && <LinearProgress />}
      <Table
        className={classes.table}
        aria-labelledby="tableTitle"
        size={"small"}
        aria-label="enhanced table"
      >
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Tooltip title="Export table">
                <CSVLink {...csvReport}>
                  <IconButton size="small">
                    <GetAppIcon />
                  </IconButton>
                </CSVLink>
              </Tooltip>
            </TableCell>
            {headCells.map((headCell) => (
              <TableCell
                style={{ fontWeight: "bold" }}
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={() => createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {stableSort(supplier_products, getComparator(order, orderBy)).map(
            (row, index) => (
              <Row
                key={index}
                row={row}
                labelId={`enhanced-table-checkbox-${index}`}
                AllowedToEdit={AllowedToEdit}
              />
            )
          )}
          <TableRow>
            <TableCell padding="checkbox"></TableCell>
            <TableCell
              component="th"
              scope="row"
              padding="none"
              style={{ fontWeight: "bold" }}
            >
              Total
            </TableCell>
            <TableCell />
            <TableCell align="right" style={{ fontWeight: "bold" }}>
              {supplier_products
                .reduce(
                  (currentTotalPrice, product) =>
                    currentTotalPrice +
                    product.requested_quantity * product.cost_unit_price,
                  0
                )
                .toFixed(2)}
            </TableCell>
            <TableCell align="right" style={{ fontWeight: "bold" }}>
              {supplier_products
                .reduce(
                  (currentTotalPrice, product) =>
                    currentTotalPrice +
                    product.selling_unit_price * product.requested_quantity,
                  0
                )
                .toFixed(2)}
            </TableCell>
            <TableCell />
            <TableCell />
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
}

const mapStateToProps = (state) => ({
  submitting_order_information: state.procurement.submitting_order_information,
  suppliers: state.procedures.suppliers,
  allSupplierProducts: state.procedures.supplier_products,
  current_user: state.auth.user,
});

export default connect(mapStateToProps, {
  get_supplier_products,
  clear_supplier_products,
  remove_product,
  add_back_product,
  add_back_products,
})(EnhancedTable);
