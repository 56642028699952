import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { add_product } from "../../../actions/procedure";
import { connect } from "react-redux";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import DialogTitle from "../../../components/MuiDialogTitle";

const useStyles = makeStyles((theme) => ({
  number: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
      {
        display: "none",
      },
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function AddProduct({
  open,
  setOpen,
  add_product,
  supplier_pk,
  procurementFormProduct,
  registerProduct,
  setOpenProduct,
  add_product_locally,
}) {
  const classes = useStyles();
  const handleClose = () => {
    setOpen(false);
    setFormData({
      name: "",
      cost_unit_price: "",
      selling_unit_price: "",
      margin: "",
      product_type: "",
      quantity: "",
    });
  };

  const [formData, setFormData] = useState({
    name: "",
    product_type: "",
    cost_unit_price: "",
    selling_unit_price: "",
    margin: "",
    quantity: "",
  });

  const handleChangeValue = (prop) => (event) => {
    setFormData({
      ...formData,
      [prop]: event.target.value,
    });
  };

  const handleChange = (prop) => (event) => {
    setFormData({
      ...formData,
      [prop]: event.target.value,
      margin:
        prop === "selling_unit_price"
          ? event.target.value > 0
            ? (
                (100 * (event.target.value - formData.cost_unit_price)) /
                event.target.value
              ).toFixed(2)
            : 0
          : formData.selling_unit_price > 0
          ? (
              (100 * (formData.selling_unit_price - event.target.value)) /
              formData.selling_unit_price
            ).toFixed(2)
          : 0,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    procurementFormProduct && setOpenProduct(false);
    if (add_product_locally) {
      add_product_locally(formData);
    } else {
      add_product(formData, supplier_pk).then(
        (product) =>
          procurementFormProduct &&
          product &&
          registerProduct(product, formData.quantity)
      );
    }
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        fullWidth
      >
        <DialogTitle
          style={{ cursor: "move" }}
          id="draggable-dialog-title"
          onClose={handleClose}
        >
          Add a new product
        </DialogTitle>
        <form onSubmit={(e) => onSubmit(e)}>
          <DialogContent>
            <FormControl fullWidth>
              <TextField
                required
                onChange={handleChangeValue("name")}
                value={formData.name}
                autoFocus
                variant="outlined"
                label="Name"
                type="text"
              />
            </FormControl>
            <FormControl fullWidth variant="outlined" required>
              <InputLabel>Product type</InputLabel>
              <Select
                value={formData.product_type}
                onChange={handleChangeValue("product_type")}
                label="Product type *"
                variant="outlined"
                fullWidth
              >
                <MenuItem value={"internal"}>Internal</MenuItem>
                <MenuItem value={"retail"}>Retail</MenuItem>
                <MenuItem value={"both"}>Both</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="outlined" fullWidth required>
              <InputLabel>Cost / unit</InputLabel>
              <OutlinedInput
                label="Cost / unit *"
                type="number"
                value={formData.cost_unit_price}
                onChange={handleChange("cost_unit_price")}
                startAdornment={
                  <InputAdornment position="start">QAR</InputAdornment>
                }
                className={classes.number}
                inputProps={{ min: 0, step: 0.01 }}
              />
            </FormControl>
            <FormControl variant="outlined" fullWidth required>
              <InputLabel>Retail / unit</InputLabel>
              <OutlinedInput
                label="Retail / unit *"
                type="number"
                value={formData.selling_unit_price}
                onChange={handleChange("selling_unit_price")}
                startAdornment={
                  <InputAdornment position="start">QAR</InputAdornment>
                }
                className={classes.number}
                inputProps={{ min: 0, step: 0.01 }}
              />
            </FormControl>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Margin</InputLabel>
              <OutlinedInput
                label="Margin"
                type="number"
                value={formData.margin}
                startAdornment={
                  <InputAdornment position="start">%</InputAdornment>
                }
                className={classes.number}
                inputProps={{ readOnly: true }}
              />
            </FormControl>
            {procurementFormProduct && (
              <FormControl fullWidth>
                <TextField
                  required
                  onChange={handleChangeValue("quantity")}
                  value={formData.quantity}
                  variant="outlined"
                  label="Quantity"
                  type="number"
                  className={classes.number}
                  inputProps={{ step: 1, min: 1 }}
                />
              </FormControl>
            )}
          </DialogContent>
          <DialogActions style={{ padding: 24 }}>
            <Button
              onClick={handleClose}
              fullWidth
              color="primary"
              variant="outlined"
              style={{ borderRadius: 24 }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              fullWidth
              color="primary"
              variant="contained"
              style={{ borderRadius: 24 }}
            >
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

// const mapStateToProps = (state) => ({
//   processing: state.procedures.processing,
// });

export default connect(null, { add_product })(AddProduct);
