import React, { Fragment, useEffect, useState } from "react";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import CreateIcon from "@material-ui/icons/Create";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Draggable from "react-draggable";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import DialogTitle from "../../../components/MuiDialogTitle";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "payment_date",
    numeric: false,
    disablePadding: true,
    label: "Payment Date",
  },
  {
    id: "amount_requested",
    numeric: true,
    disablePadding: false,
    label: "Payment Amount",
  },
  {
    id: "payment_type",
    numeric: true,
    disablePadding: false,
    label: "Payment Type",
  },
  {
    id: "associated_payment_id",
    numeric: true,
    disablePadding: false,
    label: "Payment ID",
  },
];

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },

  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  number: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
      {
        display: "none",
      },
  },
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.primary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

function EnhancedTable({
  formData,
  setFormData,
  minDate,
  setTotalPaymentPrice,
  remainingAmount,
  AllowedToEdit,
}) {
  const classes = useStyles();
  const payments = Object.values(formData.payments);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [selected, setSelected] = useState([]);
  const [openPayment, setOpenPayment] = useState(false);
  const [openEditPayment, setOpenEditPayment] = useState(false);
  const [activePayment, setActivePayment] = useState({
    payment_date: null,
    amount_requested: "",
    payment_type: "",
  });
  const [editPayment, setEditPayment] = useState({
    payment_date: null,
    amount_requested: "",
    payment_type: "",
  });
  const [openRemoveProduct, setOpenRemoveProduct] = useState(false);

  const [dateIsInvalid, setIsInvalidDate] = useState(false);
  const numSelected = selected.length;
  const numPayments = payments.length;
  const totalPrice = payments.reduce(
    (setTotalPaymentPrice, payment) =>
      setTotalPaymentPrice + parseFloat(payment.amount_requested),
    0
  );

  useEffect(() => {
    setTotalPaymentPrice(totalPrice);
  }, [setTotalPaymentPrice, totalPrice]);

  useEffect(() => {
    setActivePayment({
      payment_date: null,
      amount_requested: remainingAmount,
      payment_type: "",
    });
  }, [setActivePayment, remainingAmount]);

  const first =
    payments.length > 0 &&
    Object.values(payments).find(
      (payment) =>
        new Date(payment.payment_date).toLocaleDateString("en-GB") ===
        selected[0]
    );

  const openEditPaymentDialog = () => {
    setOpenEditPayment(true);
    setEditPayment({
      payment_date: first.payment_date,
      amount_requested: first.amount_requested,
      payment_type: first.payment_type,
    });
  };
  const removePayments = (paymentsIds) => {
    const temp = formData.payments;
    paymentsIds.forEach((paymentId) => {
      delete temp[paymentId];
    });
    setFormData({
      ...formData,
      payments: { ...temp },
    });
    setSelected([]);
  };
  const removeAllPayments = (productIds) => {
    setFormData({
      ...formData,
      payments: {},
    });
    setSelected([]);
  };
  const handleActivePaymentChange = (prop) => (event) => {
    prop === "amount_requested"
      ? setActivePayment({
          ...activePayment,
          amount_requested: parseFloat(event.target.value) || "",
        })
      : setActivePayment({ ...activePayment, [prop]: event.target.value });
  };
  const handleActivePaymentDateChange = (date) => {
    setActivePayment({ ...activePayment, payment_date: date });
    date === null || date.toString() === "Invalid Date" || date < minDate
      ? setIsInvalidDate(true)
      : setIsInvalidDate(false);
  };
  const handleCloseDialog = () => {
    setOpenPayment(false);
    setActivePayment({
      payment_date: null,
      amount_requested: remainingAmount,
      payment_type: "",
    });
  };
  const handleEditCloseDialog = () => {
    setOpenEditPayment(false);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = payments.map((n) =>
        new Date(n.payment_date).toLocaleDateString("en-GB")
      );
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleClickRow = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === numSelected - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const createSortHandler = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const isSelected = (id) => selected.indexOf(id) !== -1;
  const onSubmit = (e) => {
    e.preventDefault();
    setFormData({
      ...formData,
      payments: {
        ...formData.payments,
        [activePayment.payment_date.toLocaleDateString("en-GB")]: activePayment,
      },
    });
    setActivePayment({
      payment_date: null,
      amount_requested: "",
      payment_type: "",
    });
    setOpenPayment(false);
  };
  const onSubmitEdit = (e) => {
    e.preventDefault();
    if (editPayment.payment_date !== first.payment_date) {
      const tempPayments = { ...formData.payments };

      delete tempPayments[
        new Date(first.payment_date).toLocaleDateString("en-GB")
      ];
      setFormData({
        ...formData,
        payments: {
          ...tempPayments,
          [new Date(editPayment.payment_date).toLocaleDateString("en-GB")]:
            editPayment,
        },
      });
    } else {
      setFormData({
        ...formData,
        payments: {
          ...formData.payments,
          [new Date(editPayment.payment_date).toLocaleDateString("en-GB")]:
            editPayment,
        },
      });
    }
    setSelected([
      new Date(editPayment.payment_date).toLocaleDateString("en-GB"),
    ]);
    setOpenEditPayment(false);
  };

  const handleEditPaymentChange = (prop) => (event) => {
    prop === "amount_requested"
      ? setEditPayment({
          ...editPayment,
          amount_requested: parseFloat(event.target.value) || "",
        })
      : setEditPayment({ ...editPayment, [prop]: event.target.value });
  };
  const handleEditPaymentDateChange = (date) => {
    setEditPayment({ ...editPayment, payment_date: date });
    date === null || date.toString() === "Invalid Date" || date < minDate
      ? setIsInvalidDate(true)
      : setIsInvalidDate(false);
  };

  return (
    <div>
      <Paper className={classes.paper}>
        {AllowedToEdit && (
          <Toolbar
            className={clsx(classes.root, {
              [classes.highlight]: numSelected > 0,
            })}
          >
            {numSelected > 0 ? (
              <Typography
                className={classes.title}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {numSelected} selected
              </Typography>
            ) : (
              remainingAmount > 0 && (
                <Typography
                  className={classes.title}
                  color="inherit"
                  variant="subtitle1"
                  component="div"
                >
                  Add a payment
                </Typography>
              )
            )}
            {numSelected > 0 ? (
              numSelected === 1 ? (
                <div style={{ display: "flex" }}>
                  <Tooltip title="Edit payment">
                    <IconButton onClick={() => openEditPaymentDialog()}>
                      <CreateIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Remove selected payment">
                    <IconButton
                      onClick={() => {
                        setOpenRemoveProduct(true);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              ) : numSelected !== numPayments ? (
                <Tooltip title="Remove selected payments">
                  <IconButton onClick={() => setOpenRemoveProduct(true)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Remove all payments">
                  <IconButton onClick={() => setOpenRemoveProduct(true)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )
            ) : (
              remainingAmount > 0 && (
                <div style={{ display: "flex" }}>
                  <Tooltip title="Add a payment">
                    <IconButton onClick={() => setOpenPayment(!openPayment)}>
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              )
            )}
          </Toolbar>
        )}

        {numSelected > 0 && (
          <Dialog
            open={openRemoveProduct}
            onClose={() => setOpenRemoveProduct(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
          >
            <DialogTitle
              style={{ cursor: "move" }}
              id="draggable-dialog-title"
              onClose={() => setOpenRemoveProduct(false)}
            >
              {numSelected > 1
                ? numSelected !== numPayments
                  ? "Are you sure that you would like to remove these payments?"
                  : "Are you sure that you would like to remove all of these payments?"
                : "Are you sure that you would like to remove this payment?"}
            </DialogTitle>
            <DialogActions>
              <Button
                onClick={() => setOpenRemoveProduct(false)}
                type="submit"
                fullWidth
                color="primary"
                variant="outlined"
                style={{
                  borderRadius: 24,
                  fontWeight: "bold",
                }}
              >
                No, go back
              </Button>
              <Button
                onClick={() => {
                  numSelected > 1
                    ? numSelected !== numPayments
                      ? removePayments(selected)
                      : removeAllPayments(selected)
                    : removePayments(selected);
                  setSelected([]);
                  setOpenRemoveProduct(false);
                }}
                type="submit"
                fullWidth
                color="primary"
                variant="contained"
                style={{ borderRadius: 24 }}
              >
                Yes, I'm sure
              </Button>
            </DialogActions>
          </Dialog>
        )}

        {numPayments > 0 && (
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            // size={"small"}
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                {AllowedToEdit && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      indeterminate={
                        numSelected > 0 && numSelected < numPayments
                      }
                      checked={numPayments > 0 && numSelected === numPayments}
                      onChange={handleSelectAllClick}
                    />
                  </TableCell>
                )}
                {headCells
                  .filter((headCell) =>
                    AllowedToEdit && headCell.id === "associated_payment_id"
                      ? false
                      : true
                  )
                  .map((headCell) => (
                    <TableCell
                      style={{ fontWeight: "bold" }}
                      key={headCell.id}
                      align={headCell.numeric ? "right" : "left"}
                      padding={
                        headCell.disablePadding && AllowedToEdit
                          ? "none"
                          : "default"
                      }
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={() => createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <span className={classes.visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(payments, getComparator(order, orderBy))
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(
                    new Date(row.payment_date).toLocaleDateString("en-GB")
                  );
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      onClick={(event) =>
                        handleClickRow(
                          event,
                          new Date(row.payment_date).toLocaleDateString("en-GB")
                        )
                      }
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={new Date(row.payment_date).toLocaleDateString(
                        "en-GB"
                      )}
                      selected={isItemSelected}
                    >
                      {AllowedToEdit && (
                        <TableCell color="primary" padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>
                      )}
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding={AllowedToEdit ? "none" : "default"}
                      >
                        {new Date(row.payment_date).toLocaleDateString("en-GB")}
                      </TableCell>
                      <TableCell align="right">
                        {parseFloat(row.amount_requested).toFixed(2)}
                      </TableCell>
                      <TableCell align="right">{row.payment_type}</TableCell>
                      {!AllowedToEdit && (
                        <TableCell align="right">
                          {row.associated_payment_id || "-"}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              {remainingAmount !== 0 && (
                <TableRow>
                  {AllowedToEdit && (
                    <TableCell padding="checkbox">
                      <Checkbox style={{ display: "none" }} />
                    </TableCell>
                  )}
                  <TableCell
                    component="th"
                    scope="row"
                    padding={AllowedToEdit ? "none" : "default"}
                    style={{ fontWeight: "bold" }}
                  >
                    Remaining
                  </TableCell>
                  <TableCell align="right" style={{ fontWeight: "bold" }}>
                    {remainingAmount && remainingAmount.toFixed(2)}
                  </TableCell>
                  <TableCell />
                </TableRow>
              )}
              <TableRow>
                {AllowedToEdit && (
                  <TableCell padding="checkbox">
                    <Checkbox style={{ display: "none" }} />
                  </TableCell>
                )}
                <TableCell
                  component="th"
                  scope="row"
                  padding={AllowedToEdit ? "none" : "default"}
                  style={{ fontWeight: "bold" }}
                >
                  Total
                </TableCell>
                <TableCell align="right" style={{ fontWeight: "bold" }}>
                  {totalPrice.toFixed(2)}
                </TableCell>
                <TableCell />
              </TableRow>
            </TableBody>
          </Table>
        )}
      </Paper>
      {openPayment && (
        <Dialog
          open={openPayment}
          onClose={handleCloseDialog}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          fullWidth
        >
          <form onSubmit={(e) => onSubmit(e)}>
            <DialogTitle
              style={{ cursor: "move" }}
              id="draggable-dialog-title"
              onClose={handleCloseDialog}
            >
              Add a payment
            </DialogTitle>
            <DialogContent>
              <FormControl fullWidth variant="outlined" required>
                <InputLabel>Amount Requested</InputLabel>
                <OutlinedInput
                  label="Amount Requested *"
                  type="number"
                  value={activePayment.amount_requested}
                  onChange={handleActivePaymentChange("amount_requested")}
                  startAdornment={
                    <InputAdornment position="start">QAR</InputAdornment>
                  }
                  className={classes.number}
                  inputProps={{ min: 0, step: 0.01 }}
                />
              </FormControl>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  required
                  inputVariant="outlined"
                  label="Payment date"
                  format="dd / MM / yyyy"
                  value={activePayment.payment_date}
                  onChange={handleActivePaymentDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  minDate={minDate}
                />
              </MuiPickersUtilsProvider>
              <FormControl fullWidth variant="outlined" required>
                <InputLabel>Payment type</InputLabel>
                <Select
                  value={activePayment.payment_type}
                  onChange={handleActivePaymentChange("payment_type")}
                  label="Payment type *"
                >
                  <MenuItem value={"Bank transfer"}>Bank transfer</MenuItem>
                  <MenuItem value={"Cash"}>Cash</MenuItem>
                  <MenuItem value={"Cheque"}>Cheque</MenuItem>
                  <MenuItem value={"Credit card"}>Credit card</MenuItem>
                  <MenuItem value={"Debit card"}>Debit card</MenuItem>
                  <MenuItem value={"Credit application"}>
                    Credit application
                  </MenuItem>
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions style={{ padding: 24 }}>
              <Fragment>
                <Button
                  onClick={handleCloseDialog}
                  fullWidth
                  color="primary"
                  variant="outlined"
                  style={{ borderRadius: 24 }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={
                    remainingAmount < (activePayment.amount_requested || 0) ||
                    dateIsInvalid
                  }
                  type="submit"
                  fullWidth
                  color="primary"
                  variant="contained"
                  style={{ borderRadius: 24 }}
                >
                  Add
                </Button>
              </Fragment>
            </DialogActions>
          </form>
        </Dialog>
      )}
      {openEditPayment && (
        <Dialog
          open={openEditPayment}
          onClose={handleEditCloseDialog}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          fullWidth
        >
          <form onSubmit={(e) => onSubmitEdit(e)}>
            <DialogTitle
              style={{ cursor: "move" }}
              id="draggable-dialog-title"
              onClose={handleEditCloseDialog}
            >
              Edit payment
            </DialogTitle>
            <DialogContent>
              <FormControl fullWidth variant="outlined" required>
                <InputLabel>Amount Requested</InputLabel>
                <OutlinedInput
                  label="Amount Requested *"
                  type="number"
                  value={editPayment.amount_requested}
                  onChange={handleEditPaymentChange("amount_requested")}
                  startAdornment={
                    <InputAdornment position="start">QAR</InputAdornment>
                  }
                  className={classes.number}
                  inputProps={{ min: 0, step: 0.01 }}
                />
              </FormControl>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  required
                  inputVariant="outlined"
                  label="Payment date"
                  format="dd / MM / yyyy"
                  value={editPayment.payment_date}
                  onChange={handleEditPaymentDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  minDate={minDate}
                />
              </MuiPickersUtilsProvider>
              <FormControl fullWidth variant="outlined" required>
                <InputLabel>Payment type</InputLabel>
                <Select
                  value={editPayment.payment_type}
                  onChange={handleEditPaymentChange("payment_type")}
                  label="Payment type *"
                >
                  <MenuItem value={"Bank transfer"}>Bank transfer</MenuItem>
                  <MenuItem value={"Cash"}>Cash</MenuItem>
                  <MenuItem value={"Cheque"}>Cheque</MenuItem>
                  <MenuItem value={"Credit card"}>Credit card</MenuItem>
                  <MenuItem value={"Debit card"}>Debit card</MenuItem>
                  <MenuItem value={"Credit application"}>
                    Credit application
                  </MenuItem>
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleEditCloseDialog}
                fullWidth
                color="primary"
                variant="outlined"
                style={{ borderRadius: 24 }}
              >
                Cancel
              </Button>
              <Button
                disabled={
                  remainingAmount +
                    first.amount_requested -
                    editPayment.amount_requested <
                  0
                }
                type="submit"
                fullWidth
                color="primary"
                variant="contained"
                style={{ borderRadius: 24 }}
              >
                Edit
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </div>
  );
}

export default EnhancedTable;
