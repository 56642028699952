VerifiedIcon.displayName = "VerifiedIcon";

export default function VerifiedIcon({
                                       primary_fill = "#77E0FF",
                                       secondary_fill = "white",
                                       dimension = 12,
                                       style = {},
                                       ...other
                                     }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 12"
      fill="none"
      style={{
        minHeight: dimension,
        height: dimension,
        minWidth: dimension,
        width: dimension,
        ...style,
      }}
      {...other}
    >
      <circle cx="6" cy="6" r="6" fill={primary_fill}/>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.04222 3.75042C7.76013 3.56409 7.3804 3.64172 7.19407 3.92381L5.28555 6.81321L4.71173 6.27873C4.46435 6.04831 4.07701 6.06205 3.84659 6.30943C3.61616 6.55682 3.62991 6.94415 3.87729 7.17458L4.97604 8.19801C5.22342 8.42843 5.61076 8.41468 5.84118 8.1673C5.88253 8.12291 5.91601 8.07401 5.9417 8.02233C5.96753 7.99557 5.99128 7.96608 6.01251 7.93393L8.21561 4.59856C8.40194 4.31647 8.32431 3.93675 8.04222 3.75042Z"
        fill={secondary_fill}
      />
    </svg>
  );
}
