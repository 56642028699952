import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { load_department } from "../../../actions/departments";
import { Link, List, ListItem, Typography } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import RimadsAccordion from "../../../components/RimadsAccordion";
import { useParams } from "react-router-dom";
import { Redirect } from "react-router";
// import ReactHtmlParser from "react-html-parser";
import { CircularProgress } from "@material-ui/core";

const DepartmentTemplate = ({ load_department }) => {
  const mdspace = <div style={{ padding: 24, width: "100%" }} />;
  const smspace = <div style={{ padding: 8, width: "100%" }} />;
  var { identifier } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [department, setDepartment] = useState(null);

  useEffect(() => {
    load_department(identifier).then((dep) => {
      dep && setDepartment(dep);
      setIsLoading(false);
    });
  }, [load_department, identifier]);

  useEffect(() => {
    setIsLoading(true);
  }, [identifier]);

  if (isLoading) {
    return (
      <div style={{ display: "flex", justifyContent: "center", height: 300 }}>
        <CircularProgress style={{ margin: "auto" }} />
      </div>
    );
  }

  if (!isLoading && !department) {
    return <Redirect to={`/app/home/`} />;
  }

  let { name, description, director, resources, main_areas } = department;
  name = name.replace(/-/g, " ");

  return (
    <div style={{ maxWidth: 600 }}>
      <Typography variant="h3" style={{ textTransform: "capitalize" }}>
        {`${name} Department`}
      </Typography>
      {mdspace}
      <Typography variant="body1" style={{ whiteSpace: "pre-line" }}>
        {description}
      </Typography>
      {smspace}
      <Typography variant="body1">Director of {name}:</Typography>
      <Typography variant="body1" style={{ fontWeight: "bold" }}>
        {director ? director : "N/A"}
      </Typography>
      {mdspace}
      <Typography variant="h4">Main areas</Typography>
      {smspace}
      <List>
        {main_areas.map((main_area) => (
          <RimadsAccordion
            key={main_area.name}
            summary={main_area.name}
            details={
              <span>
                <Typography variant="body1">{main_area.description}</Typography>
                {smspace}
                <Typography variant="body1">Aveyators:</Typography>
                <Typography variant="body1">
                  {main_area.rimadsters.length > 0 ? (
                    main_area.rimadsters.map((rimadster, index) => {
                      var str = "";
                      if (
                        index === main_area.rimadsters.length - 1 &&
                        index > 0
                      ) {
                        str = (
                          <span key={index}>
                            , and <b>{rimadster}</b>
                          </span>
                        );
                      } else if (index > 0) {
                        str = <b key={index}>, {rimadster}</b>;
                      } else {
                        str = <b key={index}>{rimadster}</b>;
                      }
                      return str;
                    })
                  ) : (
                    <b>N/A</b>
                  )}
                </Typography>
              </span>
            }
          />
        ))}
        <Divider />
      </List>
      {mdspace}
      <Typography variant="h4">Resources</Typography>
      <List>
        {resources.map((resource) => (
          <ListItem
            key={resource.id}
            component={Link}
            href={resource.url}
            target="_blank"
            variant="button"
            disableGutters
          >
            {resource.name}
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default connect(null, {
  load_department,
})(DepartmentTemplate);
