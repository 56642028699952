import React, { useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import PaidMarketingExpenseStepper from "./PaidMarketingExpenseStepper";
import CancelRequest from "./CancelRequest";
import ApprovalStatus from "./ApprovalStatus";
import AccountingStatus from "./AccountingStatus";
import RequestInput from "./RequestInput";
// import CustomizeSnackbar from "../../../../components/CustomizeSnackbar";

import {
  get_marketing_expense_request,
  get_marketing_supplies_requests,
} from "../../../../actions/marketing_expenses";

import { get_webapps } from "../../../../actions/procedure";

const useStyles = makeStyles((theme) => ({
  paper: {
    flexDirection: "column",
    maxWidth: 600,
  },
  center: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
  },
}));

const ReimbursementView = ({
  get_marketing_expense_request,
  get_marketing_supplies_requests,
  get_webapps,
  data,
  current_user,
  fetched,
  processing,
}) => {
  const classes = useStyles();
  let { MERN } = useParams();

  useEffect(() => {
    get_marketing_expense_request(MERN);
    get_marketing_supplies_requests();
    get_webapps();
  }, [
    MERN,
    get_marketing_expense_request,
    get_marketing_supplies_requests,
    get_webapps,
  ]);

  const dataInCorrectFormat = data
    ? {
        MERN: data.MERN,
        justification: data.justification,
        totals: data.totals,
        created_at: data.created_at,
        ...data.offline_channels,
        ...data.online_channels,
      }
    : null;

  return fetched ? (
    <div className={classes.paper}>
      <Typography style={{ marginBottom: 40, display: "flex" }} variant="h3">
        {`${data.MERN}`}
        <CancelRequest
          MERN={MERN}
          current_user={current_user}
          data={dataInCorrectFormat}
        />
      </Typography>
      <PaidMarketingExpenseStepper data={dataInCorrectFormat} />
      {processing ? (
        <div className={classes.center}>
          <CircularProgress style={{ margin: "auto" }} />
        </div>
      ) : (
        <Fragment>
          <ApprovalStatus
            MERN={MERN}
            current_user={current_user}
            data={dataInCorrectFormat}
          />
          <AccountingStatus
            MERN={MERN}
            current_user={current_user}
            data={dataInCorrectFormat}
          />
          <RequestInput
            MERN={MERN}
            current_user={current_user}
            data={{
              ...dataInCorrectFormat.accounting_status,
              ...dataInCorrectFormat.approval_status,
              ...dataInCorrectFormat,
              channel_type: dataInCorrectFormat.conducted_by
                ? "offline"
                : "online",
            }}
          />
        </Fragment>
      )}
    </div>
  ) : (
    //   (
    //     <CustomizeSnackbar
    //       payload={{
    //         severity: "error",
    //         message: "Permission Denied",
    //       }}
    //     />
    //   )
    // ) :
    <div className={classes.center}>
      <CircularProgress style={{ margin: "auto" }} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  fetched: state.marketing_expenses.fetchedPaid,
  processing: state.marketing_expenses.submittingPaid,
  data: state.marketing_expenses.dataPaid,
  current_user: state.auth.user.username,
});

export default connect(mapStateToProps, {
  get_marketing_expense_request,
  get_marketing_supplies_requests,
  get_webapps,
})(ReimbursementView);
