import React from "react";
import { connect } from "react-redux";
import { weeklyKpisLoadDepartmentTeams } from "../../../../actions/weeklyKpis";
import TeamsContent from "./TeamsContent";
import useEffectLoadWeeklyKpisTeams from "./useEffectLoadWeeklyKpisTeams";

const LoadWeeklyKpiTeams = ({ weeklyKpisLoadDepartmentTeams, variant }) => {
  const { isLoading, data } = useEffectLoadWeeklyKpisTeams({
    weeklyKpisLoadDepartmentTeams,
    variant,
  });

  return <TeamsContent data={data} isLoading={isLoading} variant={variant} />;
};

export default connect(null, { weeklyKpisLoadDepartmentTeams })(
  LoadWeeklyKpiTeams
);
