import {
  GET_FORM_DATA_SUCCESS,
  UPDATE_FORM_STATUS_SUCCESS,
  CLEAR_FORMDATA,
  PROCESSING,
} from "../actions/reimbursementTypes";

const initialState = {
  fetched: false,
  submitting: false,
  data: null,
};

export default function reimbursement(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_FORM_DATA_SUCCESS:
      return {
        ...state,
        data: payload,
        fetched: true,
      };

    case UPDATE_FORM_STATUS_SUCCESS:
      return {
        ...state,
        submitting: false,
        data: payload.formData,
      };

    case CLEAR_FORMDATA:
      return {
        ...state,
        fetched: false,
        data: null,
      };

    case PROCESSING:
      return {
        ...state,
        submitting: payload,
      };

    default:
      return state;
  }
}
