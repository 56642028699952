import React from "react";
import { Avatar, ListItemText, makeStyles, Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    borderRadius: 64,
    margin: "auto",
    marginBottom: 10,
  },
}));

const EmployeeAvatar = ({ name, image, title }) => {
  const classes = useStyles();
  return (
    <div style={{ textAlign: "center" }}>
      <Tooltip title={name}>
        <Avatar src={image} className={classes.large} />
      </Tooltip>

      <ListItemText secondary={title} />
    </div>
  );
};

export default EmployeeAvatar;
