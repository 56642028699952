import {
  GET_FORM_DATA_SUCCESS,
  CLEAR_FORMDATA,
} from "../actions/rimadstersType";

const initialState = {
  fetched: false,
  data: null,
};

export default function rimadsters(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_FORM_DATA_SUCCESS:
      return {
        ...state,
        data: payload,
        fetched: true,
      };

    case CLEAR_FORMDATA:
      return {
        ...state,
        fetched: false,
        data: null,
      };

    default:
      return state;
  }
}
