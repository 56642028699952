import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, ListItem, ListItemText } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Divider from "@material-ui/core/Divider";
import Collapse from "@material-ui/core/Collapse";

const useStyles = makeStyles((theme) => ({
  padding: {
    paddingTop: 14,
    paddingBottom: 14,
  },
  marginBottom: {
    width: "100%",
    marginBottom: 48,
  },
  yellow: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: "#FBE773",
    color: "black",
    "& > *": {
      height: 16,
    },
  },
  blue: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: "#77E0FF",
    color: "black",
    "& > *": {
      height: 16,
    },
  },
}));

const RimadsAccordion = ({ summary, details }) => {
  const classes = useStyles();
  const [collapse, setCollapse] = useState(false);

  return (
    <div>
      <Divider />
      <ListItem
        style={{ width: "100%" }}
        button
        onClick={() => setCollapse(!collapse)}
      >
        <ListItemIcon className={classes.padding}>
          {collapse ? (
            <Avatar className={classes.blue}>
              <RemoveIcon />
            </Avatar>
          ) : (
            <Avatar className={classes.yellow}>
              <AddIcon />
            </Avatar>
          )}
        </ListItemIcon>
        <ListItemText
          primary={summary}
          primaryTypographyProps={{
            variant: "h6",
          }}
        />
      </ListItem>
      <Collapse in={collapse}>
        <ListItem>
          <ListItemIcon />
          <div className={classes.marginBottom}>{details}</div>
        </ListItem>
      </Collapse>
    </div>
  );
};

export default RimadsAccordion;
