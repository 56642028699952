import React, { Fragment } from "react";
import { TextField } from "@material-ui/core";

const AccountContent = ({ user, showPhone }) => {
  return (
    <Fragment>
      <TextField
        fullWidth
        label="Name"
        value={user.full_name}
        InputProps={{ disableUnderline: true, readOnly: true }}
      />
      <TextField
        fullWidth
        label="Email address"
        value={user.email}
        InputProps={{ disableUnderline: true, readOnly: true }}
      />
      <TextField
        fullWidth
        label="Job title"
        value={user.job_title}
        InputProps={{ disableUnderline: true, readOnly: true }}
      />
      <TextField
        fullWidth
        label="Hiring day"
        value={new Date(user.joined_at)
          .toLocaleDateString("en-GB")
          .split("/")
          .join("-")}
        InputProps={{ disableUnderline: true, readOnly: true }}
      />
      <TextField
        fullWidth
        label="Contract start date"
        value={new Date(user.contract_start_date)
          .toLocaleDateString("en-GB")
          .split("/")
          .join("-")}
        InputProps={{ disableUnderline: true, readOnly: true }}
      />
      <TextField
        fullWidth
        style={{ marginBottom: !showPhone && 0 }}
        label="Birthday"
        value={new Date(user.dob)
          .toLocaleDateString("en-GB")
          .split("/")
          .join("-")}
        InputProps={{ disableUnderline: true, readOnly: true }}
      />
      {showPhone && (
        <TextField
          fullWidth
          label="Phone number"
          value={user.phone_number}
          InputProps={{ disableUnderline: true, readOnly: true }}
        />
      )}
    </Fragment>
  );
};

export default AccountContent;
