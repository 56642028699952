import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  TextField,
  Button,
  Typography,
  CircularProgress,
} from "@material-ui/core";

import {
  get_unreturned_IRNS,
  post_return_cash_request,
} from "../../../../actions/procurement";
import Autocomplete from "@material-ui/lab/Autocomplete";

import Poppup from "../../../../components/Poppup";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 600,
  },
  center: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
  },
}));

const ReturnCashRequest = ({
  submitting,
  get_unreturned_IRNS,
  post_return_cash_request,
}) => {
  const classes = useStyles();
  const [RCRN, setRCRN] = useState(false);
  const [unreturnedIRNS, setUnreturnedIRNS] = useState([]);
  const [IRNS_to_return, setIRNS_to_return] = useState([]);
  useEffect(() => {
    get_unreturned_IRNS().then((unreturned_OERNS) =>
      setUnreturnedIRNS(unreturned_OERNS)
    );
  }, [get_unreturned_IRNS]);
  const onSubmit = (e) => {
    e.preventDefault();
    post_return_cash_request(IRNS_to_return).then((RCRN) => setRCRN(RCRN));
  };

  return RCRN ? (
    <Redirect to={`/app/forms/procurement/return-cash/form/${RCRN}`} />
  ) : (
    <div className={classes.paper}>
      <Typography style={{ marginBottom: 40 }} variant="h3">
        Return Cash Request
        <Poppup
          content={
            <Typography variant="body1">
              This request form is designed to facilitate the return of excess
              cash remaining from approved inventory stocking requests. This can
              happen when the purchase bill is less than the approved bill. 
            </Typography>
          }
        />
      </Typography>
      {submitting ? (
        <div className={classes.center}>
          <CircularProgress style={{ margin: "auto" }} />
        </div>
      ) : (
        <form style={{ width: "100%" }} onSubmit={(e) => onSubmit(e)}>
          <Autocomplete
            multiple
            options={unreturnedIRNS}
            getOptionLabel={(option) => option}
            value={IRNS_to_return}
            onChange={(event, newValue) => {
              setIRNS_to_return(newValue);
            }}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Unreturned inventory requests *"
                placeholder="Search by IRN"
              />
            )}
          />
          <FormControl fullWidth>
            <Button
              disabled={IRNS_to_return.length === 0}
              variant="contained"
              color="primary"
              type="submit"
            >
              Submit
            </Button>
          </FormControl>
        </form>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  submitting: state.procurement.submitting,
});

export default connect(mapStateToProps, {
  get_unreturned_IRNS,
  post_return_cash_request,
})(ReturnCashRequest);
