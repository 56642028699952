import axios from "axios";
import {
  SNACKBAR_WRITE,
  SNACKBAR_DELETE,
  SNACKBAR_CUSTOM,
  PROCESSING,
  LOAD_SUPPLIERS_SUCCESS,
  LOAD_SUPPLIER_PRODUCTS_SUCCESS,
  CLEAR_SUPPLIER_PRODUCTS,
  DELETE_SUPPLIER_PRODUCT,
  REMOVE_SUPPLIER_PRODUCT,
  DELETE_MULTIPLE_SUPPLIER_PRODUCTS,
  DELETE_ALL_SUPPLIER_PRODUCTS,
  CREATE_NEW_SUPPLIER,
  CREATE_NEW_PRODUCT,
  DELETE_SUPPLIER,
  EDIT_SUPPLIER,
  EDIT_PRODUCT,
  UPLOAD_PRODUCTS,
  LOAD_WEBAPPS,
  CLEAR_WEBAPP_ACCOUNTS,
  LOAD_WEBAPP_ACCOUNTS,
  CREATE_WEBAPP,
  DELETE_WEBAPP,
  CREATE_WEBAPP_ACCOUNT,
  DELETE_WEBAPP_ACCOUNT,
  EDIT_WEBAPP_ACCOUNT,
  DELETE_MULTIPLE_WEBAPP_ACCOUNTS,
  DELETE_ALL_WEBAPP_ACCOUNTS,
  SAVE_PROCUREMENT_DATA,
  DISCARD_PROCUREMENT_DATA,
  EDIT_WEBAPP,
  GET_GROUPS,
  DELETE_MULTIPLE_SUPPLIERS,
  DELETE_ALL_SUPPLIERS,
  DELETE_MULTIPLE_WEBAPPS,
  DELETE_ALL_WEBAPPS,
  GET_USER_ACCOUNTS,
} from "./procedureType";
import { AUTHENTICATED_FAIL } from "./authTypes";

import global from "../global";
const { API_URL } = global;

export const custom_snackbar = (payload) => async (dispatch) => {
  dispatch({
    type: SNACKBAR_CUSTOM,
    payload: payload,
  });
};

export const remove_snackbar = () => async (dispatch) => {
  dispatch({
    type: SNACKBAR_DELETE,
  });
};

export const get_suppliers = () => async (dispatch) => {
  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    try {
      const response = await axios.get(
        `${API_URL}/miscellaneous/suppliers/`,
        config
      );
      dispatch({
        type: LOAD_SUPPLIERS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "Unable to load suppliers",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const get_supplier_products = (pk) => async (dispatch) => {
  dispatch({
    type: PROCESSING,
    payload: true,
  });
  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    try {
      const response = await axios.get(
        `${API_URL}/miscellaneous/supplier_products/${pk}/`,
        config
      );
      dispatch({
        type: LOAD_SUPPLIER_PRODUCTS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "Unable to load supplier products",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const clear_supplier_products = () => async (dispatch) => {
  dispatch({
    type: CLEAR_SUPPLIER_PRODUCTS,
  });
};

export const delete_product = (product_pk) => async (dispatch) => {
  dispatch({
    type: PROCESSING,
    payload: true,
  });
  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    try {
      await axios.delete(
        `${API_URL}/miscellaneous/product/${product_pk}/`,
        config
      );
      dispatch({
        type: DELETE_SUPPLIER_PRODUCT,
        payload: {
          message: "This product has been successfully deleted!",
          severity: "success",
          product_pk: product_pk,
        },
      });
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "Unable to delete product",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const delete_multiple_products =
  (supplier_pk, list) => async (dispatch) => {
    dispatch({
      type: PROCESSING,
      payload: true,
    });
    const access = localStorage.getItem("access");
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `JWT ${access}`,
        },
        data: { list: list },
      };

      try {
        const response = await axios.delete(
          `${API_URL}/miscellaneous/supplier_products/${supplier_pk}/`,
          config
        );
        supplier_pk === 0
          ? dispatch({
              type: DELETE_MULTIPLE_SUPPLIER_PRODUCTS,
              payload: {
                product_pks: list,
                message: response.data.message,
                severity: response.data.severity,
              },
            })
          : dispatch({
              type: DELETE_ALL_SUPPLIER_PRODUCTS,
              payload: response.data,
            });
      } catch (error) {
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: "Unable to delete products",
            severity: "error",
          },
        });
      }
    } else {
      dispatch({
        type: AUTHENTICATED_FAIL,
      });
    }
  };

export const add_supplier = (data) => async (dispatch) => {
  dispatch({
    type: PROCESSING,
    payload: true,
  });
  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    let formData = new FormData();
    formData.append("name", data.name);
    formData.append("supplier_type", data.supplier_type.toLowerCase());
    formData.append("store_location", data.store_location.toLowerCase());

    try {
      const response = await axios.post(
        `${API_URL}/miscellaneous/suppliers/`,
        formData,
        config
      );
      response.data.supplier &&
        dispatch({
          type: CREATE_NEW_SUPPLIER,
          payload: response.data,
        });
      dispatch({
        type: SNACKBAR_WRITE,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "An error has occured! Please try again later.",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const add_product = (data, supplier_pk) => async (dispatch) => {
  dispatch({
    type: PROCESSING,
    payload: true,
  });
  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    let formData = new FormData();
    formData.append("name", data.name);
    formData.append("cost_unit_price", data.cost_unit_price);
    formData.append("selling_unit_price", data.selling_unit_price);
    formData.append("margin", data.margin);
    formData.append("product_type", data.product_type);

    try {
      const response = await axios.post(
        `${API_URL}/miscellaneous/supplier_products/${supplier_pk}/`,
        formData,
        config
      );

      response.data.product &&
        dispatch({
          type: CREATE_NEW_PRODUCT,
          payload: response.data,
        });

      dispatch({
        type: SNACKBAR_WRITE,
        payload: response.data,
      });

      return response.data.product;
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "An error has occured! Please try again later.",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const delete_supplier = (supplier_pk) => async (dispatch) => {
  dispatch({
    type: PROCESSING,
    payload: true,
  });
  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    try {
      const response = await axios.delete(
        `${API_URL}/miscellaneous/supplier/${supplier_pk}/`,
        config
      );

      dispatch({
        type: DELETE_SUPPLIER,
        payload: {
          supplier_pk: supplier_pk,
          message: response.data.message,
          severity: response.data.severity,
        },
      });
      dispatch({
        type: CLEAR_SUPPLIER_PRODUCTS,
      });
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "An error has occured! Please try again later.",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const delete_multiple_suppliers = (list) => async (dispatch) => {
  dispatch({
    type: PROCESSING,
    payload: true,
  });
  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
      data: { list: list },
    };

    try {
      const response = await axios.delete(
        `${API_URL}/miscellaneous/suppliers/`,
        config
      );
      list.length > 0
        ? dispatch({
            type: DELETE_MULTIPLE_SUPPLIERS,
            payload: {
              supplier_pks: list,
              message: response.data.message,
              severity: response.data.severity,
            },
          })
        : dispatch({
            type: DELETE_ALL_SUPPLIERS,
            payload: response.data,
          });
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "Unable to delete suppliers",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const edit_supplier = (data, supplier_pk) => async (dispatch) => {
  dispatch({
    type: PROCESSING,
    payload: true,
  });
  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    let formData = new FormData();
    formData.append("name", data.name);
    formData.append("supplier_type", data.supplier_type.toLowerCase());
    formData.append("store_location", data.store_location.toLowerCase());

    try {
      const response = await axios.put(
        `${API_URL}/miscellaneous/supplier/${supplier_pk}/`,
        formData,
        config
      );

      response.data.supplier &&
        dispatch({
          type: EDIT_SUPPLIER,
          payload: {
            supplierId: supplier_pk,
            supplier: response.data.supplier,
          },
        });

      dispatch({
        type: SNACKBAR_WRITE,
        payload: response.data,
      });

      return response.data.supplier;
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "An error has occured! Please try again later.",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const edit_product = (data, supplier_pk) => async (dispatch) => {
  dispatch({
    type: PROCESSING,
    payload: true,
  });
  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    let formData = new FormData();
    formData.append("id", data.id);
    formData.append("name", data.name);
    formData.append("product_type", data.product_type);
    formData.append("cost_unit_price", data.cost_unit_price);
    formData.append("selling_unit_price", data.selling_unit_price);
    formData.append("margin", data.margin);

    try {
      const response = await axios.put(
        `${API_URL}/miscellaneous/supplier_products/${supplier_pk}/`,
        formData,
        config
      );

      response.data.product &&
        dispatch({
          type: EDIT_PRODUCT,
          payload: {
            productId: data.id,
            product: response.data.product,
          },
        });

      dispatch({
        type: SNACKBAR_WRITE,
        payload: response.data,
      });

      return response.data.product && response.data.product.id;
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "An error has occured! Please try again later.",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const remove_product = (product_pk) => async (dispatch) => {
  dispatch({
    type: REMOVE_SUPPLIER_PRODUCT,
    payload: {
      product_pk: product_pk,
    },
  });
};

export const add_back_product = (data) => async (dispatch) => {
  dispatch({
    type: CREATE_NEW_PRODUCT,
    payload: data,
  });
};

export const add_back_products = (data) => async (dispatch) => {
  dispatch({
    type: UPLOAD_PRODUCTS,
    payload: data.products,
  });
};

export const get_webapps = () => async (dispatch) => {
  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    try {
      const response = await axios.get(
        `${API_URL}/miscellaneous/webapps/`,
        config
      );

      dispatch({
        type: LOAD_WEBAPPS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "Unable to load webapps",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const clear_webapp_accounts = () => async (dispatch) => {
  dispatch({
    type: CLEAR_WEBAPP_ACCOUNTS,
  });
};

export const get_webapp_accounts = (pk) => async (dispatch) => {
  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    try {
      const response = await axios.get(
        `${API_URL}/miscellaneous/webapp_accounts/${pk}/`,
        config
      );
      dispatch({
        type: LOAD_WEBAPP_ACCOUNTS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "Unable to load webapp accounts",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const add_webapp = (data) => async (dispatch) => {
  dispatch({
    type: PROCESSING,
    payload: true,
  });
  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    let formData = new FormData();
    formData.append("name", data.name.toLowerCase());
    formData.append("manager_groups", data.manager_groups);

    try {
      const response = await axios.post(
        `${API_URL}/miscellaneous/webapps/`,
        formData,
        config
      );
      response.data.webapp &&
        dispatch({
          type: CREATE_WEBAPP,
          payload: response.data,
        });
      dispatch({
        type: SNACKBAR_WRITE,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "An error has occured! Please try again later.",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const edit_webapp = (data, webapp_pk) => async (dispatch) => {
  dispatch({
    type: PROCESSING,
    payload: true,
  });
  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    let formData = new FormData();
    formData.append("name", data.name.toLowerCase());
    formData.append("manager_groups", data.manager_groups);

    try {
      const response = await axios.put(
        `${API_URL}/miscellaneous/webapp/${webapp_pk}/`,
        formData,
        config
      );
      response.data.webapp &&
        dispatch({
          type: EDIT_WEBAPP,
          payload: {
            webappId: webapp_pk,
            webapp: response.data.webapp,
          },
        });
      dispatch({
        type: SNACKBAR_WRITE,
        payload: response.data,
      });
      return response.data.webapp;
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "An error has occured! Please try again later.",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const delete_webapp = (webapp_pk) => async (dispatch) => {
  dispatch({
    type: PROCESSING,
    payload: true,
  });
  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    try {
      const response = await axios.delete(
        `${API_URL}/miscellaneous/webapp/${webapp_pk}/`,
        config
      );

      dispatch({
        type: DELETE_WEBAPP,
        payload: {
          webapp_pk: webapp_pk,
          message: response.data.message,
          severity: response.data.severity,
        },
      });
      dispatch({
        type: CLEAR_WEBAPP_ACCOUNTS,
      });
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "An error has occured! Please try again later.",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const delete_multiple_webapps = (list) => async (dispatch) => {
  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
      data: { list: list },
    };

    try {
      const response = await axios.delete(
        `${API_URL}/miscellaneous/webapps/`,
        config
      );
      list.length > 0
        ? dispatch({
            type: DELETE_MULTIPLE_WEBAPPS,
            payload: {
              webapp_pks: list,
              message: response.data.message,
              severity: response.data.severity,
            },
          })
        : dispatch({
            type: DELETE_ALL_WEBAPPS,
            payload: response.data,
          });
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "Unable to delete webapps",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const add_webapp_account = (data, webapp_pk) => async (dispatch) => {
  dispatch({
    type: PROCESSING,
    payload: true,
  });
  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    let formData = new FormData();
    formData.append("username", data.username);
    formData.append("password", data.password);
    formData.append("account_type", data.account_type.toLowerCase());
    formData.append("url", data.url);
    formData.append("backup_email", data.backup_email || "");
    formData.append("phone_number", data.phone_number || "");

    try {
      const response = await axios.post(
        `${API_URL}/miscellaneous/webapp_accounts/${webapp_pk}/`,
        formData,
        config
      );

      response.data.account &&
        dispatch({
          type: CREATE_WEBAPP_ACCOUNT,
          payload: response.data,
        });

      dispatch({
        type: SNACKBAR_WRITE,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "An error has occured! Please try again later.",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const delete_account = (account_pk) => async (dispatch) => {
  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    try {
      await axios.delete(
        `${API_URL}/miscellaneous/account/${account_pk}/`,
        config
      );
      dispatch({
        type: DELETE_WEBAPP_ACCOUNT,
        payload: {
          message: "This account has been successfully deleted!",
          severity: "success",
          account_pk: account_pk,
        },
      });
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "Unable to delete account",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const delete_multiple_accounts =
  (webapp_pk, list) => async (dispatch) => {
    const access = localStorage.getItem("access");
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `JWT ${access}`,
        },
        data: { list: list },
      };

      try {
        const response = await axios.delete(
          `${API_URL}/miscellaneous/webapp_accounts/${webapp_pk}/`,
          config
        );
        webapp_pk === 0
          ? dispatch({
              type: DELETE_MULTIPLE_WEBAPP_ACCOUNTS,
              payload: {
                account_pks: list,
                message: response.data.message,
                severity: response.data.severity,
              },
            })
          : dispatch({
              type: DELETE_ALL_WEBAPP_ACCOUNTS,
              payload: response.data,
            });
      } catch (error) {
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: "Unable to delete accounts",
            severity: "error",
          },
        });
      }
    } else {
      dispatch({
        type: AUTHENTICATED_FAIL,
      });
    }
  };

export const edit_webapp_account = (data, webapp_pk) => async (dispatch) => {
  dispatch({
    type: PROCESSING,
    payload: true,
  });
  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    let formData = new FormData();
    formData.append("id", data.id);
    formData.append("username", data.username);
    formData.append("password", data.password);
    formData.append("account_type", data.account_type.toLowerCase());
    formData.append("url", data.url);
    formData.append("backup_email", data.backup_email || "");
    formData.append("phone_number", data.phone_number || "");

    try {
      const response = await axios.put(
        `${API_URL}/miscellaneous/webapp_accounts/${webapp_pk}/`,
        formData,
        config
      );

      response.data.account &&
        dispatch({
          type: EDIT_WEBAPP_ACCOUNT,
          payload: {
            accountId: data.id,
            account: response.data.account,
          },
        });

      dispatch({
        type: SNACKBAR_WRITE,
        payload: response.data,
      });

      return response.data.account;
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "An error has occured! Please try again later.",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const save_procurement = (formData) => async (dispatch) => {
  dispatch({
    type: SAVE_PROCUREMENT_DATA,
    payload: formData,
  });
};

export const discard_procurement = () => async (dispatch) => {
  dispatch({
    type: DISCARD_PROCUREMENT_DATA,
  });
};

export const get_all_groups = () => async (dispatch) => {
  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    try {
      const response = await axios.get(`${API_URL}/accounts/groups/`, config);

      dispatch({
        type: GET_GROUPS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "An error has occured! Please try again later.",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const get_all_user_accounts = () => async (dispatch) => {
  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    try {
      const response = await axios.get(
        `${API_URL}/accounts/simple_accounts/`,
        config
      );

      dispatch({
        type: GET_USER_ACCOUNTS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "An error has occured! Please try again later.",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const transfer_products = (supplier_pk, list) => async (dispatch) => {
  dispatch({
    type: PROCESSING,
    payload: true,
  });
  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    let formData = new FormData();
    formData.append("supplier_pk", JSON.stringify(supplier_pk));
    formData.append("products", JSON.stringify(list));

    try {
      const response = await axios.post(
        `${API_URL}/miscellaneous/transfer_supplier/`,
        formData,
        config
      );
      dispatch({
        type: PROCESSING,
        payload: false,
      });
      dispatch({
        type: SNACKBAR_WRITE,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "Unable to transfer products",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};
