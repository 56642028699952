import React from "react";
import {Typography, Card} from "@material-ui/core";
import rimads_strategy from "../../rimads-strategy.jpeg";

const Strategy = () => {
  const mdspace = <div style={{padding: 24, width: "100%"}}/>;
  const smspace = <div style={{padding: 8, width: "100%"}}/>;
  return (
    <div style={{maxWidth: 600}}>
      <Typography variant="h3">Strategy</Typography>
      {mdspace}
      <Typography variant="body1">
        Our mission is to reshape healthcare into a safer, faster, and widely
        accessible ecosystem. In order to accomplish this mission, Avey has
        developed a <b>hexagon strategy</b> that relies on six major pillars:
      </Typography>
      {mdspace}
      <Card elevation={8}>
        <img
          src={rimads_strategy}
          alt="avey strategy"
          height="100%"
          width="100%"
          style={{transition: "opacity 400ms ease 0ms"}}
        />
      </Card>
      {mdspace}
      <Typography variant="h6">1. Technology Superiority</Typography>
      {smspace}
      <Typography variant="body1">
        The key to Avey success is to possess a unique, valuable and nearly
        inimitable technology. This starts by focusing on challenging problems
        (e.g., performing medical diagnosis and prognosis using AI) that are
        hard to replicate and creating a framework through which they can be
        clearly defined, approached, and solved. To Avey, this framework
        encompasses four major components, namely, talents, culture, tools, and
        data. This framework will not eliminate risk, but rather increase the
        odds of success.
      </Typography>
      {mdspace}
      <Typography variant="h6">2. Design Elegance</Typography>
      {smspace}
      <Typography variant="body1">
        Identifying the correct problem and inventing the right solution are not
        enough to create a superior value proposition. As such, our algorithmic
        solution will be escorted with unique UX and UI to abstract its
        complexity and make it look and feel beautiful, elegant, friendly, and
        easy to use.
      </Typography>
      {mdspace}
      <Typography variant="h6">3. Marketing Quality</Typography>
      {smspace}
      <Typography variant="body1">
        A superior technology with an elegant interface serves as a foundation
        for marketing quality because it can drive positive word-of-mouth and
        enable universal reachability. However, to fully materialize marketing
        quality we will further apply effective segmentation & targeting,
        establish a brand of trust, speed, & elegance, utilize efficient
        customer acquisition techniques (e.g., via using a viral engine of
        growth), and ensure loyal retention (e.g., via being customer centric).
      </Typography>
      {mdspace}
      <Typography variant="h6">4. Customer Centrality</Typography>
      {smspace}
      <Typography variant="body1">
        It is not enough to put a superior product in the hands of a user.
        Avey will genuinely demonstrate strategic empathy via walking in the
        shoe of every single user, delighting them, constantly identifying their
        pain points, quickly adapting to their needs, predicting their
        convenience spots, and offering them unique solutions, especially in an
        increasingly fast-changing and dynamic world. To us, “fast adaptation”
        does not entail “fast following” that suggests copying what seems to be
        working for others, but rather innovating and leading by far.
      </Typography>
      {mdspace}
      <Typography variant="h6">5. Operational Excellence</Typography>
      {smspace}
      <Typography variant="body1">
        The key to avoiding any gap between ideas and actions is efficient and
        effective execution. Avey aims to execute with efficiency via
        automation and with effectiveness via data analytics. Every process
        within Avey will be automated, accelerated, and constantly elevated.
        The objective of this strategic technology-based and data-driven
        approach is to create an agile and nimble company that averts inertia
        and lethargy.
      </Typography>
      {mdspace}
      <Typography variant="h6">6. Financial Innovation</Typography>
      {smspace}
      <Typography variant="body1">
        Our approach to finance will not be limited to reducing cost but further
        to innovating on revenue channels via harnessing the power of data
        science and artificial intelligence.
      </Typography>
    </div>
  );
};

export default Strategy;
