export const CLEAR_UNPAID_FORMDATA = "CLEAR_UNPAID_FORMDATA";
export const CLEAR_PAID_FORMDATA = "CLEAR_PAID_FORMDATA";

export const GET_UNPAID_FORMDATA = "GET_UNPAID_FORMDATA";
export const GET_PAID_FORMDATA = "GET_PAID_FORMDATA";

export const UPDATE_UNPAID_FORMSTATUS = "UPDATE_UNPAID_FORMSTATUS";
export const UPDATE_PAID_FORMSTATUS = "UPDATE_PAID_FORMSTATUS";

export const PROCESSING_UNPAID_REQUEST = "PROCESSING_UNPAID_REQUEST";
export const PROCESSING_PAID_REQUEST = "PROCESSING_PAID_REQUEST";

export const GET_MARKETING_SUPPLIES_REQUESTS =
  "GET_MARKETING_SUPPLIES_REQUESTS";
