import React, {useState} from "react";
import {connect} from "react-redux";

import {makeStyles} from "@material-ui/core/styles";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ImageUpload from "../ImageUpload";
import CircularProgress from "@material-ui/core/CircularProgress";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import {post_reimbursement_request} from "../../../actions/reimbursement";
import {Redirect} from "react-router";

import Poppup from "../../../components/Poppup";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 600,
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "25ch",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
  },
  number: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
      {
        display: "none",
      },
  },
}));

const ReimbursementRequest = ({submitting, post_reimbursement_request}) => {
  const classes = useStyles();

  const [formData, setFormData] = useState({
    expense_for: "Avey",
    payment_type: "Bank transfer",
    reimbursement_amount: "",
    reasons_for_reimbursement: "",
    receipt_image: null,
  });
  const handleChange = (prop) => (event) => {
    setFormData({...formData, [prop]: event.target.value});
  };

  const [RRN, setRRN] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    post_reimbursement_request(formData).then((RRN) => setRRN(RRN));
    setFormData({
      ...formData,
      receipt_image: null,
    });
  };

  return RRN ? (
    <Redirect to={`/app/forms/reimbursement/form/${RRN}/`}/>
  ) : (
    <div className={classes.paper}>
      <Typography style={{marginBottom: 40}} variant="h3">
        Reimbursement
        <Poppup
          content={
            <Typography variant="body1">
              The purpose of this request form is to submit a request for a
              reimbursement when an employee makes a purchase on behalf of the
              company. Reimbursement requests must be submitted with receipts in
              order to be approved.
            </Typography>
          }
        />
      </Typography>
      {submitting ? (
        <div className={classes.center}>
          <CircularProgress style={{margin: "auto"}}/>
        </div>
      ) : (
        <form style={{width: "100%"}} onSubmit={(e) => onSubmit(e)}>
          <FormControl fullWidth variant="outlined" required>
            <InputLabel>Expense for</InputLabel>
            <Select
              value={formData.expense_for}
              onChange={handleChange("expense_for")}
              label="Expense for *"
            >
              <MenuItem value={"Rimads"}>Avey</MenuItem>
              <MenuItem value={"Reaf Life"}>Reaf Life</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth variant="outlined" required>
            <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
            <OutlinedInput
              label="Amount *"
              type="number"
              value={formData.reimbursement_amount}
              onChange={handleChange("reimbursement_amount")}
              startAdornment={
                <InputAdornment position="start">QAR</InputAdornment>
              }
              className={classes.number}
              inputProps={{min: 0, step: 0.01}}
            />
          </FormControl>
          <FormControl fullWidth variant="outlined" required>
            <TextField
              required
              label="Reasons"
              multiline
              onChange={handleChange("reasons_for_reimbursement")}
              value={formData.reasons_for_reimbursement}
              rows={4}
              variant="outlined"
            />
          </FormControl>
          <FormControl fullWidth variant="outlined" required>
            <TextField
              id="standard-select-currency"
              required
              select
              label="Preferred reimbursement type"
              value={formData.payment_type}
              onChange={handleChange("payment_type")}
              helperText={
                formData.payment_type === "Bank transfer" &&
                "This amount will be sent alongside your next salary transfer."
              }
              variant="outlined"
            >
              <MenuItem value={"Bank transfer"}>Bank transfer</MenuItem>
              <MenuItem value={"Cash"}>Cash</MenuItem>
              <MenuItem value={"Cheque"}>Cheque</MenuItem>
            </TextField>
          </FormControl>
          <FormControl fullWidth>
            <ImageUpload
              allowedToEdit={true}
              passReceipt={(file) =>
                setFormData({...formData, receipt_image: file})
              }
              receipt_image={formData.receipt_image}
            />
          </FormControl>
          <FormControl fullWidth>
            <Button variant="contained" color="primary" type="submit">
              Submit
            </Button>
          </FormControl>
        </form>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  submitting: state.reimbursement.submitting,
});

export default connect(mapStateToProps, {post_reimbursement_request})(
  ReimbursementRequest
);
