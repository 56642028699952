import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
// import { makeStyles } from "@material-ui/core";
import {
  load_birthdays,
  load_new_to_rimads,
  load_upcoming_events,
  load_whos_out,
} from "../../actions/home";
import "./styles.css";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";

// const useStyles = makeStyles((theme) => ({}));

function getNumberWithOrdinal(n) {
  var s = ["th", "st", "nd", "rd"],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

const Calendar = ({
                    load_birthdays,
                    load_new_to_rimads,
                    load_upcoming_events,
                    load_whos_out,
                    birthdays,
                    new_to_rimads,
                    upcoming_events,
                    whos_out,
                  }) => {
  //   const classes = useStyles();
  const [dateRange, setDateRange] = useState(null);
  const [employeeBirthdays, setBirthdays] = useState([]);
  const [newEmployees, setNewEmployees] = useState([]);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [whosOut, setWhosOut] = useState([]);

  const handleCalendarDateChange = (dateInfo) => {
    setDateRange({
      activeStart: new Date(dateInfo.view.activeStart),
      activeEnd: new Date(dateInfo.view.activeEnd),
    });
  };

  useEffect(() => {
    if (dateRange !== null) {
      const data = {
        start: dateRange.activeStart.toISOString(),
        end: dateRange.activeEnd.toISOString(),
      };
      const dataAlt = {
        start: dateRange.activeStart.getMonth(),
        end: dateRange.activeEnd.getMonth() || 12,
      };
      load_birthdays(dataAlt, true);
      load_new_to_rimads(dataAlt, true);
      load_upcoming_events(data, true);
      load_whos_out(data, true);
    }
  }, [
    load_new_to_rimads,
    load_upcoming_events,
    load_whos_out,
    load_birthdays,
    dateRange,
  ]);

  useEffect(() => {
    if (dateRange !== null) {
      const month = new Date(dateRange.activeEnd).getMonth();
      const year =
        month === 0
          ? new Date(dateRange.activeEnd).getFullYear() - 1
          : new Date(dateRange.activeEnd).getFullYear();

      setBirthdays(
        birthdays.map((employee) => {
          var dob = new Date(employee.dob);
          dob.setFullYear(year);
          return {
            employee: employee,
            type: "BIRTHDAY",
            title: `${employee.full_name}, Happy Birthday!`,
            start: dob.toISOString(),
            color: "#77E0FF",
          };
        })
      );
    }
  }, [birthdays, dateRange]);

  useEffect(() => {
    if (dateRange !== null) {
      const month = new Date(dateRange.activeEnd).getMonth();
      const year =
        month === 0
          ? new Date(dateRange.activeEnd).getFullYear() - 1
          : new Date(dateRange.activeEnd).getFullYear();

      setNewEmployees(
        new_to_rimads
          .map((new_employee) => {
            var anniversary = new Date(new_employee.joined_at);
            const difference = year - anniversary.getFullYear();
            anniversary.setFullYear(year);
            return (
              difference >= 0 && {
                new_employee: new_employee,
                type: "NEW_EMPLOYEE",
                title:
                  difference > 0
                    ? `${new_employee.full_name}, ${getNumberWithOrdinal(
                      difference
                    )} anniversary at Avey!`
                    : `${new_employee.full_name} joined Avey!`,
                start: anniversary.toISOString(),
                color: "#77E0FF",
              }
            );
          })
          .filter((x) => x)
      );
    }
  }, [new_to_rimads, dateRange]);

  useEffect(() => {
    setUpcomingEvents(
      upcoming_events.map((event) => ({
        event: event,
        type: "UPCOMING_EVENT",
        title: event.title,
        start: new Date(event.date_start).toISOString(),
        end: new Date(event.date_end).toISOString(),
        color: "#77E0FF",
        textColor: "black",
      }))
    );
  }, [upcoming_events]);

  useEffect(() => {
    setWhosOut(
      whos_out.map((time_off) => ({
        time_off: time_off,
        type: "TIME_OFF",
        title: `${time_off.employee.full_name}'s out`,
        start: new Date(time_off.date_start).toISOString(),
        end: new Date(time_off.date_end).toISOString(),
        color: "#C2C2C2",
        textColor: "black",
      }))
    );
  }, [whos_out]);
  return (
    <FullCalendar
      plugins={[dayGridPlugin]}
      initialView="dayGridMonth"
      headerToolbar={{
        left: "title",
        right: "prev,today,next",
      }}
      height={1000}
      events={[
        ...employeeBirthdays,
        ...newEmployees,
        ...upcomingEvents,
        ...whosOut,
      ]}
      eventStartEditable={false}
      eventDurationEditable={false}
      droppable={false}
      nowIndicator={true}
      selectOverlap={function (event) {
        return event.display === "background";
      }}
      eventOverlap={function (stillEvent, movingEvent) {
        return stillEvent.display === "background";
      }}
      datesSet={(dateInfo) => handleCalendarDateChange(dateInfo)}
    />
  );
};

const mapStateToProps = (state) => ({
  new_to_rimads: state.calendar.new_to_rimads,
  birthdays: state.calendar.birthdays,
  upcoming_events: state.calendar.upcoming_events,
  whos_out: state.calendar.whos_out,
});

export default connect(mapStateToProps, {
  load_birthdays,
  load_new_to_rimads,
  load_upcoming_events,
  load_whos_out,
})(Calendar);
