import React from "react";
import {
  Switch,
  Route,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
import "./App.css";

import Activate from "./containers/Authenticate/Activate";

import HomeNotificationWrapper from "./components/HomeNotificationWrapper";

import Login from "./containers/Authenticate/Login";
import Logout from "./containers/Authenticate/Logout";
import ResetPassword from "./containers/Authenticate/ResetPassword";
import ResetPasswordConfirm from "./containers/Authenticate/ResetPasswordConfirm";
import Signup from "./containers/Authenticate/Signup";
import NotFoundView from "./views/errors/NotFoundView";

import { Provider } from "react-redux";
import store from "./store";
import GlobalStyles from "./components/GlobalStyles";
import theme from "./theme";
import { ThemeProvider } from "@material-ui/core";

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Provider store={store}>
        <Router>
          <Switch>
            <Route exact path={[``]}>
              <Redirect to="/app/home" />
            </Route>
            <Route path={[`/app`]} component={HomeNotificationWrapper} />
            <Route exact path={[`/login`]} component={Login} />
            <Route exact path={[`/logout`]} component={Logout} />
            <Route exact path={[`/signup`]} component={Signup} />
            <Route exact path={[`/reset-password`]} component={ResetPassword} />
            <Route
              exact
              path={`/password/reset/confirm/:uid/:token`}
              component={ResetPasswordConfirm}
            />
            <Route
              exact
              path={[`/activate/:uid/:token`]}
              component={Activate}
            />
            <Route path={["*"]} component={NotFoundView} />
          </Switch>
        </Router>
      </Provider>
    </ThemeProvider>
  );
}
