import React from "react";
import { Avatar, Card, Divider, Grid, Typography } from "@material-ui/core";
import CircularProgressWithLabel from "../../../containers/StaticPages/MyAccount/LabeledCircularProgress";

const BaseRow = ({
  _from_value,
  _to_value,
  label = "",
  alignItems = "center",
}) => (
  <Grid
    item
    xs={12}
    style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    }}
  >
    <Grid container spacing={3} alignItems={alignItems}>
      <Grid item xs={4}>
        {_from_value}
      </Grid>
      <Grid item xs={4}>
        <Typography variant="caption">{label}</Typography>
      </Grid>
      <Grid item xs={4}>
        {_to_value}
      </Grid>
    </Grid>
  </Grid>
);

const MetricRow = ({
  _from_value,
  _to_value,
  denominator = 100,
  units = "%",
  label = "",
}) => (
  <BaseRow
    label={label}
    _from_value={
      <CircularProgressWithLabel
        value={_from_value}
        denominator={denominator}
        size="sm_alt"
        units={units}
        color={"textPrimary"}
        style={{ justifyContent: "flex-end", display: "flex" }}
      />
    }
    _to_value={
      <CircularProgressWithLabel
        value={_to_value}
        denominator={denominator}
        size="sm_alt"
        units={units}
        color={"textPrimary"}
        style={{ flexDirection: "row-reverse" }}
      />
    }
  />
);

const NoteRow = ({ _from_value, _to_value, label = "" }) => (
  <BaseRow
    label={label}
    _from_value={
      <Typography
        component="div"
        variant="caption"
        style={{ textAlign: "start", whiteSpace: "break-spaces" }}
      >
        {_from_value}
      </Typography>
    }
    _to_value={
      <Typography
        component="div"
        variant="caption"
        style={{ textAlign: "end", whiteSpace: "break-spaces" }}
      >
        {_to_value}
      </Typography>
    }
  />
);

const GridDivider = () => (
  <Grid item xs={12} style={{ margin: "16px 0px" }}>
    <Divider />
  </Grid>
);

export default function EvaluationHistoryCard({ evaluation = {} }) {
  const {
    // id = 572,
    _to = {
      id: 2,
      full_name: "James France",
      picture: null,
      job_title: "Frontend Engineer",
    },
    _from = {
      id: 2,
      full_name: "Franco James",
      picture: null,
      job_title: "Backend Engineer",
    },
    // created_at = "2023-01-24T20:02:59.695691+03:00",
    // modified_at = "2023-01-24T20:02:59.696068+03:00",
    time_is_not_applicable = null,
    week_start_date = "2022-12-27",
    // week_end_date = "2023-01-03",
    utilization_percent_from = 4,
    utilization_percent_to = 9,
    performance_level_percent_from = 88,
    performance_level_percent_to = 93,
    num_days_physical_attendance_from = 2,
    num_days_physical_attendance_to = 7,
    note_from = "23232",
    note_to = "-",
    tasks_from = "-",
    tasks_to = "-",
    num_workdays = 7,
    // department_team = 1,
  } = evaluation;

  return (
    <div>
      <Card elevation={8} style={{ padding: 20 }}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <Typography
              display="inline"
              variant="body2"
              style={{ fontWeight: "bold" }}
            >
              {new Date(week_start_date).toLocaleDateString("en-GB", {
                day: "numeric",
                month: "long",
                year: "2-digit",
              })}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <div style={{ marginRight: 24, textAlign: "right" }}>
              <Typography variant="body2" style={{ fontWeight: "bold" }}>
                {_from?.full_name}
              </Typography>
              <Typography variant="caption">{"Supervisor"}</Typography>
            </div>
            <Avatar
              src={_from?.picture}
              alt={_from?.full_name}
              variant="square"
            />
          </Grid>
          <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
            <Avatar src={_to?.picture} alt={_to?.full_name} variant="square" />
            <div style={{ marginLeft: 24, textAlign: "left" }}>
              <Typography variant="body2" style={{ fontWeight: "bold" }}>
                {_to?.full_name}
              </Typography>
              <Typography variant="caption">{"Supervisee"}</Typography>
            </div>
          </Grid>
          {Boolean(time_is_not_applicable) ? (
            <Grid item xs={12}>
              <Typography variant="caption">{"Not applicable"}</Typography>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Grid container spacing={0}>
                <MetricRow
                  _from_value={utilization_percent_from}
                  _to_value={utilization_percent_to}
                  label="Utilization"
                />
                <MetricRow
                  _from_value={performance_level_percent_from}
                  _to_value={performance_level_percent_to}
                  label="Performance level"
                />
                <MetricRow
                  _from_value={num_days_physical_attendance_from}
                  _to_value={num_days_physical_attendance_to}
                  label="Physical attendance"
                  units={`/ ${num_workdays}`}
                  denominator={num_workdays}
                />
                <GridDivider />
                <NoteRow
                  _from_value={note_from || "-"}
                  _to_value={note_to || "-"}
                  label={"Notes"}
                />
                <GridDivider />
                <NoteRow
                  _from_value={tasks_from || "-"}
                  _to_value={tasks_to || "-"}
                  label={"Tasks completed"}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Card>
    </div>
  );
}
