import axios from "axios";
import { SNACKBAR_WRITE } from "./procedureType";

import {
  CLEAR_UNPAID_FORMDATA,
  CLEAR_PAID_FORMDATA,
  GET_UNPAID_FORMDATA,
  GET_PAID_FORMDATA,
  UPDATE_UNPAID_FORMSTATUS,
  UPDATE_PAID_FORMSTATUS,
  PROCESSING_UNPAID_REQUEST,
  PROCESSING_PAID_REQUEST,
  GET_MARKETING_SUPPLIES_REQUESTS,
} from "./marketingTypes";
import { AUTHENTICATED_FAIL } from "./authTypes";

import global from "../global";
const { API_URL } = global;

export const post_unpaid_campaign_request = (data) => async (dispatch) => {
  dispatch({
    type: PROCESSING_UNPAID_REQUEST,
    payload: true,
  });
  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };
    let formData = new FormData();
    formData.append("campaign_name", data.campaign_name);
    formData.append("category", data.category);
    formData.append(
      "qualitative_justification",
      data.qualitative_justification
    );
    // formData.append("expected_cost", data.expected_cost);
    // formData.append("expected_ROI", data.expected_ROI);
    formData.append(
      "start_date",
      new Date(data.start_date).toLocaleDateString("en-GB")
    );
    formData.append(
      "end_date",
      data.end_date !== null &&
        new Date(data.end_date).toLocaleDateString("en-GB")
    );
    try {
      const response = await axios.post(
        `${API_URL}/marketing_expenses/unpaid_campaign/`,
        formData,
        config
      );
      dispatch({
        type: SNACKBAR_WRITE,
        payload: response.data,
      });
      dispatch({
        type: PROCESSING_UNPAID_REQUEST,
        payload: false,
      });

      return response.data.UCN;
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "An error has occured! Please try again later.",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const post_marketing_expense_request = (data) => async (dispatch) => {
  dispatch({
    type: PROCESSING_PAID_REQUEST,
    payload: true,
  });
  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    let formData = new FormData();
    formData.append("channel_type", data.channel_type);
    formData.append("payment_type", data.payment_type);
    formData.append("amount_requested", data.amount_requested);
    data.channel_type === "online" &&
      formData.append(
        "webapps",
        JSON.stringify(data.webapps.map((webapp) => webapp.id))
      );
    data.channel_type === "offline" &&
      formData.append("conducted_by", data.conducted_by);
    data.channel_type === "offline" &&
      formData.append("conducted_at", data.conducted_at);
    data.channel_type === "offline" && formData.append("actors", data.actors);
    data.channel_type === "offline" && data.inventory_requests
      ? formData.append(
          "inventory_requests",
          JSON.stringify(data.inventory_requests)
        )
      : formData.append("inventory_requests", JSON.stringify([]));
    formData.append(
      "start_date",
      new Date(data.start_date).toLocaleDateString("en-GB")
    );
    formData.append(
      "end_date",
      data.end_date !== null &&
        new Date(data.end_date).toLocaleDateString("en-GB")
    );
    formData.append("justification", data.justification);
    try {
      const response = await axios.post(
        `${API_URL}/marketing_expenses/paid_expense/`,
        formData,
        config
      );
      dispatch({
        type: SNACKBAR_WRITE,
        payload: response.data,
      });
      dispatch({
        type: PROCESSING_PAID_REQUEST,
        payload: false,
      });

      return response.data.MERN;
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "An error has occured! Please try again later.",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const get_unpaid_campaign_request = (UCN) => async (dispatch) => {
  dispatch({
    type: CLEAR_UNPAID_FORMDATA,
  });

  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    try {
      const response = await axios.get(
        `${API_URL}/marketing_expenses/unpaid_campaign/${UCN}/`,
        config
      );
      dispatch({
        type: GET_UNPAID_FORMDATA,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "Unable to load request",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const get_marketing_expense_request = (MERN) => async (dispatch) => {
  dispatch({
    type: CLEAR_PAID_FORMDATA,
  });

  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    try {
      const response = await axios.get(
        `${API_URL}/marketing_expenses/paid_expense/${MERN}/`,
        config
      );
      dispatch({
        type: GET_PAID_FORMDATA,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "Unable to load request",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const update_unpaid_campaign_request =
  (data, UCN) => async (dispatch) => {
    dispatch({
      type: PROCESSING_UNPAID_REQUEST,
      payload: true,
    });
    const access = localStorage.getItem("access");
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: `JWT ${access}`,
        },
      };
      let formData = new FormData();
      formData.append("campaign_name", data.campaign_name);
      formData.append("category", data.category);
      formData.append(
        "qualitative_justification",
        data.qualitative_justification
      );
      // formData.append("expected_cost", data.expected_cost);
      // formData.append("expected_ROI", data.expected_ROI);
      formData.append(
        "start_date",
        new Date(data.start_date).toLocaleDateString("en-GB")
      );
      formData.append(
        "end_date",
        data.end_date !== null &&
          new Date(data.end_date).toLocaleDateString("en-GB")
      );
      try {
        const response = await axios.put(
          `${API_URL}/marketing_expenses/unpaid_campaign/${UCN}/`,
          formData,
          config
        );
        dispatch({
          type: UPDATE_UNPAID_FORMSTATUS,
          payload: response.data,
        });
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: response.data.message,
            severity: response.data.severity,
          },
        });
      } catch (error) {
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: "An error has occured! Please try again later.",
            severity: "error",
          },
        });
      }
    } else {
      dispatch({
        type: AUTHENTICATED_FAIL,
      });
    }
  };

export const update_marketing_expense_request =
  (data, MERN) => async (dispatch) => {
    dispatch({
      type: PROCESSING_PAID_REQUEST,
      payload: true,
    });
    const access = localStorage.getItem("access");
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `JWT ${access}`,
        },
      };
      let formData = new FormData();
      formData.append("channel_type", data.channel_type);
      formData.append("payment_type", data.payment_type);
      formData.append("amount_requested", data.amount_requested);
      data.channel_type === "online" &&
        formData.append(
          "webapps",
          JSON.stringify(data.webapps.map((webapp) => webapp.id))
        );
      data.channel_type === "offline" &&
        formData.append("conducted_by", data.conducted_by);
      data.channel_type === "offline" &&
        formData.append("conducted_at", data.conducted_at);
      data.channel_type === "offline" && formData.append("actors", data.actors);
      data.channel_type === "offline" && data.inventory_requests
        ? formData.append(
            "inventory_requests",
            JSON.stringify(data.inventory_requests)
          )
        : formData.append("inventory_requests", JSON.stringify([]));
      formData.append(
        "start_date",
        new Date(data.start_date).toLocaleDateString("en-GB")
      );
      formData.append(
        "end_date",
        data.end_date !== null &&
          new Date(data.end_date).toLocaleDateString("en-GB")
      );
      formData.append("justification", data.justification);
      try {
        const response = await axios.put(
          `${API_URL}/marketing_expenses/paid_expense/${MERN}/`,
          formData,
          config
        );
        dispatch({
          type: UPDATE_PAID_FORMSTATUS,
          payload: response.data,
        });
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: response.data.message,
            severity: response.data.severity,
          },
        });
      } catch (error) {
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: "An error has occured! Please try again later.",
            severity: "error",
          },
        });
      }
    } else {
      dispatch({
        type: AUTHENTICATED_FAIL,
      });
    }
  };

export const update_unpaid_campaign_request_status =
  (status, UCN, note) => async (dispatch) => {
    const access = localStorage.getItem("access");
    if (localStorage.getItem("access")) {
      dispatch({
        type: PROCESSING_UNPAID_REQUEST,
        payload: true,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `JWT ${access}`,
        },
      };

      const body = JSON.stringify({ status: status, note: note });
      try {
        const response = await axios.post(
          `${API_URL}/marketing_expenses/unpaid_campaign/${UCN}/`,
          body,
          config
        );
        dispatch({
          type: UPDATE_UNPAID_FORMSTATUS,
          payload: response.data,
        });
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: response.data.message,
            severity: response.data.severity,
          },
        });
      } catch (error) {
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: "An error has occured! Please try again later.",
            severity: "error",
          },
        });
      }
    } else {
      dispatch({
        type: AUTHENTICATED_FAIL,
      });
    }
  };

export const update_marketing_expense_request_status =
  (status, MERN, note) => async (dispatch) => {
    const access = localStorage.getItem("access");
    if (localStorage.getItem("access")) {
      dispatch({
        type: PROCESSING_PAID_REQUEST,
        payload: true,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `JWT ${access}`,
        },
      };

      const body = JSON.stringify({ status: status, note: note });
      try {
        const response = await axios.post(
          `${API_URL}/marketing_expenses/paid_expense/${MERN}/`,
          body,
          config
        );
        dispatch({
          type: UPDATE_PAID_FORMSTATUS,
          payload: response.data,
        });
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: response.data.message,
            severity: response.data.severity,
          },
        });
      } catch (error) {
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: "An error has occured! Please try again later.",
            severity: "error",
          },
        });
      }
    } else {
      dispatch({
        type: AUTHENTICATED_FAIL,
      });
    }
  };

export const submit_marketing_expense_payment_details =
  (form, MERN) => async (dispatch) => {
    dispatch({
      type: PROCESSING_PAID_REQUEST,
      payload: true,
    });
    const access = localStorage.getItem("access");
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `JWT ${access}`,
        },
      };

      const body = JSON.stringify({
        status: "to collect",
        payment_type: form.payment_type,
        associated_payment_id: form.associated_payment_id,
      });

      try {
        const response = await axios.post(
          `${API_URL}/marketing_expenses/paid_expense/${MERN}/`,
          body,
          config
        );
        dispatch({
          type: UPDATE_PAID_FORMSTATUS,
          payload: response.data,
        });
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: response.data.message,
            severity: response.data.severity,
          },
        });
      } catch (error) {
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: "An error has occured! Please try again later.",
            severity: "error",
          },
        });
      }
    } else {
      dispatch({
        type: AUTHENTICATED_FAIL,
      });
    }
  };

export const get_marketing_supplies_requests = () => async (dispatch) => {
  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    try {
      const response = await axios.get(
        `${API_URL}/procurement/inventory_requests/`,
        config
      );
      dispatch({
        type: GET_MARKETING_SUPPLIES_REQUESTS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "An error has occured! Please try again later.",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const update_unpaid_campaign_request_accounted_by =
  (form, UCN) => async (dispatch) => {
    const access = localStorage.getItem("access");
    if (localStorage.getItem("access")) {
      dispatch({
        type: PROCESSING_UNPAID_REQUEST,
        payload: true,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `JWT ${access}`,
        },
      };

      const body = JSON.stringify({
        status: "account",
        expected_cost: form.expected_cost,
        expected_ROI: form.expected_ROI,
      });
      try {
        const response = await axios.post(
          `${API_URL}/marketing_expenses/unpaid_campaign/${UCN}/`,
          body,
          config
        );
        dispatch({
          type: UPDATE_UNPAID_FORMSTATUS,
          payload: response.data,
        });
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: response.data.message,
            severity: response.data.severity,
          },
        });
      } catch (error) {
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: "An error has occured! Please try again later.",
            severity: "error",
          },
        });
      }
    } else {
      dispatch({
        type: AUTHENTICATED_FAIL,
      });
    }
  };
