import React, { Fragment } from "react";

import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import NonEditableProducts from "./NonEditableProducts";
import NonEditableOnlinePayments from "./NonEditableOnlinePayments";
import OfflinePayments from "./OfflinePayments";
// import CircularProgress from "@material-ui/core/CircularProgress";
// import ProcurementProducts from "./ProcurementProducts";
// import ProcurementDetails from "./ProcurementDetails";
import { TextField } from "@material-ui/core";
// import { date } from "date-fns/locale/af";
// import Progress from "./Progress";

// const [loaded, setLoaded] = useState(false);

// const onLoad = () => {
//   setFormData(procurementData);
//   setLoaded(true);
//   procurementData.supplier &&
//     get_supplier_products(procurementData.supplier.id);
// };

// const onSave = () => {
//   save_procurement(formData);
// };

// const onDiscard = () => {
//   discard_procurement();
// };

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
  },
  subheader: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 20,
    marginLeft: 0,
    marginTop: 60,
  },
  card: {
    minWidth: 200,
    position: "fixed",
    top: "50%",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
  },
}));

const NonEditableInputView = ({ data, IRN }) => {
  const classes = useStyles();

  return (
    data.supplier && (
      <div style={{ width: "100%" }}>
        <FormControl fullWidth>
          <TextField
            label="Procurement type"
            InputProps={{ disableUnderline: true, readOnly: true }}
            value={data.request_type}
          />
        </FormControl>
        <FormControl fullWidth>
          <TextField
            label="Ordering for"
            InputProps={{ disableUnderline: true, readOnly: true }}
            value={data.ordering_for}
          />
        </FormControl>
        <Typography className={classes.subheader}>Products</Typography>
        <FormControl fullWidth>
          <TextField
            label="Supplier"
            InputProps={{ disableUnderline: true, readOnly: true }}
            value={data.supplier.name}
          />
        </FormControl>
        <NonEditableProducts
          IRN={IRN}
          supplier_name={data.supplier.name}
          supplier_products={Object.values(data.products)}
          approval_status={data.approval_status}
          expected_receiver_staff_username={
            data.expected_receiver_staff.username
          }
        />
        <Typography className={classes.subheader}>Details</Typography>
        {/* <ProcurementDetails
                  }
                  formData={data}
                  setFormData={setFormData}
                  setNext={() => {}}
                /> */}
        <FormControl fullWidth>
          <TextField
            label="Receiver staffs"
            InputProps={{ disableUnderline: true, readOnly: true }}
            value={data.expected_receiver_staff
              .map((staff) => staff.full_name)
              .join(", ")}
          />
        </FormControl>
        <FormControl fullWidth>
          <TextField
            label="Delivery destination"
            InputProps={{ disableUnderline: true, readOnly: true }}
            value={data.delivery_destination}
          />
        </FormControl>
        {data.expected_delivery_cost && (
          <FormControl fullWidth>
            <TextField
              label="Expected delivery cost"
              InputProps={{ disableUnderline: true, readOnly: true }}
              value={data.expected_delivery_cost.toFixed(2)}
            />
          </FormControl>
        )}
        <FormControl fullWidth>
          <TextField
            label="Expected delivery date"
            InputProps={{ disableUnderline: true, readOnly: true }}
            value={new Date(data.expected_delivery_date).toLocaleDateString(
              "en-GB"
            )}
          />
        </FormControl>

        <Fragment>
          <Typography className={classes.subheader}>Payments</Typography>
          <FormControl fullWidth>
            <TextField
              label="Payment type"
              InputProps={{ disableUnderline: true, readOnly: true }}
              value={data.payment_type}
            />
          </FormControl>
          {data.payment_type === "offline" && (
            <OfflinePayments
              formData={data}
              setTotalPaymentPrice={() => {}}
              AllowedToEdit={false}
              remainingAmount={0}
            />
          )}
          {data.payment_type === "online" && (
            <NonEditableOnlinePayments formData={data} />
          )}
        </Fragment>
      </div>
    )
  );
};

export default NonEditableInputView;
