import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {
  Grid,
  Typography,
  CircularProgress,
  makeStyles,
  Dialog,
} from "@material-ui/core";
import {
  load_birthdays,
  load_new_to_rimads,
  load_upcoming_events,
  load_whos_out,
  load_anniversaries,
} from "../../actions/home";
import Event from "./Event";
import EmployeeAvatar from "./EmployeeAvatar";
import Calendar from "./Calendar";
import CakeIcon from "@material-ui/icons/Cake";
import Quarter from "./Milestones/Quarter";

const useStyles = makeStyles((theme) => ({
  pseudoLink: {
    cursor: "pointer",
    fontWeight: "bold",
    "&:hover": {
      color: "#77E0FF",
      textDecoration: "underline",
    },
  },
  dialogPaper: {
    minWidth: "80vw",
    padding: 20,
  },
}));

function getNumberWithOrdinal(n) {
  var s = ["th", "st", "nd", "rd"],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

const Home = ({
                current_user,
                load_birthdays,
                birthdays,
                load_new_to_rimads,
                new_to_rimads,
                load_upcoming_events,
                upcoming_events,
                load_whos_out,
                whos_out,
                load_anniversaries,
                anniversaries,
              }) => {
  const classes = useStyles();
  const [loadingNew, setLoadingNew] = useState(true);
  const [loadingBirthdays, setLoadingBirthdays] = useState(true);
  const [loadingEvents, setLoadingEvents] = useState(true);
  const [loadingWhosOut, setLoadingWhosOut] = useState(true);
  const [loadingAnniversaries, setLoadingAnniversaries] = useState(true);
  const [message, setMessage] = useState("");
  const [openCalendar, setOpenCalendar] = useState(false);

  useEffect(() => {
    const today = new Date().toISOString();
    let nextWeek = new Date();
    let lastWeek = new Date();
    nextWeek.setDate(nextWeek.getDate() + 7);
    lastWeek.setDate(lastWeek.getDate() - 14);

    load_new_to_rimads({
      start: lastWeek.toISOString(),
      end: today,
    }).then(() => setLoadingNew(false));

    load_upcoming_events({
      start: today,
      end: nextWeek.toISOString(),
    }).then(() => setLoadingEvents(false));

    load_birthdays({
      day: new Date().getDate(),
      start: new Date().getMonth() + 1,
      end: new Date().getMonth() + 1,
    }).then(() => setLoadingBirthdays(false));

    load_anniversaries({
      day: new Date().getDate(),
      start: new Date().getMonth() + 1,
      end: new Date().getMonth() + 1,
    }).then(() => setLoadingAnniversaries(false));

    load_whos_out({
      start: today,
      end: today,
    }).then(() => setLoadingWhosOut(false));
  }, [
    load_birthdays,
    load_new_to_rimads,
    load_upcoming_events,
    load_whos_out,
    load_anniversaries,
  ]);

  useEffect(() => {
    const currentHour = new Date().getHours();

    if (0 <= currentHour && currentHour <= 11) {
      setMessage("Good Morning");
    } else if (12 <= currentHour && currentHour <= 17) {
      setMessage("Good Afternoon");
    } else {
      setMessage("Good Evening");
    }
  }, []);

  return (
    <div>
      <Typography variant="h3" style={{marginBottom: 40, fontWeight: "bold"}}>
        {message},{" "}
        {current_user.full_name && current_user.full_name.split(" ")[0]}
      </Typography>

      <Quarter/>

      <Grid container spacing={2} style={{marginBottom: 80}}>
        <Grid item xs={12} md={8} style={{display: "flex"}}>
          <Typography variant="h4" style={{fontWeight: "bold"}}>
            New to Avey
          </Typography>
          <div style={{flexGrow: 1}}/>
          {loadingNew && <CircularProgress/>}
        </Grid>

        {new_to_rimads.length > 0 ? (
          new_to_rimads.map((employee) => (
            <Grid key={employee.id} item xs={12} md={7}>
              <Event
                employee={employee}
                image={employee.picture}
                title={`${employee.full_name}, ${employee.job_title}`}
                subtitle={`Started ${new Date(
                  employee.joined_at
                ).toLocaleDateString("en-US", {
                  weekday: "long",
                  day: "numeric",
                  month: "long",
                  // year: "numeric",
                })}`}
              />
            </Grid>
          ))
        ) : (
          <Grid item xs={12} md={8}>
            <Typography color="textSecondary" variant="subtitle2">
              There are no new people.
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2} style={{marginBottom: 80}}>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2} style={{marginBottom: 80}}>
            <Grid item xs={12} md={10} style={{display: "flex"}}>
              <Typography variant="h4" style={{fontWeight: "bold"}}>
                Upcoming Events
              </Typography>
              <div style={{flexGrow: 1}}/>
              {loadingEvents || loadingBirthdays || loadingAnniversaries ? (
                <CircularProgress/>
              ) : (
                <Typography
                  variant="body1"
                  color="primary"
                  className={classes.pseudoLink}
                  onClick={() => setOpenCalendar(!openCalendar)}
                >
                  View calendar
                </Typography>
              )}
            </Grid>

            {upcoming_events.length > 0 && (
              <Grid item xs={12} md={10}>
                <Typography
                  variant="h6"
                  style={{fontWeight: "bolder", marginTop: 20}}
                >
                  Events
                </Typography>
              </Grid>
            )}
            {upcoming_events.map((event) => (
              <Grid key={event.id} item xs={12} md={10}>
                <Event
                  image={event.picture}
                  title={event.title}
                  subtitle={`${new Date(event.date_start).toLocaleDateString(
                    "en-US",
                    {
                      weekday: "long",
                      day: "numeric",
                      month: "long",
                    }
                  )}${event.subtitle ? `-${event.subtitle}` : ""}`}
                />
              </Grid>
            ))}

            {birthdays.length > 0 && (
              <Grid item xs={12} md={10}>
                <Typography
                  variant="h6"
                  style={{fontWeight: "bolder", marginTop: 20}}
                >
                  Birthdays
                </Typography>
              </Grid>
            )}
            {birthdays.map((employee) => {
              var dob = new Date(employee.dob);
              dob.setFullYear(new Date().getFullYear());
              return (
                <Grid key={employee.id} item xs={12} md={10}>
                  <Event
                    image={employee.picture}
                    title={
                      <div style={{display: "flex", flexDirection: "row"}}>
                        <div> {employee.full_name}</div>
                        <div style={{flexGrow: 1}}/>
                        <CakeIcon color="primary"/>
                      </div>
                    }
                    subtitle={
                      <span>
                        {new Date(dob).toLocaleDateString("en-US", {
                          weekday: "long",
                          day: "numeric",
                          month: "long",
                        })}{" "}
                        - Happy birthday!
                      </span>
                    }
                  />
                </Grid>
              );
            })}

            {anniversaries.length > 0 && (
              <Grid item xs={12} md={10}>
                <Typography
                  variant="h6"
                  style={{fontWeight: "bolder", marginTop: 20}}
                >
                  Anniversaries
                </Typography>
              </Grid>
            )}
            {anniversaries.map((employee) => {
              var joined_at = new Date(employee.joined_at);
              const year = joined_at.getFullYear();
              const current_year = new Date().getFullYear();
              joined_at.setFullYear(current_year);
              var difference = current_year - year;
              return (
                difference > 0 && (
                  <Grid key={employee.id} item xs={12} md={10}>
                    <Event
                      image={employee.picture}
                      title={employee.full_name}
                      subtitle={`${new Date(joined_at).toLocaleDateString(
                        "en-US",
                        {
                          weekday: "long",
                          day: "numeric",
                          month: "long",
                        }
                      )} - ${getNumberWithOrdinal(
                        difference
                      )} anniversary at Avey!`}
                    />
                  </Grid>
                )
              );
            })}

            {upcoming_events.length === 0 &&
              birthdays.length === 0 &&
              anniversaries.length === 0 && (
                <Grid item xs={12} md={8}>
                  <Typography color="textSecondary" variant="subtitle2">
                    There are no upcoming events.
                  </Typography>
                </Grid>
              )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container spacing={2} style={{maxWidth: 500}}>
            <Grid item xs={12} style={{display: "flex"}}>
              <Typography variant="h4" style={{fontWeight: "bold"}}>
                Who's out
              </Typography>
              <div style={{flexGrow: 1}}/>
              {loadingWhosOut && <CircularProgress/>}
            </Grid>
            {whos_out.length > 0 ? (
              whos_out.map((time_off) => (
                <Grid key={time_off.id} item xs={6}>
                  <EmployeeAvatar
                    name={time_off.employee.full_name}
                    image={time_off.employee.picture}
                    title={`${new Date(time_off.date_start).toLocaleDateString(
                      "en-US",
                      {
                        day: "numeric",
                        month: "long",
                      }
                    )} to ${new Date(time_off.date_end).toLocaleDateString(
                      "en-US",
                      {
                        day: "numeric",
                        month: "long",
                      }
                    )}`}
                  />
                </Grid>
              ))
            ) : (
              <Grid item xs={12} md={8}>
                <Typography color="textSecondary" variant="subtitle2">
                  Nobody is out today.
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={openCalendar}
        onClose={() => setOpenCalendar(false)}
        fullWidth
        classes={{paper: classes.dialogPaper}}
      >
        <Calendar/>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  current_user: state.auth.user,
  new_to_rimads: state.home.new_to_rimads,
  birthdays: state.home.birthdays,
  upcoming_events: state.home.upcoming_events,
  whos_out: state.home.whos_out,
  anniversaries: state.home.anniversaries,
});

export default connect(mapStateToProps, {
  load_birthdays,
  load_new_to_rimads,
  load_upcoming_events,
  load_whos_out,
  load_anniversaries,
})(Home);
