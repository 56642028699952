import React from "react";
import { connect } from "react-redux";
import {
  weeklyKpisLoadDepartmentTeam,
  weeklyKpisResetDepartmentTeam,
} from "../../../actions/weeklyKpis";
import { useParams } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";
import WeeklyKpisTeamRoutes from "./WeeklyKpisTeamRoutes";
import CustomCircularProgress from "../../CustomCircularProgress";
import WeeklyKpisDepartmentTeamTab from "./WeeklyKpisDepartmentTeamTab";

const getTabs = ({ id, is_manager, is_supervisor, is_supervisee }) => {
  const ActiveEvaluationsTab = (
    <WeeklyKpisDepartmentTeamTab
      to={`/app/forms/team-performance/teams/${id}/weekly-team-evaluations`}
      text="Active Evaluations"
    />
  );
  const PastEvaluationsTab = (
    <WeeklyKpisDepartmentTeamTab
      to={`/app/forms/team-performance/teams/${id}/history`}
      text="Past Evaluations"
    />
  );
  const MetricsTab = (
    <WeeklyKpisDepartmentTeamTab
      to={`/app/forms/team-performance/teams/${id}/metrics`}
      text="Metrics"
    />
  );

  if (is_supervisor) {
    return (
      <React.Fragment>
        {ActiveEvaluationsTab}
        {PastEvaluationsTab}
        {MetricsTab}
      </React.Fragment>
    );
  } else if (is_manager) {
    return (
      <React.Fragment>
        {PastEvaluationsTab}
        {MetricsTab}
      </React.Fragment>
    );
  } else if (is_supervisee) {
    return (
      <React.Fragment>
        {ActiveEvaluationsTab}
        {PastEvaluationsTab}
      </React.Fragment>
    );
  }
  return null;
};

const useEffectDepartmentTeam = ({
  weeklyKpisLoadDepartmentTeam,
  weeklyKpisResetDepartmentTeam,
}) => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    setIsLoading(true);
    if (id) {
      weeklyKpisLoadDepartmentTeam(id).then(() => setIsLoading(false));
    }
    return () => {
      weeklyKpisResetDepartmentTeam();
      setIsLoading(true);
    };
  }, [weeklyKpisLoadDepartmentTeam, weeklyKpisResetDepartmentTeam, id]);

  return { isLoading };
};

const DepartmentTeam = ({
  weeklyKpisLoadDepartmentTeam,
  weeklyKpisResetDepartmentTeam,
  department_team,
}) => {
  const { isLoading } = useEffectDepartmentTeam({
    weeklyKpisLoadDepartmentTeam,
    weeklyKpisResetDepartmentTeam,
  });

  if (isLoading) {
    return <CustomCircularProgress />;
  }

  const { id, name, slug, is_manager, is_supervisor, is_supervisee } =
    department_team;

  if (!id) {
    return <Typography variant="body2">{"No department team"}</Typography>;
  }

  const Tabs = getTabs({
    id,
    is_manager,
    is_supervisor,
    is_supervisee,
  });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <Typography variant="h3" gutterBottom>
          {name}
        </Typography>
        <Typography>{slug}</Typography>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 24 }}>
        <Grid container spacing={3} justifyContent="center">
          {Tabs}
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <WeeklyKpisTeamRoutes />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  department_team: state.weekly_kpis.department_team,
});

export default connect(mapStateToProps, {
  weeklyKpisLoadDepartmentTeam,
  weeklyKpisResetDepartmentTeam,
})(DepartmentTeam);
