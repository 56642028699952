import React from "react";
import {Card, CardActionArea} from "@material-ui/core";
import {NavLink} from "react-router-dom";

export default function CustomNavLinkCard({
                                            to = "",
                                            children,
                                            style = {textAlign: "center"},
                                            getActiveStyle = (isActive) => ({
                                              backgroundColor: isActive ? "#77E0FF" : "white",
                                              color: isActive ? "white" : "black",
                                              transition: "all 0.3s ease",
                                            }),
                                          }) {
  return (
    <CardActionArea
      component={NavLink}
      to={to.toString()}
      style={getActiveStyle}
    >
      <Card
        elevation={8}
        style={{
          padding: 12,
          width: "100%",
          backgroundColor: "transparent",
          color: "inherit",
          ...style,
        }}
      >
        {children}
      </Card>
    </CardActionArea>
  );
}
