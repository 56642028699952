import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  TextField,
  Button,
  Typography,
  CircularProgress,
} from "@material-ui/core";

import {
  get_unreturned_OERNS,
  post_return_cash_request,
} from "../../../../actions/operation_expenses";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Poppup from "../../../../components/Poppup";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 600,
  },
  center: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
  },
}));

const ReturnCashRequest = ({
  submitting,
  get_unreturned_OERNS,
  post_return_cash_request,
}) => {
  const classes = useStyles();
  const [RCRN, setRCRN] = useState(false);
  const [unreturnedOERNS, setUnreturnedOERNS] = useState([]);
  const [OERNS_to_return, setOERNS_to_return] = useState([]);
  useEffect(() => {
    get_unreturned_OERNS().then((unreturned_OERNS) =>
      setUnreturnedOERNS(unreturned_OERNS)
    );
  }, [get_unreturned_OERNS]);
  const onSubmit = (e) => {
    e.preventDefault();
    post_return_cash_request(OERNS_to_return).then((RCRN) => setRCRN(RCRN));
  };

  return RCRN ? (
    <Redirect to={`/app/forms/operation-expenses/return-cash/form/${RCRN}`} />
  ) : (
    <div className={classes.paper}>
      <Typography style={{ marginBottom: 40 }} variant="h3">
        Return Cash Request
        <Poppup
          content={
            <Typography variant="body1">
              The return cash request can be triggered by the accounting and/or
              logistics team. This request form is meant to facilitate the
              return of cash remaining from approved Operations Expense requests
              from the logistics team to the accounting team.
            </Typography>
          }
        />
      </Typography>
      {submitting ? (
        <div className={classes.center}>
          <CircularProgress style={{ margin: "auto" }} />
        </div>
      ) : (
        <form style={{ width: "100%" }} onSubmit={(e) => onSubmit(e)}>
          <Autocomplete
            multiple
            options={unreturnedOERNS}
            getOptionLabel={(option) => option}
            value={OERNS_to_return}
            onChange={(event, newValue) => {
              setOERNS_to_return(newValue);
            }}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Unreturned operation expense requests *"
                placeholder="Search by OERN"
              />
            )}
          />
          <FormControl fullWidth>
            <Button
              disabled={OERNS_to_return.length === 0}
              variant="contained"
              color="primary"
              type="submit"
            >
              Submit
            </Button>
          </FormControl>
        </form>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  submitting: state.operation_expenses.submitting,
});

export default connect(mapStateToProps, {
  get_unreturned_OERNS,
  post_return_cash_request,
})(ReturnCashRequest);
