import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { weeklyKpisLoadDepartmentTeams } from "../../../../actions/weeklyKpis";
import CustomInfiniteScroll from "../../../CustomInfiniteScroll";
import WeeklyKpisTeamCard from "./WeeklyKpisTeamCard";
import CustomSkeleton from "../../../CustomInfiniteScroll/CustomSkeleton";

const variantPropsMapper = {
  supervisor: {
    noCount: "You don't supervise any team.",
  },
  supervisee: {
    noCount: "You're not in any team",
  },
  manager: {
    noCount: "You're don't manage any team.",
  },
};

const TeamsContent = ({
  isLoading,
  weeklyKpisLoadDepartmentTeams,
  data,
  setData,
  variant,
}) => {
  const { noCount } = variantPropsMapper[variant];
  const { results = [], count = 0, next = null } = data;

  return (
    <div>
      {isLoading ? (
        <CustomSkeleton variant="team" count={3} spacing={3} />
      ) : count === 0 ? (
        <Typography variant="body2">{noCount}</Typography>
      ) : (
        <React.Fragment>
          <Grid container spacing={3}>
            {results.map((department_team, index) => (
              <Grid item key={index} xs={12}>
                <WeeklyKpisTeamCard
                  department_team={department_team}
                  variant={variant}
                />
              </Grid>
            ))}
          </Grid>
          <CustomInfiniteScroll
            numResults={results.length}
            next={next}
            loadNext={() =>
              weeklyKpisLoadDepartmentTeams({
                page_number: next,
                variant: variant,
              }).then((payload) => payload && setData(payload))
            }
            skeletonProps={{ variant: "card" }}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default connect(null, { weeklyKpisLoadDepartmentTeams })(TeamsContent);
