import React from "react";
import {CircularProgress, Typography, Box, Grid} from "@material-ui/core";

const opacity = 0.4;

const getColorProps = (percent) => {
  if (percent >= 66) {
    return {
      backgroundColor: `rgba(237, 114, 115, ${opacity})`,
      color: "#ED7273",
    };
  } else if (percent >= 33) {
    return {
      backgroundColor: `rgba(251, 231, 115, ${opacity})`,
      color: "#FBE773",
    };
  }
  return {
    backgroundColor: `rgba(119, 224, 255, ${opacity})`,
    color: "#77E0FF",
  };
};

function CircularProgressWithLabel({
                                     value = 0,
                                     denominator = 100,
                                     title = "",
                                     size = "lg",
                                     units = "",
                                     color = "textPrimary",
                                     ...other
                                   }) {
  var percent = (value * 100) / denominator;
  percent = percent > 100 ? 100 : percent;

  if (size === "sm_alt") {
    return (
      <Grid container spacing={3} alignItems="center" {...other}>
        <Grid item style={{flexGrow: 1}}>
          <Typography
            variant="body2"
            component="div"
            display="inline"
            color={color}
            style={{fontWeight: "bold"}}
          >
            {value}
          </Typography>
          <Typography
            component="div"
            display="inline"
            variant="body2"
            style={{marginLeft: 4}}
            color={color}
          >
            {units}
          </Typography>
        </Grid>
        <Grid item>
          <CircularProgress
            variant="determinate"
            value={percent || 100}
            size={25}
            thickness={percent === 0 ? 0 : 4}
            style={{
              borderRadius: 1000,
              ...getColorProps(percent),
            }}
          />
        </Grid>
      </Grid>
    );
  }

  if (size === "sm") {
    return (
      <Grid container spacing={3} {...other}>
        <Grid item xs={8}>
          <Typography component="div" variant="body2">
            {title}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <CircularProgress
            variant="determinate"
            value={percent || 100}
            size={25}
            thickness={percent === 0 ? 0 : 4}
            style={{
              borderRadius: 1000,
              ...getColorProps(percent),
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Typography component="div" display="inline" color={color}>
            {value}
          </Typography>
          <Typography
            component="div"
            display="inline"
            variant="body2"
            style={{marginLeft: 4}}
            color={color}
          >
            {units}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <div style={{margin: 20}}>
      <Box position="relative">
        <CircularProgress
          variant="determinate"
          value={percent || 100}
          size={100}
          thickness={percent === 0 ? 0 : 1}
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "auto",
            border: "1px solid #E2E2E2",
            borderRadius: 1000,
          }}
        />

        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <span style={{display: "flex"}}>
            <Typography variant="h4" style={{fontWeight: "bold"}}>
              {value}
            </Typography>
            <Typography variant="subtitle2" style={{lineHeight: 2.5}}>
              {`/${denominator}`}
            </Typography>
          </span>
        </Box>
      </Box>
      <Typography style={{textAlign: "center", marginTop: 10}}>
        {title}
      </Typography>
    </div>
  );
}

export default CircularProgressWithLabel;
