import React from "react";
import { connect } from "react-redux";
import { Bar } from "react-chartjs-2";
import { Card, Typography } from "@material-ui/core";
import ChartDataLabels from "chartjs-plugin-datalabels";
import CustomSkeleton from "../../CustomInfiniteScroll/CustomSkeleton";

const isDateValid = (date) => !isNaN(new Date(date).getTime());

const formatDate = (date) =>
  new Date(date).toLocaleDateString("en-GB", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

const formatInputDate = (date) =>
  date ? new Date(date).toLocaleDateString("en-gb") : null;

const useEffectDepartTeamEvaluationMetric = ({
  loadMetrics,
  department_team,
  tags,
  metric,
  yAxisLabel,
  stepSize = 5,
  start_date,
  end_date,
  denominator,
}) => {
  const [isLoading, setisLoading] = React.useState(true);
  const [showLabels, setShowLables] = React.useState(false);
  const [data, setData] = React.useState({ labels: [], datasets: [] });

  const handleToggleShowLabels = () => setShowLables(!showLabels);

  const options = {
    plugins: {
      datalabels: {
        formatter: (value, ctx) =>
          `${ctx.dataset.labels[ctx.dataIndex]}` || value,
        color: () => "black",
        textAlign: "left",
        // font: { weight: "bold" },
        opacity: showLabels ? 1 : 0,
      },
      legend: { position: "bottom", display: showLabels },
    },
    scales: {
      yAxes: {
        title: { display: true, text: yAxisLabel, font: { size: 12 } },
        stacked: false,
        min: 0,
        max: denominator,
        ticks: {
          // forces step size to be 50 units
          stepSize: stepSize,
        },
      },
      xAxes: {
        title: { display: true, text: "Time", font: { size: 12 } },
        stacked: false,
      },
    },
  };

  React.useEffect(() => {
    if (isDateValid(start_date) && isDateValid(end_date)) {
      setisLoading(true);
      loadMetrics({
        start_date: formatInputDate(start_date),
        end_date: formatInputDate(end_date),
        department_team: department_team?.id,
        date_format: "%d/%m/%Y",
        metric: metric,
        supervisees: tags
          .filter((tag) => tag.bnakyEngine === "Employees")
          .map(({ username }) => username),
        departments: tags
          .filter((tag) => tag.bnakyEngine === "Departments")
          .map(({ name }) => name),
        "department-teams": tags
          .filter((tag) => tag.bnakyEngine === "Department teams")
          .map(({ slug }) => slug),
      }).then((payload) => {
        payload && setData(payload);
        setTimeout(() => setisLoading(false), 500);
      });
      return () => setisLoading(false);
    }
  }, [loadMetrics, department_team, start_date, end_date, metric, tags]);

  return { isLoading, data, options, showLabels, handleToggleShowLabels };
};

const DepartmentTeamEvaluationMetric = ({
  loadMetrics,
  department_team,
  tags,
  metric = "",
  yAxisLabel = "",
  stepSize = 5,
  title = "",
  duration,
  denominator = 100,
}) => {
  const { start_date, end_date } = duration;
  const { isLoading, data, options, showLabels, handleToggleShowLabels } =
    useEffectDepartTeamEvaluationMetric({
      loadMetrics,
      department_team,
      tags,
      metric,
      yAxisLabel,
      stepSize,
      start_date,
      end_date,
      denominator,
    });

  return isLoading ? (
    <CustomSkeleton variant="chart" />
  ) : (
    <Card elevation={8} style={{ padding: 20, textAlign: "center" }}>
      <Typography component="div" variant="h5">
        {title}
      </Typography>
      <Typography component="div" variant="body2">
        {`${formatDate(start_date)} - ${formatDate(end_date)}`}
      </Typography>
      <Bar data={data} plugins={[ChartDataLabels]} options={options} />
      <Typography
        className="button"
        onClick={handleToggleShowLabels}
        variant="caption"
      >
        {showLabels ? "Hide labels" : "Show labels"}
      </Typography>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  department_team: state.weekly_kpis?.department_team,
  duration: state.weekly_kpis.duration,
  tags: state.weekly_kpis?.tags,
});

export default connect(mapStateToProps, {})(DepartmentTeamEvaluationMetric);
