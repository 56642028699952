import React, { useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReimbursementStepper from "./ReimbursementStepper";
import CancelRequest from "./CancelRequest";
import ApprovalStatus from "./ApprovalStatus";
import AccountingStatus from "./AccountingStatus";
import RequestInput from "./RequestInput";
import CustomizeSnackbar from "../../../components/CustomizeSnackbar";

import { get_reimbursement_request } from "../../../actions/reimbursement";
import Poppup from "../../../components/Poppup";

const useStyles = makeStyles((theme) => ({
  paper: {
    flexDirection: "column",
    maxWidth: 600,
  },
  center: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
  },
}));

const ReimbursementView = ({
  get_reimbursement_request,
  data,
  current_user,
  fetched,
  processing,
}) => {
  const classes = useStyles();
  let { RRN } = useParams();

  useEffect(() => {
    get_reimbursement_request(RRN);
  }, [RRN, get_reimbursement_request]);

  return fetched ? (
    data.requester === current_user ||
    data.approval_status.approvers.includes(current_user) ||
    data.accounting_status.accountants.includes(current_user) ? (
      <div className={classes.paper}>
        <div style={{ marginBottom: 40, display: "flex" }}>
          <Typography variant="h3">
            {`${data.RRN}`}
            <Poppup
              content={
                <Typography variant="body1">
                  The purpose of this request form is to submit a request for a
                  reimbursement when an employee makes a purchase on behalf of
                  the company. Reimbursement requests must be submitted with
                  receipts in order to be approved.
                </Typography>
              }
            />
          </Typography>
          <CancelRequest RRN={RRN} current_user={current_user} data={data} />
        </div>

        <ReimbursementStepper data={data} />
        {processing ? (
          <div className={classes.center}>
            <CircularProgress style={{ margin: "auto" }} />
          </div>
        ) : (
          <Fragment>
            <ApprovalStatus RRN={RRN} current_user={current_user} data={data} />
            <AccountingStatus
              RRN={RRN}
              current_user={current_user}
              data={data}
            />
            <RequestInput RRN={RRN} current_user={current_user} data={data} />
          </Fragment>
        )}
      </div>
    ) : (
      <CustomizeSnackbar
        payload={{
          severity: "error",
          message: "Permission Denied",
        }}
      />
    )
  ) : (
    <div className={classes.center}>
      <CircularProgress style={{ margin: "auto" }} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  fetched: state.reimbursement.fetched,
  processing: state.reimbursement.processing,
  data: state.reimbursement.data,
  current_user: state.auth.user.username,
});

export default connect(mapStateToProps, { get_reimbursement_request })(
  ReimbursementView
);
