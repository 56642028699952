import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Draggable from "react-draggable";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import DialogTitle from "../../../../components/MuiDialogTitle";
import { LinearProgress, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import { OE_cash_returned } from "../../../../actions/operation_expenses";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "OERN",
    label: "OERN",
  },
  {
    id: "amount_returned",
    numeric: true,
    label: "Amount Returned",
  },
  {
    id: "date_received",
    numeric: true,
    label: "Date Received",
  },
];

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    marginBottom: theme.spacing(3),
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  number: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
      {
        display: "none",
      },
  },
}));

function EnhancedTable({ data, OE_cash_returned, submitting, groups }) {
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [openOERN, setOpenOERN] = useState(false);
  const [activeOERN, setActiveOERN] = useState({
    OERN: null,
    amount_returned: null,
  });

  const handleChange = () => (event) => {
    setActiveOERN({
      ...activeOERN,
      amount_returned: event.target.value,
    });
  };
  const handleCloseDialog = () => {
    setActiveOERN({
      OERN: null,
      amount_returned: null,
    });
    setOpenOERN(false);
  };
  const handleOpenDialog = (OERN) => {
    setActiveOERN(
      data.operation_expense_requests.find(
        (operation_expense_request) => operation_expense_request.OERN === OERN
      )
    );
    setOpenOERN(true);
  };
  const createSortHandler = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    OE_cash_returned(activeOERN.OERN, activeOERN.amount_returned);
    setActiveOERN({
      OERN: null,
      amount_returned: null,
    });
    setOpenOERN(false);
  };

  return (
    <div>
      <Paper className={classes.paper}>
        {submitting && <LinearProgress />}
        <Table>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  style={{ fontWeight: "bold" }}
                  key={headCell.id}
                  align={headCell.numeric ? "right" : "left"}
                  padding="default"
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={() => createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(
              data.operation_expense_requests,
              getComparator(order, orderBy)
            ).map((row) => (
              <TableRow
                hover
                role="checkbox"
                onClick={() =>
                  groups.includes("accounting") && handleOpenDialog(row.OERN)
                }
                tabIndex={-1}
                key={row.OERN}
              >
                <TableCell padding="default">{row.OERN}</TableCell>
                <TableCell align="right">{row.amount_returned}</TableCell>
                <TableCell align="right">{row.date_received}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>

      {openOERN && (
        <Dialog
          open={openOERN}
          onClose={handleCloseDialog}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          fullWidth
        >
          <form onSubmit={(e) => onSubmit(e)}>
            <DialogTitle
              style={{ cursor: "move" }}
              id="draggable-dialog-title"
              onClose={handleCloseDialog}
            >
              {activeOERN.OERN}
            </DialogTitle>
            <DialogContent>
              <FormControl fullWidth>
                <TextField
                  required
                  variant="outlined"
                  label="Amount Returned"
                  onChange={handleChange()}
                  value={activeOERN.amount_returned}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">QAR</InputAdornment>
                    ),
                  }}
                  type="number"
                  inputProps={{ min: 0, step: 0.01 }}
                  className={classes.number}
                />
              </FormControl>
            </DialogContent>
            <DialogActions style={{ padding: 24 }}>
              <Fragment>
                <Button
                  onClick={handleCloseDialog}
                  fullWidth
                  color="primary"
                  variant="outlined"
                  style={{ borderRadius: 24 }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  fullWidth
                  color="primary"
                  variant="contained"
                  style={{ borderRadius: 24 }}
                >
                  Mark as received
                </Button>
              </Fragment>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  groups: state.auth.user.groups,
  submitting: state.operation_expenses.submitting_RCRN,
  data: state.operation_expenses.RCRN_data,
});

export default connect(mapStateToProps, { OE_cash_returned })(EnhancedTable);
