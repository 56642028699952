PendingIcon.displayName = "PendingIcon";

export default function PendingIcon({
  primary_fill = "#949494",
  dimension = 12,
  style = {},
  ...other
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 12"
      fill="none"
      style={{
        minHeight: dimension,
        height: dimension,
        minWidth: dimension,
        width: dimension,
        ...style,
      }}
      {...other}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.9 6C0.9 8.81665 3.18335 11.1 6 11.1C8.81665 11.1 11.1 8.81665 11.1 6C11.1 3.18335 8.81665 0.9 6 0.9C3.18335 0.9 0.9 3.18335 0.9 6ZM6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0Z"
        fill={primary_fill}
      />
    </svg>
  );
}
