import React, {useState} from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router";
import {
  InputLabel,
  FormControl,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
  Divider,
  Checkbox,
  Paper,
  FormHelperText,
  CardActionArea,
  Link,
} from "@material-ui/core";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {post_time_off_request} from "../../../../actions/personal";
import ImageUpload from "../../ImageUpload";
import InfoPopover from "../../Infopopover";
import Poppup from "../../../../components/Poppup";

var weekday = new Array(7);
weekday[0] = "Sunday";
weekday[1] = "Monday";
weekday[2] = "Tuesday";
weekday[3] = "Wednesday";
weekday[4] = "Thursday";
weekday[5] = "Friday";
weekday[6] = "Saturday";

var getDaysArray = function (s, e) {
  for (var a = {}, d = new Date(s); d <= e; d.setDate(d.getDate() + 1)) {
    a[d.toLocaleDateString()] = ![5, 6].includes(new Date(d).getDay());
  }
  return a;
};

const TimeOffRequest = ({submitting, post_time_off_request}) => {
  const [formData, setFormData] = useState({
    date_start: null,
    date_end: null,
    days_off: {},
    time_off_type: "",
    doctor_note: null,
    note: "",
  });

  const handleChange = (prop) => (event) =>
    setFormData({...formData, [prop]: event.target.value});

  const onChangeDate = (name, date) => {
    var range = {};
    if (name === "date_start" && formData.date_end) {
      range = getDaysArray(date, formData.date_end);
    }
    if (name === "date_end" && formData.date_start) {
      range = getDaysArray(formData.date_start, date);
    }
    setFormData({...formData, [name]: date, days_off: range});
  };

  const daysOff = Object.entries(formData.days_off)
    .filter(([k, v]) => v)
    .map(([k, v]) => k);

  const numDaysOff = Object.values(daysOff).length;
  const subheader =
    formData.date_start && formData.date_end && numDaysOff === 0
      ? "Please select at least 1 weekday"
      : null;

  const [TMO, setTMO] = useState(false);
  const onSubmit = (e) => {
    e.preventDefault();
    post_time_off_request({
      ...formData,
      date_start: new Date(daysOff[0]).toLocaleDateString("fr-CA"),
      date_end: new Date(daysOff[numDaysOff - 1]).toLocaleDateString("fr-CA"),
      days_off: daysOff,
    }).then((TMO) => setTMO(TMO));
  };

  if (TMO) {
    return <Redirect to={`/app/forms/personal/time-off/${TMO}/`}/>;
  }

  return (
    <div style={{maxWidth: 600}}>
      <Typography style={{marginBottom: 40}} variant="h3">
        Time off
        <Poppup
          content={
            <Typography variant="body1">
              For more information, read the{" "}
              <Link
                href="/app/policies"
                target="_blank"
                style={{fontWeight: "bold"}}
              >
                time off policies
              </Link>{" "}
            </Typography>
          }
        />
      </Typography>

      {submitting ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "100%",
            minHeight: 300,
          }}
        >
          <CircularProgress style={{margin: "auto"}}/>
        </div>
      ) : (
        <form style={{width: "100%"}} onSubmit={(e) => onSubmit(e)}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <KeyboardDatePicker
                  inputVariant="outlined"
                  label="Starting date"
                  format="dd/MM/yyyy"
                  value={formData.date_start}
                  onChange={(date) => onChangeDate("date_start", date)}
                  autoOk
                  okLabel={null}
                  cancelLabel={null}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <KeyboardDatePicker
                  inputVariant="outlined"
                  label="Ending date"
                  format="dd/MM/yyyy"
                  value={formData.date_end}
                  onChange={(date) => onChangeDate("date_end", date)}
                  minDate={formData.date_start && new Date(formData.date_start)}
                  autoOk
                  okLabel={null}
                  cancelLabel={null}
                  fullWidth
                />
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>

          <Paper elevation={3}>
            {Object.keys(formData.days_off).length !== 0 && (
              <List dense disablePadding>
                {Object.entries(formData.days_off).map(
                  ([day, isChecked], index) => {
                    const isWeekend = [5, 6].includes(new Date(day).getDay());
                    const position = daysOff.indexOf(day) + 1;
                    return (
                      <CardActionArea key={day}>
                        {index !== 0 && <Divider/>}
                        <ListItem
                          onClick={() =>
                            setFormData({
                              ...formData,
                              days_off: {
                                ...formData.days_off,
                                [day]: !isChecked,
                              },
                            })
                          }
                        >
                          <ListItemIcon>
                            <Typography>{position || "-"}</Typography>
                          </ListItemIcon>

                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={isChecked}
                              disableRipple
                              color="primary"
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  {weekday[new Date(day).getDay()]}
                                </Grid>
                                <Grid item xs={6}>
                                  {new Date(day).toLocaleDateString("en-GB")}
                                </Grid>
                              </Grid>
                            }
                            secondary={isWeekend && "weekend"}
                          />
                        </ListItem>
                      </CardActionArea>
                    );
                  }
                )}
              </List>
            )}
          </Paper>
          {subheader && (
            <FormHelperText style={{marginTop: 20}} error>
              {subheader}
            </FormHelperText>
          )}
          <FormControl
            fullWidth
            variant="outlined"
            required
            style={{
              marginTop:
                formData.date_start && formData.date_end
                  ? subheader
                    ? 20
                    : 30
                  : 0,
            }}
          >
            <InputLabel>Time-off type</InputLabel>
            <Select
              value={formData.time_off_type}
              onChange={handleChange("time_off_type")}
              label="Time off type *"
            >
              <MenuItem value={"Annual paid leave"}>Annual paid leave</MenuItem>
              <MenuItem value={"Sick leave"}>Sick leave</MenuItem>
              <MenuItem value={"Parental leave"}>Parental leave</MenuItem>
              <MenuItem value={"Bereavement leave"}>Bereavement leave</MenuItem>
              <MenuItem value={"Unpaid leave"}>Unpaid leave</MenuItem>
            </Select>
          </FormControl>
          {formData.time_off_type.toLowerCase() === "sick leave" && numDaysOff > 2 && (
            <Grid container spacing={1}>
              <Grid item xs={11}>
                <FormControl fullWidth>
                  <ImageUpload
                    label="Doctor's note"
                    allowedToEdit={true}
                    passReceipt={(file) =>
                      setFormData({...formData, doctor_note: file})
                    }
                    receipt_image={formData.doctor_note}
                    required={!Boolean(formData.note)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={1} style={{margin: "auto"}}>
                <InfoPopover
                  info="Sick leave lasting over two working days is subject to the
                    employee providing a medical certificate as evidence of
                    their sickness by a medical doctor."
                />
              </Grid>
            </Grid>
          )}
          <TextField
            fullWidth
            variant="outlined"
            label="Note"
            multiline
            onChange={handleChange("note")}
            value={formData.note}
            minRows={4}
          />
          <Button
            fullWidth
            disabled={formData.time_off_type.length === 0 || numDaysOff === 0}
            variant="contained"
            color="primary"
            type="submit"
          >
            Submit
          </Button>
        </form>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  submitting: state.personal.submitting,
});

export default connect(mapStateToProps, {post_time_off_request})(
  TimeOffRequest
);
