import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { weeklyKpisLoadEvaluations } from "../../../actions/weeklyKpis";
import CustomInfiniteScroll from "../../CustomInfiniteScroll";
import CustomSkeleton from "../../CustomInfiniteScroll/CustomSkeleton";
import EvaluationHistoryCard from "./EvaluationHistoryCard";
import WeeklyKpisFilters from "./WeeklyKpisFilters";

const status = "L";
const getIsoString = (date) => new Date(date).toLocaleDateString();

const useEffectWeeklyKpisPastEvaluations = ({
  weeklyKpisLoadEvaluations,
  department_team,
  employee_tag_ids = [],
  duration,
}) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const { start_date, end_date, option } = duration;
  let filterQuery =
    employee_tag_ids.length > 0
      ? `&_to__id__in=${employee_tag_ids.join(",")}`
      : "";
  filterQuery = filterQuery + `&department_team__id=${department_team.id}`;
  filterQuery =
    filterQuery +
    `&week_start_date__gte=${getIsoString(
      start_date
    )}&ordering=-week_start_date`;
  if (option === "Custom Date") {
    filterQuery = filterQuery + `&week_end_date__lte=${getIsoString(end_date)}`;
  }

  React.useEffect(() => {
    setIsLoading(true);
    const delayDebounceFn = setTimeout(() => {
      weeklyKpisLoadEvaluations({
        page_number: 1,
        filterQuery: filterQuery,
        status: status,
      }).then(() => setIsLoading(false));
    }, 500);
    return () => {
      setIsLoading(false);
      clearTimeout(delayDebounceFn);
    };
  }, [filterQuery, start_date, end_date, weeklyKpisLoadEvaluations]);

  return { isLoading, filterQuery };
};

const WeeklyKpisPastEvaluations = ({
  weeklyKpisLoadEvaluations,
  past_evaluations,
  department_team,
  employee_tag_ids,
  duration,
}) => {
  const { isLoading, filterQuery } = useEffectWeeklyKpisPastEvaluations({
    weeklyKpisLoadEvaluations,
    department_team,
    employee_tag_ids,
    duration,
  });
  const { results = [], count = 0, next = null } = past_evaluations;

  const filters = React.useMemo(
    () => <WeeklyKpisFilters AcceptableEngines={["Employees"]} />,
    []
  );

  return (
    <Grid container spacing={3}>
      {filters}
      <Grid item xs={12}>
        {isLoading ? (
          <CustomSkeleton variant="history" count={3} spacing={3} />
        ) : count === 0 ? (
          <Typography variant="body2" style={{ marginTop: 24 }}>
            {"Past evaluations not found"}
          </Typography>
        ) : (
          <Grid container spacing={3}>
            {results.map((evaluation, index) => (
              <Grid item key={index} xs={12}>
                <EvaluationHistoryCard evaluation={evaluation} />
              </Grid>
            ))}
            <Grid item xs={12}>
              <CustomInfiniteScroll
                numResults={results.length}
                next={next}
                loadNext={() =>
                  weeklyKpisLoadEvaluations({
                    page_number: next,
                    filterQuery: filterQuery,
                    status: status,
                  })
                }
                skeletonProps={{ variant: "history" }}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  past_evaluations: state.weekly_kpis.past_evaluations,
  department_team: state.weekly_kpis?.department_team,
  employee_tag_ids: state.weekly_kpis?.tags
    .filter(({ bnakyEngine }) => bnakyEngine === "Employees")
    .map(({ id }) => id),
  duration: state.weekly_kpis.duration,
});

export default connect(mapStateToProps, { weeklyKpisLoadEvaluations })(
  WeeklyKpisPastEvaluations
);
