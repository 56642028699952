import React, { Fragment, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Badge,
  List,
  ListSubheader,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  LinearProgress,
  Avatar,
  withStyles,
  IconButton,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { connect } from "react-redux";
import {
  load_notifications,
  mark_notification_as_read,
  mark_notifications_as_read,
} from "../../actions/auth";
import { PlaylistAddCheck, Search as SearchIcon } from "@material-ui/icons";
import { Collapse } from "@material-ui/core";

const StyledBadge = withStyles((theme) => ({
  badge: {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: `1px solid ${theme.palette.secondary.main}`,
      content: '""',
      zIndex: -100,
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

const NavNotifications = ({
  current_user,
  load_notifications,
  mark_notification_as_read,
  mark_notifications_as_read,
  pageNumber,
  notificationsLoaded,
  toggleNavNotifications,
  navbarHeight,
}) => {
  const [search, setSearch] = useState(false);
  const [filter, setFilter] = useState(null);
  const notifications = current_user.notifications
    ? filter
      ? current_user.notifications.filter(
          (notification) =>
            notification.message.toLowerCase() === filter.toLowerCase()
        )
      : current_user.notifications
    : [];

  return (
    <List disablePadding>
      <ListSubheader
        disableGutters
        style={{
          display: "flex",
          flexDirection: "column",
          borderBottom: `1px solid #F2F2F2`,
          backgroundColor: "white",
          minHeight: navbarHeight,
          zIndex: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            minHeight: navbarHeight,
            position: "sticky",
            margin: 20,
            marginTop: 0,
            marginBottom: 0,
          }}
        >
          <Typography
            variant="h6"
            style={{
              fontWeight: "bold",
              color: "black",
              margin: "auto",
            }}
          >
            Notifications
          </Typography>
          {current_user.notifications.find(
            (notification) => !notification.read
          ) && (
            <Fragment>
              <div style={{ flexGrow: 1 }} />
              <IconButton
                size="small"
                color="primary"
                onClick={() => mark_notifications_as_read()}
                style={{ margin: "auto" }}
              >
                <PlaylistAddCheck />
              </IconButton>
            </Fragment>
          )}

          <IconButton
            size="small"
            color="primary"
            onClick={() => {
              if (search) {
                setSearch(false);
                setFilter(null);
              } else {
                setSearch(true);
              }
            }}
            style={{ marginLeft: 10, margin: "auto" }}
          >
            <SearchIcon />
          </IconButton>
        </div>
        <Collapse in={search}>
          <div style={{ paddingLeft: 20, paddingRight: 20 }}>
            <Autocomplete
              options={[
                "Accounted",
                "Approved",
                "Cancelled",
                "Cash returned",
                "Pending",
                "Ready to collect",
                "Received",
                "Rejected",
                "Requested",
                "To collect",
              ]}
              getOptionLabel={(option) => option}
              value={filter}
              onChange={(event, newValue) => setFilter(newValue)}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Filter Notifications"
                  style={{ marginBottom: 0 }}
                />
              )}
            />
          </div>
        </Collapse>
      </ListSubheader>

      {!notificationsLoaded ? (
        <LinearProgress />
      ) : (
        <Fragment>
          {notifications.length > 0 ? (
            notifications.map((notification) => (
              <ListItem
                key={notification.id}
                button
                component={NavLink}
                to={`/app/forms/${notification.link}/${notification.request_number}/`}
                onClick={() => {
                  !notification.read &&
                    mark_notification_as_read(notification.id);
                  toggleNavNotifications();
                }}
              >
                <ListItemAvatar style={{ minWidth: 16, width: 16 }}>
                  <StyledBadge
                    overlap="circle"
                    color="secondary"
                    variant="dot"
                    invisible={notification.read}
                  />
                </ListItemAvatar>

                <ListItemAvatar>
                  {notification.picture ? (
                    <Avatar
                      alt={notification.sender}
                      src={notification.picture}
                    />
                  ) : (
                    <Avatar>
                      {notification.sender.split(" ").map((n) => n[0])}
                    </Avatar>
                  )}
                </ListItemAvatar>

                <div>
                  <ListItemText
                    primary={notification.request_number}
                    secondary={notification.message}
                  />
                  <ListItemText
                    secondary={`${notification.sender} - ${new Date(
                      notification.date
                    ).toLocaleDateString("en-GB")}`}
                  />
                </div>
              </ListItem>
            ))
          ) : (
            <ListItem>
              <ListItemText
                secondary="You're all caught up, check back later for new notifications."
                secondaryTypographyProps={{
                  style: { textAlign: "center", margin: "15%" },
                }}
              />
            </ListItem>
          )}
          {pageNumber > 0 && (
            <ListItem button onClick={() => load_notifications(pageNumber)}>
              <ListItemText style={{ marginLeft: 16 }} primary="see more" />
            </ListItem>
          )}
        </Fragment>
      )}
    </List>
  );
};

const mapStateToProps = (state) => ({
  notificationsLoaded: state.auth.notificationsLoaded,
  current_user: state.auth.user,
  pageNumber: state.auth.pageNumber,
});

export default connect(mapStateToProps, {
  load_notifications,
  mark_notification_as_read,
  mark_notifications_as_read,
})(NavNotifications);
