import React, {useEffect, useState} from "react";
import {connect} from "react-redux";

import {makeStyles} from "@material-ui/core/styles";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import {
  post_marketing_expense_request,
  get_marketing_supplies_requests,
} from "../../../../actions/marketing_expenses";

import {get_webapps} from "../../../../actions/procedure";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {Redirect} from "react-router";

import Poppup from "../../../../components/Poppup";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 600,
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "25ch",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
  },
  number: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
      {
        display: "none",
      },
  },
}));

const UnpaidCampaignRequest = ({
                                 submitting,
                                 get_webapps,
                                 post_marketing_expense_request,
                                 get_marketing_supplies_requests,
                                 marketing_supplies_requests,
                                 webapps,
                               }) => {
  const classes = useStyles();

  const [MERN, setMERN] = useState(false);

  useEffect(() => {
    get_marketing_supplies_requests();
    get_webapps();
  }, [get_marketing_supplies_requests, get_webapps]);

  const [formData, setFormData] = useState({
    channel_type: "",
    payment_type: "",
    amount_requested: "",
    start_date: null,
    end_date: null,
    webapps: [],
    conducted_by: "",
    conducted_at: "",
    actors: "",
    inventory_requests: [],
    justification: "",
  });

  const handleDateChange = (date, prop) => {
    setFormData({...formData, [prop]: date});
  };
  // const handleCategoryChange = (category) => {
  //   setFormData({ ...formData, category: category || null });
  // };
  const handleChange = (prop) => (event) => {
    setFormData({...formData, [prop]: event.target.value});
  };
  const handleChangeWebapps = (webapps) => {
    setFormData({
      ...formData,
      webapps: webapps,
    });
  };
  const handleChangeMSRN = (MSRN) => {
    setFormData({
      ...formData,
      inventory_requests: MSRN,
    });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    post_marketing_expense_request(formData).then((MERN) => setMERN(MERN));
  };

  return MERN ? (
    <Redirect to={`/app/forms/marketing-expenses/paid-form/${MERN}/`}/>
  ) : (
    <div className={classes.paper}>
      <Typography style={{marginBottom: 40}} variant="h3">
        Marketing Expenses
        <Poppup
          content={
            <Typography variant="body1">
              This request form is created to enable requests for paid marketing
              expenses. Paid marketing expenses are defined as marketing
              expenses for which Avey needs to directly bear a cost.
            </Typography>
          }
        />
      </Typography>
      {submitting ? (
        <div className={classes.center}>
          <CircularProgress style={{margin: "auto"}}/>
        </div>
      ) : (
        <form style={{width: "100%"}} onSubmit={(e) => onSubmit(e)}>
          <FormControl fullWidth variant="outlined" required>
            <InputLabel>Channel type</InputLabel>
            <Select
              value={formData.channel_type}
              onChange={handleChange("channel_type")}
              label="Channel type *"
            >
              <MenuItem value={"online"}>Online</MenuItem>
              <MenuItem value={"offline"}>Offline</MenuItem>
            </Select>
          </FormControl>
          {formData.channel_type === "offline" && (
            <div>
              <FormControl fullWidth>
                <TextField
                  required
                  label="Conducted by"
                  onChange={handleChange("conducted_by")}
                  value={formData.conducted_by}
                  variant="outlined"
                />
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  required
                  label="Conducted at"
                  onChange={handleChange("conducted_at")}
                  value={formData.conducted_at}
                  variant="outlined"
                />
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  required
                  multiline
                  label="Actors"
                  onChange={handleChange("actors")}
                  value={formData.actors}
                  variant="outlined"
                />
              </FormControl>
              <Autocomplete
                multiple
                id="tags-outlined"
                options={marketing_supplies_requests}
                getOptionLabel={(option) => option}
                getOptionSelected={(option, value) =>
                  option.id === value.id ? option : null
                }
                value={formData.inventory_requests}
                onChange={(event, newValue) => {
                  handleChangeMSRN(newValue);
                }}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Marketing supplies requests"
                    placeholder="Search for groups"
                  />
                )}
              />
            </div>
          )}
          {formData.channel_type === "online" && (
            <Autocomplete
              multiple
              required
              id="tags-outlined"
              options={webapps}
              getOptionLabel={(option) => option.name}
              value={formData.webapps}
              onChange={(event, newValue) => {
                handleChangeWebapps(newValue);
              }}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  // required
                  {...params}
                  variant="outlined"
                  label="Websites / Apps"
                  placeholder="Search for websites / apps"
                  required={formData.webapps.length === 0}
                />
              )}
            />
          )}
          <FormControl fullWidth variant="outlined" required>
            <InputLabel htmlFor="outlined-adornment-amount">
              Amount requested
            </InputLabel>
            <OutlinedInput
              label="Amount requested *"
              type="number"
              value={formData.amount_requested}
              onChange={handleChange("amount_requested")}
              startAdornment={
                <InputAdornment position="start">QAR</InputAdornment>
              }
              className={classes.number}
              inputProps={{min: 0, step: 0.01}}
            />
          </FormControl>
          <FormControl fullWidth variant="outlined" required>
            <InputLabel>Payment method</InputLabel>
            <Select
              value={formData.payment_type}
              onChange={handleChange("payment_type")}
              label="Payment method *"
            >
              <MenuItem value={"Bank transfer"}>Bank transfer</MenuItem>
              <MenuItem value={"Cash"}>Cash</MenuItem>
              <MenuItem value={"Cheque"}>Cheque</MenuItem>
              <MenuItem value={"Credit card"}>Credit card</MenuItem>
              <MenuItem value={"Debit card"}>Debit card</MenuItem>
              <MenuItem value={"Credit application"}>
                Credit application
              </MenuItem>
            </Select>
          </FormControl>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              fullWidth
              required
              inputVariant="outlined"
              label="Start date"
              format="dd / MM / yyyy"
              value={formData.start_date || null}
              onChange={(date) => handleDateChange(date, "start_date")}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              minDate={new Date()}
            />
          </MuiPickersUtilsProvider>
          {formData.start_date && (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                fullWidth
                required
                inputVariant="outlined"
                label="End date"
                format="dd / MM / yyyy"
                value={formData.end_date || null}
                onChange={(date) => handleDateChange(date, "end_date")}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                minDate={formData.start_date}
              />
            </MuiPickersUtilsProvider>
          )}
          <FormControl fullWidth>
            <TextField
              required
              multiline
              label="Justification"
              onChange={handleChange("justification")}
              value={formData.justification}
              variant="outlined"
            />
          </FormControl>
          <FormControl fullWidth>
            <Button
              disabled={
                (formData.start_date !== null
                  ? formData.start_date.toString() === "Invalid Date"
                  : true) ||
                (formData.end_date !== null
                  ? formData.end_date.toString() === "Invalid Date"
                  : false) ||
                (formData.end_date !== null &&
                  formData.start_date > formData.end_date)
              }
              variant="contained"
              color="primary"
              type="submit"
            >
              Submit
            </Button>
          </FormControl>
        </form>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  submitting: state.marketing_expenses.submittingPaid,
  marketing_supplies_requests:
  state.marketing_expenses.marketing_supplies_requests,
  webapps: state.procedures.webapps,
});

export default connect(mapStateToProps, {
  post_marketing_expense_request,
  get_marketing_supplies_requests,
  get_webapps,
})(UnpaidCampaignRequest);
