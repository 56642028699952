import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";

// import CircularProgress from "@material-ui/core/CircularProgress";
// import NonEditableInputView from "./NonEditableInputView";
import EditableInputView from "./Form";
// import InputExpenses from "./InputExpenses";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
  },
  subheader: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 20,
    marginLeft: 0,
  },
  card: {
    minWidth: 200,
    position: "fixed",
    top: "50%",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
  },
}));

const RequestInput = ({ current_user, OERN, data }) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    payment_start_date: null,
    payment_end_date: null,
    cash_requested_amount: "",
    cash_justification: "",
    credit_requested_amount: "",
    credit_justification: "",
  });

  const [edit, setEdit] = useState(false);

  useEffect(() => {
    setFormData({
      approval_status: data.approval_status.status,
      payment_start_date: data.payment_start_date,
      payment_end_date: data.payment_end_date,
      cash_requested_amount: data.cash_requested_amount || "",
      cash_justification: data.cash_justification || "",
      credit_requested_amount: data.credit_requested_amount || "",
      credit_justification: data.credit_justification || "",
    });
  }, [data, setFormData]);

  const AllowedToEdit = () => {
    return (
      ["pending", "returned"].includes(data.approval_status.status) &&
      current_user === data.requester
    );
  };

  return (
    <div className={classes.paper}>
      {data.approval_status.note && (
        <FormControl fullWidth>
          <TextField
            label="Approver's note"
            multiline
            InputProps={{ disableUnderline: true, readOnly: true }}
            value={data.approval_status.note}
          />
        </FormControl>
      )}
      <div>
        <Typography
          style={{ marginBottom: 20, fontWeight: "bold", alignSelf: "start" }}
          variant="h5"
        >
          Operation Expense information
          {AllowedToEdit() && (
            <Button
              color="primary"
              style={{ fontWeight: "bold" }}
              onClick={() => {
                setEdit(!edit);
                setFormData({
                  approval_status: data.approval_status.status,
                  payment_start_date: data.payment_start_date,
                  payment_end_date: data.payment_end_date,
                  cash_requested_amount: data.cash_requested_amount || "",
                  cash_justification: data.cash_justification || "",
                  credit_requested_amount: data.credit_requested_amount || "",
                  credit_justification: data.credit_justification || "",
                });
              }}
            >
              {edit ? "Cancel" : "Edit"}
            </Button>
          )}
        </Typography>

        <FormControl fullWidth>
          <TextField
            label="Requester"
            InputProps={{ disableUnderline: true, readOnly: true }}
            value={data.requester_full_name}
          />
        </FormControl>
      </div>
      {!AllowedToEdit() || !edit ? (
        <div>
          <FormControl fullWidth>
            <TextField
              label="Start date"
              InputProps={{ disableUnderline: true, readOnly: true }}
              value={new Date(data.payment_start_date).toLocaleDateString(
                "en-GB"
              )}
            />
          </FormControl>
          {data.payment_end_date && (
            <FormControl fullWidth>
              <TextField
                label="End date"
                InputProps={{ disableUnderline: true, readOnly: true }}
                value={new Date(data.payment_end_date).toLocaleDateString(
                  "en-GB"
                )}
              />
            </FormControl>
          )}
          {data.cash_requested_amount && (
            <FormControl fullWidth>
              <TextField
                label="Cash Amount"
                InputProps={{ disableUnderline: true, readOnly: true }}
                value={data.cash_requested_amount}
                multiline
              />
            </FormControl>
          )}
          {data.cash_justification && (
            <FormControl fullWidth>
              <TextField
                label="Cash justification"
                InputProps={{ disableUnderline: true, readOnly: true }}
                value={data.cash_justification}
                multiline
              />
            </FormControl>
          )}
          {data.credit_requested_amount && (
            <FormControl fullWidth>
              <TextField
                label="Credit Amount"
                InputProps={{ disableUnderline: true, readOnly: true }}
                value={data.credit_requested_amount}
                multiline
              />
            </FormControl>
          )}
          {data.credit_justification && (
            <FormControl fullWidth>
              <TextField
                label="Credit justification"
                InputProps={{ disableUnderline: true, readOnly: true }}
                value={data.credit_justification}
                multiline
              />
            </FormControl>
          )}
        </div>
      ) : (
        <EditableInputView data={formData} OERN={OERN} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  current_user: state.auth.user.username,
  submitting: state.operation_expenses.submitting,
  data: state.operation_expenses.data,
});

export default connect(mapStateToProps, {})(RequestInput);
