import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { get_operation_expense_request } from "../../../../actions/operation_expenses";
import { CircularProgress, Typography, makeStyles } from "@material-ui/core";
import ApprovalStatus from "./ApprovalStatus";
import CancelRequest from "./CancelRequest";
import OperationExpenseStepper from "./OperationExpenseStepper";
import AccountingStatuses from "./AccountingStatuses/AccountingStatus";
import RequestInput from "./RequestInput";

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: 600,
    flexDirection: "column",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
  },
}));

const OperationExpenseView = ({
  get_operation_expense_request,
  current_user,
  submitting,
  data,
}) => {
  const classes = useStyles();
  const { OERN } = useParams();

  useEffect(() => {
    get_operation_expense_request(OERN);
  }, [OERN, get_operation_expense_request]);

  return data === null || submitting ? (
    <div className={classes.center}>
      <CircularProgress style={{ margin: "auto" }} />
    </div>
  ) : (
    <div className={classes.paper}>
      <Typography style={{ marginBottom: 40, display: "flex" }} variant="h3">
        {`${data.OERN}`}
        <CancelRequest OERN={OERN} current_user={current_user} data={data} />
      </Typography>

      <OperationExpenseStepper data={data} current_user={current_user} />
      <ApprovalStatus OERN={OERN} current_user={current_user} data={data} />

      {data.approval_status.status === "approved" && (
        <AccountingStatuses
          OERN={OERN}
          accounting_statuses={data.accounting_statuses}
          cash_requested_amount={data.cash_requested_amount}
          credit_requested_amount={data.credit_requested_amount}
          is_accountant={data.accountants.includes(current_user)}
        />
      )}

      <RequestInput OERN={OERN} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  submitting: state.operation_expenses.submitting,
  data: state.operation_expenses.data,
  current_user: state.auth.user.username,
});

export default connect(mapStateToProps, { get_operation_expense_request })(
  OperationExpenseView
);
