// imports the React Javascript Library
import React, {useState, Fragment} from "react";
import {Fab, Grid} from "@material-ui/core";
import PublishIcon from "@material-ui/icons/Publish";
import {ExcelRenderer} from "react-excel-renderer";

const ExcelUpload = ({passProducts}) => {
  const [value, setValue] = useState("");

  const handleUploadClick = (event) => {
    var file = event.target.files[0];
    if (typeof file !== "undefined") {
      setValue("");
      ExcelRenderer(file, (err, resp) => {
        if (err) {
          alert(err);
        } else {
          passProducts(resp.cols, resp.rows);
        }
      });
    }
  };

  return (
    <Fragment>
      <Grid container justify="center" alignItems="center">
        <label htmlFor="excel_file" style={{marginBottom: 0, width: "100%"}}>
          <Fab
            component="span"
            style={{
              color: "#77E0FF",
              backgroundColor: "white",
              width: "100%",
              borderRadius: 4,
            }}
          >
            <PublishIcon/>
          </Fab>
        </label>
      </Grid>
      <Grid container justify="center" alignItems="center">
        <input
          required
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          style={{
            opacity: 0,
            width: "1px",
            height: "1px",
            pointerEvents: "none",
          }}
          id="excel_file"
          name="excel_file"
          type="file"
          value={value}
          onClick={() => setValue("")}
          onChange={handleUploadClick}
          // onClick={handleUploadClick}
        />
      </Grid>
    </Fragment>
  );
};

export default ExcelUpload;
