import React from "react";
import {
  Typography,
  Dialog,
  makeStyles,
  Box,
  Avatar,
  Grid,
  IconButton,
} from "@material-ui/core";
import MilestoneStatusIcon from "./MilestoneStatusIcon";
import {Close as CloseIcon} from "@material-ui/icons";

const bgColor = "#77E0FF";

const useStyles = makeStyles({
  dialogPaper: {
    borderRadius: 16,
  },
});

export default function MilestoneDialog({isOpen, setIsOpen, milestone}) {
  const classes = useStyles();
  const {title, description, is_completed, date, employees} = milestone;
  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      fullWidth
      classes={{paper: classes.dialogPaper}}
    >
      <Box style={{padding: 24, backgroundColor: bgColor}}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 14,
          }}
        >
          <div style={{display: "flex", alignItems: "center"}}>
            {is_completed && (
              <MilestoneStatusIcon
                is_completed={is_completed}
                style={{marginRight: 16}}
                primary_fill="#FFF"
                secondary_fill={bgColor}
                dimension={24}
              />
            )}
            <Typography
              variant="h4"
              style={{fontWeight: "bolder", color: "white"}}
            >
              {title}
            </Typography>
          </div>
          <IconButton
            size="small"
            onClick={() => setIsOpen(false)}
            style={{color: "white"}}
          >
            <CloseIcon/>
          </IconButton>
        </div>

        <Typography variant="body2" style={{color: "white", marginBottom: 4}}>
          {`${new Date(date).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "long",
            year: "numeric",
          })}`}
        </Typography>
      </Box>

      <Box style={{padding: 24}}>
        {description && (
          <Typography style={{marginBottom: 24}}>{description}</Typography>
        )}

        {employees.length > 0 && (
          <Typography
            variant="h4"
            style={{fontWeight: "bolder", marginBottom: 12}}
          >
            {"Aveyators"}
          </Typography>
        )}

        <Grid container spacing={3}>
          {employees.map(({full_name, picture}, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              key={index}
              style={{display: "flex", alignItems: "center"}}
            >
              <Avatar
                style={{
                  height: 32,
                  width: 32,
                  borderRadius: 8,
                  marginRight: 8,
                }}
                variant="square"
                src={picture}
                alt={full_name}
              />
              <Typography> {full_name}</Typography>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Dialog>
  );
}
