const {
  REACT_APP_RP_API_URL,
  REACT_APP_RP_WS_URL,
  REACT_APP_REFRESH_NOTIFICATIONS_RATE,
} = process.env;
const exports = {
  API_URL: REACT_APP_RP_API_URL || "http://localhost:8000",
  WS_URL: REACT_APP_RP_WS_URL || "wss://localhost:8000/ws/notification/",
  REFRESH_NOTIFICATIONS_RATE: parseInt(REACT_APP_REFRESH_NOTIFICATIONS_RATE),
};

export default exports;
