import React from "react";
import { Grid, Typography } from "@material-ui/core";
import EmergencyContact from "./EmergencyContact";

const EmergencyContacts = ({ emergency_contacts }) => {
  return (
    <div>
      <Grid container spacing={2}>
        {emergency_contacts.length > 0 ? (
          emergency_contacts.map((contact) => (
            <EmergencyContact
              key={contact.id}
              contact={contact}
              editable={false}
            />
          ))
        ) : (
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="textSecondary">
              Rimadster has no emergency contacts!
            </Typography>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default EmergencyContacts;
