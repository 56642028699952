// imports the React Javascript Library
import React, {Fragment, useEffect, useState} from "react";
import {
  Fab,
  Grid,
  InputLabel,
  IconButton,
  Button,
  Card,
  Paper,
} from "@material-ui/core";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import CloseIcon from "@material-ui/icons/Close";
import {Document, Page, pdfjs} from "react-pdf";

const ImageUpload = ({
                       label,
                       allowedToEdit,
                       passReceipt,
                       receipt_image,
                       required = true,
                     }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isPDF, setIsPDF] = useState(false);

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({numPages}) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  const previousPage = (e) => {
    e.preventDefault();
    changePage(-1);
  };

  const nextPage = (e) => {
    e.preventDefault();
    changePage(1);
  };

  useEffect(() => {
    if (typeof receipt_image === "string") {
      receipt_image.endsWith(".pdf") && setIsPDF(true);
    }
    setSelectedFile(receipt_image);
  }, [receipt_image]);

  const handleUploadClick = (event) => {
    var file = event.target.files[0];
    file.type === "application/pdf" ? setIsPDF(true) : setIsPDF(false);
    if (typeof file !== "undefined") {
      passReceipt(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        setSelectedFile(reader.result);
      };
    }
  };

  return selectedFile === null ? (
    <Fragment>
      <InputLabel style={{marginLeft: 14}} required={required} shrink={true}>
        {label || "Receipt"}
      </InputLabel>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{marginTop: 20}}
        component={Paper}
        elevation={3}
      >
        <label
          htmlFor={label || "receipt_image"}
          style={{marginBottom: 0, width: "100%"}}
        >
          <Fab
            component="span"
            variant="extended"
            style={{
              color: "#77E0FF",
              backgroundColor: "white",
              width: "100%",
              borderRadius: 4,
            }}
          >
            <AddPhotoAlternateIcon/>
          </Fab>
        </label>
      </Grid>
      <Grid container justifyContent="center" alignItems="center">
        <input
          required={required}
          accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
          style={{
            opacity: 0,
            width: "1px",
            height: "1px",
            pointerEvents: "none",
          }}
          id={label || "receipt_image"}
          name={label || "receipt_image"}
          type="file"
          onChange={handleUploadClick}
        />
      </Grid>
    </Fragment>
  ) : (
    <div style={{position: "relative"}}>
      {allowedToEdit && (
        <IconButton
          onClick={() => setSelectedFile(null)}
          style={{
            position: "absolute",
            color: "#77E0FF",
            zIndex: 1,
            right: 0,
          }}
        >
          <CloseIcon/>
        </IconButton>
      )}
      {isPDF ? (
        !(typeof receipt_image === "string") ? (
          <div>
            <div style={{display: "flex", marginTop: 40}}>
              <Button
                type="button"
                disabled={pageNumber <= 1}
                onClick={previousPage}
              >
                Previous
              </Button>
              <div style={{flexGrow: 1}}/>
              <Button disabled>
                Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
              </Button>
              <div style={{flexGrow: 1}}/>
              <Button
                type="button"
                disabled={pageNumber >= numPages}
                onClick={(e) => nextPage(e)}
              >
                Next
              </Button>
            </div>
            <Card elevation={8} style={{maxWidth: "600px", marginTop: 40}}>
              <Document
                file={selectedFile}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page pageNumber={pageNumber}/>
              </Document>
            </Card>
          </div>
        ) : (
          <a
            href={selectedFile}
            style={{color: "#77E0FF", fontWeight: "bold"}}
            target="_blank"
            rel="noreferrer"
          >
            Open PDF in a new tab
          </a>
        )
      ) : (
        <img
          src={selectedFile}
          alt={label || "receipt_image"}
          style={{borderRadius: "4px", maxWidth: "100%"}}
        />
      )}
    </div>
  );
};

export default ImageUpload;
