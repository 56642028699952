import React from "react";
import {
  Box,
  Card,
  Typography,
  List,
  CircularProgress,
  Collapse,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { connect } from "react-redux";
import Milestone from "./Milestone";
import VerifiedIcon from "../../../Icons/VerifiedIcon";
import QuarterMonthProgress from "./QuarterMonthProgress";

function SelectedQuarterMonth({ quarter_month, isLoadingMilestones }) {
  const {
    month_start_date,
    milestones = [],
    percentage_completion = 0,
  } = quarter_month;

  const month = new Date(month_start_date).toLocaleDateString("en-GB", {
    month: "long",
  });
  const year = new Date(month_start_date).toLocaleDateString("en-GB", {
    year: "2-digit",
  });
  const percentageCompleted = Math.ceil(percentage_completion * 100) + "%";

  return (
    <Card elevation={8}>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          position: "relative",
        }}
      >
        <QuarterMonthProgress
          percentageCompleted={percentageCompleted}
          start_date={month_start_date}
        />
        <div
          style={{
            zIndex: 2,
            padding: 16,
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", alignItems: "baseline" }}>
            <Typography
              style={{
                marginRight: 4,
                fontWeight: "bold",
                color: "white",
              }}
              variant="h4"
            >
              {month}
            </Typography>
            <Typography
              variant="h6"
              style={{
                color: "white",
              }}
            >{`'${year}`}</Typography>
          </div>
          {isLoadingMilestones ? (
            <CircularProgress size={24} style={{ color: "white" }} />
          ) : (
            percentage_completion === 1 && (
              <VerifiedIcon
                dimension={24}
                primary_fill="transparent"
                secondary_fill="white"
              />
            )
          )}
        </div>
      </Box>

      <Collapse in={!isLoadingMilestones}>
        <List>
          {milestones.length > 0
            ? milestones.map((milestone) => (
                <Milestone milestone={milestone} key={milestone.id} />
              ))
            : !isLoadingMilestones && (
                <ListItem>
                  <ListItemText
                    secondary={"There are no milestones for this month!"}
                  />
                </ListItem>
              )}
        </List>
      </Collapse>
    </Card>
  );
}

const mapStateToProps = (state) => ({
  isLoadingMilestones: state.milestone.isLoadingMilestones,
});

export default connect(mapStateToProps, {})(SelectedQuarterMonth);
