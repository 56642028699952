import React, {useEffect, useState} from "react";
import {connect} from "react-redux";

import {makeStyles} from "@material-ui/core/styles";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ImageUpload from "../ImageUpload";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import Typography from "@material-ui/core/Typography";
import {update_reimbursement_request_form} from "../../../actions/reimbursement";
import {Fragment} from "react";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  number: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
      {
        display: "none",
      },
  },
}));

const RequestInput = ({
                        data,
                        current_user,
                        RRN,
                        update_reimbursement_request_form,
                      }) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    associated_payment_id: "",
    payment_type: "",
    reimbursement_amount: "",
    reasons_for_reimbursement: "",
    receipt_image: "",
  });
  const [edit, setEdit] = useState(false);
  const [edited, setEdited] = useState(false);
  useEffect(() => {
    setFormData({
      associated_payment_id: data.accounting_status.associated_payment_id,
      payment_type: data.accounting_status.payment_type,
      reimbursement_amount: data.reimbursement_amount,
      reasons_for_reimbursement: data.reasons_for_reimbursement,
      receipt_image: data.receipt_image,
      expense_for: data.expense_for,
    });
  }, [data]);
  const handleChange = (prop) => (event) => {
    setFormData({...formData, [prop]: event.target.value});
    setEdited(true);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    update_reimbursement_request_form(formData, RRN);
    setEdit(false);
    setEdited(false);
  };
  const AllowedToEdit = () => {
    return (
      ["pending", "returned"].includes(data.approval_status.status) &&
      current_user === data.requester
    );
  };

  return (
    <Fragment>
      {data.approval_status.note && (
        <FormControl fullWidth>
          <TextField
            label="Approver's note"
            multiline
            InputProps={{disableUnderline: true, readOnly: true}}
            value={data.approval_status.note}
          />
        </FormControl>
      )}

      <Typography
        style={{marginBottom: 20, fontWeight: "bold", alignSelf: "start"}}
        variant="h5"
      >
        Reimbursement information
        {AllowedToEdit() && (
          <Button
            color="primary"
            style={{fontWeight: "bold"}}
            onClick={() => {
              setEdit(!edit);
              setEdited(false);
              setFormData({
                associated_payment_id:
                data.accounting_status.associated_payment_id,
                payment_type: data.accounting_status.payment_type,
                reimbursement_amount: data.reimbursement_amount,
                reasons_for_reimbursement: data.reasons_for_reimbursement,
                receipt_image: data.receipt_image,
                expense_for: data.expense_for,
              });
            }}
          >
            {edit ? "Cancel" : "Edit"}
          </Button>
        )}
      </Typography>

      {AllowedToEdit() && edit ? (
        <form onSubmit={(e) => onSubmit(e)}>
          <FormControl fullWidth variant="outlined" required>
            <InputLabel>Expense for</InputLabel>
            <Select
              value={formData.expense_for}
              onChange={handleChange("expense_for")}
              label="Expense for *"
            >
              <MenuItem value={"Rimads"}>Avey</MenuItem>
              <MenuItem value={"Reaf Life"}>Reaf Life</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth variant="outlined">
            <InputLabel required htmlFor="outlined-adornment-amount">
              Amount
            </InputLabel>
            <OutlinedInput
              required
              label="Amount *"
              type="number"
              value={formData.reimbursement_amount}
              onChange={handleChange("reimbursement_amount")}
              startAdornment={
                <InputAdornment position="start">QAR</InputAdornment>
              }
              className={classes.number}
              inputProps={{min: 0, step: 0.01}}
            />
          </FormControl>
          <FormControl fullWidth variant="outlined">
            <TextField
              required
              label="Reasons"
              multiline
              onChange={handleChange("reasons_for_reimbursement")}
              value={formData.reasons_for_reimbursement}
              rows={4}
              variant="outlined"
            />
          </FormControl>
          <FormControl fullWidth variant="outlined" required>
            <InputLabel>Form of Payment</InputLabel>
            <Select
              value={formData.payment_type}
              onChange={handleChange("payment_type")}
              label="Form of Payment *"
            >
              <MenuItem value={"Bank transfer"}>Bank transfer</MenuItem>
              <MenuItem value={"Cash"}>Cash</MenuItem>
              <MenuItem value={"Cheque"}>Cheque</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <ImageUpload
              allowedToEdit={true}
              passReceipt={(file) => {
                setFormData({...formData, receipt_image: file});
                setEdited(true);
              }}
              receipt_image={formData.receipt_image}
            />
          </FormControl>
          <FormControl fullWidth>
            {edited ? (
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            ) : (
              <Button
                disabled
                variant="contained"
                color="primary"
                type="submit"
              >
                Save
              </Button>
            )}
          </FormControl>
        </form>
      ) : (
        <form>
          <FormControl fullWidth>
            <InputLabel htmlFor="outlined-adornment-requester">
              Requester
            </InputLabel>
            <Input
              disableUnderline={true}
              inputProps={{readOnly: true}}
              label="Requester"
              value={data.requester_full_name}
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              label="Expense for"
              InputProps={{disableUnderline: true, readOnly: true}}
              value={data.expense_for}
            />
          </FormControl>
          <FormControl fullWidth>
            <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
            <Input
              disableUnderline={true}
              inputProps={{readOnly: true}}
              label="Amount"
              value={data.reimbursement_amount.toFixed(2)}
              startAdornment={
                <InputAdornment position="start">QAR</InputAdornment>
              }
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              label="Reasons"
              multiline
              InputProps={{disableUnderline: true, readOnly: true}}
              value={data.reasons_for_reimbursement}
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              label="Form of Payment *"
              value={data.accounting_status.payment_type}
              InputProps={{disableUnderline: true, readOnly: true}}
            />
          </FormControl>
          {data.accounting_status.payment_type !== "Cash" &&
            data.accounting_status.associated_payment_id && (
              <FormControl fullWidth>
                <InputLabel>Payment ID</InputLabel>
                <Input
                  disableUnderline={true}
                  label="Payment ID *"
                  type="text"
                  value={data.accounting_status.associated_payment_id}
                />
              </FormControl>
            )}
          <FormControl fullWidth>
            <ImageUpload
              allowedToEdit={false}
              receipt_image={data.receipt_image}
            />
          </FormControl>
        </form>
      )}
    </Fragment>
  );
};

export default connect(null, {
  update_reimbursement_request_form,
})(RequestInput);
