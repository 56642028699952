import { combineReducers } from "redux";
import auth from "./auth";
import procedures from "./procedures";
import procurement from "./procurement";
import reimbursement from "./reimbursement";
import marketing_expenses from "./marketing_expenses";
import operation_expenses from "./operation_expenses";
import rimadsters from "./rimadsters";
import personal from "./personal";
import home from "./home";
import calendar from "./calendar";
import milestone from "./milestone";
import weekly_kpis from "./weeklyKpis";

export default combineReducers({
  auth,
  procedures,
  procurement,
  reimbursement,
  marketing_expenses,
  operation_expenses,
  rimadsters,
  personal,
  home,
  calendar,
  milestone,
  weekly_kpis,
});
