import React from "react";
import { connect } from "react-redux";
import {
  weeklyKpisSearchTags,
  weeklyKpisSelectTags,
} from "../../../../actions/weeklyKpis";
import CustomSearchField from "../../../CustomSearchField";
import { Autocomplete } from "@material-ui/lab";

const getOptionLabel = (option) => {
  const { bnakyEngine } = option;
  switch (bnakyEngine) {
    case "Departments":
      return option?.name;

    case "Department teams":
      return option?.slug;

    case "Employees":
      return [option?.username, option?.full_name].join(" - ");

    default:
      return "";
  }
};

const getOptionSelected = (option, value) => {
  if (option.bnakyEngine === value.bnakyEngine) {
    switch (value.bnakyEngine) {
      case "Departments":
        return option?.name === value?.name;

      case "Department teams":
        return option?.slug === value?.slug;

      case "Employees":
        return (
          [option?.username, option?.full_name].join(" - ") ===
          [value?.username, value?.full_name].join(" - ")
        );

      default:
        return false;
    }
  }
  return false;
};

const WeeklyKpisTagFilter = ({
  department_team__id,
  weeklyKpisSearchTags,
  weeklyKpisSelectTags,
  tags = [],
  AcceptableEngines = ["Departments", "Department teams", "Employees"],
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [options, setOptions] = React.useState([]);

  const handleChange = (newValue) => setSearchQuery(newValue);

  React.useEffect(() => {
    setIsLoading(true);
    const delayDebounceFn = setTimeout(() => {
      setIsLoading(true);
      weeklyKpisSearchTags({
        searchQuery: searchQuery,
        AcceptableEngines: AcceptableEngines,
        department_team__id: department_team__id,
      }).then((payload) => {
        Boolean(payload) && setOptions(payload);
        setIsLoading(false);
      });
    }, 800);
    return () => {
      setIsLoading(false);
      clearTimeout(delayDebounceFn);
    };
  }, [
    searchQuery,
    AcceptableEngines,
    department_team__id,
    weeklyKpisSearchTags,
  ]);

  return (
    <Autocomplete
      multiple
      freeSolo
      disableCloseOnSelect
      inputValue={searchQuery}
      groupBy={({ bnakyEngine }) =>
        AcceptableEngines.length > 1 ? bnakyEngine : null
      }
      onInputChange={(event, newInputValue) => {
        handleChange(newInputValue);
        Boolean(newInputValue === "") && weeklyKpisSelectTags([]);
      }}
      options={options}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      value={tags}
      onChange={(event, newValue) => weeklyKpisSelectTags(newValue)}
      filterSelectedOptions
      renderInput={(params) => (
        <CustomSearchField
          {...params}
          placeholder="Search & select tags"
          isLoading={isLoading}
          fullWidth={false}
        />
      )}
    />
  );
};

const mapStateToProps = (state) => ({
  department_team__id: state.weekly_kpis?.department_team?.id,
  tags: state.weekly_kpis.tags,
});

export default connect(mapStateToProps, {
  weeklyKpisSearchTags,
  weeklyKpisSelectTags,
})(WeeklyKpisTagFilter);
