import React from "react";
import FormControl from "@material-ui/core/FormControl";
import { TextField } from "@material-ui/core";

const NonEditableOnlinePayments = ({ formData }) => {
  return (
    <div>
      <FormControl fullWidth>
        <TextField
          label="Payment Date"
          InputProps={{ disableUnderline: true, readOnly: true }}
          value={new Date(formData.payments.payment_date).toLocaleDateString(
            "en-GB"
          )}
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          label="Expected delivery date"
          InputProps={{ disableUnderline: true, readOnly: true }}
          value={formData.payments.webapp.name}
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          label="Expected delivery date"
          InputProps={{ disableUnderline: true, readOnly: true }}
          value={formData.payments.webapp_account.username}
        />
      </FormControl>
    </div>
  );
};

export default NonEditableOnlinePayments;
