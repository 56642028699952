import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
  Select,
  MenuItem,
  Divider,
  Typography,
  Grid,
  Tooltip,
  IconButton,
  CircularProgress,
  CardContent,
  Card,
  Backdrop,
} from "@material-ui/core";
import api from "./api";
import InfoIcon from "@material-ui/icons/Info";
import moment from "moment";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const getMetricWithPeriod = api.getMetricWithPeriod;
const getMetricWithDates = api.getMetricWithDates;

function getColor(str) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 6) - hash);
  }
  hash = (hash & 0x00ffffff).toString(16).toUpperCase();
  return "#" + "00000".substring(0, 6 - hash.length) + hash;
}

const assignChartProperties = (chartData) => {
  let chartDataObject = chartData;

  chartDataObject.datasets.map((dataset) => {
    if (dataset.label === "avg" || dataset.label === "sum") {
      dataset.borderColor = "#77E0FF";
      dataset.backgroundColor = "#77E0FF";
    } else if (dataset.label === "min" || dataset.label === "max") {
      dataset.borderColor = "#e0e0e0";
      dataset.backgroundColor = "#e0e0e0";
    } else {
      dataset.borderColor = getColor(dataset.label);
      dataset.backgroundColor = getColor(dataset.label);
    }

    dataset.fill = false;
    dataset.lineTension = 0;
    return dataset;
  });

  return chartDataObject;
};

const Metric = ({ metric }) => {
  const [chartState, setChartState] = useState({
    data: {
      avg: 0,
      max: 0,
      min: 0,
      total: 0,
      datasets: [],
    },
    isLoading: true,
  });
  const [metricState, setMetricState] = useState({
    period: "year",
    granularity: "weekly",
    start_date: null,
    end_date: null,
  });
  const { period, granularity, start_date, end_date } = metricState;
  const { data, isLoading } = chartState;

  useEffect(() => {
    if (period === "custom") {
      if (start_date && end_date) {
        getMetricWithDates(
          metric.id,
          moment(start_date).format("YYYY-MM-DD"),
          moment(end_date).format("YYYY-MM-DD"),
          granularity
        ).then((res) =>
          setChartState({
            data: assignChartProperties(res.data),
            isLoading: false,
          })
        );
      }
    } else {
      getMetricWithPeriod(metric.id, period, granularity).then((res) =>
        setChartState({
          data: assignChartProperties(res.data),
          isLoading: false,
        })
      );
    }
  }, [metric.id, period, granularity, start_date, end_date]);

  const handleChange = (e) => {
    setChartState({ ...chartState, isLoading: true });
    setMetricState({ ...metricState, [e.target.name]: e.target.value });
  };

  const handleDateChange = (date, date_name) => {
    setChartState({ ...chartState, isLoading: true });
    setMetricState({ ...metricState, [date_name]: date });
  };

  const options = {
    responsive: true,
    animation: {
      duration: 0,
    },
    title: {
      display: true,
      fontSize: "15",
      text: metric.name,
    },
    legend: {
      position: "top",
    },
    scales: {
      xAxes: [
        {
          type: "time",
          distribution: "series",
          offset: "true",
          time: {
            displayFormats: {
              year: "MMM D",
            },
          },
        },
      ],
      yAxes: [
        {
          display: true,
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Typography variant="h5">{metric.name}</Typography>
        <div style={{ flexGrow: 1 }} />
        <Tooltip
          title={metric.desc}
          enterTouchDelay={50}
          leaveTouchDelay={2500}
        >
          <IconButton color="primary">
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </div>

      <Card elevation={4} style={{ marginBottom: 30 }}>
        <CardContent style={{ position: "relative" }}>
          <Bar
            width={600}
            height={400}
            data={data}
            options={options}
            key={Math.random()}
          />
          <Backdrop
            style={{
              position: "absolute",
              zIndex: 1000,
              backgroundColor: "black",
              opacity: 0.4,
            }}
            open={isLoading}
          >
            <CircularProgress style={{ margin: "auto" }} />
          </Backdrop>
        </CardContent>
      </Card>

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Card elevation={4}>
              <CardContent>
                <Typography
                  variant="h6"
                  color="primary"
                  style={{ marginBottom: 20 }}
                >
                  Period
                </Typography>
                <Select
                  value={period}
                  onChange={(e) => handleChange(e)}
                  name="period"
                  fullWidth
                >
                  <MenuItem value="day">Yesterday</MenuItem>
                  <MenuItem value="week">Last Week</MenuItem>
                  <MenuItem value="month">Last Month</MenuItem>
                  <MenuItem value="year">Last Year</MenuItem>
                  <MenuItem value="lifetime">Lifetime</MenuItem>
                  <Divider />
                  <MenuItem value="custom">Custom</MenuItem>
                </Select>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card elevation={4}>
              <CardContent>
                <Typography
                  variant="h6"
                  color="primary"
                  style={{ marginBottom: 20 }}
                >
                  Granularity
                </Typography>
                <Select
                  value={granularity}
                  onChange={(e) => handleChange(e)}
                  name="granularity"
                  fullWidth
                >
                  <MenuItem value="daily">Daily</MenuItem>
                  <MenuItem value="weekly">Weekly</MenuItem>
                  <MenuItem value="monthly">Monthly</MenuItem>
                  <MenuItem value="quarterly">Quarterly</MenuItem>
                  <MenuItem value="yearly">Yearly</MenuItem>
                </Select>
              </CardContent>
            </Card>
          </Grid>
          {period === "custom" && (
            <Grid item xs={12} md={6}>
              <KeyboardDatePicker
                style={{ width: "100%" }}
                inputVariant="outlined"
                label="Start date"
                format="dd / MM / yyyy"
                value={start_date}
                onChange={(date) => handleDateChange(date, "start_date")}
                minDate={new Date("2021-05-05")}
                maxDate={new Date()}
              />
            </Grid>
          )}
          {period === "custom" && (
            <Grid item xs={12} md={6}>
              {start_date && (
                <KeyboardDatePicker
                  style={{ width: "100%" }}
                  inputVariant="outlined"
                  label="End date"
                  format="dd / MM / yyyy"
                  value={end_date}
                  onChange={(date) => handleDateChange(date, "end_date")}
                  minDate={start_date}
                  maxDate={new Date()}
                />
              )}
            </Grid>
          )}
        </Grid>
      </MuiPickersUtilsProvider>

      <Card elevation={4} style={{ marginTop: 40, marginBottom: 30 }}>
        <CardContent>
          <Grid container spacing={4} style={{ textAlign: "center" }}>
            <Grid item xs={6} md={3}>
              Total: {Math.round((data.total + Number.EPSILON) * 100) / 100}
            </Grid>
            <Grid item xs={6} md={3}>
              Max: {Math.round((data.max + Number.EPSILON) * 100) / 100}
            </Grid>
            <Grid item xs={6} md={3}>
              Avg: {Math.round((data.avg + Number.EPSILON) * 100) / 100}
            </Grid>
            <Grid item xs={6} md={3}>
              Min: {Math.round((data.min + Number.EPSILON) * 100) / 100}
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Divider style={{ marginTop: 60, marginBottom: 60 }} />
    </div>
  );
};

export default Metric;
