import React, {useState} from "react";
import {connect} from "react-redux";

import {makeStyles} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import {post_unpaid_campaign_request} from "../../../../actions/marketing_expenses";
import {Redirect} from "react-router";

import Poppup from "../../../../components/Poppup";

const categories = [
  "Discount",
  "Rebate",
  "Voucher",
  "Reward scheme",
  "Bundling",
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 600,
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "25ch",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
  },
  number: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
      {
        display: "none",
      },
  },
}));

const UnpaidCampaignRequest = ({
                                 submitting,
                                 post_unpaid_campaign_request,
                               }) => {
  const classes = useStyles();

  const [UCN, setUCN] = useState(false);

  const [formData, setFormData] = useState({
    campaign_name: "",
    category: null,
    qualitative_justification: "",
    // expected_cost: "",
    // expected_ROI: "",
    start_date: null,
    end_date: null,
  });

  const handleDateChange = (date, prop) => {
    setFormData({...formData, [prop]: date});
  };
  const handleCategoryChange = (category) => {
    setFormData({...formData, category: category || null});
  };
  const handleChange = (prop) => (event) => {
    setFormData({...formData, [prop]: event.target.value});
  };
  const onSubmit = (e) => {
    e.preventDefault();
    post_unpaid_campaign_request(formData).then((UCN) => setUCN(UCN));
  };

  return UCN ? (
    <Redirect to={`/app/forms/marketing-expenses/unpaid-form/${UCN}/`}/>
  ) : (
    <div className={classes.paper}>
      <Typography style={{marginBottom: 40}} variant="h3">
        Unpaid Campaign
        <Poppup
          content={
            <Typography variant="body1">
              This request form is created to enable requests for paid marketing
              expenses. Paid marketing expenses are defined as marketing
              expenses for which Avey needs to indirectly bear a cost. This
              indirect cost is not something that we pay for, but can be for
              example a discount on retail price.
            </Typography>
          }
        />
      </Typography>
      {submitting ? (
        <div className={classes.center}>
          <CircularProgress style={{margin: "auto"}}/>
        </div>
      ) : (
        <form style={{width: "100%"}} onSubmit={(e) => onSubmit(e)}>
          <FormControl fullWidth>
            <TextField
              required
              label="Campaign name"
              onChange={handleChange("campaign_name")}
              value={formData.campaign_name}
              variant="outlined"
            />
          </FormControl>
          <Autocomplete
            required
            options={categories}
            getOptionLabel={(option) => option}
            onInputChange={(event, newValue) => {
              handleCategoryChange(newValue);
            }}
            getOptionSelected={(option, value) =>
              option === value ? option : value
            }
            value={formData.category}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                InputLabelProps={{shrink: true}}
                label="Category"
                variant="outlined"
              />
            )}
          />
          <FormControl fullWidth>
            <TextField
              required
              multiline
              label="Qualitative justification"
              onChange={handleChange("qualitative_justification")}
              value={formData.qualitative_justification}
              variant="outlined"
            />
          </FormControl>
          {/* <FormControl fullWidth variant="outlined" required>
            <InputLabel htmlFor="outlined-adornment-amount">
              Expected cost
            </InputLabel>
            <OutlinedInput
              label="Expected cost *"
              type="number"
              value={formData.expected_cost}
              onChange={handleChange("expected_cost")}
              startAdornment={
                <InputAdornment position="start">QAR</InputAdornment>
              }
              className={classes.number}
              inputProps={{ min: 0, step: 0.01 }}
            />
          </FormControl>
          <FormControl fullWidth variant="outlined" required>
            <InputLabel htmlFor="outlined-adornment-amount">
              Expected ROI
            </InputLabel>
            <OutlinedInput
              label="Expected ROI *"
              type="number"
              value={formData.expected_ROI}
              onChange={handleChange("expected_ROI")}
              startAdornment={
                <InputAdornment position="start">QAR</InputAdornment>
              }
              className={classes.number}
              inputProps={{ min: 0, step: 0.01 }}
            />
          </FormControl> */}
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              fullWidth
              required
              inputVariant="outlined"
              label="Start date"
              format="dd / MM / yyyy"
              value={formData.start_date || null}
              onChange={(date) => handleDateChange(date, "start_date")}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              minDate={new Date()}
            />
          </MuiPickersUtilsProvider>
          {formData.start_date && (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                fullWidth
                required
                inputVariant="outlined"
                label="End date"
                format="dd / MM / yyyy"
                value={formData.end_date || null}
                onChange={(date) => handleDateChange(date, "end_date")}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                minDate={formData.start_date}
              />
            </MuiPickersUtilsProvider>
          )}
          <FormControl fullWidth>
            <Button
              disabled={
                (formData.start_date !== null
                  ? formData.start_date.toString() === "Invalid Date"
                  : true) ||
                (formData.end_date !== null
                  ? formData.end_date.toString() === "Invalid Date"
                  : false) ||
                (formData.end_date !== null &&
                  formData.start_date > formData.end_date)
              }
              variant="contained"
              color="primary"
              type="submit"
            >
              Submit
            </Button>
          </FormControl>
        </form>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  submitting: state.marketing_expenses.submittingUnpaid,
});

export default connect(mapStateToProps, {post_unpaid_campaign_request})(
  UnpaidCampaignRequest
);
