import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    "@global": {
      "*": {
        boxSizing: "border-box",
        margin: 0,
        padding: 0,
      },
      html: {
        "-webkit-font-smoothing": "antialiased",
        "-moz-osx-font-smoothing": "grayscale",
        height: "100%",
        width: "100%",
      },
      body: {
        // backgroundColor: "#f4f6f8",
        backgroundColor: "#ffffff",
        height: "100%",
        width: "100%",
      },
      a: {
        textDecoration: "none",
      },
      "#root": {
        height: "100%",
        width: "100%",
      },
      "*::-webkit-scrollbar": {
        display: "none",
        // width: "0.5em",
      },
      // "*::-webkit-scrollbar-track": {
      //   "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
      // },
      // "*::-webkit-scrollbar-thumb": {
      //   backgroundColor: "#ed7273",
      //   outline: "0px solid",
      // borderRadius: "10px",
      // },
    },
  })
);

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
