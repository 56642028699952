import axios from "axios";
import global from "../global";
const { API_URL } = global;

export default function Api(props = {}) {
  const { headers = {} } = props;
  return axios.create({
    baseURL: API_URL,
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
      "Content-Type": "application/json",
      ...headers,
    },
  });
}
