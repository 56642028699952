export const LOAD_SUPPLIERS_SUCCESS = "LOAD_SUPPLIERS_SUCCESS";
export const LOAD_SUPPLIER_PRODUCTS_SUCCESS = "LOAD_SUPPLIER_PRODUCTS_SUCCESS";
export const CLEAR_SUPPLIER_PRODUCTS = "CLEAR_SUPPLIER_PRODUCTS";

export const LOAD_GROUP_STAFF_SUCCESS = "LOAD_GROUP_STAFF_SUCCESS";
export const LOAD_GROUP_STAFF_FAILURE = "LOAD_GROUP_STAFF_FAILURE";

export const SNACKBAR_WRITE = "SNACKBAR_WRITE";
export const SNACKBAR_CUSTOM = "SNACKBAR_CUSTOM";
export const SNACKBAR_DELETE = "SNACKBAR_DELETE";

export const PROCESSING = "PROCESSING";

export const DELETE_SUPPLIER = "DELETE_SUPPLIER";
export const DELETE_MULTIPLE_SUPPLIERS = "DELETE_MULTIPLE_SUPPLIERS";
export const DELETE_ALL_SUPPLIERS = "DELETE_ALL_SUPPLIERS";
export const DELETE_SUPPLIER_PRODUCT = "DELETE_SUPPLIER_PRODUCT";
export const REMOVE_SUPPLIER_PRODUCT = "REMOVE_SUPPLIER_PRODUCT";
export const DELETE_MULTIPLE_SUPPLIER_PRODUCTS =
  "DELETE_MULTIPLE_SUPPLIER_PRODUCTS";
export const DELETE_ALL_SUPPLIER_PRODUCTS = "DELETE_ALL_SUPPLIER_PRODUCTS";

export const CREATE_NEW_SUPPLIER = "CREATE_NEW_SUPPLIER";
export const EDIT_SUPPLIER = "EDIT_SUPPLIER";
export const CREATE_NEW_PRODUCT = "CREATE_NEW_PRODUCT";
export const UPLOAD_PRODUCTS = "UPLOAD_PRODUCTS";
export const EDIT_PRODUCT = "EDIT_PRODUCT";

export const LOAD_WEBAPPS = "LOAD_WEBAPPS";
export const LOAD_WEBAPP_ACCOUNTS = "LOAD_WEBAPP_ACCOUNTS";
export const CLEAR_WEBAPP_ACCOUNTS = "CLEAR_WEBAPP_ACCOUNTS";

export const CREATE_WEBAPP = "CREATE_WEBAPP";
export const CREATE_WEBAPP_ACCOUNT = "CREATE_WEBAPP_ACCOUNT";

export const EDIT_WEBAPP = "EDIT_WEBAPP";
export const EDIT_WEBAPP_ACCOUNT = "EDIT_WEBAPP_ACCOUNT";

export const DELETE_WEBAPP = "DELETE_WEBAPP";
export const DELETE_MULTIPLE_WEBAPPS = "DELETE_MULTIPLE_WEBAPPS";
export const DELETE_ALL_WEBAPPS = "DELETE_ALL_WEBAPPS";

export const DELETE_WEBAPP_ACCOUNT = "DELETE_WEBAPP_ACCOUNT";
export const DELETE_MULTIPLE_WEBAPP_ACCOUNTS =
  "DELETE_MULTIPLE_WEBAPP_ACCOUNTS";
export const DELETE_ALL_WEBAPP_ACCOUNTS = "DELETE_ALL_WEBAPP_ACCOUNTS";

export const SAVE_PROCUREMENT_DATA = "SAVE_PROCUREMENT_DATA";
export const DISCARD_PROCUREMENT_DATA = "DISCARD_PROCUREMENT_DATA";

export const GET_GROUPS = "GET_GROUPS";
export const GET_USER_ACCOUNTS = "GET_USER_ACCOUNTS";
