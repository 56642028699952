import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, CircularProgress } from "@material-ui/core";

import { get_RCRN } from "../../../../actions/operation_expenses";
import ReturnCashTable from "./ReturnCashTable";
import Poppup from "../../../../components/Poppup";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 600,
  },
  center: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
  },
}));

const ReturnCashRequest = ({ submitting, get_RCRN, data }) => {
  const classes = useStyles();
  const { RCRN } = useParams();

  useEffect(() => {
    get_RCRN(RCRN);
  }, [get_RCRN, RCRN]);

  return data === null ? (
    <div className={classes.center}>
      <CircularProgress style={{ margin: "auto" }} />
    </div>
  ) : (
    <div className={classes.paper}>
      <Typography style={{ marginBottom: 40 }} variant="h3">
        {data.RCRN}
        <Poppup
          content={
            <Typography variant="body1">
              The return cash request can be triggered by the accounting and/or
              logistics team. This request form is meant to facilitate the
              return of cash remaining from approved Operations Expense requests
              from the logistics team to the accounting team.
            </Typography>
          }
        />
      </Typography>
      <ReturnCashTable />
    </div>
  );
};

const mapStateToProps = (state) => ({
  submitting: state.operation_expenses.submitting_RCRN,
  data: state.operation_expenses.RCRN_data,
});

export default connect(mapStateToProps, { get_RCRN })(ReturnCashRequest);
