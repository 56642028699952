import React from "react";
import { Grid, Typography } from "@material-ui/core";
import CardLink from "../../components/CardLink";

const ProcurementDirectory = () => {
  return (
    <div style={{ width: 655 }}>
      <Typography variant="h3" style={{ paddingBottom: 48 }}>
        Procurement
      </Typography>
      <Typography variant="h6" style={{ marginBottom: 36 }}>
        The purpose of this module is to manage all procurement requests.
      </Typography>
      <Grid
        container
        spacing={4}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid item>
          <CardLink title={"Request Form"} link="/app/forms/procurement/form" />
        </Grid>
        <Grid item>
          <CardLink title={"Suppliers"} link="/app/records/suppliers" />
        </Grid>
        <Grid item>
          <CardLink title={"Online Accounts"} link="/app/records/webapps" />
        </Grid>
        <Grid item>
          <CardLink
            title={"Return Cash"}
            link="/app/forms/procurement/return-cash/form"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ProcurementDirectory;
