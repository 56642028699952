import {
  LOAD_QUARTER,
  LOAD_MILESTONES,
  IS_LOADING_MILESTONES,
} from "../actions/milestoneTypes";

const initialState = {
  quarter: null,
  isLoadingMilestones: true,
};

export default function milestone(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case IS_LOADING_MILESTONES:
      return {
        ...state,
        isLoadingMilestones: payload,
      };

    case LOAD_MILESTONES:
      return {
        ...state,
        quarter: {
          ...state.quarter,
          months: state.quarter.months.map((quarter_month, index) =>
            index === payload.quarter_month_index
              ? { ...quarter_month, ...payload.quarter_month_payload }
              : quarter_month
          ),
        },
        isLoadingMilestones: false,
      };
    case LOAD_QUARTER:
      return {
        ...state,
        quarter: payload,
        isLoadingMilestones: true,
      };

    default:
      return state;
  }
}
