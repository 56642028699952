import React, { useState } from "react";
import { connect } from "react-redux";

import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "../../../components/MuiDialogTitle";
import { TextField, InputAdornment } from "@material-ui/core";

import {
  submit_reimbursement_payment_details,
  update_reimbursement_request_status,
} from "../../../actions/reimbursement";

const PaymentInformation = ({
  data,
  submit_reimbursement_payment_details,
  update_reimbursement_request_status,
  RRN,
  current_user,
}) => {
  const [paymentData, setPaymentData] = useState({
    payment_type: data.accounting_status.payment_type || "",
    associated_payment_id: data.accounting_status.associated_payment_id || "",
  });

  const [open, setOpen] = useState(false);
  const [action, setAction] = useState(null);
  const handleClick = (action) => {
    setOpen(!open);
    action !== null && setAction(action);
  };

  const handleChange = (prop) => (event) => {
    setPaymentData({ ...paymentData, [prop]: event.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    handleClick("to collect");
  };

  return (
    <div>
      {data.accounting_status.accountants.includes(current_user) &&
        data.approval_status.status === "approved" &&
        ((data.accounting_status.status === "pending" && (
          <form
            style={{ width: "100%", marginBottom: 40 }}
            onSubmit={(e) => onSubmit(e)}
          >
            <Typography
              style={{
                marginBottom: 20,
                fontWeight: "bold",
                alignSelf: "start",
              }}
              variant="h5"
            >
              Prepare the payment
            </Typography>
            <FormControl
              fullWidth
              variant="outlined"
              style={{ marginBottom: 20 }}
            >
              <TextField
                disabled
                value={data.reimbursement_amount}
                label="Amount"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">QAR</InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <FormControl
              fullWidth
              variant="outlined"
              style={{ marginBottom: 20 }}
              required
            >
              <InputLabel>Form of Payment</InputLabel>
              <Select
                value={paymentData.payment_type}
                onChange={handleChange("payment_type")}
                label="Form of Payment *"
              >
                <MenuItem value={"Bank transfer"}>Bank transfer</MenuItem>
                <MenuItem value={"Cash"}>Cash</MenuItem>
                <MenuItem value={"Cheque"}>Cheque</MenuItem>
              </Select>
            </FormControl>
            {paymentData.payment_type === "Cheque" && (
              <FormControl
                fullWidth
                variant="outlined"
                style={{ marginBottom: 20 }}
                required
              >
                <InputLabel>Cheque Number</InputLabel>
                <OutlinedInput
                  label="Cheque Number *"
                  onChange={handleChange("associated_payment_id")}
                  value={paymentData.associated_payment_id}
                />
              </FormControl>
            )}
            <Button fullWidth variant="contained" color="primary" type="submit">
              {paymentData.payment_type === "Bank transfer" &&
                "Mark bank transfer as transferred"}
              {paymentData.payment_type === "Cash" &&
                "Mark cash as ready to collect"}
              {paymentData.payment_type === "Cheque" &&
                "Mark cheque as ready to collect"}
            </Button>
          </form>
        )) ||
          (data.accounting_status.status === "to collect" && (
            <div style={{ marginBottom: 40 }}>
              <Typography
                style={{
                  marginBottom: 20,
                  fontWeight: "bold",
                  alignSelf: "start",
                }}
                variant="h5"
              >
                Has the payment been made?
              </Typography>
              <Button
                variant="contained"
                color="primary"
                style={{ width: "40%" }}
                onClick={() => handleClick("received")}
              >
                Confirm
              </Button>
            </div>
          )))}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle
          style={{ cursor: "move" }}
          id="draggable-dialog-title"
          onClose={() => handleClick(null)}
        >
          {(action === "to collect" &&
            "Have you reviewed everything? This action cannot be undone.") ||
            (action === "received" &&
              "Are you sure? This action cannot be undone.")}
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => handleClick(null)}
            type="submit"
            fullWidth
            color="primary"
            variant="outlined"
            style={{
              borderRadius: 24,
              fontWeight: "bold",
            }}
          >
            No, go back
          </Button>
          {(action === "to collect" && (
            <Button
              onClick={() => {
                submit_reimbursement_payment_details(paymentData, RRN);
                handleClick(null);
              }}
              type="submit"
              fullWidth
              color="primary"
              variant="contained"
              style={{ borderRadius: 24 }}
            >
              Yes, I have
            </Button>
          )) ||
            (action === "received" && (
              <Button
                onClick={() => {
                  update_reimbursement_request_status("received", RRN);
                  handleClick(null);
                }}
                type="submit"
                fullWidth
                color="primary"
                variant="contained"
                style={{ borderRadius: 24 }}
              >
                Yes, I'm sure
              </Button>
            ))}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default connect(null, {
  submit_reimbursement_payment_details,
  update_reimbursement_request_status,
})(PaymentInformation);
