import React, { useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import UnpaidCampaignStepper from "./UnpaidCampaignStepper";
import CancelRequest from "./CancelRequest";
import ApprovalStatus from "./ApprovalStatus";
import AccountingStatus from "./AccountingStatus";
import RequestInput from "./RequestInput";
import CustomizeSnackbar from "../../../../components/CustomizeSnackbar";

import { get_unpaid_campaign_request } from "../../../../actions/marketing_expenses";

const useStyles = makeStyles((theme) => ({
  paper: {
    flexDirection: "column",
    maxWidth: 600,
  },
  center: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
  },
}));

const ReimbursementView = ({
  get_unpaid_campaign_request,
  data,
  current_user,
  fetched,
  processing,
}) => {
  const classes = useStyles();
  let { UCN } = useParams();

  useEffect(() => {
    get_unpaid_campaign_request(UCN);
  }, [UCN, get_unpaid_campaign_request]);

  return fetched ? (
    data.requester === current_user ||
    data.accounting_status.accountants.includes(current_user) ||
    data.approval_status.approvers.includes(current_user) ? (
      <div className={classes.paper}>
        <Typography style={{ marginBottom: 40, display: "flex" }} variant="h3">
          {`${data.UCN}`}
          <CancelRequest UCN={UCN} current_user={current_user} data={data} />
        </Typography>
        <UnpaidCampaignStepper data={data} />
        {processing ? (
          <div className={classes.center}>
            <CircularProgress style={{ margin: "auto" }} />
          </div>
        ) : (
          <Fragment>
            <AccountingStatus
              UCN={UCN}
              current_user={current_user}
              data={data}
            />
            <ApprovalStatus UCN={UCN} current_user={current_user} data={data} />
            <RequestInput UCN={UCN} current_user={current_user} data={data} />
          </Fragment>
        )}
      </div>
    ) : (
      <CustomizeSnackbar
        payload={{
          severity: "error",
          message: "Permission Denied",
        }}
      />
    )
  ) : (
    <div className={classes.center}>
      <CircularProgress style={{ margin: "auto" }} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  fetched: state.marketing_expenses.fetchedUnpaid,
  processing: state.marketing_expenses.submittingUnpaid,
  data: state.marketing_expenses.dataUnpaid,
  current_user: state.auth.user.username,
});

export default connect(mapStateToProps, { get_unpaid_campaign_request })(
  ReimbursementView
);
