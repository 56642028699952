import React, { useEffect, useState, Fragment } from "react";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { update_operation_expense_request_approval_status } from "../../../../actions/operation_expenses";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import DialogTitle from "../../../../components/MuiDialogTitle";

const ApprovalStatus = ({
  OERN,
  current_user,
  data,
  update_operation_expense_request_approval_status,
}) => {
  const [note, setNote] = useState("");
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState(null);

  useEffect(() => {
    setNote(data.approval_status.note || "");
  }, [setNote, data.approval_status.note]);

  const handleChange = (prop) => (event) => {
    setNote(event.target.value);
  };

  const handleClick = (action) => {
    setOpen(!open);
    action !== null && setAction(action);
  };

  return (
    data.approval_status.status !== "cancelled" &&
    data.approval_status.approvers.includes(current_user) &&
    data.approval_status.status === "pending" && (
      <div style={{ width: "100%" }}>
        <Typography
          style={{ marginBottom: 20, fontWeight: "bold", alignSelf: "start" }}
          variant="h5"
        >
          Proceed with the request
        </Typography>
        <FormControl fullWidth variant="outlined" style={{ marginBottom: 20 }}>
          {action === "approve" || action === null ? (
            <div>
              <InputLabel>Note</InputLabel>
              <OutlinedInput
                label="Note"
                fullWidth
                onChange={handleChange()}
                value={note}
              />
            </div>
          ) : (
            <div>
              <InputLabel required>Note</InputLabel>
              <OutlinedInput
                required
                fullWidth
                label="Note"
                onChange={handleChange()}
                value={note}
              />
            </div>
          )}
        </FormControl>
        <div style={{ marginBottom: 64, textAlign: "center", display: "flex" }}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => handleClick("approve")}
          >
            Approve
          </Button>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            onClick={() => handleClick("reject")}
            style={{ marginRight: 10, marginLeft: 10 }}
          >
            Reject
          </Button>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            onClick={() => handleClick("return")}
          >
            Send back
          </Button>
        </div>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
        >
          <DialogTitle
            style={{ cursor: "move" }}
            id="draggable-dialog-title"
            onClose={() => handleClick(null)}
          >
            {(action === "approve" &&
              "Are you sure that you want to approve this operation expense request? This action cannot be undone.") ||
              (action === "reject" &&
                (note === ""
                  ? "Please provide a reason to the requester."
                  : "Are you sure that you want to reject this operation expense request? This action cannot be undone.")) ||
              (action === "return" &&
                (note === ""
                  ? "Please provide a reason to the requester."
                  : "Are you sure that you want to return this operation expense request? This action cannot be undone."))}
          </DialogTitle>
          <DialogActions>
            {["reject", "return"].includes(action) && note === "" ? (
              <Button
                onClick={() => handleClick(null)}
                type="submit"
                fullWidth
                color="primary"
                variant="contained"
                style={{ borderRadius: 24 }}
              >
                OK
              </Button>
            ) : (
              <Fragment>
                <Button
                  onClick={() => handleClick(null)}
                  type="submit"
                  fullWidth
                  color="primary"
                  variant="outlined"
                  style={{ borderRadius: 24 }}
                >
                  No, go back
                </Button>
                <Button
                  onClick={() => {
                    update_operation_expense_request_approval_status(
                      action,
                      OERN,
                      note
                    );
                    handleClick(null);
                  }}
                  type="submit"
                  fullWidth
                  color="primary"
                  variant="contained"
                  style={{ borderRadius: 24 }}
                >
                  {(action === "approve" && "Yes, approve it") ||
                    (action === "reject" && "Yes, reject it") ||
                    (action === "return" && "Yes, return it")}
                </Button>
              </Fragment>
            )}
          </DialogActions>
        </Dialog>
      </div>
    )
  );
};

export default connect(null, {
  update_operation_expense_request_approval_status,
})(ApprovalStatus);
