import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import {
  create_operation_expense_request_status,
  delete_operation_expense_request_status,
} from "../../../../../actions/operation_expenses";
import {
  lighten,
  makeStyles,
  Table,
  CircularProgress,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  InputLabel,
  FormControl,
  Button,
  Backdrop,
  TableContainer,
  Select,
  MenuItem,
} from "@material-ui/core";

import {
  RemoveCircle as RemoveCircleIcon,
  Add as AddIcon,
  Create as CreateIcon,
  CheckCircle as CheckCircleIcon,
} from "@material-ui/icons";

import DialogTitle from "../../../../../components/MuiDialogTitle";
import EditStatus from "./EditStatus";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "status",
    numeric: false,
    disablePadding: true,
    label: "Status",
  },
  {
    id: "payment_type",
    numeric: true,
    disablePadding: false,
    label: "Payment type",
  },
  {
    id: "payment_source",
    numeric: true,
    disablePadding: false,
    label: "Payment source",
  },
  {
    id: "amount_paid",
    numeric: true,
    disablePadding: false,
    label: "Amount",
  },
];

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },

  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  number: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
      {
        display: "none",
      },
  },
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.primary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
  dividerFullWidth: {
    marginTop: theme.spacing(3),
  },
}));

function EnhancedTable({
  OERN,
  accounting_statuses,
  create_operation_expense_request_status,
  delete_operation_expense_request_status,
  cash_requested_amount,
  credit_requested_amount,
  is_accountant,
}) {
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [selected, setSelected] = useState([]);
  const numSelected = selected.length;
  const numStatuses = accounting_statuses.length;
  const [isLoading, setIsLoading] = useState(false);

  const [createMode, setCreateMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [newStatus, setNewStatus] = useState({
    status: "pending",
    payment_type: "Cash",
    payment_source: "Petty cash",
    amount_paid: "",
  });
  const handleChange = (prop) => (event) => {
    setNewStatus({ ...newStatus, [prop]: event.target.value });
  };

  useEffect(() => {
    setSelected([]);
  }, [setSelected]);

  useEffect(() => {
    createMode &&
      setNewStatus({
        status: "pending",
        payment_type: "Cash",
        payment_source: "Petty cash",
        amount_paid: "",
      });
  }, [createMode]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelected(
        accounting_statuses.filter(
          (accounting_status) =>
            accounting_status.status.toLowerCase() !== "received"
        )
      );
      return;
    }
    setSelected([]);
  };
  const handleClickRow = (event, row) => {
    const selectedIndex = selected.indexOf(row);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === numSelected - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const createSortHandler = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const isSelected = (id) => selected.map((s) => s.id).indexOf(id) !== -1;
  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    create_operation_expense_request_status(OERN, newStatus).then((created) => {
      setIsLoading(false);
      created && setCreateMode(false);
    });
  };

  const total_cash_status = accounting_statuses
    .filter(
      (as) =>
        as.payment_type === "Cash" &&
        ["to collect", "received"].includes(as.status.toLowerCase())
    )
    .reduce(
      (currentTotalPrice, product) => currentTotalPrice + product.amount_paid,
      0
    )
    .toFixed(2);
  const total_credit_status = accounting_statuses
    .filter(
      (as) =>
        as.payment_type === "Credit card" &&
        ["to collect", "received"].includes(as.status.toLowerCase())
    )
    .reduce(
      (currentTotalPrice, product) => currentTotalPrice + product.amount_paid,
      0
    )
    .toFixed(2);

  const remaining_cash = cash_requested_amount
    ? cash_requested_amount - total_cash_status
    : 0;
  const remaining_credit = credit_requested_amount
    ? credit_requested_amount - total_credit_status
    : 0;

  const onDelete = (e) => {
    e.preventDefault();
    setIsLoading(true);
    delete_operation_expense_request_status(OERN, {
      statuses: selected.map((s) => s.id),
    }).then((deleted) => {
      setIsLoading(false);
      if (deleted) {
        setDeleteMode(false);
        setSelected([]);
      }
    });
  };

  return (
    <div style={{ marginBottom: 40 }}>
      <TableContainer component={Paper} style={{ marginBottom: 100 }}>
        {is_accountant && (
          <Toolbar
            className={clsx(classes.root, {
              [classes.highlight]: numSelected > 0,
            })}
          >
            <Typography
              className={classes.title}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {numSelected > 0
                ? `${numSelected} selected payments`
                : (remaining_cash > 0 || remaining_credit > 0) &&
                  "Add a payment"}
            </Typography>

            {numSelected > 0 ? (
              <div>
                {numSelected === 1 ? (
                  <div style={{ display: "flex" }}>
                    {selected[0].status !== "received" && (
                      <Tooltip
                        title="Edit payment"
                        onClick={() => setEditMode(!editMode)}
                      >
                        <IconButton>
                          <CreateIcon />
                        </IconButton>
                      </Tooltip>
                    )}

                    <Tooltip title="Remove selected payment">
                      <IconButton onClick={() => setDeleteMode(!deleteMode)}>
                        <RemoveCircleIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                ) : (
                  <Tooltip
                    title={
                      numSelected !== numStatuses
                        ? "Remove selected payments"
                        : "Remove all payments"
                    }
                  >
                    <IconButton onClick={() => setDeleteMode(!deleteMode)}>
                      <RemoveCircleIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            ) : (
              (remaining_cash > 0 || remaining_credit > 0) && (
                <div style={{ display: "flex" }}>
                  <Tooltip title="Add a payment">
                    <IconButton onClick={() => setCreateMode(!createMode)}>
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              )
            )}
          </Toolbar>
        )}

        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <TableHead>
            <TableRow>
              {is_accountant && (
                <TableCell padding="checkbox">
                  {accounting_statuses.find(
                    (accounting_status) =>
                      accounting_status.status.toLowerCase() !== "received"
                  ) && (
                    <Checkbox
                      color="primary"
                      indeterminate={
                        numSelected > 0 && numSelected < numStatuses
                      }
                      checked={numStatuses > 0 && numSelected === numStatuses}
                      onChange={handleSelectAllClick}
                    />
                  )}
                </TableCell>
              )}
              {headCells.map((headCell) => (
                <TableCell
                  style={{ fontWeight: "bold" }}
                  key={headCell.id}
                  align={headCell.numeric ? "right" : "left"}
                  padding="default"
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={() => createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(accounting_statuses, getComparator(order, orderBy))
              // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) =>
                      row.status.toLowerCase() !== "received" &&
                      is_accountant &&
                      handleClickRow(event, row)
                    }
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    {is_accountant && (
                      <TableCell color="primary" padding="checkbox">
                        {row.status.toLowerCase() === "received" ? (
                          <Checkbox
                            icon={<CheckCircleIcon color="primary" />}
                            disabled
                          />
                        ) : (
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        )}
                      </TableCell>
                    )}
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="default"
                      style={{ textTransform: "capitalize" }}
                    >
                      {row.status}
                    </TableCell>
                    <TableCell align="right">{row.payment_type}</TableCell>
                    <TableCell align="right">{row.payment_source}</TableCell>
                    <TableCell align="right">
                      {row.amount_paid.toFixed(2)} QAR
                    </TableCell>
                  </TableRow>
                );
              })}
            {remaining_cash > 0 && (
              <TableRow hover role="checkbox">
                <TableCell color="primary" padding="checkbox" />
                <TableCell
                  component="th"
                  scope="row"
                  padding="none"
                  style={{ textTransform: "capitalize", fontWeight: "bold" }}
                  colSpan={3}
                >
                  Remaining cash
                </TableCell>
                <TableCell align="right">
                  {remaining_cash.toFixed(2)} QAR
                </TableCell>
              </TableRow>
            )}
            {remaining_credit > 0 && (
              <TableRow hover role="checkbox">
                <TableCell color="primary" padding="checkbox" />
                <TableCell
                  component="th"
                  scope="row"
                  padding="none"
                  style={{ textTransform: "capitalize", fontWeight: "bold" }}
                  colSpan={3}
                >
                  Remaining credit
                </TableCell>
                <TableCell align="right">
                  {remaining_credit.toFixed(2)} QAR
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        <Dialog
          open={createMode}
          onClose={() => setCreateMode(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
        >
          <Backdrop
            style={{
              position: "absolute",
              zIndex: 1000,
              backgroundColor: "black",
              opacity: 0.4,
            }}
            open={isLoading}
          >
            <CircularProgress style={{ margin: "auto" }} />
          </Backdrop>
          <DialogTitle onClose={() => setCreateMode(false)}>
            Prepare a payment
          </DialogTitle>
          <form component="form" onSubmit={(e) => onSubmit(e)}>
            <DialogContent style={{ padding: 24, paddingBottom: 0 }}>
              <FormControl fullWidth variant="outlined" required>
                <InputLabel>Payment type</InputLabel>
                <Select
                  value={newStatus.payment_type}
                  onChange={handleChange("payment_type")}
                  label="Payment type *"
                >
                  <MenuItem value={"Cash"}>Cash</MenuItem>
                  <MenuItem value={"Credit card"}>Credit card</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth variant="outlined" required>
                <InputLabel>Payment source</InputLabel>
                <Select
                  value={newStatus.payment_source}
                  onChange={handleChange("payment_source")}
                  label="Payment source *"
                >
                  <MenuItem value={"Petty cash"}>Petty cash</MenuItem>
                  <MenuItem value={"Sales cash"}>Sales cash</MenuItem>
                </Select>
              </FormControl>
              <TextField
                required
                label="Amount"
                onChange={handleChange("amount_paid")}
                value={newStatus.amount_paid}
                variant="outlined"
                type="number"
                inputProps={{
                  min: 0,
                  max:
                    newStatus.payment_type === "Cash"
                      ? remaining_cash
                      : remaining_credit,
                }}
                fullWidth
              />
            </DialogContent>
            <DialogActions style={{ padding: 24 }}>
              <Button
                onClick={() => setCreateMode(false)}
                color="primary"
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                style={{ paddingLeft: 40, paddingRight: 40 }}
              >
                Save
              </Button>
            </DialogActions>
          </form>
        </Dialog>

        <Dialog
          open={deleteMode}
          onClose={() => setDeleteMode(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
        >
          <Backdrop
            style={{
              position: "absolute",
              zIndex: 1000,
              backgroundColor: "black",
              opacity: 0.4,
            }}
            open={isLoading}
          >
            <CircularProgress style={{ margin: "auto" }} />
          </Backdrop>
          <DialogTitle onClose={() => setDeleteMode(false)}>
            Warning!
          </DialogTitle>
          <DialogContent style={{ padding: 24, paddingBottom: 0 }}>
            <Typography style={{ fontWeight: "bold" }}>
              Are you sure that you want to remove{" "}
              {selected.length > 1 ? "these payments" : "this payment"}?
            </Typography>
            <Typography color="textSecondary" variant="caption">
              The requester and all approvers will be notified
            </Typography>
          </DialogContent>
          <DialogActions style={{ padding: 24 }}>
            <Button
              onClick={() => setDeleteMode(false)}
              color="primary"
              variant="outlined"
            >
              No, go back!
            </Button>
            <Button
              onClick={(e) => onDelete(e)}
              color="primary"
              variant="contained"
              style={{ paddingLeft: 40, paddingRight: 40 }}
            >
              Yes I'm sure!
            </Button>
          </DialogActions>
        </Dialog>

        {selected.length > 0 && (
          <EditStatus
            OERN={OERN}
            accounting_status={selected[0]}
            editMode={editMode}
            setEditMode={setEditMode}
            setSelected={setSelected}
          />
        )}
      </TableContainer>
    </div>
  );
}

const mapStateToProps = (state) => ({
  current_user: state.auth.user,
});

export default connect(mapStateToProps, {
  create_operation_expense_request_status,
  delete_operation_expense_request_status,
})(EnhancedTable);
