import { useEffect } from "react";
import { connect } from "react-redux";
import { custom_snackbar } from "../actions/procedure";
import { Redirect } from "react-router-dom";

const CustomizeSnackbar = ({ custom_snackbar }) => {
  useEffect(() => {
    custom_snackbar({
      severity: "info",
      message: "Permission Denied",
    });
  });

  return <Redirect to="" />;
};

export default connect(null, { custom_snackbar })(CustomizeSnackbar);
