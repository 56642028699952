import React from "react";
import { Collapse, Grid, MenuItem, TextField, Card } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { connect } from "react-redux";
import { weeklyKpisSelectDuration } from "../../../../actions/weeklyKpis";

const isDateValid = (date) => !isNaN(new Date(date).getTime());

const CUSTOM_DATE = "Custom Date";
const LAST_WEEK = "Last week";
const LAST_MONTH = "Last month";
const LAST_SIX_MONTHS = "Last 6 months";
const LAST_YEAR = "Last year";
const ALL_TIME = "All time";
const options = [
  LAST_WEEK,
  LAST_MONTH,
  LAST_SIX_MONTHS,
  LAST_YEAR,
  ALL_TIME,
  CUSTOM_DATE,
];

const now = new Date();

const optionMapper = {
  [LAST_WEEK]: {
    start_date: new Date().setDate(now.getDate() - now.getDay() - 7),
    end_date: new Date(),
  },
  [LAST_MONTH]: {
    start_date: new Date(now.getFullYear(), now.getMonth() - 1, 1),
    end_date: new Date(),
  },
  [LAST_SIX_MONTHS]: {
    start_date: new Date(now.getFullYear(), now.getMonth() - 6, 1),
    end_date: new Date(),
  },
  [LAST_YEAR]: {
    start_date: new Date(now.getFullYear() - 1, 1, 1),
    end_date: new Date(),
  },
  [ALL_TIME]: {
    start_date: new Date("2020-01-01"),
    end_date: new Date(),
  },
};

const WeeklyKpisDurationFilter = ({ duration, weeklyKpisSelectDuration }) => {
  // React.useEffect(() => {
  //   weeklyKpisSelectDuration({
  //     option: LAST_MONTH,
  //     ...optionMapper[LAST_MONTH],
  //   });
  // }, [weeklyKpisSelectDuration]);

  const handleChangeDate = (name, date) => {
    isDateValid(date) &&
      weeklyKpisSelectDuration({ ...duration, [name]: date });
  };
  const handleChangeSelect = (e) => {
    const newValue = e.target.value;
    weeklyKpisSelectDuration({
      ...(newValue === CUSTOM_DATE ? duration : optionMapper[newValue]),
      option: newValue,
    });
  };

  const { start_date, end_date, option } = duration;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <TextField
        component={Card}
        elevation={8}
        variant="standard"
        select
        value={option}
        onChange={handleChangeSelect}
        InputProps={{ disableUnderline: true }}
        style={{
          minWidth: 200,
          textAlign: "start",
          padding: "8px 16px",
          width: "100%",
          minHeight: 55,
          display: "grid",
          alignItems: "center",
        }}
      >
        {options.map((entry, index) => (
          <MenuItem key={index} value={entry}>
            {entry}
          </MenuItem>
        ))}
      </TextField>
      <Collapse in={option === CUSTOM_DATE}>
        <Grid container spacing={2} style={{ marginTop: 24 }}>
          <Grid item xs={12}>
            <KeyboardDatePicker
              inputVariant="standard"
              label="Start date"
              format="dd/MM/yyyy"
              value={start_date}
              onChange={(date) => handleChangeDate("start_date", date)}
              autoOk
              okLabel={null}
              cancelLabel={null}
              maxDate={now}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12}>
            <KeyboardDatePicker
              inputVariant="standard"
              label="End date"
              format="dd/MM/yyyy"
              value={end_date}
              onChange={(date) => handleChangeDate("end_date", date)}
              minDate={start_date ? new Date(start_date) : now}
              maxDate={now}
              autoOk
              okLabel={null}
              cancelLabel={null}
              style={{ width: "100%" }}
            />
          </Grid>
        </Grid>
      </Collapse>
    </MuiPickersUtilsProvider>
  );
};

const mapStateToProps = (state) => ({
  duration: state.weekly_kpis.duration,
});

export default connect(mapStateToProps, { weeklyKpisSelectDuration })(
  WeeklyKpisDurationFilter
);
