import axios from "axios";
import { SNACKBAR_WRITE } from "./procedureType";

import {
  CLEAR_FORMDATA,
  GET_FORM_DATA_SUCCESS,
  UPDATE_FORM_STATUS_SUCCESS,
  PROCESSING,
  UPDATE_PROCUREMENT_PRODUCT,
  PROCESSING_PRODUCT,
  CLEAR_RCRN_DATA,
  GET_RCRN,
  PROCESSING_RCRN,
  UPDATING_RCRN,
  FETCHED_ALL_IR,
} from "./procurementTypes";
import { AUTHENTICATED_FAIL } from "./authTypes";

import global from "../global";
const { API_URL } = global;

export const post_procurement_request =
  (data, totalPrice) => async (dispatch) => {
    dispatch({
      type: PROCESSING,
      payload: true,
    });
    const access = localStorage.getItem("access");
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `JWT ${access}`,
        },
      };

      let formData = new FormData();
      formData.append("delivery_destination", data.delivery_destination);
      formData.append("expected_delivery_cost", data.expected_delivery_cost);
      formData.append(
        "expected_delivery_date",
        new Date(data.expected_delivery_date).toLocaleDateString("en-GB")
      );
      formData.append(
        "expected_receiver_staff_pks",
        JSON.stringify(data.expected_receiver_staff.map((staff) => staff.id))
      );
      formData.append("payment_type", data.payment_type);
      if (data.payment_type === "offline") {
        formData.append(
          "payments",
          JSON.stringify(Object.values(data.payments))
        );
      } else {
        formData.append(
          "payments",
          JSON.stringify({
            payment_date: new Date(
              data.payments.payment_date
            ).toLocaleDateString("en-GB"),
            webapp_account_pk: data.payments.webapp_account.id,
          })
        );
      }
      formData.append("products", JSON.stringify(Object.values(data.products)));
      formData.append("supplier_pk", data.supplier.id);
      formData.append("request_type", data.request_type);
      formData.append("ordering_for", data.ordering_for);
      formData.append("total_amount_requested", totalPrice);

      try {
        const response = await axios.post(
          `${API_URL}/procurement/`,
          formData,
          config
        );
        dispatch({
          type: SNACKBAR_WRITE,
          payload: response.data,
        });
        dispatch({
          type: PROCESSING,
          payload: false,
        });

        return response.data.IRN;
      } catch (error) {
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: "An error has occured! Please try again later.",
            severity: "error",
          },
        });
      }
    } else {
      dispatch({
        type: AUTHENTICATED_FAIL,
      });
    }
  };

export const get_procurement_request = (IRN) => async (dispatch) => {
  dispatch({
    type: CLEAR_FORMDATA,
  });

  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    try {
      const response = await axios.get(
        `${API_URL}/procurement/${IRN}/`,
        config
      );
      dispatch({
        type: GET_FORM_DATA_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "Unable to load request",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const update_procurement_request_form =
  (data, totalPrice, IRN) => async (dispatch) => {
    dispatch({
      type: PROCESSING,
      payload: true,
    });
    const access = localStorage.getItem("access");
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `JWT ${access}`,
        },
      };

      let formData = new FormData();
      formData.append("delivery_destination", data.delivery_destination);
      data.expected_delivery_cost !== null &&
        formData.append("expected_delivery_cost", data.expected_delivery_cost);
      formData.append(
        "expected_delivery_date",
        new Date(data.expected_delivery_date).toLocaleDateString("en-GB")
      );
      formData.append(
        "expected_receiver_staff_pks",
        JSON.stringify(data.expected_receiver_staff.map((staff) => staff.id))
      );
      formData.append("payment_type", data.payment_type);
      if (data.payment_type === "offline") {
        formData.append(
          "payments",
          JSON.stringify(Object.values(data.payments))
        );
      } else {
        formData.append(
          "payments",
          JSON.stringify({
            payment_date: new Date(
              data.payments.payment_date
            ).toLocaleDateString("en-GB"),
            webapp_account_pk: data.payments.webapp_account.id,
          })
        );
      }
      formData.append("products", JSON.stringify(Object.values(data.products)));
      formData.append("supplier_pk", data.supplier.id);
      formData.append("request_type", data.request_type);
      formData.append("ordering_for", data.ordering_for);
      formData.append("total_amount_requested", totalPrice);

      try {
        const response = await axios.put(
          `${API_URL}/procurement/${IRN}/`,
          formData,
          config
        );
        dispatch({
          type: UPDATE_FORM_STATUS_SUCCESS,
          payload: response.data,
        });
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: response.data.message,
            severity: response.data.severity,
          },
        });
      } catch (error) {
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: "An error has occured! Please try again later.",
            severity: "error",
          },
        });
      }
    } else {
      dispatch({
        type: AUTHENTICATED_FAIL,
      });
    }
  };

export const update_procurement_request_status =
  (status, IRN, note, pk) => async (dispatch) => {
    const access = localStorage.getItem("access");
    if (localStorage.getItem("access")) {
      dispatch({
        type: PROCESSING,
        payload: true,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `JWT ${access}`,
        },
      };

      const body = JSON.stringify({
        status: status,
        note: note,
        payment_pk: pk,
      });

      try {
        const response = await axios.post(
          `${API_URL}/procurement/${IRN}/`,
          body,
          config
        );
        dispatch({
          type: UPDATE_FORM_STATUS_SUCCESS,
          payload: response.data,
        });
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: response.data.message,
            severity: response.data.severity,
          },
        });
      } catch (error) {
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: "An error has occured! Please try again later.",
            severity: "error",
          },
        });
      }
    } else {
      dispatch({
        type: AUTHENTICATED_FAIL,
      });
    }
  };

export const submit_procurement_payment_details =
  (form, IRN) => async (dispatch) => {
    dispatch({
      type: PROCESSING,
      payload: true,
    });
    const access = localStorage.getItem("access");
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `JWT ${access}`,
        },
      };

      const body = JSON.stringify({
        status: "to collect",
        payment_pk: form.payment_pk,
        payment_type: form.payment_type,
        associated_payment_id: form.associated_payment_id,
      });

      try {
        const response = await axios.post(
          `${API_URL}/procurement/${IRN}/`,
          body,
          config
        );
        dispatch({
          type: UPDATE_FORM_STATUS_SUCCESS,
          payload: response.data,
        });
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: response.data.message,
            severity: response.data.severity,
          },
        });
      } catch (error) {
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: "An error has occured! Please try again later.",
            severity: "error",
          },
        });
      }
    } else {
      dispatch({
        type: AUTHENTICATED_FAIL,
      });
    }
  };

export const update_procurement_product = (product) => async (dispatch) => {
  dispatch({
    type: PROCESSING_PRODUCT,
    payload: true,
  });
  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    const body = JSON.stringify({
      id: product.id,
      received_quantity: product.received_quantity,
      unfit_quantity: product.unfit_quantity,
      reason_for_unfit: product.reason_for_unfit,
      delivery_date: product.delivery_date,
    });

    try {
      const response = await axios.post(
        `${API_URL}/procurement/order_information/`,
        body,
        config
      );

      dispatch({
        type: UPDATE_PROCUREMENT_PRODUCT,
        payload: response.data,
      });
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: response.data.message,
          severity: response.data.severity,
        },
      });
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "An error has occured! Please try again later.",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const get_unreturned_IRNS = () => async (dispatch) => {
  dispatch({
    type: FETCHED_ALL_IR,
    payload: false,
  });
  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    try {
      const response = await axios.get(
        `${API_URL}/procurement/return_cash_request/`,
        config
      );

      dispatch({
        type: FETCHED_ALL_IR,
        payload: true,
      });

      return response.data;
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "Unable to load unreturned operation expense requests",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
  dispatch({
    type: FETCHED_ALL_IR,
    payload: true,
  });
};

export const post_return_cash_request =
  (inventory_requests) => async (dispatch) => {
    dispatch({
      type: PROCESSING,
      payload: true,
    });
    const access = localStorage.getItem("access");
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `JWT ${access}`,
        },
      };
      let formData = new FormData();
      formData.append("inventory_requests", JSON.stringify(inventory_requests));

      try {
        const response = await axios.post(
          `${API_URL}/procurement/return_cash_request/`,
          formData,
          config
        );
        dispatch({
          type: SNACKBAR_WRITE,
          payload: response.data,
        });
        dispatch({
          type: PROCESSING,
          payload: false,
        });

        return response.data.RCRN;
      } catch (error) {
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: "An error has occured! Please try again later.",
            severity: "error",
          },
        });
      }
    } else {
      dispatch({
        type: AUTHENTICATED_FAIL,
      });
    }
  };

export const get_RCRN = (RCRN) => async (dispatch) => {
  dispatch({
    type: CLEAR_RCRN_DATA,
  });
  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    try {
      const response = await axios.get(
        `${API_URL}/procurement/return_cash_request/${RCRN}/`,
        config
      );

      dispatch({
        type: GET_RCRN,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "Unable to load return cash request",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const IR_cash_returned = (IRN, amount_returned) => async (dispatch) => {
  dispatch({
    type: PROCESSING_RCRN,
    payload: true,
  });
  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };
    let formData = new FormData();
    formData.append("IRN", IRN);
    formData.append("amount_returned", amount_returned);

    try {
      const response = await axios.put(
        `${API_URL}/procurement/return_cash_request/`,
        formData,
        config
      );

      dispatch({
        type: SNACKBAR_WRITE,
        payload: response.data,
      });
      dispatch({
        type: PROCESSING_RCRN,
        payload: false,
      });
      dispatch({
        type: UPDATING_RCRN,
        payload: response.data.inventory_request,
      });
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "An error has occured! Please try again later.",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
  dispatch({
    type: PROCESSING_RCRN,
    payload: false,
  });
};
