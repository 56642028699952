import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import { update_password } from "../../../actions/auth";

import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

function passwordsAreValid(onSubmit, input, setHints) {
  let errors = {
    uppercase: "1 uppercase letter",
    lowercase: "1 lowercase letter",
    special: "1 number",
    length: "a minimum of 8 characters",
    noNewLine: "no new line",
    newpassword: "Your passwords must not match",
  };
  let isValid = true;

  var upperCaseRequirement = new RegExp(/(?=.*[A-Z])/);
  var lowerCaseRequirement = new RegExp(/(?=.*[a-z])/);
  var lengthRequirement = new RegExp(/(?=^.{8,}$)/);
  var noNewLine = new RegExp(/(?![.\n]).*$/);
  var specialRequirement = new RegExp(/(?=.*\d)/);

  if (!upperCaseRequirement.test(input["new_password1"])) {
    isValid = false;
  } else {
    delete errors["uppercase"];
  }

  if (!lowerCaseRequirement.test(input["new_password1"])) {
    isValid = false;
  } else {
    delete errors["lowercase"];
  }

  if (!lengthRequirement.test(input["new_password1"])) {
    isValid = false;
  } else {
    delete errors["length"];
  }

  if (!noNewLine.test(input["new_password1"])) {
    isValid = false;
  } else {
    delete errors["noNewLine"];
  }

  if (!specialRequirement.test(input["new_password1"])) {
    isValid = false;
  } else {
    delete errors["special"];
  }

  if (onSubmit && input["old_password"] === input["new_password1"]) {
    isValid = false;
  } else {
    delete errors["newpassword"];
  }

  setHints(errors);

  return isValid;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "25ch",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
  },
  number: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
      {
        display: "none",
      },
  },
}));

const ChangePassword = ({
  current_user,
  update_password,
  old_password_error_state,
  new_password1_error_state,
  processing,
}) => {
  const classes = useStyles();

  const [old_password_error, setOld_password_error] = useState(null);
  const [new_password1_error, setNew_password1_error] = useState(null);
  useEffect(() => {
    setOld_password_error(old_password_error_state);
    setNew_password1_error(new_password1_error_state);
  }, [old_password_error_state, new_password1_error_state]);
  const [showPasswords, setShowPasswords] = useState({
    old_password: false,
    new_password1: false,
  });
  const [hints, setHints] = useState({});
  const [formData, setFormData] = useState({
    old_password: "",
    new_password1: "",
  });
  const handleChange = (prop) => (event) => {
    setFormData({ ...formData, [prop]: event.target.value });
    passwordsAreValid(
      false,
      { ...formData, [prop]: event.target.value },
      setHints
    );
    prop === "old_password"
      ? setOld_password_error(null)
      : setNew_password1_error(null);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    if (passwordsAreValid(true, formData, setHints)) {
      update_password(
        formData["old_password"],
        formData["new_password1"],
        formData["new_password1"]
      ).then((error) => {
        if (error !== null && !error) {
          setFormData({
            old_password: "",
            new_password1: "",
          });
          setShowPasswords({
            old_password: false,
            new_password1: false,
          });
        }
      });
    }
  };

  return (
    <div>
      {processing ? (
        <div className={classes.center}>
          <CircularProgress style={{ margin: "auto" }} />
        </div>
      ) : (
        <form onSubmit={(e) => onSubmit(e)}>
          <input type="hidden" value={current_user.username} />
          <FormControl
            required
            fullWidth
            variant="outlined"
            error={old_password_error ? true : false}
          >
            <InputLabel>Current Password</InputLabel>
            <OutlinedInput
              autoComplete="off"
              type={showPasswords.old_password ? "text" : "password"}
              label="Current Password *"
              onChange={handleChange("old_password")}
              value={formData.old_password}
              endAdornment={
                <InputAdornment>
                  <IconButton
                    onClick={() => {
                      setShowPasswords({
                        ...showPasswords,
                        old_password: !showPasswords.old_password,
                      });
                    }}
                  >
                    {showPasswords.old_password ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
            {old_password_error && (
              <FormHelperText error id="standard-weight-helper-text">
                {old_password_error}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl
            required
            fullWidth
            variant="outlined"
            error={hints.newpassword || new_password1_error ? true : false}
          >
            <InputLabel>New password</InputLabel>
            <OutlinedInput
              autoComplete="off"
              type={showPasswords.new_password1 ? "text" : "password"}
              label="New password *"
              onChange={handleChange("new_password1")}
              value={formData.new_password1}
              endAdornment={
                <InputAdornment>
                  <IconButton
                    onClick={() => {
                      setShowPasswords({
                        ...showPasswords,
                        new_password1: !showPasswords.new_password1,
                      });
                    }}
                  >
                    {showPasswords.new_password1 ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
            {new_password1_error ? (
              <FormHelperText id="standard-weight-helper-text">
                {new_password1_error}
              </FormHelperText>
            ) : (
              formData.new_password1.length > 0 && (
                <FormHelperText id="standard-weight-helper-text">
                  {Object.values(hints).length > 0 &&
                    (hints.newpassword
                      ? hints.newpassword
                      : `At least ${Object.values(hints).join(", ")}`)}
                </FormHelperText>
              )
            )}
          </FormControl>
          <FormControl fullWidth style={{ marginBottom: 0 }}>
            <Button
              disabled={
                Object.values(hints).length > 0 ||
                formData.new_password1.length === 0 ||
                formData.old_password.length === 0
              }
              variant="contained"
              color="primary"
              type="submit"
            >
              Save
            </Button>
          </FormControl>
        </form>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  current_user: state.auth.user,
  old_password_error_state: state.auth.old_password_error,
  new_password1_error_state: state.auth.new_password1_error,
  processing: state.auth.processingPasswordReset,
});

export default connect(mapStateToProps, { update_password })(ChangePassword);
