import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { checkAuthenticated, verify } from "../../actions/auth";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import LockOpenOutlinedIcon from "@material-ui/icons/LockOpenOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  center: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
  },
}));

const Activate = (props) => {
  useEffect(() => {
    props.checkAuthenticated();
  }, [props]);

  const classes = useStyles();

  const verify_account = (e) => {
    const uid = props.match.params.uid;
    const token = props.match.params.token;

    props.verify(uid, token);
    return <Redirect to="/login" />;
  };

  if (props.isLoading) {
    <div className={classes.center}>
      <CircularProgress style={{ margin: "auto" }} />
    </div>;
  }

  return props.isAuthenticated ? (
    <Redirect to="/app" />
  ) : (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOpenOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Verify your Account
        </Typography>
        <Button
          type="button"
          onClick={verify_account}
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Verify
        </Button>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isLoading: state.auth.isLoading,
});

export default connect(mapStateToProps, { checkAuthenticated, verify })(
  Activate
);
