import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_LOADED_SUCCESS,
  USER_LOADED_FAIL,
  AUTHENTICATED_SUCCESS,
  AUTHENTICATED_FAIL,
  LOGOUT,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAIL,
  PASSWORD_RESET_CONFIRM_SUCCESS,
  PASSWORD_RESET_CONFIRM_FAIL,
  SIGNUP_SUCCESS,
  SIGNUP_FAIL,
  ACTIVATION_SUCCESS,
  ACTIVATION_FAIL,
  EMAIL_NOT_FOUND,
  LOAD_NOTIFICATION_SUCCESS,
  LOAD_NOTIFICATION_FAILURE,
  MARK_NOTIFICAITON_AS_READ,
  MARK_NOTIFICATIONS_AS_READ,
  CLEAR_NOTIFICATIONS,
  SNACKBAR_DELETE,
  CHANGE_PASSWORD_ERROR,
  PROCESSING_PASSWORD_RESET,
  INCOMING_NOTIFICATION,
  PROCESSING_PROFILE_PICTURE_UPDATE,
  CHANGE_PROFILE_PICTURE,
  UPDATE_USER_INFORMATION,
  LOAD_USER_TIME_OFFS_SUCCESS,
  LOAD_USER_TIME_OFFS_FAILURE,
  LOAD_EMERGENCY_CONTACTS,
  CREATE_EMERGENCY_CONTACT,
  UPDATE_EMERGENCY_CONTACT,
  DELETE_EMERGENCY_CONTACT,
} from "../actions/authTypes";

const initialState = {
  access: localStorage.getItem("access"),
  refresh: localStorage.getItem("refresh"),
  isAuthenticated: null,
  user: JSON.parse(localStorage.getItem("user")),
  error: null,
  isLoading: true,
  notificationsLoaded: true,
  pageNumber: 1,
  message: null,
  severity: null,
  old_password_error: null,
  new_password1_error: null,
  processingPasswordReset: false,
  processingProfilePictureUpdate: false,
  time_offs: [],
  emergency_contacts: [],
};

export default function auth(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_EMERGENCY_CONTACTS:
      return {
        ...state,
        emergency_contacts: payload,
      };

    case CREATE_EMERGENCY_CONTACT:
      return {
        ...state,
        emergency_contacts: [...state.emergency_contacts, payload],
      };

    case UPDATE_EMERGENCY_CONTACT:
      return {
        ...state,
        emergency_contacts: state.emergency_contacts.map((contact) =>
          contact.id === payload.id ? payload : contact
        ),
      };

    case DELETE_EMERGENCY_CONTACT:
      return {
        ...state,
        emergency_contacts: state.emergency_contacts.filter(
          (contact) => contact.id !== payload
        ),
      };

    case CHANGE_PROFILE_PICTURE:
      localStorage.setItem(
        "user",
        JSON.stringify({
          ...state.user,
          picture: payload,
        })
      );
      return {
        ...state,
        user: {
          ...state.user,
          picture: payload,
          notifications: state.user.notifications.map((notification) =>
            notification.sender === state.user.full_name
              ? {
                  ...notification,
                  picture: payload,
                }
              : notification
          ),
        },
      };

    case PROCESSING_PROFILE_PICTURE_UPDATE:
      return {
        ...state,
        processingProfilePictureUpdate: payload,
      };

    case INCOMING_NOTIFICATION:
      return {
        ...state,
        user: {
          ...state.user,
          notifications: [
            payload["notification"],
            ...state.user.notifications.filter(
              (notification) =>
                notification.request_number !==
                payload["notification"].request_number
            ),
          ],
        },
      };

    case PROCESSING_PASSWORD_RESET:
      return {
        ...state,
        processingPasswordReset: payload,
      };

    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        old_password_error: payload.old_password_error,
        new_password1_error: payload.new_password1_error,
      };

    case SNACKBAR_DELETE:
      return {
        ...state,
        message: null,
        severity: null,
      };

    case AUTHENTICATED_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
      };
    case AUTHENTICATED_FAIL:
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
      };
    case LOGIN_SUCCESS:
      localStorage.setItem("access", payload.access);
      return {
        ...state,
        isAuthenticated: true,
        access: payload.access,
        refresh: payload.refresh,
        isLoading: true,
      };

    case LOGIN_FAIL:
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      localStorage.removeItem("user");
      return {
        ...state,
        access: null,
        refresh: null,
        isAuthenticated: false,
        user: null,
        error: null,
        isLoading: false,
        pageNumber: 1,
        message: "Incorrect Credentials!",
        severity: "error",
      };

    case SIGNUP_FAIL:
    case LOGOUT:
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      localStorage.removeItem("user");
      return {
        ...state,
        access: null,
        refresh: null,
        isAuthenticated: false,
        user: null,
        error: null,
        isLoading: false,
        pageNumber: 1,
      };

    case UPDATE_USER_INFORMATION:
    case USER_LOADED_SUCCESS:
      localStorage.setItem("user", JSON.stringify(payload));
      return {
        ...state,
        user: payload,
        isLoading: false,
      };
    case USER_LOADED_FAIL:
      return {
        ...state,
        user: null,
        isLoading: false,
      };
    case PASSWORD_RESET_CONFIRM_SUCCESS:
    case PASSWORD_RESET_CONFIRM_FAIL:
      return state;
    case SIGNUP_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
      };
    case ACTIVATION_SUCCESS:
    case ACTIVATION_FAIL:
      return state;
    case PASSWORD_RESET_SUCCESS:
      localStorage.removeItem("error");
      return {
        ...state,
        error: null,
        isLoading: false,
      };
    case PASSWORD_RESET_FAIL:
      localStorage.setItem("error", EMAIL_NOT_FOUND);
      return {
        ...state,
        error: EMAIL_NOT_FOUND,
        isLoading: false,
      };

    case LOAD_NOTIFICATION_SUCCESS:
      return {
        ...state,
        pageNumber: payload["page_number"],
        user: {
          ...state.user,
          notifications: state.user.notifications.concat(
            payload["notifications"]
          ),
        },
        notificationsLoaded: true,
      };
    case LOAD_NOTIFICATION_FAILURE:
      return {
        ...state,
        pageNumber: 1,
        user: {
          ...state.user,
          notifications: [],
        },
      };
    case MARK_NOTIFICAITON_AS_READ:
      return {
        ...state,
        user: {
          ...state.user,
          notifications: state.user.notifications.map((notification) =>
            notification.id !== payload
              ? notification
              : {
                  ...notification,
                  read: true,
                }
          ),
        },
      };

    case MARK_NOTIFICATIONS_AS_READ:
      return {
        ...state,
        user: {
          ...state.user,
          notifications: state.user.notifications.map((notification) => ({
            ...notification,
            read: true,
          })),
        },
      };

    case CLEAR_NOTIFICATIONS:
      return {
        ...state,
        pageNumber: 1,
        user: {
          ...state.user,
          notifications: [],
        },
        notificationsLoaded: false,
      };
    case LOAD_USER_TIME_OFFS_SUCCESS:
      return {
        ...state,
        time_offs: payload,
      };
    case LOAD_USER_TIME_OFFS_FAILURE:
      return {
        ...state,
        time_offs: [],
      };
    default:
      return state;
  }
}
