import React, { useEffect, useState, Fragment } from "react";
import {
  Typography,
  FormControl,
  Select,
  MenuItem,
  StepLabel,
  Step,
  Stepper,
} from "@material-ui/core";
import CancelRequest from "./CancelRequest";

function ProcurementStepper({
  procurementFormData,
  activeStepper,
  setActiveStepper,
  current_user,
}) {
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    if (
      procurementFormData.inventory_request_payments[activeStepper]
        .accounting_status.status === "received"
    )
      setActiveStep(4);
    else if (
      procurementFormData.inventory_request_payments[activeStepper]
        .accounting_status.status === "to collect"
    )
      setActiveStep(3);
    else if (
      ["approved", "rejected", "cancelled"].includes(
        procurementFormData.approval_status.status
      )
    )
      setActiveStep(2);
    else if (procurementFormData.approval_status.status === "pending")
      setActiveStep(1);
    else if (procurementFormData.approval_status.status === "returned")
      setActiveStep(0);
  }, [procurementFormData, setActiveStep, activeStepper]);

  function getSteps() {
    return procurementFormData.inventory_request_payments.map(
      (payment) =>
        (procurementFormData.approval_status.status === "cancelled" && [
          ["Requested", procurementFormData.created_at],
          [
            "Cancelled",
            procurementFormData.approval_status.date_approved_or_cancelled,
          ],
        ]) ||
        (procurementFormData.approval_status.status === "rejected" && [
          ["Requested", procurementFormData.created_at],
          [
            "Rejected",
            procurementFormData.approval_status.date_approved_or_cancelled,
          ],
        ]) ||
        (procurementFormData.approval_status.status === "returned" && [
          ["Returned", procurementFormData.created_at],
          ["Approved", "-"],
          ["Prepared", "-"],
          ["Paid", "-"],
        ]) || [
          ["Requested", procurementFormData.created_at],
          [
            procurementFormData.approval_status.date_approved_or_cancelled
              ? "Approved"
              : "Approve",
            procurementFormData.approval_status.date_approved_or_cancelled ||
              "-",
          ],
          [
            payment.accounting_status.date_prepared ? "Prepared" : "Prepare",
            payment.accounting_status.date_prepared || "-",
          ],
          [
            payment.accounting_status.date_received ? "Paid" : "Pay",
            payment.accounting_status.date_received || "-",
          ],
        ]
    );
  }

  const handleChangeIndex = (index) => {
    setActiveStepper(index);
    if (
      procurementFormData.inventory_request_payments[index].accounting_status
        .status === "received"
    )
      setActiveStep(4);
    else if (
      procurementFormData.inventory_request_payments[index].accounting_status
        .status === "to collect"
    )
      setActiveStep(3);
    else if (
      ["approved", "rejected", "cancelled"].includes(
        procurementFormData.approval_status.status
      )
    )
      setActiveStep(2);
    else if (procurementFormData.approval_status.status === "pending")
      setActiveStep(1);
    else if (procurementFormData.approval_status.status === "returned")
      setActiveStep(0);
  };

  return (
    <div
      style={{
        width: "100%",
        marginBottom: 40,
      }}
    >
      <div style={{ marginBottom: 40, display: "flex" }}>
        <Typography variant="h3">{`${procurementFormData.IRN}`}</Typography>
        <CancelRequest
          IRN={procurementFormData.IRN}
          current_user={current_user}
          data={procurementFormData}
        />
        <div style={{ flexGrow: 1 }} />
        {procurementFormData.inventory_request_payments.length > 1 &&
          procurementFormData.approval_status.status === "approved" && (
            <FormControl variant="outlined">
              <Select
                value={new Date(
                  procurementFormData.inventory_request_payments[
                    activeStepper
                  ].payment_date
                ).toLocaleDateString("en-GB")}
              >
                {procurementFormData.inventory_request_payments.map(
                  (payment, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => handleChangeIndex(index)}
                      style={{
                        color:
                          payment.accounting_status.date_received && "#ed7273",
                        backgroundColor: index === activeStepper && "#F2F2F2",
                      }}
                      value={new Date(payment.payment_date).toLocaleDateString(
                        "en-GB"
                      )}
                    >
                      {new Date(payment.payment_date).toLocaleDateString(
                        "en-GB"
                      )}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          )}
      </div>
      {getSteps().map((steps, index) => {
        return (
          index === activeStepper && (
            <Stepper
              key={index}
              activeStep={activeStep}
              alternativeLabel
              style={{ padding: 0, width: "100%" }}
            >
              {steps.map((label) => (
                <Step key={label[0]}>
                  <StepLabel>
                    <Fragment>
                      {label[0]}
                      <Typography style={{ color: "#949494", fontSize: 10 }}>
                        {label[1]}
                      </Typography>
                    </Fragment>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          )
        );
      })}

      {/* {procurementFormData.inventory_request_payments.length > 1 &&
        procurementFormData.approval_status.status === "approved" && (
          <Fragment>
            <Fab
              component="span"
              style={{
                color: "#ed7273",
                backgroundColor: "transparent",
              }}
              onClick={handleClick}
            >
              <ArrowDropDownIcon />
            </Fab>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {procurementFormData.inventory_request_payments.map(
                (payment, index) => {
                  return (
                    <MenuItem
                      key={index}
                      onClick={() => handleChangeIndex(index)}
                      style={{
                        color:
                          payment.accounting_status.date_received && "#ed7273",
                        backgroundColor: index === activeStepper && "#F2F2F2",
                      }}
                    >
                      {new Date(payment.payment_date).toLocaleDateString(
                        "en-GB"
                      )}
                    </MenuItem>
                  );
                }
              )}
            </Menu>
          </Fragment>
        )} */}
    </div>
  );
}

export default ProcurementStepper;
