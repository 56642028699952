import {createMuiTheme, colors} from "@material-ui/core";

const theme = createMuiTheme({
  overrides: {
    MuiTypography: {
      root: {
        "&.button": {
          cursor: "pointer",
          width: "max-content",
          maxWidth: "100%",
          transition: "all 0.3s ease",
          userSelect: "none",
          "&:hover": {
            opacity: 0.5,
          },
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "16px",
      },
    },
    // MuiBackdrop: {
    //   root: {
    //     backgroundColor: "transparent",
    //   },
    // },
    MuiStepIcon: {
      root: {
        color: "#f2f2f2",
        "&$active": {
          color: "white",
          "& $text": {
            fill: "#333333",
          },
        },
        "&$completed": {
          color: "#77E0FF",
          "& $text": {
            fill: "white",
          },
        },
      },
      text: {
        fill: "#949494",
      },
      active: {
        borderRadius: "50%",
        border: "1px solid black",
      },
      completed: {},
    },
    MuiStepLabel: {
      label: {
        color: "#949494",
        "&$active": {
          color: "#333333",
        },
        "&$completed": {
          color: "#77E0FF",
          fontWeight: "bold",
        },
        "&$alternativeLabel": {
          marginTop: "4px",
        },
      },
    },
    MuiStepConnector: {
      root: {
        "&$alternativeLabel": {
          left: "calc(-50% + 16px)",
          right: "calc(50% + 16px)",
          top: "11px",
        },
      },
      active: {
        "& $line": {
          borderColor: "#77E0FF",
        },
      },
      completed: {
        "& $line": {
          borderColor: "#77E0FF",
        },
      },
      line: {
        borderColor: "#F2F2F2",
        borderRadius: "5px",
        border: "2px solid",
      },
      lineVertical: {
        borderColor: "transparent",
        borderRadius: "5px",
        border: "0px solid",
        minHeight: "1px",
      },
    },
    MuiButton: {
      root: {
        fontSize: 20,
        fontWeight: "bold",
        whiteSpace: "nowrap",
      },
      containedPrimary: {
        color: "white",
        backgroundColor: "#77E0FF",
        "&:hover": {
          color: "#77E0FF",
          backgroundColor: "rgba(119, 224, 255, 0.4)",
        },
      },
      containedSecondary: {
        color: "white",
        backgroundColor: "#ED7273",
        "&:hover": {
          color: "#ED7273",
          backgroundColor: "rgba(237, 114, 115, 0.4)",
        },
      },
    },
    MuiStep: {
      root: {
        "&:last-child": {
          paddingRight: 0,
        },
        "&:first-child": {
          paddingLeft: 0,
        },
      },
    },
    MuiInputBase: {
      root: {
        fontSize: 16,
      },
    },
    MuiTableRow: {
      root: {
        "&$selected": {
          backgroundColor: "#FCEEEE",
        },
      },
    },
    MuiFab: {
      root: {
        boxShadow: "0",
        // color: "white",
        // backgroundColor: "#ED7273",
      },
    },
    MuiPickersToolbarText: {
      toolbarBtnSelected: {
        color: "white",
      },
      toolbarTxt: {
        color: "white",
      },
    },
    MuiPickersDay: {
      daySelected: {
        color: "white",
      },
      day: {
        margin: 0,
      },
    },
    MuiAlert: {
      message: {
        fontSize: 22,
      },
      icon: {
        alignItems: "center",
        marginRight: "auto",
        paddingRight: "12px",
      },
    },
    MuiAvatar: {
      colorDefault: {
        backgroundColor: "#77E0FF",
      },
      root: {
        fontSize: 14,
        fontWeight: "bold",
      },
      square: {
        borderRadius: 6,
      },
    },
    MuiListItem: {
      root: {
        fontFamily: "'Nunito', sans-serif",
      },
      button: {
        "&:hover": {
          backgroundColor: "rgba(119, 224, 255, 0.1)",
        },
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: 10,
      },
    },
    MuiDialogContent: {
      root: {
        paddingTop: 12,
        paddingRight: 24,
        paddingLeft: 24,
        paddingBottom: 0,
      },
    },
    MuiDialogActions: {
      root: {
        padding: 24,
      },
    },
    MuiFormControl: {
      fullWidth: {
        marginBottom: 24,
        // "&:not(:last-child)": {
        // },
      },
    },
    MuiCardActionArea: {
      root: {
        borderRadius: 10,
      },
    },
    MuiCard: {
      root: {
        borderRadius: 10,
        // transition: "all 0.3s ease",
      },
    },
    MuiAccordionDetails: {
      root: {
        marginBottom: 48,
      },
    },
    MuiTab: {
      root: {
        minWidth: 0,
        padding: 0,
        fontSize: 18,
        "@media (min-width: 0px)": {
          minWidth: 0,
        },
        "&:not(:last-child)": {
          marginRight: 48,
        },
      },
      wrapper: {
        flexDirection: "row",
      },
      textColorInherit: {
        "&$selected": {
          color: "#77E0FF",
        },
      },
    },
  },
  typography: {
    fontFamily: "'Nunito', sans-serif",
    h1: {
      fontWeight: 600,
      fontSize: 64,
      letterSpacing: "-0.24px",
    },
    h2: {
      fontWeight: 600,
      fontSize: 48,
      letterSpacing: "-0.24px",
    },
    h3: {
      fontWeight: 600,
      fontSize: 32,
      letterSpacing: "-0.06px",
    },
    h4: {
      fontWeight: 600,
      fontSize: 24,
      letterSpacing: "-0.06px",
    },
    h5: {
      fontWeight: 600,
      fontSize: 20,
      letterSpacing: "-0.05px",
    },
    h6: {
      fontWeight: 600,
      fontSize: 18,
      letterSpacing: "-0.05px",
    },
    subtitle1: {
      fontWeight: 600,
      fontSize: 16,
      letterSpacing: "-0.05px",
    },
    subtitle2: {
      fontWeight: 600,
      fontSize: 14,
      letterSpacing: "-0.05px",
    },
    body1: {
      fontWeight: 500,
      fontSize: 16,
      letterSpacing: "-0.05px",
    },
    body2: {
      fontWeight: 500,
      fontSize: 14,
      letterSpacing: "-0.05px",
    },
    caption: {
      fontWeight: 500,
      fontSize: 12,
      letterSpacing: "-0.05px",
    },
    overline: {
      fontWeight: 700,
      fontSize: 12,
      letterSpacing: "1.2px",
    },
    button: {
      fontWeight: 700,
      fontSize: 16,
      letterSpacing: "-0.05px",
      textTransform: "none",
    },
  },
  palette: {
    background: {
      dark: "#F4F6F8",
      default: colors.common.white,
      paper: colors.common.white,
    },
    primary: {
      main: "#77E0FF",
    },
    secondary: {
      main: "#ED7273",
    },
    tertiary: {
      main: "#FBE773",
    },
    text: {
      primary: "#333333",
      secondary: "#949494",
    },
  },
  shadows: [
    "none",
    "rgb(145 158 171 / 24%) 0px 1px 2px 0px",
    "rgb(145 158 171 / 24%) 0px 2px 2px 0px",
    "rgb(145 158 171 / 24%) 0px 3px 4px 0px",
    "rgb(145 158 171 / 24%) 0px 4px 5px 0px",
    "rgb(145 158 171 / 24%) 0px 5px 6px 0px",
    "rgb(145 158 171 / 24%) 0px 6px 7px 0px",
    "rgb(145 158 171 / 24%) 0px 7px 8px 0px",
    "rgb(145 158 171 / 24%) 0px 8px 16px 0px",
    "rgb(145 158 171 / 24%) 0px 8px 16px 0px",
    "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 12px 24px 0px",
    "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 12px 24px 0px",
    "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 12px 24px 0px",
    "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 12px 24px 0px",
    "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
    "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
    "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
    "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
    "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 20px 40px -4px",
    "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 20px 40px -4px",
    "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 20px 40px -4px",
    "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 20px 40px -4px",
    "rgb(145 158 171 / 24%) 0px 0px 4px 0px, rgb(145 158 171 / 24%) 0px 24px 48px 0px",
    "rgb(145 158 171 / 24%) 0px 0px 4px 0px, rgb(145 158 171 / 24%) 0px 24px 48px 0px",
    "rgb(145 158 171 / 24%) 0px 0px 4px 0px, rgb(145 158 171 / 24%) 0px 24px 48px 0px",
  ],
  h1: {
    fontWeight: 600,
    fontSize: 64,
    letterSpacing: "-0.24px",
  },
  h2: {
    fontWeight: 600,
    fontSize: 48,
    letterSpacing: "-0.24px",
  },
  h3: {
    fontWeight: 600,
    fontSize: 32,
    letterSpacing: "-0.06px",
  },
  h4: {
    fontWeight: 600,
    fontSize: 24,
    letterSpacing: "-0.06px",
  },
  h5: {
    fontWeight: 600,
    fontSize: 20,
    letterSpacing: "-0.05px",
  },
  h6: {
    fontWeight: 600,
    fontSize: 18,
    letterSpacing: "-0.05px",
  },
  subtitle1: {
    fontWeight: 600,
    fontSize: 16,
    letterSpacing: "-0.05px",
  },
  subtitle2: {
    fontWeight: 600,
    fontSize: 14,
    letterSpacing: "-0.05px",
  },
  body1: {
    fontWeight: 500,
    fontSize: 16,
    letterSpacing: "-0.05px",
  },
  body2: {
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: "-0.05px",
  },
  caption: {
    fontWeight: 500,
    fontSize: 12,
    letterSpacing: "-0.05px",
  },
  overline: {
    fontWeight: 700,
    fontSize: 12,
    letterSpacing: "1.2px",
  },
  button: {
    fontWeight: 700,
    fontSize: 14,
    letterSpacing: "-0.05px",
  },
});

export default theme;
