import React from "react";
import { ListItem, ListItemText } from "@material-ui/core";
import ArrowRightIcon from "../../../Icons/ArrowRightIcon";
import MilestoneStatusIcon from "./MilestoneStatusIcon";
import MilestoneDialog from "./MilestoneDialog";

export default function Milestone({ milestone }) {
  const { title, is_completed, date } = milestone;
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <React.Fragment>
      <ListItem
        style={{ display: "flex", alignItems: "center" }}
        button
        onClick={() => setIsOpen(true)}
      >
        <MilestoneStatusIcon
          is_completed={is_completed}
          style={{ marginRight: 12 }}
        />
        <ListItemText
          primary={title}
          secondary={new Date(date).toLocaleDateString("en-gb", {
            day: "2-digit",
            month: "long",
            year: "numeric",
          })}
          primaryTypographyProps={{
            color: is_completed ? "textPrimary" : "textSecondary",
          }}
          secondaryTypographyProps={{
            variant: "caption",
          }}
        />

        <ArrowRightIcon
          dimension={16}
          style={{ marginRight: 8 }}
          primary_fill={"#C2C2C2"}
        />
      </ListItem>

      <MilestoneDialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        milestone={milestone}
      />
    </React.Fragment>
  );
}
