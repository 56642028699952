import React, {
  useEffect,
  useState,
  Fragment,
  //  useRef
} from "react";

import clsx from "clsx";
import {makeStyles, TextField} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {connect} from "react-redux";
import {
  get_all_user_accounts,
  get_suppliers,
  get_webapps,
  save_procurement,
  discard_procurement,
  get_supplier_products,
  get_all_groups,
} from "../../../actions/procedure";

import {
  post_procurement_request,
  update_procurement_request_form,
} from "../../../actions/procurement";

import ProcurementProducts from "./ProcurementProducts";
import ProcurementDetails from "./ProcurementDetails";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import OnlinePayments from "./OnlinePayments";
import OfflinePayments from "./OfflinePayments";
import {Redirect} from "react-router";

import Poppup from "../../../components/Poppup";

const procurement_types = [
  "Inventory stocking",
  "Office supplies",
  "Marketing supplies",
  "Operating expenses",
  "IT expenses",
];

const useStyles = makeStyles((theme) => ({
  paper: {
    marginBottom: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
  },
  subheader: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 20,
    marginLeft: 0,
    marginTop: 60,
  },
  card: {
    minWidth: 200,
    position: "fixed",
    top: "50%",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
  },
}));

const ProcurementRequest = ({
                              get_suppliers,
                              get_webapps,
                              get_all_user_accounts,
                              post_procurement_request,
                              update_procurement_request_form,
                              get_all_groups,
                              data,
                              IRN,
                              processing,
                            }) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    request_type: null,
    ordering_for: "",
    delivery_destination: null,
    expected_delivery_cost: "",
    expected_delivery_date: null,
    expected_receiver_staff: [],
    supplier: null,
    products: {},
    payment_type: "",
    payments: {},
  });
  const [next, setNext] = useState({
    type: false,
    products: false,
    details: false,
    payments: false,
  });
  const [totalProductPrice, setTotalProductPrice] = useState(0);
  const [totalPaymentPrice, setTotalPaymentPrice] = useState(0);

  const [redirectIRN, setRedirectIRN] = useState(false);

  useEffect(() => {
    get_suppliers();
    get_webapps();
    get_all_user_accounts();
    get_all_groups();
    data && setFormData(data);
    data &&
    setNext({
      type: true,
      products: true,
      details: true,
      payments: true,
    });
    data && get_supplier_products(data.supplier.id);
  }, [data, get_suppliers, get_webapps, get_all_user_accounts, get_all_groups]);

  const handleFormDataChange = (prop) => (event) => {
    setFormData({...formData, [prop]: event.target.value});
    formData.request_type !== null && setNext({...next, type: true});
  };
  const handleProcurementTypeChange = (request_type) => {
    setFormData({...formData, request_type: request_type || null});
    if (formData.ordering_for !== "" && request_type !== "") {
      setNext({...next, type: true});
    } else {
      setNext({...next, type: false});
    }
  };
  const handlePaymentTypeChange = (prop) => (event) => {
    setFormData({
      ...formData,
      payment_type: event.target.value,
      payments: {},
    });
    setNext({...next, payments: false});
  };
  const onSubmit = (e) => {
    e.preventDefault();
    if (IRN) {
      update_procurement_request_form(formData, totalPrice, IRN);
    } else {
      post_procurement_request(formData, totalPrice).then((redirectIRN) =>
        setRedirectIRN(redirectIRN)
      );
    }
  };

  const totalPrice = totalProductPrice + formData.expected_delivery_cost;
  const remainingAmount = totalPrice - totalPaymentPrice;

  return redirectIRN ? (
    <Redirect to={`/app/forms/procurement/form/${redirectIRN}/`}/>
  ) : (
    <div
      className={clsx({
        [classes.paper]: !data,
      })}
    >
      {!IRN && (
        <Typography style={{marginBottom: 40}} variant="h3">
          Procurement
          <Poppup
            content={
              <Typography variant="body1">
                {(formData.request_type === "Inventory stocking" &&
                    "The purpose of this request form is to submit stocking requests. Please limit this request to one supplier, regardless of the number of products you are requesting to procure from them.") ||
                  (formData.request_type === "Office supplies" &&
                    "The purpose of this request form is to handle requests for procuring office supplies. Please limit this request to one supplier, regardless of the number of items you are requesting to procure from them. In order for this form to be processed, a verified formal quotation from a supplier should be attached to it.") ||
                  (formData.request_type === "Marketing supplies" &&
                    "The purpose of this request form is to handle procurement requests for marketing supplies. Please limit this request to one supplier, regardless of the number of products you are requesting to procure from them. In order for this form to be processed, a verified formal quotation from a supplier should be attached to it.") ||
                  (formData.request_type === "Operating expenses" &&
                    "The purpose of this request form is to submit requests for procuring operating expenses such as bags. Please limit this request to one supplier, regardless of the number of items you are requesting to procure from them. In order for this form to be processed, a verified formal quotation from a supplier should be attached to it.") ||
                  "The purpose of this module is to manage all procurement requests."}
              </Typography>
            }
          />
        </Typography>
      )}
      <div>
        <div>
          <Autocomplete
            required
            options={procurement_types}
            getOptionLabel={(option) => option}
            onInputChange={(event, newValue) => {
              handleProcurementTypeChange(newValue);
            }}
            getOptionSelected={(option, value) =>
              option === value ? option : value
            }
            value={formData.request_type}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                InputLabelProps={{shrink: true}}
                label="Procurement type"
                variant="outlined"
              />
            )}
          />
          <FormControl fullWidth variant="outlined" required>
            <InputLabel>Ordering for</InputLabel>
            <Select
              value={formData.ordering_for}
              onChange={handleFormDataChange("ordering_for")}
              label="Ordering for *"
            >
              <MenuItem value={"Rimads"}>Avey</MenuItem>
              <MenuItem value={"Reaf Life"}>Reaf Life</MenuItem>
            </Select>
          </FormControl>
        </div>
        {next.type && (
          <div style={{width: "100%"}}>
            <Typography className={classes.subheader}>Products</Typography>
            <ProcurementProducts
              IRN={IRN}
              formData={formData}
              setFormData={setFormData}
              supplier_products={Object.values(formData.products)}
              setTotalProductPrice={setTotalProductPrice}
              setNext={(products) => setNext({...next, products: products})}
            />
            {next.products && (
              <Fragment>
                <Typography className={classes.subheader}>Details</Typography>
                <ProcurementDetails
                  minDate={new Date()}
                  formData={formData}
                  setFormData={setFormData}
                  setNext={(details) => setNext({...next, details: details})}
                />
                {next.details && (
                  <Fragment>
                    <Typography className={classes.subheader}>
                      Payments
                    </Typography>
                    <FormControl fullWidth variant="outlined" required>
                      <InputLabel>Payment type</InputLabel>
                      <Select
                        value={formData.payment_type}
                        onChange={handlePaymentTypeChange("payment_type")}
                        label="Payment type *"
                      >
                        {formData.supplier.store_location ===
                          "physical store" && (
                            <MenuItem value={"offline"}>Offline</MenuItem>
                          )}
                        {formData.supplier.store_location ===
                          "online store" && (
                            <MenuItem value={"online"}>Online</MenuItem>
                          )}
                        {formData.supplier.store_location === "both" && (
                          <MenuItem value={"online"}>Online</MenuItem>
                        )}
                        {formData.supplier.store_location === "both" && (
                          <MenuItem value={"offline"}>Offline</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    {formData.payment_type === "online" && (
                      <OnlinePayments
                        minDate={new Date()}
                        formData={formData}
                        setFormData={setFormData}
                        setNext={(payments) =>
                          setNext({...next, payments: payments})
                        }
                      />
                    )}
                    {formData.payment_type === "offline" && (
                      <OfflinePayments
                        minDate={new Date()}
                        formData={formData}
                        setFormData={setFormData}
                        setNext={(payments) =>
                          setNext({...next, payments: payments})
                        }
                        setTotalPaymentPrice={setTotalPaymentPrice}
                        remainingAmount={remainingAmount}
                        AllowedToEdit={true}
                      />
                    )}
                    {(formData.payment_type === "online" && next.payments && (
                        <FormControl fullWidth>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={(e) => onSubmit(e)}
                          >
                            Submit
                          </Button>
                        </FormControl>
                      )) ||
                      (formData.payment_type === "offline" &&
                        parseFloat(totalPrice) ===
                        parseFloat(totalPaymentPrice) &&
                        parseFloat(totalPrice) > 0 && (
                          <FormControl fullWidth>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={(e) => onSubmit(e)}
                            >
                              Submit
                            </Button>
                          </FormControl>
                        )) || (
                        <FormControl fullWidth>
                          <Button variant="contained" color="primary" disabled>
                            Submit
                          </Button>
                        </FormControl>
                      )}
                  </Fragment>
                )}
              </Fragment>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  processing: state.procedures.processing,
});

export default connect(mapStateToProps, {
  get_suppliers,
  get_webapps,
  save_procurement,
  discard_procurement,
  get_supplier_products,
  get_all_user_accounts,
  post_procurement_request,
  update_procurement_request_form,
  get_all_groups,
})(ProcurementRequest);
