import React from "react";
import {
  Button,
  Checkbox,
  Collapse,
  Divider,
  Grid,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@material-ui/core";
import SliderWithInputField from "./SliderWithInputField";
import CircularProgressWithLabel from "../../../containers/StaticPages/MyAccount/LabeledCircularProgress";

export default function WeeklyKpisEvaluationFormData({
  perspective, // from, to
  isEditMode,
  formData,
  handleChange,
  handleChangeIsApplicable,
  num_workdays = 7,
}) {
  const {
    utilization_percent,
    utilization_percent_initial,
    performance_level_percent,
    performance_level_percent_initial,
    num_days_physical_attendance,
    num_days_physical_attendance_initial,
    note,
    note_initial,
    time_is_not_applicable,
    tasks_initial,
    tasks,
  } = formData;

  const isRequired = !Boolean(time_is_not_applicable);
  const isCollapseOpen = isEditMode && isRequired;

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Collapse in={isEditMode}>
          <ListItem disableGutters style={{ padding: 0 }}>
            <ListItemText
              primary="Is applicable"
              primaryTypographyProps={{ variant: "body2" }}
            />
            <Checkbox
              color="primary"
              checked={!Boolean(time_is_not_applicable)}
              onClick={handleChangeIsApplicable}
            />
          </ListItem>
        </Collapse>
      </Grid>
      <Grid item xs={12}>
        <CircularProgressWithLabel
          value={utilization_percent_initial}
          denominator={100}
          title="Utilization"
          size="sm"
          units="%"
          color={isCollapseOpen ? "textSecondary" : "textPrimary"}
        />
        <Collapse in={isCollapseOpen}>
          <SliderWithInputField
            name="utilization_percent"
            required={isRequired}
            value={utilization_percent}
            setValue={handleChange}
          />
        </Collapse>
      </Grid>
      <Grid item xs={12}>
        <CircularProgressWithLabel
          value={performance_level_percent_initial}
          denominator={100}
          title="Performance level"
          size="sm"
          units="%"
          color={isCollapseOpen ? "textSecondary" : "textPrimary"}
        />
        <Collapse in={isCollapseOpen}>
          <SliderWithInputField
            name="performance_level_percent"
            required={isRequired}
            value={performance_level_percent}
            setValue={handleChange}
          />
        </Collapse>
      </Grid>
      <Grid item xs={12}>
        <CircularProgressWithLabel
          value={num_days_physical_attendance_initial}
          units={`/ ${num_workdays}`}
          denominator={5}
          title="Physical attendance"
          size="sm"
          color={isCollapseOpen ? "textSecondary" : "textPrimary"}
        />
        <Collapse in={isCollapseOpen}>
          <SliderWithInputField
            name="num_days_physical_attendance"
            required={isRequired}
            value={num_days_physical_attendance}
            setValue={handleChange}
            inputProps={{ step: 1, min: 0, max: num_workdays }}
            endAdornment={`/ ${num_workdays}`}
          />
        </Collapse>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      {perspective === "to" && (
        <Grid item xs={12}>
          <React.Fragment>
            <Typography variant="caption" color="textSecondary">
              {"Completed tasks"}
            </Typography>
            <Typography
              variant="body2"
              style={{
                overflowWrap: "break-word",
                marginBottom: isEditMode ? 24 : 0,
                transition: "all 0.3s ease",
                whiteSpace: "break-spaces",
              }}
              gutterBottom
            >
              {tasks_initial}
            </Typography>
          </React.Fragment>
          <Collapse in={isEditMode}>
            <TextField
              label="Completed tasks"
              name="tasks"
              value={tasks}
              onChange={handleChange}
              multiline
              minRows={3}
              fullWidth
              variant="outlined"
            />
          </Collapse>
        </Grid>
      )}
      <Grid item xs={12}>
        <React.Fragment>
          <Typography variant="caption" color="textSecondary">
            {"Notes"}
          </Typography>
          <Typography
            variant="body2"
            style={{
              overflowWrap: "break-word",
              marginBottom: isEditMode ? 24 : 0,
              transition: "all 0.3s ease",
            }}
            gutterBottom
          >
            {note_initial}
          </Typography>
        </React.Fragment>
        <Collapse in={isEditMode}>
          <TextField
            label="Notes"
            name="note"
            value={note}
            onChange={handleChange}
            multiline
            minRows={3}
            fullWidth
            variant="outlined"
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ minWidth: 100 }}
            >
              {"Save"}
            </Button>
          </div>
        </Collapse>
      </Grid>
    </React.Fragment>
  );
}
