import React, { Component } from "react";
import ReconnectingWebSocket from "reconnecting-websocket";
import { checkAuthenticated, incoming_notification } from "../actions/auth";
import { connect } from "react-redux";
import Navbar from "./Navbar";
import { Redirect } from "react-router";
import CircularProgress from "@material-ui/core/CircularProgress";

import global from "../global";
const { WS_URL } = global;

class HomeNotificationWrapper extends Component {
  componentDidMount() {
    this.props.checkAuthenticated().then(() => {
      if (this.props.isAuthenticated) {
        var client = new ReconnectingWebSocket(
          `${WS_URL}` + this.props.current_user.id + "/"
        );
        client.onmessage = (message) => {
          const dataFromServer = JSON.parse(message.data);
          if (dataFromServer) {
            this.props.incoming_notification(
              dataFromServer,
              this.props.location.pathname
            );
          }
        };
      }
    });
  }

  render() {
    return this.props.isLoading ? (
      <div
        style={{ display: "flex", justifyContent: "center", height: "100%" }}
      >
        <CircularProgress style={{ margin: "auto" }} />
      </div>
    ) : this.props.isAuthenticated ? (
      <Navbar />
    ) : (
      <Redirect to="/login" />
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  current_user: state.auth.user,
  isLoading: state.auth.isLoading,
});

export default connect(mapStateToProps, {
  checkAuthenticated,
  incoming_notification,
})(HomeNotificationWrapper);
