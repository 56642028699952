import React from "react";
import {
  Avatar,
  Button,
  CircularProgress,
  Grid,
  ListItem,
  ListItemAvatar,
  Typography,
} from "@material-ui/core";
import GetTimeSince from "../WeeklyKpisTeams/GetTimeSince";

export default function WeeklyKpisEvaluationCardHeader({
  evaluation = {},
  time_last_saved,
  isEditMode,
  isProcessing,
  setIsEditMode,
}) {
  const { week_start_date, time_is_not_applicable, _to } = evaluation;
  const { full_name, picture } = _to || {};

  return (
    <Grid
      item
      xs={12}
      style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 12,
      }}
    >
      <ListItem disableGutters style={{ padding: 0 }}>
        <ListItemAvatar>
          <Avatar
            src={picture}
            alt={full_name}
            variant="square"
            style={{
              height: 56,
              width: 56,
              marginRight: 24,
            }}
          />
        </ListItemAvatar>
        <div style={{ flexGrow: 1 }}>
          <Typography>{full_name}</Typography>
          {time_last_saved ? (
            <React.Fragment>
              <Typography
                component="div"
                variant="caption"
                color="secondary"
                style={{ fontWeight: "bold" }}
              >
                {"Saved"}
              </Typography>
            </React.Fragment>
          ) : (
            <Typography
              component="div"
              variant="caption"
              style={{ fontWeight: "bold" }}
              color={
                Boolean(time_is_not_applicable) ? "textPrimary" : "primary"
              }
            >
              {Boolean(time_is_not_applicable)
                ? "Not applicable"
                : "Incomplete"}
            </Typography>
          )}
          {time_last_saved && (
            <Typography
              component="div"
              variant="caption"
            >{`Last updated ${GetTimeSince(time_last_saved)}`}</Typography>
          )}
        </div>
        <div style={{ textAlign: "right" }}>
          <Typography
            variant="body2"
            style={{ fontWeight: "bold", marginBottom: 4 }}
          >
            {new Date(week_start_date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "2-digit",
            })}
          </Typography>
          {isProcessing ? (
            <CircularProgress size={24} />
          ) : (
            <Button
              color="primary"
              variant={isEditMode ? "outlined" : "contained"}
              size="small"
              onClick={() => setIsEditMode(!isEditMode)}
            >
              {isEditMode ? "Cancel" : "Edit"}
            </Button>
          )}
        </div>
      </ListItem>
    </Grid>
  );
}
