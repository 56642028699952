import {
  BIRTHDAYS_CALENDAR_SUCCESS,
  BIRTHDAYS_CALENDAR_FAILURE,
  NEW_TO_RIMADS_CALENDAR_SUCCESS,
  NEW_TO_RIMADS_CALENDAR_FAILURE,
  UPCOMING_EVENTS_CALENDAR_SUCCESS,
  UPCOMING_EVENTS_CALENDAR_FAILURE,
  WHOS_OUT_CALENDAR_SUCCESS,
  WHOS_OUT_CALENDAR_FAILURE,
} from "../actions/calendarTypes";

const initialState = {
  birthdays: [],
  new_to_rimads: [],
  upcoming_events: [],
  whos_out: [],
};

export default function calendar(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case BIRTHDAYS_CALENDAR_SUCCESS:
      return {
        ...state,
        birthdays: payload,
      };
    case BIRTHDAYS_CALENDAR_FAILURE:
      return {
        ...state,
        birthdays: [],
      };
    case NEW_TO_RIMADS_CALENDAR_SUCCESS:
      return {
        ...state,
        new_to_rimads: payload,
      };
    case NEW_TO_RIMADS_CALENDAR_FAILURE:
      return {
        ...state,
        new_to_rimads: [],
      };
    case UPCOMING_EVENTS_CALENDAR_SUCCESS:
      return {
        ...state,
        upcoming_events: payload,
      };
    case UPCOMING_EVENTS_CALENDAR_FAILURE:
      return {
        ...state,
        upcoming_events: [],
      };
    case WHOS_OUT_CALENDAR_SUCCESS:
      return {
        ...state,
        whos_out: payload,
      };
    case WHOS_OUT_CALENDAR_FAILURE:
      return {
        ...state,
        whos_out: [],
      };

    default:
      return state;
  }
}
