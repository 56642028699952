import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, CircularProgress } from "@material-ui/core";

import { get_RCRN } from "../../../../actions/procurement";
import ReturnCashTable from "./ReturnCashTable";

import Poppup from "../../../../components/Poppup";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 600,
  },
  center: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
  },
}));

const ReturnCashRequest = ({ submitting, get_RCRN, data }) => {
  const classes = useStyles();
  const { RCRN } = useParams();

  useEffect(() => {
    get_RCRN(RCRN);
  }, [get_RCRN, RCRN]);

  return data === null ? (
    <div className={classes.center}>
      <CircularProgress style={{ margin: "auto" }} />
    </div>
  ) : (
    <div className={classes.paper}>
      <Typography style={{ marginBottom: 40 }} variant="h3">
        {data.RCRN}
        <Poppup
          content={
            <Typography variant="body1">
              This request form is designed to facilitate the return of excess
              cash remaining from approved inventory stocking requests. This can
              happen when the purchase bill is less than the approved bill. 
            </Typography>
          }
        />
      </Typography>
      <ReturnCashTable />
    </div>
  );
};

const mapStateToProps = (state) => ({
  submitting: state.procurement.submitting_RCRN,
  data: state.procurement.RCRN_data,
});

export default connect(mapStateToProps, { get_RCRN })(ReturnCashRequest);
