import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import {
  get_webapp_accounts,
  clear_webapp_accounts,
} from "../../../actions/procedure";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import CreateIcon from "@material-ui/icons/Create";

import AddWebApp from "../WebApps/AddWebApp";
import EditWebApp from "../WebApps/EditWebApp";

import AddWebAppAccount from "../WebApps/AddWebAppAccount";
import EditWebAppAccount from "../WebApps/EditWebAppAccount";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  paper: {
    width: "100%",
    marginBottom: 20,
  },
  title: {
    flex: "1 1 100%",
  },
}));

const OnlinePayments = ({
  minDate,
  formData,
  setFormData,
  webapps,
  get_webapp_accounts,
  clear_webapp_accounts,
  webapp_accounts,
  setNext,
}) => {
  const classes = useStyles();
  const [webApp, setWebApp] = useState(null);

  useEffect(() => {
    formData.payments.webapp && setWebApp(formData.payments.webapp);
  }, [setWebApp, formData]);

  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      payments: { ...formData.payments, payment_date: date },
    });
    date === null || date.toString() === "Invalid Date"
      ? setNext(false)
      : formData.payments.webap_account &&
        formData.payments.webap_account !== null &&
        setNext(true);
  };

  const [openNewWebapp, setOpenNewWebapp] = useState(false);
  const [openEditWebapp, setOpenEditWebapp] = useState(false);
  const [openNewWebappAccount, setOpenNewWebappAccount] = useState(false);
  const [openEditWebappAccount, setOpenEditWebappAccount] = useState(false);

  const handleWebAppChange = (webapp) => {
    if (webapp !== null) {
      get_webapp_accounts(webapp.id);
      setWebApp(webapp);
    } else {
      clear_webapp_accounts();
      setWebApp(null);
      setFormData({
        ...formData,
        payments: { ...formData.payments, webapp_account: null },
      });
    }
    handleAccountChange(null);
  };

  const handleAccountChange = (account) => {
    setFormData({
      ...formData,
      payments: { ...formData.payments, webapp_account: account },
    });
    account !== null
      ? formData.payments.payment_date &&
        formData.payments.payment_date !== null &&
        setNext(true)
      : setNext(false);
  };

  return (
    <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          fullWidth
          required
          inputVariant="outlined"
          className={classes.marginBottom}
          label="Planned order date"
          format="dd / MM / yyyy"
          value={formData.payments.payment_date || null}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          minDate={minDate}
          maxDate={formData.expected_delivery_date}
        />
      </MuiPickersUtilsProvider>
      <div style={{ display: "flex" }} className={classes.marginBottom}>
        <Autocomplete
          required
          options={webapps}
          getOptionLabel={(option) => option.name}
          onChange={(event, newValue) => {
            handleWebAppChange(newValue);
          }}
          getOptionSelected={(option, value) =>
            option.id === value.id ? option : null
          }
          fullWidth
          value={webApp || null}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              InputLabelProps={{ shrink: true }}
              label="WebApp"
              variant="outlined"
              placeholder="Search for an online account"
            />
          )}
        />
        {webApp ? (
          <div>
            <Tooltip title="Edit webapp">
              <IconButton onClick={() => setOpenEditWebapp(!openEditWebapp)}>
                <CreateIcon />
              </IconButton>
            </Tooltip>
          </div>
        ) : (
          <div>
            <Tooltip title="Add webapp">
              <IconButton onClick={() => setOpenNewWebapp(!openNewWebapp)}>
                <AddIcon />
              </IconButton>
            </Tooltip>
            <AddWebApp open={openNewWebapp} setOpen={setOpenNewWebapp} />
          </div>
        )}
      </div>
      {webApp && (
        <div style={{ display: "flex" }} className={classes.marginBottom}>
          <Autocomplete
            required
            options={webapp_accounts}
            getOptionLabel={(option) => option.username}
            onChange={(event, newValue) => {
              handleAccountChange(newValue);
            }}
            getOptionSelected={(option, value) =>
              option.id === value.id ? option : null
            }
            fullWidth
            value={formData.payments.webapp_account || null}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                InputLabelProps={{ shrink: true }}
                label="Account"
                variant="outlined"
                placeholder="Search for an online account"
              />
            )}
          />
          {formData.payments.webapp_account ? (
            <div>
              <Tooltip title="Edit webapp account">
                <IconButton
                  onClick={() =>
                    setOpenEditWebappAccount(!openEditWebappAccount)
                  }
                >
                  <CreateIcon />
                </IconButton>
              </Tooltip>
              <EditWebAppAccount
                open={openEditWebappAccount}
                setOpen={setOpenEditWebappAccount}
                account={formData.payments.webapp_account}
                webapp_pk={webApp.id}
                setSelected={() => {}}
                setAccount={(account) => {
                  setFormData({
                    ...formData,
                    payments: {
                      ...formData.payments,
                      webapp_account: account,
                    },
                  });
                }}
              />
            </div>
          ) : (
            <div>
              <Tooltip title="Add account">
                <IconButton
                  onClick={() => setOpenNewWebappAccount(!openNewWebappAccount)}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
              <AddWebAppAccount
                open={openNewWebappAccount}
                setOpen={setOpenNewWebappAccount}
                webapp_pk={webApp.id}
              />
            </div>
          )}
        </div>
      )}
      {webApp && (
        <EditWebApp
          open={openEditWebapp}
          setOpen={setOpenEditWebapp}
          webapp={webApp}
          setWebApp={setWebApp}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  webapps: state.procedures.webapps,
  webapp_accounts: state.procedures.webapp_accounts,
});

export default connect(mapStateToProps, {
  get_webapp_accounts,
  clear_webapp_accounts,
})(OnlinePayments);
