export const CLEAR_FORMDATA = "CLEAR_PROCUREMENT_FORMDATA";
export const GET_FORM_DATA_SUCCESS = "GET_PROCUREMENT_FORM_DATA";
export const UPDATE_FORM_STATUS_SUCCESS = "UPDATE_PROCUREMENT_FORM_STATUS";
export const UPDATE_PROCUREMENT_PRODUCT = "UPDATE_PROCUREMENT_PRODUCT";
export const PROCESSING = "PROCESSING_PROCUREMENT_REQUEST";
export const PROCESSING_PRODUCT = "PROCESSING_PROCUREMENT_PRODUCT";

export const FETCHED_ALL_IR = "FETCHED_ALL_IR";
export const CLEAR_RCRN_DATA = "CLEAR_PROCUREMENT_RCRN_DATA";
export const GET_RCRN = "GET_PROCUREMENT_RCRN";
export const UPDATING_RCRN = "UPDATING_PROCUREMENT_RCRN";
export const PROCESSING_RCRN = "PROCESSING_PROCUREMENT_RCRN";
