import React from "react";
import {Divider, List, Typography} from "@material-ui/core";
import RimadsAccordion from "../../components/RimadsAccordion";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paddingLeft: {
    paddingLeft: 20,
  },
}));

const Policies = () => {
  const classes = useStyles();
  const lgspace = <div style={{padding: 48, width: "100%"}}/>;
  const mdspace = <div style={{padding: 24, width: "100%"}}/>;
  const smspace = <div style={{padding: 8, width: "100%"}}/>;

  return (
    <div style={{maxWidth: 600}}>
      <Typography variant="h3">Company Policies</Typography>
      {mdspace}
      <Typography variant="body1">
        Avey is a principle-centered company. It conducts business ethically,
        transparently, and honestly, holding certain values at heart. This
        applies to every business decision it makes and policy it employs.
      </Typography>
      {mdspace}

      <Typography variant="h5">Our Values</Typography>
      {smspace}
      <List>
        <RimadsAccordion
          summary="Grit"
          details={
            <span>
              <Typography variant="body1">
                Avey is a mission-driven company. We believe that
                accomplishing our mission and producing impactful work takes
                grit. To this end, every Aveyator holds wholeheartedly
                themselves to high standards and puts in all the needed time and
                effort to do their part in ensuring the company’s success.
              </Typography>
            </span>
          }
        />
        <RimadsAccordion
          summary="Passion"
          details={
            <span>
              <Typography variant="body1">
                We love what we do and cherish our story. We work every day
                towards accomplishing our mission with joy and passion because
                we believe that we are bringing a positive change to the world.
              </Typography>
            </span>
          }
        />
        <RimadsAccordion
          summary="Creativity"
          details={
            <span>
              <Typography variant="body1">
                We value creativity so much and do not fear trials and errors.
                We pose no limits on our imagination and believe that creative
                ideas are the starting point in any paradigm shift. In light of
                this, we strive to continuously offer unique, elegant,
                efficient, effective, and easy-to-use solutions, which are
                imagined creatively, pursued passionately, and materialized with
                grit.
              </Typography>
            </span>
          }
        />
        <RimadsAccordion
          summary="Customer Centrality"
          details={
            <span>
              <Typography variant="body1">
                Our solutions are only meaningful if they are useful to our end
                users. As such, we always inform our perspective with their
                perspective and try our best to delight them. If in any service
                we offer to them we fail to live up to their expectations, we
                quickly re-calibrate and re-offer them an elevated version of
                the service, even without charging them if needed. It is never a
                loss with them because we are for them.
              </Typography>
            </span>
          }
        />
        <Divider/>
      </List>

      {lgspace}

      <Typography variant="h5">Equal Opportunity Policy</Typography>
      {smspace}
      <List>
        <RimadsAccordion
          summary="Equal Opportunity Policy"
          details={
            <span>
              <Typography variant="body1">
                Avey believes in equal opportunity for all people. In
                particular, candidates applying for any job at Avey will never
                be excluded or denied because of their race, gender, ethnicity,
                religion, or anything other than their mere ability to fit the
                role as per its clearly defined and published requirements.
              </Typography>
            </span>
          }
        />
        <RimadsAccordion
          summary="The Way We Hire"
          details={
            <span>
              <Typography variant="body1">
                Avey places a great emphasis on the candidates who share our
                values and demonstrate genuine passion about the work we do. In
                addition, it tries to ensure that we always get the right people
                for any job via running a 3-round interviewing process in which
                work ethics, grit, logic, and knowledge are measured and a
                collective decision is made accordingly.
              </Typography>
            </span>
          }
        />
        <Divider/>
      </List>

      {lgspace}

      <Typography variant="h5">Code of Conduct</Typography>
      {smspace}
      <List>
        <RimadsAccordion
          summary="Working Hours"
          details={
            <span>
              <Typography variant="body1">
                Aveyators make up Avey and there is no Avey without Aveyators.
                They act as one body with one soul, one heart, and one mission.
                They set ambitious goals and work tirelessly to achieve them
                without worrying about the time spent and the effort put, but
                the joy, the outcome, and the societal impact that transcends
                beyond the workplace.
              </Typography>
              <br/>
              <Typography variant="body1">
                Avey hires only the best and provides them with an innovative
                and symbiotic culture where they can blossom and flourish. It
                believes on working physically from its offices to facilitate
                synergistic collaboration and natural serendipity. It expects
                Aveyators to spend 9 hours (including lunch break) in offices
                every working day, enjoying the job together and achieving
                collectively what others may consider unachievable.
              </Typography>
              <br/>
              <Typography variant="body1">
                In light of this company-wide policy, the directors of the
                departments can specify work modes and schedules within their
                departments as necessary to enable Aveyators, while ensuring
                utmost efficiency and high productivity.
              </Typography>
              <br/>
              <Typography variant="body1">
                The executives of Avey, including chiefs and directors, are
                always at the forefront, working very hard, and not limiting
                themselves with operation hours and days. They are in constant
                call, coordination, and cooperation among each other for the
                best of every Aveyator, every customer, and the company as a
                whole.
              </Typography>
            </span>
          }
        />
        <RimadsAccordion
          summary="Dress Code"
          details={
            <span>
              <Typography variant="body1">
                Aveyators are not expected to dress formally at work. They are
                only invited to dress formally when a situation for formal wear
                arises. Although casual wear is permitted, all Aveyators are
                expected to dress moderately.
              </Typography>
            </span>
          }
        />
        <RimadsAccordion
          summary="Confidentiality"
          details={
            <span>
              <Typography variant="body1">
                Avey employs a policy of strict confidentiality. Every
                Aveyator is expected to disclose information to members of
                their own department or other departments only when it is
                relevant and necessary. Besides, Aveyators are not allowed to
                disclose any confidential information that pertains to the
                company with any outside party, unless an agreement that allows
                that exists between Avey and the outside party.
              </Typography>
            </span>
          }
        />
        <RimadsAccordion
          summary="Substance Abuse"
          details={
            <span>
              <Typography variant="body1">
                Avey employs a strict policy for substance abuse. If a
                Aveyator’s responsibilities and/or surrounding culture at work
                are affected by their substance abuse, they will receive a
                warning. If after the warning this continues, their contract
                with Avey will be terminated.
              </Typography>
            </span>
          }
        />
        <RimadsAccordion
          summary="Sexual Harassment"
          details={
            <span>
              <Typography variant="body1">
                Avey employs a strict policy for sexual harassment. If an
                Aveyator accuses another Aveyator of sexual harassment, they
                must report this to their direct supervisor and HR. When such a
                complaint is received, HR will conduct a thorough investigation
                about the case. If the accused Aveyator is found to be guilty,
                their contract with Avey will be terminated.
              </Typography>
            </span>
          }
        />
        <Divider/>
      </List>

      {lgspace}

      <Typography variant="h5">Time off Policies</Typography>
      {smspace}
      <List>
        <RimadsAccordion
          summary="Annual Paid Leave"
          details={
            <span>
              <Typography paragraph variant="body1">
                Every full-time Aveyator who has at least a one-year contract
                with Avey is entitled to normal yearly paid leave of eighteen
                (18) working days.
              </Typography>
              <Typography paragraph variant="body1">
                All annual leave days are to be applied for at least thirty (30)
                days before the desired leave date(s). Avey will not be
                responsible for any loss that might arise on you from any
                arrangements you make before your leave days are approved.
              </Typography>
              <Typography paragraph variant="body1">
                In the case of emergency situations that do not allow for a
                30-day notice, you should contact the Community and Talent
                department.
              </Typography>
              <Typography paragraph variant="body1">
                In addition to the above, you will receive full pay during
                official holidays as follows:
              </Typography>
              <ul style={{"list-style": "disc"}}>
                <Typography variant="body1" className={classes.paddingLeft}>
                  <li>
                    Three working days for Eid Al Fitr (if it lies in working
                    days).
                  </li>
                </Typography>
                <Typography variant="body1" className={classes.paddingLeft}>
                  <li>
                    Three working days for Eid Al Adha (if it lies in working
                    days).
                  </li>
                </Typography>
                <Typography variant="body1" className={classes.paddingLeft}>
                  <li>
                    One working day for the National Day (if it lies in a
                    working day).
                  </li>
                </Typography>
                <Typography variant="body1" className={classes.paddingLeft}>
                  <li>
                    One working day for the National Sports Day (if it lies in a
                    working day).
                  </li>
                </Typography>
              </ul>
              <Typography paragraph variant="body1">
                You are also entitled to a leave of three (3) additional working
                days with full pay during each year of your contract period.
                These days will be decided on by the management of Avey.
              </Typography>
              <Typography variant="body1">
                Avey encourages every Aveyator to take their annual paid
                leave in full so as to relax, recharge, and rejuvenate. As such,
                paid time-off days from any contract year do not carry on to
                subsequent contract years.
              </Typography>
            </span>
          }
        />
        <RimadsAccordion
          summary="Sick Leave"
          details={
            <span>
              <Typography paragraph variant="body1">
                Each Aveyator is entitled to sick leave as outlined below:
              </Typography>
              <Typography
                paragraph
                variant="body1"
                className={classes.paddingLeft}
              >
                The Aveyator must apply on the portal for sick leave and they
                must have a sick leave certificate for the application to be
                approved.
              </Typography>
              <Typography
                paragraph
                variant="body1"
                className={classes.paddingLeft}
              >
                {
                  "Directors can only approve a sick leave of a maximum of 2 consecutive days (could happen multiple times per year) without a certificate, but justification is required."
                }
              </Typography>
              <Typography
                paragraph
                variant="body1"
                className={classes.paddingLeft}
              >
                The Aveyator will receive full payment for two calendar weeks
                (or 10 working days) of accumulated sick leave days.
              </Typography>
              <Typography
                paragraph
                variant="body1"
                className={classes.paddingLeft}
              >
                After the initial two calendar weeks (or 10 working days) of
                accumulated sick leave days have been exhausted, the Aveyator
                will receive half a day’s payment for every sick leave day taken
                for the next four calendar weeks (or 20 working days) of
                accumulated sick leave days.
              </Typography>
              <Typography
                paragraph
                variant="body1"
                className={classes.paddingLeft}
              >
                Any sick leave from the above that is not approved will not be
                paid.
              </Typography>
              <Typography
                paragraph
                variant="body1"
                className={classes.paddingLeft}
              >
                Thereafter, any extension of sick leave should be requested by
                the Aveyator and approved by the Avey management, but it will
                not be paid.
              </Typography>
              <Typography
                paragraph
                variant="body1"
                className={classes.paddingLeft}
              >
                The Aveyator’s count for sick leave days is reset at the start
                of every contract year.
              </Typography>
            </span>
          }
        />
        <RimadsAccordion
          summary="Maternity Leave"
          details={
            <span>
              <Typography variant="body1">
                Maternity leave is fully paid and lasts for 2 calendar months.
                If an Aveyator wishes to extend their maternity leave, they must
                seek approval from the Avey management. If an extension is
                granted, it will be unpaid.
              </Typography>
            </span>
          }
        />
        <RimadsAccordion
          summary="Paternity Leave"
          details={
            <span>
              <Typography variant="body1">
                Paternity leave is fully paid and lasts for 1 calendar week. If
                an Aveyator wishes to extend their paternity leave, they must
                seek approval from the Avey management. If an extension is
                granted, it will be unpaid.
              </Typography>
            </span>
          }
        />
        <RimadsAccordion
          summary="Bereavement Leave"
          details={
            <span>
              <Typography variant="body1">
                Aveyators are entitled to bereavement leave if they suffer from
                the death of a first or second degree relative during their
                employment at Avey. Bereavement leave lasts for 3 days and
                will be fully paid.
              </Typography>
            </span>
          }
        />
        <RimadsAccordion
          summary="Unpaid Leave"
          details={
            <span>
              <Typography variant="body1">
                Any leave that is not “annual paid”, “sick”, “maternity”,
                “paternity”, or “bereavement” is an unpaid leave. If an Aveyator
                wishes to take an unpaid leave, they must seek approval from the
                Avey management.
              </Typography>
            </span>
          }
        />
        <Divider/>
      </List>

      {lgspace}

      <Typography variant="h5">Disciplinary Action Policies</Typography>
      {smspace}
      <List>
        <RimadsAccordion
          summary="Confidentiality"
          details={
            <span>
              <Typography variant="body1">
                If an Aveyator discloses information that pertains to the
                company to an outside party without a prior agreement between
                Avey and the outside party, they will be issued a warning. If
                after the warning this happens again, their contract with Avey
                will be terminated.
              </Typography>
            </span>
          }
        />
        <RimadsAccordion
          summary="Sexual Harassment"
          details={
            <span>
              <Typography variant="body1">
                If an Aveyator is accused of sexual harassment and an
                investigation finds them guilty, their contract with Avey will
                be terminated.
              </Typography>
            </span>
          }
        />
        <RimadsAccordion
          summary="Going on Leave without Approval"
          details={
            <span>
              <Typography variant="body1">
                If for any reason an Aveyator goes on leave without first
                obtaining an approval from their direct supervisor, their
                contract with Avey will be terminated.
              </Typography>
            </span>
          }
        />
        <RimadsAccordion
          summary="Underperformance"
          details={
            <span>
              <Typography variant="body1">
                If an Aveyator is deemed by their direct supervisor to be
                underperforming, a meeting will take place between the Aveyator
                and the direct supervisor to identify and discuss the reasons
                for underperformance. Based on the outcome of the meeting, a
                warning can be issued by the direct supervisor. If afterwards
                the Aveyator continues to underperform, the direct supervisor
                has the right to terminate their contract.
              </Typography>
            </span>
          }
        />
        <RimadsAccordion
          summary="Losing Company Money"
          details={
            <span>
              <Typography variant="body1">
                If for any reason an Aveyator who is responsible for company
                money loses the money, or cannot account for it, they will be
                liable for the entire amount of the money that was lost or
                cannot be accounted for. This amount will be deducted from their
                next salary payment or payments.
              </Typography>
            </span>
          }
        />
        <RimadsAccordion
          summary="Reimbursement Policy"
          details={
            <span>
              <Typography variant="body1">
                If an Aveyator wants to be reimbursed for an expense that they
                have paid on behalf of Avey, they must provide the receipt for
                their payment. If the Aveyator cannot provide the receipt, they
                cannot be reimbursed.
              </Typography>
            </span>
          }
        />
        <RimadsAccordion
          summary="Complaint Policy"
          details={
            <span>
              <Typography variant="body1">
                If an Aveyator feels that anyone within the company has violated
                a company policy, they can report this violation to their direct
                supervisor who can subsequently submit it to HR. If the direct
                supervisor is the one who has committed the violation, the
                Aveyator can submit the complaint directly to HR. If a direct
                supervisor receives a complaint, they have the prerogative of
                either resolving it to the satisfaction of the violating and
                reporting parties or submitting it directly to HR. If a
                complaint reaches HR, a corresponding action will be taken as
                per the company’s “Employee Disciplinary Action Policies”.
              </Typography>
            </span>
          }
        />
        <Divider/>
      </List>
    </div>
  );
};

export default Policies;
