import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import { FormControl } from "@material-ui/core";
import { edit_webapp_account } from "../../../actions/procedure";
import { connect } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";

import DialogTitle from "../../../components/MuiDialogTitle";

const Types = ["marketing", "procurement", "social media"];

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function EditWebAppAccount({
  open,
  setOpen,
  account,
  edit_webapp_account,
  webapp_pk,
  setSelected,
  setAccount,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  const [formData, setFormData] = useState(null);

  useEffect(() => {
    setFormData({
      id: account.id,
      username: account.username,
      password: account.password,
      account_type: account.account_type,
      url: account.url,
      backup_email: account.backup_email,
      phone_number: account.phone_number,
    });
  }, [setFormData, account, open]);

  const handleChange = (prop) => (event) => {
    setFormData({
      ...formData,
      [prop]: event.target.value,
    });
  };

  const handleAccountTypeChange = (account_type) => {
    setFormData({ ...formData, account_type: account_type || null });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    edit_webapp_account(formData, webapp_pk).then((account) => {
      if (account) {
        setSelected([account.id]);
        setAccount(account);
      }
    });
    handleClose();
  };

  return (
    formData && (
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        fullWidth
      >
        <DialogTitle
          style={{ cursor: "move" }}
          id="draggable-dialog-title"
          onClose={handleClose}
        >
          Edit Account
        </DialogTitle>
        <form onSubmit={(e) => onSubmit(e)}>
          <DialogContent>
            <FormControl fullWidth>
              <TextField
                required
                onChange={handleChange("username")}
                value={formData.username}
                autoFocus
                variant="outlined"
                label="Username"
                type="text"
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                required
                onChange={handleChange("password")}
                value={formData.password}
                variant="outlined"
                label="Password"
                type="text"
              />
            </FormControl>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <Autocomplete
                required
                options={Types}
                getOptionLabel={(option) => option}
                onInputChange={(event, newValue) => {
                  handleAccountTypeChange(newValue);
                }}
                getOptionSelected={(option, value) =>
                  option === value ? option : value
                }
                value={formData.account_type}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    InputLabelProps={{ shrink: true }}
                    label="Account type"
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                required
                onChange={handleChange("url")}
                value={formData.url}
                variant="outlined"
                label="URL"
                type="text"
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                onChange={handleChange("backup_email")}
                value={formData.backup_email}
                variant="outlined"
                label="Backup email"
                type="email"
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                onChange={handleChange("phone_number")}
                value={formData.phone_number}
                variant="outlined"
                label="Phone Number"
                inputProps={{ pattern: "[+][0-9]*" }}
                placeholder="Include area code: +97466748668"
              />
            </FormControl>
          </DialogContent>
          <DialogActions style={{ padding: 24 }}>
            <Button
              onClick={handleClose}
              fullWidth
              color="primary"
              variant="outlined"
              style={{ borderRadius: 24 }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              fullWidth
              color="primary"
              variant="contained"
              style={{ borderRadius: 24 }}
              disabled={
                formData.username === account.username &&
                formData.password === account.password &&
                formData.account_type === account.account_type &&
                formData.url === account.url &&
                formData.backup_email === account.backup_email &&
                formData.phone_number === account.phone_number
              }
            >
              Edit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    )
  );
}

// const mapStateToProps = (state) => ({
//   processing: state.procedures.processing,
// });

export default connect(null, { edit_webapp_account })(EditWebAppAccount);
