import React, { useState } from "react";
import { connect } from "react-redux";
import { update_operation_expense_request_status } from "../../../../../actions/operation_expenses";
import {
  Dialog,
  DialogActions,
  DialogContent,
  CircularProgress,
  TextField,
  Button,
  Backdrop,
  Grid,
} from "@material-ui/core";
import DialogTitle from "../../../../../components/MuiDialogTitle";

function EditStatus({
  OERN,
  accounting_status,
  update_operation_expense_request_status,
  editMode,
  setEditMode,
  setSelected,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    update_operation_expense_request_status(OERN, {
      id: accounting_status.id,
      status:
        accounting_status.status.toLowerCase() === "pending"
          ? "to collect"
          : "received",
    }).then((edited) => {
      setIsLoading(false);
      if (edited) {
        setEditMode(false);
        setSelected([]);
      }
    });
  };

  return (
    <Dialog
      open={editMode}
      onClose={() => setEditMode(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <Backdrop
        style={{
          position: "absolute",
          zIndex: 1000,
          backgroundColor: "black",
          opacity: 0.4,
        }}
        open={isLoading}
      >
        <CircularProgress style={{ margin: "auto" }} />
      </Backdrop>
      <DialogTitle onClose={() => setEditMode(false)}>
        Proceed with the payment
      </DialogTitle>
      <form component="form" onSubmit={(e) => onSubmit(e)}>
        <DialogContent style={{ padding: 24 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <TextField
                label="Payment type"
                value={accounting_status.payment_type}
                InputProps={{ disableUnderline: true, readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                required
                label="Payment source"
                value={accounting_status.payment_source}
                InputProps={{ disableUnderline: true, readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                required
                label="Amount"
                value={accounting_status.amount_paid.toFixed(2)}
                InputProps={{ disableUnderline: true, readOnly: true }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: 24 }}>
          <Button
            onClick={() => setEditMode(false)}
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            style={{ paddingLeft: 40, paddingRight: 40 }}
          >
            Mark payment as{" "}
            {accounting_status.status.toLowerCase() === "pending"
              ? "ready to collect"
              : "received"}
            !
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default connect(null, {
  update_operation_expense_request_status,
})(EditStatus);
