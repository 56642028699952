ArrowRightIcon.displayName = "ArrowRightIcon";

export default function ArrowRightIcon({
  primary_fill = "#949494",
  dimension = 12,
  style = {},
  ...other
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 12"
      fill="none"
      style={{
        minHeight: dimension,
        height: dimension,
        minWidth: dimension,
        width: dimension,
        ...style,
      }}
      {...other}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.81909 5.9002L6.75928 9.54674C6.42363 9.94675 6.4758 10.5431 6.87582 10.8788C7.27583 11.2144 7.87221 11.1623 8.20786 10.7622L11.6113 6.70621C11.6381 6.67421 11.6625 6.64095 11.6844 6.60667C12.0641 6.26787 12.1081 5.68631 11.7788 5.29381L8.37536 1.23778C8.03971 0.837767 7.44333 0.785592 7.04332 1.12124C6.6433 1.4569 6.59113 2.05327 6.92678 2.45329L9.81909 5.9002Z"
        fill={primary_fill}
      />
    </svg>
  );
}
