import React from "react";

const useEffectLoadWeeklyKpisTeams = ({
  weeklyKpisLoadDepartmentTeams,
  variant,
}) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [data, setData] = React.useState({
    results: [],
    count: 0,
    next: null,
  });

  React.useEffect(() => {
    setIsLoading(true);
    weeklyKpisLoadDepartmentTeams({ page_number: 1, variant: variant }).then(
      (payload) => {
        payload && setData(payload);
        const timeout = setTimeout(() => setIsLoading(false), 500);
        return () => clearTimeout(timeout);
      }
    );
    return () => setIsLoading(false);
  }, [weeklyKpisLoadDepartmentTeams, variant]);

  return { isLoading, data, setData };
};

export default useEffectLoadWeeklyKpisTeams;
