import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import { FormControl } from "@material-ui/core";
import { add_webapp_account } from "../../../actions/procedure";
import { connect } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";

import DialogTitle from "../../../components/MuiDialogTitle";

const Types = ["Marketing", "Procurement"];

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function AddWebAppAccount({ open, setOpen, add_webapp_account, webapp_pk }) {
  const handleClose = () => {
    setOpen(false);
    setFormData({
      username: "",
      password: "",
      account_type: null,
      url: "",
      backup_email: "",
      phone_number: "",
    });
  };

  const [formData, setFormData] = useState({
    username: "",
    password: "",
    account_type: null,
    url: "",
    backup_email: "",
    phone_number: "",
  });

  const handleChange = (prop) => (event) => {
    setFormData({
      ...formData,
      [prop]: event.target.value,
    });
  };

  const handleAccountTypeChange = (account_type) => {
    setFormData({ ...formData, account_type: account_type || null });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    add_webapp_account(formData, webapp_pk);
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        fullWidth
      >
        <DialogTitle
          style={{ cursor: "move" }}
          id="draggable-dialog-title"
          onClose={handleClose}
        >
          Add a new online user account
        </DialogTitle>
        <form onSubmit={(e) => onSubmit(e)}>
          <DialogContent>
            <FormControl fullWidth>
              <TextField
                required
                onChange={handleChange("username")}
                value={formData.username}
                autoFocus
                variant="outlined"
                label="Username"
                type="text"
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                required
                onChange={handleChange("password")}
                value={formData.password}
                variant="outlined"
                label="Password"
                type="text"
              />
            </FormControl>
            <Autocomplete
              required
              options={Types}
              getOptionLabel={(option) => option}
              onInputChange={(event, newValue) => {
                handleAccountTypeChange(newValue);
              }}
              getOptionSelected={(option, value) =>
                option === value ? option : value
              }
              fullWidth
              value={formData.account_type}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  InputLabelProps={{ shrink: true }}
                  label="Account type"
                  variant="outlined"
                />
              )}
            />
            <FormControl fullWidth>
              <TextField
                required
                onChange={handleChange("url")}
                value={formData.url}
                variant="outlined"
                label="URL"
                type="text"
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                onChange={handleChange("backup_email")}
                value={formData.backup_email}
                variant="outlined"
                label="Backup email"
                type="email"
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                onChange={handleChange("phone_number")}
                value={formData.phone_number}
                variant="outlined"
                label="Phone Number"
                inputProps={{ pattern: "[+][0-9]*" }}
                placeholder="Include area code: +97466748668"
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              fullWidth
              color="primary"
              variant="outlined"
              style={{ borderRadius: 24 }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              fullWidth
              color="primary"
              variant="contained"
              style={{ borderRadius: 24 }}
            >
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

// const mapStateToProps = (state) => ({
//   processing: state.procedures.processing,
// });

export default connect(null, { add_webapp_account })(AddWebAppAccount);
