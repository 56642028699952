import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  get_rimadsters,
  load_detailed_employee,
} from "../../actions/rimadsters";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Rimadster from "./Rimadster";
import CustomCircularProgress from "../../components/CustomCircularProgress";

const Rimadsters = ({
  get_rimadsters,
  fetched,
  data,
  current_user,
  load_detailed_employee,
}) => {
  const [rimadster, setRimadster] = useState(false);

  useEffect(() => {
    get_rimadsters();
  }, [get_rimadsters]);

  const loadRimadster = (id) => {
    setRimadster(true);
    load_detailed_employee(id).then((rimadster) => {
      rimadster && setRimadster(rimadster);
    });
  };

  return (
    <div>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Typography variant="h3">{"Aveyators"}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">
            {"Here is the list of all Aveyators in alphabetical order."}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <CustomCircularProgress open={!fetched} />
          <TableContainer style={{ borderTop: "1px solid #e2e2e2" }}>
            <Table aria-label="simple table">
              <TableBody>
                {(data || [])
                  .sort((a, b) => (a.full_name > b.full_name ? 1 : -1))
                  .map((rimadster) => {
                    return (
                      <TableRow
                        key={rimadster.id}
                        style={{ height: "100px" }}
                        hover
                        onClick={() => loadRimadster(rimadster.id)}
                      >
                        <TableCell>
                          <ListItemAvatar>
                            <Avatar
                              // style={{ height: 50, width: 50 }}
                              src={rimadster.picture}
                              alt={rimadster.full_name}
                            />
                          </ListItemAvatar>
                        </TableCell>
                        <TableCell>
                          <ListItemText
                            primaryTypographyProps={{
                              style: {
                                fontWeight:
                                  rimadster.id === current_user.id && "bold",
                              },
                              variant: "body2",
                            }}
                            primary={rimadster.full_name}
                            secondary={
                              rimadster.time_off &&
                              `on leave until ${new Date(
                                rimadster.time_off
                              ).toLocaleDateString("en-GB")}`
                            }
                          />
                        </TableCell>
                        <TableCell style={{ maxWidth: "220px" }}>
                          <ListItemText
                            primaryTypographyProps={{
                              style: {
                                fontWeight:
                                  rimadster.id === current_user.id && "bold",
                              },
                              variant: "body2",
                            }}
                            primary={rimadster.job_title}
                          />
                        </TableCell>
                        <TableCell>
                          <ListItemText
                            primaryTypographyProps={{
                              style: {
                                fontWeight:
                                  rimadster.id === current_user.id && "bold",
                              },
                              variant: "body2",
                            }}
                            primary={rimadster.phone_number}
                          />
                        </TableCell>
                        <TableCell>
                          <ListItemText
                            primaryTypographyProps={{
                              style: {
                                fontWeight:
                                  rimadster.id === current_user.id && "bold",
                              },
                              variant: "body2",
                            }}
                            primary={rimadster.email}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <Rimadster rimadster={rimadster} setRimadster={setRimadster} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  fetched: state.rimadsters.fetched,
  data: state.rimadsters.data,
  current_user: state.auth.user,
});

export default connect(mapStateToProps, {
  get_rimadsters,
  load_detailed_employee,
})(Rimadsters);
