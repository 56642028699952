import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
// import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";
import WebAppAccounts from "./WebAppAccounts";
import WebApps from "./WebApps";

import {
  get_webapps,
  get_webapp_accounts,
  clear_webapp_accounts,
  get_all_groups,
} from "../../../actions/procedure";

import Poppup from "../../../components/Poppup";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
  },
}));

const WebAppForm = ({
  processing,
  get_webapps,
  get_all_groups,
  webapps,
  get_webapp_accounts,
  clear_webapp_accounts,
}) => {
  const classes = useStyles();

  const [webapp, setWebApp] = useState(null);

  useEffect(() => {
    get_webapps();
    get_all_groups();
  }, [get_webapps, get_all_groups]);

  const handleChange = (webapp) => {
    if (webapp !== null) {
      get_webapp_accounts(webapp.id);
      setWebApp(webapp);
    } else {
      clear_webapp_accounts();
      setWebApp(null);
    }
  };

  return (
    <div className={classes.paper}>
      <Typography style={{ marginBottom: 40 }} component="h1" variant="h4">
        Websites / Apps
        <Poppup
          content={
            <Typography variant="body1">
              This page allows you to add and edit our online accounts. You can
              always also add and edit online accounts inside the appropriate
              request forms.
            </Typography>
          }
        />
      </Typography>
      <WebApps />
      <Typography
        style={{ marginTop: 40, marginBottom: 40 }}
        component="h1"
        variant="h4"
      >
        Accounts
      </Typography>
      <Autocomplete
        id="combo-box-demo"
        style={{ marginBottom: 20 }}
        options={webapps}
        getOptionLabel={(option) => `${option.name}`}
        value={webapp}
        getOptionSelected={(option, value) =>
          option.id === value.id ? option : null
        }
        onChange={(event, newValue) => {
          handleChange(newValue);
        }}
        fullWidth
        renderInput={(params) => (
          <TextField
            {...params}
            InputLabelProps={{ shrink: true }}
            label="Website / App"
            variant="outlined"
            placeholder="Search for a website or app"
          />
        )}
      />
      <WebAppAccounts webapp={webapp} setWebApp={setWebApp} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  processing: state.procedures.processing,
  webapps: state.procedures.webapps,
});

export default connect(mapStateToProps, {
  get_webapps,
  get_webapp_accounts,
  clear_webapp_accounts,
  get_all_groups,
})(WebAppForm);
