import axios from "axios";
import { SNACKBAR_WRITE } from "./procedureType";
import { GET_FORM_DATA_SUCCESS } from "./rimadstersType";
import { AUTHENTICATED_FAIL } from "./authTypes";

import global from "../global";
import Api from "./_shared";
const { API_URL } = global;

export const get_rimadsters = () => async (dispatch) => {
  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    try {
      const response = await axios.get(
        `${API_URL}/accounts/rimadsters/`,
        config
      );
      dispatch({
        type: GET_FORM_DATA_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "Unable to load request",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const load_detailed_employee = (id) => async (dispatch) => {
  const access = localStorage.getItem("access");
  if (access) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    try {
      const response = await axios.get(
        `${API_URL}/accounts/employee_detailed_view/${id}/`,
        config
      );

      return response.data;
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "Unable to load employee!",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const loadMailingLists =
  ({ page_number = 1 }) =>
  async (dispatch) => {
    try {
      const response = await Api().get(
        `/accounts/mailing_lists/?page=${page_number}`
      );

      const { results, page, next, count } = response.data;

      return { results, page, next: next ? page + 1 : null, count };
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "Unable to load mailing lists!",
          severity: "error",
        },
      });
      return null;
    }
  };

export const loadMailingList =
  ({ id }) =>
  async (dispatch) => {
    try {
      const response = await Api().get(`/accounts/mailing_lists/${id}/`);

      return response.data;
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "Unable to load mailing lists!",
          severity: "error",
        },
      });
      return null;
    }
  };
