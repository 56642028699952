import React from "react";
import { connect } from "react-redux";
import { weeklyKpisLoadEvaluations } from "../../../actions/weeklyKpis";
import { Grid, Typography } from "@material-ui/core";
import CustomSkeleton from "../../CustomInfiniteScroll/CustomSkeleton";
import WeeklyKpisEvaluation from "../WeeklyKpisEvaluation";
import getPerspective from "./getPerspective";
import CustomInfiniteScroll from "../../CustomInfiniteScroll";

const gridItemProps = { xs: 12, md: 6 };
const variant = "evaluation";
const status = "A";

const useEffectWeeklyKpisActiveEvaluations = ({
  weeklyKpisLoadEvaluations,
  id,
}) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const filterQuery = `&department_team__id=${id}`;

  React.useEffect(() => {
    setIsLoading(true);
    weeklyKpisLoadEvaluations({
      page_number: 1,
      filterQuery: filterQuery,
      status: status,
    }).then(() => setTimeout(() => setIsLoading(false), 500));
    return () => setIsLoading(false);
  }, [weeklyKpisLoadEvaluations, filterQuery]);

  return { isLoading, filterQuery };
};

const WeeklyKpisActiveEvaluations = ({
  department_team,
  weeklyKpisLoadEvaluations,
  active_evaluations = {},
  user,
}) => {
  const { id } = department_team;
  const { isLoading, filterQuery } = useEffectWeeklyKpisActiveEvaluations({
    weeklyKpisLoadEvaluations,
    id,
  });
  const { results, next, count } = active_evaluations;

  return isLoading ? (
    <CustomSkeleton
      variant={variant}
      count={2}
      gridItemProps={gridItemProps}
      style={{ justifyContent: "center" }}
    />
  ) : count === 0 ? (
    <Typography variant="body2" style={{ marginTop: 24 }}>
      {"Weekly evaluations not yet available"}
    </Typography>
  ) : (
    <React.Fragment>
      <Grid
        container
        spacing={3}
        style={{
          justifyContent: count === 1 ? "center" : "flex-start",
          marginBottom: 24,
        }}
      >
        {results.map((evaluation) => (
          <Grid item xs={12} md={6} key={evaluation.id}>
            <WeeklyKpisEvaluation
              evaluation={evaluation}
              perspective={getPerspective(
                user?.id,
                evaluation?._from.id,
                evaluation?._to?.id
              )}
            />
          </Grid>
        ))}
      </Grid>
      <CustomInfiniteScroll
        numResults={results.length}
        next={next}
        skeletonProps={{
          variant: variant,
          gridItemProps: gridItemProps,
        }}
        loadNext={() => {
          weeklyKpisLoadEvaluations({
            page_number: next,
            filterQuery: filterQuery,
            status: status,
          });
        }}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  department_team: state.weekly_kpis?.department_team,
  active_evaluations: state.weekly_kpis.active_evaluations,
});

export default connect(mapStateToProps, {
  weeklyKpisLoadEvaluations,
})(WeeklyKpisActiveEvaluations);
