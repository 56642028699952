import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { reset_password_confirm, checkAuthenticated } from "../../actions/auth";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  center: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
  },
}));

const ResetPasswordConfirm = ({
  isAuthenticated,
  isLoading,
  checkAuthenticated,
  match,
  reset_password_confirm,
}) => {
  useEffect(() => {
    checkAuthenticated();
  }, [checkAuthenticated]);
  const classes = useStyles();
  const [requestSent, setRequestSent] = useState(false);
  const [formData, setFormData] = useState({
    new_password: "",
    re_new_password: "",
  });
  const { new_password, re_new_password } = formData;
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    if (new_password === re_new_password) {
      const uid = match.params.uid;
      const token = match.params.token;

      reset_password_confirm(uid, token, new_password, re_new_password);
      setRequestSent(true);
    }
  };

  if (isLoading) {
    return (
      <div className={classes.center}>
        <CircularProgress style={{ margin: "auto" }} />
      </div>
    );
  }

  if (requestSent) {
    return <Redirect to="/login" />;
  }

  return isAuthenticated ? (
    <Redirect to="/app" />
  ) : (
    <div className="container mt-5">
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Forgot Your Password?
          </Typography>
          <form className={classes.form} onSubmit={(e) => onSubmit(e)}>
            <TextField
              variant="outlined"
              required
              fullWidth
              margin="normal"
              name="new_password"
              label="New Password"
              type="password"
              value={new_password}
              onChange={(e) => onChange(e)}
              minLength="6"
            />
            <TextField
              variant="outlined"
              required
              fullWidth
              margin="normal"
              name="re_new_password"
              label="Confirm New Password"
              type="password"
              value={re_new_password}
              onChange={(e) => onChange(e)}
              minLength="6"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Reset Password
            </Button>
          </form>
        </div>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isLoading: state.auth.isLoading,
});

export default connect(mapStateToProps, {
  reset_password_confirm,
  checkAuthenticated,
})(ResetPasswordConfirm);
