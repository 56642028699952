import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Typography from "@material-ui/core/Typography";

import { update_marketing_expense_request } from "../../../../actions/marketing_expenses";

import { Fragment } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "25ch",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
  },
  number: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
      {
        display: "none",
      },
  },
}));

const RequestInput = ({
  data,
  current_user,
  MERN,
  update_marketing_expense_request,
  marketing_supplies_requests,
  webapps,
}) => {
  const classes = useStyles();
  const [formData, setFormData] = useState(null);

  const [edit, setEdit] = useState(false);
  const [edited, setEdited] = useState(false);

  useEffect(() => {
    setFormData(data);
    setEdited(false);
  }, [setFormData, data, edit]);

  const handleDateChange = (date, prop) => {
    setFormData({ ...formData, [prop]: date });
    setEdited(true);
  };
  const handleChange = (prop) => (event) => {
    setFormData({ ...formData, [prop]: event.target.value });
    setEdited(true);
  };
  const handleChangeMSRN = (MSRN) => {
    setFormData({
      ...formData,
      inventory_requests: MSRN,
    });
    setEdited(true);
  };
  const handleChangeWebapps = (webapps) => {
    setFormData({
      ...formData,
      webapps: webapps,
    });
    setEdited(true);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    update_marketing_expense_request(formData, MERN);
    setEdit(false);
    setEdited(false);
  };

  const AllowedToEdit = () => {
    return (
      ["pending", "returned"].includes(data.approval_status.status) &&
      current_user === data.requester
    );
  };

  return (
    formData && (
      <Fragment>
        {data.approval_status.note && (
          <FormControl fullWidth>
            <TextField
              label="Approver's note"
              multiline
              InputProps={{ disableUnderline: true, readOnly: true }}
              value={data.approval_status.note}
            />
          </FormControl>
        )}
        <Typography
          style={{ marginBottom: 20, fontWeight: "bold", alignSelf: "start" }}
          variant="h5"
        >
          Paid marketing expense information
          {AllowedToEdit() && (
            <Button
              color="primary"
              style={{ fontWeight: "bold" }}
              onClick={() => {
                setEdit(!edit);
              }}
            >
              {edit ? "Cancel" : "Edit"}
            </Button>
          )}
        </Typography>

        {AllowedToEdit() && edit ? (
          <form style={{ width: "100%" }} onSubmit={(e) => onSubmit(e)}>
            <FormControl fullWidth variant="outlined" required>
              <InputLabel>Channel type</InputLabel>
              <Select
                value={formData.channel_type}
                onChange={handleChange("channel_type")}
                label="Channel type *"
              >
                <MenuItem value={"online"}>Online</MenuItem>
                <MenuItem value={"offline"}>Offline</MenuItem>
              </Select>
            </FormControl>
            {formData.channel_type === "offline" && (
              <div>
                <FormControl fullWidth>
                  <TextField
                    required
                    label="Conducted by"
                    onChange={handleChange("conducted_by")}
                    value={formData.conducted_by ? formData.conducted_by : ""}
                    variant="outlined"
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    required
                    label="Conducted at"
                    onChange={handleChange("conducted_at")}
                    value={formData.conducted_at ? formData.conducted_at : ""}
                    variant="outlined"
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    required
                    multiline
                    label="Actors"
                    onChange={handleChange("actors")}
                    value={formData.actors ? formData.actors : ""}
                    variant="outlined"
                  />
                </FormControl>
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  options={marketing_supplies_requests}
                  getOptionLabel={(option) => option}
                  value={
                    formData.inventory_requests
                      ? formData.inventory_requests
                      : []
                  }
                  onChange={(event, newValue) => {
                    handleChangeMSRN(newValue);
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Marketing supplies requests"
                      placeholder="Search for marketing supplies requests"
                    />
                  )}
                />
              </div>
            )}
            {formData.channel_type === "online" && (
              <Autocomplete
                multiple
                id="tags-outlined"
                options={webapps}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) =>
                  option.id === value.id ? option : null
                }
                value={formData.webapps ? formData.webapps : []}
                onChange={(event, newValue) => {
                  handleChangeWebapps(newValue);
                }}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Websites / Apps"
                    placeholder="Search for websites / apps"
                    required={
                      formData.webapps ? formData.webapps.length === 0 : true
                    }
                  />
                )}
              />
            )}
            <FormControl fullWidth variant="outlined" required>
              <InputLabel htmlFor="outlined-adornment-amount">
                Amount requested
              </InputLabel>
              <OutlinedInput
                label="Amount requested *"
                type="number"
                value={formData.amount_requested}
                onChange={handleChange("amount_requested")}
                startAdornment={
                  <InputAdornment position="start">QAR</InputAdornment>
                }
                className={classes.number}
                inputProps={{ min: 0, step: 0.01 }}
              />
            </FormControl>
            <FormControl fullWidth variant="outlined" required>
              <InputLabel>Payment method</InputLabel>
              <Select
                value={formData.payment_type}
                onChange={handleChange("payment_type")}
                label="Payment method *"
              >
                <MenuItem value={"Bank transfer"}>Bank transfer</MenuItem>
                <MenuItem value={"Cash"}>Cash</MenuItem>
                <MenuItem value={"Cheque"}>Cheque</MenuItem>
                <MenuItem value={"Credit card"}>Credit card</MenuItem>
                <MenuItem value={"Debit card"}>Debit card</MenuItem>
                <MenuItem value={"Credit application"}>
                  Credit application
                </MenuItem>
              </Select>
            </FormControl>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                fullWidth
                required
                inputVariant="outlined"
                label="Start date"
                format="dd / MM / yyyy"
                value={formData.start_date || null}
                onChange={(date) => handleDateChange(date, "start_date")}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                minDate={new Date()}
              />
            </MuiPickersUtilsProvider>
            {formData.start_date && (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  required
                  inputVariant="outlined"
                  label="End date"
                  format="dd / MM / yyyy"
                  value={formData.end_date || null}
                  onChange={(date) => handleDateChange(date, "end_date")}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  minDate={formData.start_date}
                />
              </MuiPickersUtilsProvider>
            )}
            <FormControl fullWidth>
              <TextField
                required
                multiline
                label="Justification"
                onChange={handleChange("justification")}
                value={formData.justification}
                variant="outlined"
              />
            </FormControl>
            <FormControl fullWidth>
              <Button
                disabled={
                  (formData.start_date !== null
                    ? formData.start_date.toString() === "Invalid Date"
                    : true) ||
                  (formData.end_date !== null
                    ? formData.end_date.toString() === "Invalid Date"
                    : false) ||
                  !edited ||
                  (formData.end_date !== null &&
                    formData.start_date > formData.end_date)
                }
                variant="contained"
                color="primary"
                type="submit"
              >
                Submit
              </Button>
            </FormControl>
          </form>
        ) : (
          <div>
            <FormControl fullWidth>
              <TextField
                label="Requester"
                value={data.requester_full_name}
                InputProps={{ disableUnderline: true, readOnly: true }}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                label="Channel type"
                value={formData.channel_type}
                InputProps={{ disableUnderline: true, readOnly: true }}
              />
            </FormControl>
            {formData.channel_type === "offline" && (
              <div>
                <FormControl fullWidth>
                  <TextField
                    label="Conducted by"
                    value={formData.conducted_by ? formData.conducted_by : ""}
                    InputProps={{ disableUnderline: true, readOnly: true }}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    label="Conducted at"
                    value={formData.conducted_at ? formData.conducted_at : ""}
                    InputProps={{ disableUnderline: true, readOnly: true }}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    multiline
                    label="Actors"
                    value={formData.actors ? formData.actors : ""}
                    InputProps={{ disableUnderline: true, readOnly: true }}
                  />
                </FormControl>
                {formData.inventory_requests.length > 0 && (
                  <FormControl fullWidth>
                    <TextField
                      multiline
                      label="Marketing supplies requests"
                      value={formData.inventory_requests.join(", ")}
                      InputProps={{ disableUnderline: true, readOnly: true }}
                    />
                  </FormControl>
                )}
              </div>
            )}
            {formData.channel_type === "online" && formData.webapps.length > 0 && (
              <FormControl fullWidth>
                <TextField
                  label="Websites / Apps"
                  value={
                    formData.webapps
                      ? formData.webapps.map((webapp) => webapp.name).join(", ")
                      : ""
                  }
                  InputProps={{ disableUnderline: true, readOnly: true }}
                />
              </FormControl>
            )}
            <FormControl fullWidth>
              <TextField
                label="Amount requested"
                value={`${formData.amount_requested} QAR`}
                InputProps={{ disableUnderline: true, readOnly: true }}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                multiline
                label="Payment method"
                value={formData.payment_type}
                InputProps={{ disableUnderline: true, readOnly: true }}
              />
            </FormControl>
            {formData.associated_payment_id && (
              <FormControl fullWidth>
                <TextField
                  multiline
                  label="Payment ID"
                  value={formData.associated_payment_id}
                  InputProps={{ disableUnderline: true, readOnly: true }}
                />
              </FormControl>
            )}
            <FormControl fullWidth>
              <TextField
                label="Start date"
                value={new Date(formData.start_date).toLocaleDateString(
                  "en-GB"
                )}
                InputProps={{ disableUnderline: true, readOnly: true }}
              />
            </FormControl>
            {formData.end_date && (
              <FormControl fullWidth>
                <TextField
                  label="End date"
                  value={new Date(formData.end_date).toLocaleDateString(
                    "en-GB"
                  )}
                  InputProps={{ disableUnderline: true, readOnly: true }}
                />
              </FormControl>
            )}
            <FormControl fullWidth>
              <TextField
                multiline
                label="Justification"
                value={formData.justification ? formData.justification : ""}
                InputProps={{ disableUnderline: true, readOnly: true }}
              />
            </FormControl>
          </div>
        )}
      </Fragment>
    )
  );
};

const mapStateToProps = (state) => ({
  submitting: state.marketing_expenses.submittingPaid,
  marketing_supplies_requests:
    state.marketing_expenses.marketing_supplies_requests,
  webapps: state.procedures.webapps,
});

export default connect(mapStateToProps, {
  update_marketing_expense_request,
})(RequestInput);
