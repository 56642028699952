import React from "react";
import { Skeleton } from "@material-ui/lab";
import { Grid } from "@material-ui/core";

const skeletonPropsVariantMapper = {
  default: {
    width: "100%",
    variant: "text",
  },
  card: {
    width: "100%",
    height: "140px",
    variant: "rect",
    style: { borderRadius: 10 },
  },
  evaluation: {
    height: "317px",
    width: "100%",
    variant: "rect",
    style: { borderRadius: 10 },
  },
  employee: {
    width: "100%",
    height: "96px",
    variant: "rect",
    style: { borderRadius: 10 },
  },
  team: {
    width: "100%",
    height: "96px",
    variant: "rect",
    style: { borderRadius: 10 },
  },
  history: {
    width: "100%",
    height: "236px",
    variant: "rect",
    style: { borderRadius: 10 },
  },
  chart: {
    width: "100%",
    height: "527px",
    variant: "rect",
    style: { borderRadius: 10 },
  },
  table: {
    width: "100%",
    height: "100px",
    variant: "rect",
  },
};

export default function CustomSkeleton({
  variant = "default",
  count = 1,
  gridItemProps = { xs: 12 },
  ...other
}) {
  const props = skeletonPropsVariantMapper[variant];

  return (
    <Grid container spacing={3} {...other}>
      {[...Array(count)].map((_, index) => (
        <Grid item key={index} {...gridItemProps}>
          <Skeleton animation="wave" {...props} />
        </Grid>
      ))}
    </Grid>
  );
}
