import React from "react";
import { Grid } from "@material-ui/core";
import WeeklyKpisTagFilter from "./WeeklyKpisTagFilter";
import WeeklyKpisDurationFilter from "./WeeklyKpisDurationFilter";

export default function WeeklyKpisFilters({
  AcceptableEngines = ["Departments", "Department teams", "Employees"],
}) {
  return (
    <Grid item xs={12}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <WeeklyKpisTagFilter AcceptableEngines={AcceptableEngines} />
        </Grid>
        <Grid item xs={12} md={4}>
          <WeeklyKpisDurationFilter />
        </Grid>
      </Grid>
    </Grid>
  );
}
