import React from "react";
import {Typography} from "@material-ui/core";

const Culture = () => {
  const mdspace = <div style={{padding: 24, width: "100%"}}/>;
  const smspace = <div style={{padding: 8, width: "100%"}}/>;

  return (
    <div style={{maxWidth: 600}}>
      <Typography variant="h3">Culture</Typography>
      {mdspace}
      <Typography variant="body1">
        In order for Avey to achieve its mission, it strives to hire the right
        people and provide them with a work environment that unlocks their
        potential, enables them to innovate, allows them to progress quickly,
        and drives their growth exponentially. At Avey, this work environment
        is created and cultivated through applying five major principles.
      </Typography>
      {mdspace}
      <Typography variant="h6">
        1. A team with a shared vision, common values, complementary skills, and
        diverse backgrounds
      </Typography>
      {smspace}
      <Typography variant="body1">
        Avey strives to recruit people who have a conviction on its story and
        a deep belief in its mission. Such a conviction and belief give a
        special meaning to work at Avey. In addition, Avey seeks certain
        values and characteristics in its hires, including integrity, passion,
        grit, acumen, and multi-dimensional knowledge.
      </Typography>
      {smspace}
      <Typography variant="body1">
        Moreover, Avey keeps its different teams small in numbers and
        complementary in skills so as to boost collaboration, streamline
        communication, concretize achievements, and make the whole of each team
        more than its sum.
      </Typography>
      {smspace}
      <Typography variant="body1">
        Lastly, Avey believes that a team-based culture cannot be realized
        without diversity and inclusion. As such, it deeply embraces diversity
        and inclusion to enrich the experience of Aveyators and give every one
        of them an equal opportunity to innovate, contribute, and blossom,
        irrespective of their gender, color, race, ethnicity, religion, and
        background.
      </Typography>
      {mdspace}
      <Typography variant="h6">2. Ideas over hierarchy</Typography>
      {smspace}
      <Typography variant="body1">
        Avey adopts a departmental and managerial structure that does not
        exceed more than two levels of indirections between any two Aveyators,
        irrespective of their positions. The aim of this structure is to
        organize and streamline processes, maintain agility, expedite
        decision-making, and accelerate execution.
      </Typography>
      {smspace}
      <Typography variant="body1">
        Yet, Avey is led by ideas more than hierarchies. In particular,
        Aveyators are constantly encouraged to voice their opinions and
        participate on all levels.
      </Typography>
      {smspace}
      <Typography variant="body1">
        Alongside, at the end of every quarter, Avey holds a company-wide
        meeting-of-the-minds, where the management transparently demonstrates
        the status of the company, solicits feedback, and presents its short-,
        mid-, and long-term plans.
      </Typography>
      {smspace}
      <Typography variant="body1">
        This philosophy is deeply enshrined in the Avey culture and
        communicated across all levels. To further concretize it, Avey has
        created a system for all Aveyators to submit monthly culture & company
        evaluations, observations, and suggestions for improvements.
      </Typography>
      {mdspace}
      <Typography variant="h6">
        3. The path to success goes through the struggles of failures
      </Typography>
      {smspace}
      <Typography variant="body1">
        Avey is built around experimenting and learning, without fearing
        failures. More precisely, Avey embraces the process notwithstanding
        the outcome. As such, it appreciates and rewards failures that come out
        of trials and hard work, let alone successes. It is this willingness to
        fail that provides Avey with the ability to succeed.
      </Typography>
      {mdspace}
      <Typography variant="h6">
        4. Attendance for innovation and flexibility for enablement
      </Typography>
      {smspace}
      <Typography variant="body1">
        Avey believes that when people are together, a critical blend of
        energy, serendipity, and innovation is created. Consequently, it invites
        its people to show up to work every working day and collaborate together
        as one solid, synergistic, and reliable team. In addition, Avey
        believes that trust is a lubricant that drives the cranks of its
        production engine and greases the right connections among its team
        members. To this end, it gives its people full autonomy on their
        schedules (i.e., Aveyators decide at what time they come to the office
        and at what time they leave every day), enabling thereby a trustworthy
        and adaptable work environment, which enhances creativity, increases
        productivity, and cultivates ownership & leadership.
      </Typography>
      {mdspace}
      <Typography variant="h6">
        5. A quality workspace for driving natural collaboration and creativity
        while increasing wellbeing, concentration, and confidentiality
      </Typography>
      {smspace}
      <Typography variant="body1">
        A human-centered workspace is core to any culture, let alone the culture
        of Avey. Avey believes that: (1) an open workspace can foster
        creativity and collaboration, (2) a private breakout space can guarantee
        concentration and protect confidentiality, (3) social and entertainment
        spaces can bridge gaps and promote new levels of interactions and
        friendships across different teams rather than only within teams, and
        (4) workout spaces can recharge bodies and boost energy and alertness.
        Consequently, Avey adopts a heterogeneous environment that attempts to
        fulfil these four different objectives. In addition, it employs
        comfortable ergonomics to reduce muscle fatigue. Lastly, it offers daily
        fresh and free fruits and vegetables in its offices to inculcate healthy
        lifestyles and advance wellbeing.
      </Typography>
    </div>
  );
};

export default Culture;
