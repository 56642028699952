import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import { Typography, Grid, Tooltip } from "@material-ui/core";
import LabeledCircularProgress from "../LabeledCircularProgress";
import { load_time_offs } from "../../../../actions/auth";
import { NavLink } from "react-router-dom";
import TimeOffHistoryTable from "./TimeOffHistoryTable";
import { CircularProgress } from "@material-ui/core";

const TimeOff = ({ time_offs, load_time_offs, current_user }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    load_time_offs().then(() => setIsLoading(false));
  }, [load_time_offs]);

  const balance = time_offs
    ? time_offs.map((time_off) => {
        return {
          ...time_off,
          date_start: new Date(time_off.date_start),
        };
      })
    : [];

  const csvReport = {
    data: time_offs,
    headers: [
      {
        key: "TMO",
        label: "Request number",
      },
      {
        key: "time_off_type",
        label: "Type",
      },
      {
        key: "status",
        label: "Status",
      },
      {
        key: "days_off",
        label: "Days",
      },
      {
        key: "date_start",
        label: "Start date",
      },
      {
        key: "date_end",
        label: "End date",
      },
    ],
    filename: `TimeOffHistory_${current_user.full_name}`,
  };

  const current_date = new Date();
  var start = new Date(current_user.contract_start_date);
  start.setFullYear(current_date.getFullYear());
  start > current_date && start.setFullYear(current_date.getFullYear() - 1);
  var end = new Date(start);
  end.setFullYear(start.getFullYear() + 1);

  const APL = balance
    .filter(
      (time_off) =>
        time_off.date_start >= start &&
        time_off.date_start < end &&
        time_off.status.toLowerCase() === "approved" &&
        time_off.time_off_type.toLowerCase() === "annual paid leave"
    )
    .reduce((previousValue, currentValue) => {
      return previousValue + currentValue.days_off;
    }, 0);

  const SL = balance
    .filter(
      (time_off) =>
        time_off.date_start >= start &&
        time_off.date_start < end &&
        time_off.status.toLowerCase() === "approved" &&
        time_off.time_off_type.toLowerCase() === "sick leave"
    )
    .reduce((previousValue, currentValue) => {
      return previousValue + currentValue.days_off;
    }, 0);

  const PL = balance
    .filter(
      (time_off) =>
        time_off.date_start >= start &&
        time_off.date_start < end &&
        time_off.status.toLowerCase() === "approved" &&
        time_off.time_off_type.toLowerCase() === "parental leave"
    )
    .reduce((previousValue, currentValue) => {
      return previousValue + currentValue.days_off;
    }, 0);

  const BL = balance
    .filter(
      (time_off) =>
        time_off.date_start >= start &&
        time_off.date_start < end &&
        time_off.status.toLowerCase() === "approved" &&
        time_off.time_off_type.toLowerCase() === "bereavement leave"
    )
    .reduce((previousValue, currentValue) => {
      return previousValue + currentValue.days_off;
    }, 0);

  const UL = balance
    .filter(
      (time_off) =>
        time_off.date_start >= start &&
        time_off.date_start < end &&
        time_off.status.toLowerCase() === "approved" &&
        time_off.time_off_type.toLowerCase() === "unpaid leave"
    )
    .reduce((previousValue, currentValue) => {
      return previousValue + currentValue.days_off;
    }, 0);

  return (
    <div>
      <div style={{ display: "flex" }}>
        <Typography variant="h6" style={{ fontWeight: "bold" }}>
          Time off balance
        </Typography>
        <div style={{ flexGrow: 1 }} />
        {isLoading ? (
          <CircularProgress size={20} />
        ) : (
          <Typography
            color="primary"
            component={NavLink}
            to="/app/forms/personal/time-off"
            target="_blank"
            variant="button"
          >
            Request time off
          </Typography>
        )}
      </div>

      {APL + SL + PL + BL + UL > 0 ? (
        <Grid container>
          {APL > 0 && (
            <Grid item md={4} xs={6}>
              <LabeledCircularProgress
                value={APL}
                denominator={18}
                title="Annual paid leave"
              />
            </Grid>
          )}
          {SL > 0 && (
            <Grid item md={4} xs={6}>
              <LabeledCircularProgress
                value={SL}
                denominator={10}
                title="Sick leave"
              />
            </Grid>
          )}
          {PL > 0 && (
            <Grid item md={4} xs={6}>
              <LabeledCircularProgress
                value={PL}
                denominator={current_user.gender === "F" ? 20 : 10}
                title={
                  current_user.gender === "F"
                    ? "Maternity leave"
                    : "Paternity leave"
                }
              />
            </Grid>
          )}
          {BL > 0 && (
            <Grid item md={4} xs={6}>
              <LabeledCircularProgress
                value={BL}
                denominator={3}
                title="Bereavement leave"
              />
            </Grid>
          )}
          {UL > 0 && (
            <Grid item md={4} xs={6}>
              <LabeledCircularProgress
                value={UL}
                denominator={0}
                title="Unpaid leave"
              />
            </Grid>
          )}
        </Grid>
      ) : (
        <div style={{ marginBottom: 20 }}>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            style={{ marginTop: 10 }}
          >
            Your time off balance will appear here
          </Typography>
          {/* <Typography
            variant="subtitle2"
            color="textSecondary"
            style={{ marginTop: 10 }}
          >
            You are eligible for <b>21</b> paid days off
          </Typography> */}
        </div>
      )}

      {(time_offs || []).length > 0 ? (
        <div>
          <div style={{ display: "flex", marginTop: 20 }}>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              History
            </Typography>
            <div style={{ flexGrow: 1 }} />
            <Tooltip title="Export table">
              <CSVLink {...csvReport}>
                <Typography color="primary" variant="button">
                  Download history
                </Typography>
              </CSVLink>
            </Tooltip>
          </div>

          <TimeOffHistoryTable rows={time_offs} hide_title={true} />
        </div>
      ) : (
        <div>
          <div style={{ display: "flex", marginTop: 20 }}>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              History
            </Typography>
          </div>
          <div>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              style={{ marginTop: 10 }}
            >
              Your time off history will appear here
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  current_user: state.auth.user,
  time_offs: state.auth.time_offs,
});

export default connect(mapStateToProps, {
  load_time_offs,
})(TimeOff);
