import React, { useState } from "react";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
// import AddProduct from "./AddProduct";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "../../../components/MuiDialogTitle";
import ExcelUpload from "../ExcelUpload";
import EditProduct from "./EditProduct";
import AddProduct from "./AddProduct";
import {
  transfer_products,
  get_supplier_products,
} from "../../../actions/procedure";
import { connect } from "react-redux";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Product",
  },
  {
    id: "product_type",
    numeric: true,
    disablePadding: false,
    label: "Product Type",
  },
  {
    id: "cost_unit_price",
    numeric: true,
    disablePadding: false,
    label: "Unit cost (QAR)",
  },
  {
    id: "selling_unit_price",
    numeric: true,
    disablePadding: false,
    label: "Unit price (QAR)",
  },
  { id: "margin", numeric: true, disablePadding: false, label: "Margin (%)" },
];

function EnhancedTableHead({
  classes,
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            style={{ fontWeight: "bold" }}
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.primary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.dark,
        },
  title: {
    flex: "0 1 100%",
  },
}));

const EnhancedTableToolbar = ({
  selected,
  numSelected,
  numProducts,
  setSelected,
  products,
  setProducts,
}) => {
  const classes = useToolbarStyles();
  const [openNewProduct, setOpenNewProduct] = useState(false);
  const [openEditProduct, setOpenEditProduct] = useState(false);
  const [openDeleteProduct, setOpenDeleteProduct] = useState(false);
  const first = products.find(
    (product) => product && product.id === selected[0]
  );

  const delete_products = (productIds) => {
    const temp = products;
    productIds.forEach((productId) => {
      delete temp[productId];
    });
    setProducts(temp);
  };

  const edit_product_locally = (edited_product) => {
    setProducts(
      products.map((product) =>
        product.id === edited_product.id ? edited_product : product
      )
    );
  };

  const add_product_locally = (new_product) => {
    const array1 = products.map((product) => product.id);
    const new_index = Math.max(...array1) + 1;
    setProducts([...products, { ...new_product, id: new_index }]);
  };

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Products to upload in batch
        </Typography>
      )}

      {numSelected > 0 ? (
        numSelected === 1 ? (
          <div style={{ display: "flex" }}>
            <Tooltip title="Edit product">
              <IconButton onClick={() => setOpenEditProduct(!openEditProduct)}>
                <CreateIcon />
              </IconButton>
            </Tooltip>
            {first && (
              <EditProduct
                open={openEditProduct}
                setOpen={setOpenEditProduct}
                product={first}
                setSelected={setSelected}
                edit_product_locally={edit_product_locally}
              />
            )}
            <Tooltip title="Remove selected product">
              <IconButton onClick={() => setOpenDeleteProduct(true)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        ) : (
          <Tooltip
            title={
              numSelected !== numProducts
                ? "Remove selected products"
                : "Remove all products"
            }
          >
            <IconButton onClick={() => setOpenDeleteProduct(true)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )
      ) : (
        <div style={{ display: "flex" }}>
          <Tooltip title="Add Product">
            <IconButton onClick={() => setOpenNewProduct(!openNewProduct)}>
              <AddIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Close Sheet">
            <IconButton onClick={() => setProducts([])}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
          <AddProduct
            open={openNewProduct}
            setOpen={setOpenNewProduct}
            add_product_locally={add_product_locally}
          />
        </div>
      )}

      <Dialog
        open={openDeleteProduct}
        onClose={() => setOpenDeleteProduct(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle
          style={{ cursor: "move" }}
          id="draggable-dialog-title"
          onClose={() => setOpenDeleteProduct(false)}
        >
          {numSelected > 1
            ? numSelected !== numProducts
              ? "Are you sure that you would like to remove these products? This action cannot be undone."
              : "Are you sure that you would like to remove all of this supplier's products? This action cannot be undone."
            : "Are you sure that you would like to remove this product? This action cannot be undone."}
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => setOpenDeleteProduct(false)}
            type="submit"
            fullWidth
            color="primary"
            variant="outlined"
            style={{
              borderRadius: 24,
              fontWeight: "bold",
            }}
          >
            No, go back
          </Button>
          <Button
            onClick={() => {
              delete_products(selected);
              setSelected([]);
              setOpenDeleteProduct(false);
            }}
            type="submit"
            fullWidth
            color="primary"
            variant="contained"
            style={{ borderRadius: 24 }}
          >
            Yes, I'm sure
          </Button>
        </DialogActions>
      </Dialog>
    </Toolbar>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },

  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

function createData(row, id, name_index, cp_index, rp_index, margin_index) {
  const name = row[name_index];
  const product_type = "retail";
  const cost_unit_price = parseFloat(row[cp_index]).toFixed(2);
  const selling_unit_price = parseFloat(row[rp_index]).toFixed(2);
  const margin = parseFloat(row[margin_index]).toFixed(2);
  const zero = 0;

  return {
    id: id,
    name: name,
    product_type: product_type,
    cost_unit_price:
      cost_unit_price === "NaN" ? zero.toFixed(2) : cost_unit_price,
    selling_unit_price:
      selling_unit_price === "NaN" ? zero.toFixed(2) : selling_unit_price,
    margin: margin === "NaN" ? zero.toFixed(2) : margin,
  };
}

function EnhancedTable({
  supplier_pk,
  transfer_products,
  get_supplier_products,
}) {
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [selected, setSelected] = useState([]);
  const [products, setProducts] = useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = products.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const Upload = (e) => {
    e.preventDefault();
    transfer_products(supplier_pk, products).then(() => {
      setProducts([]);
      get_supplier_products(supplier_pk);
    });
  };

  return (
    <div className={classes.root}>
      <ExcelUpload
        passProducts={(cols, rows) => {
          const temp = rows;
          var headers = temp.shift().map((c) => c.toLowerCase().trim());
          var name_index = headers.indexOf("name");
          var cp_index = headers.indexOf("cprice");
          var rp_index = headers.indexOf("rprice");
          var margin_index = headers.indexOf("pmargin");
          setProducts(
            temp.map((row, id) =>
              createData(row, id, name_index, cp_index, rp_index, margin_index)
            )
          );
        }}
      />

      {products.length > 0 && (
        <Paper className={classes.paper}>
          <EnhancedTableToolbar
            selected={selected}
            products={products}
            numSelected={selected.length}
            numProducts={products.length}
            // delete_product={delete_product}
            // delete_multiple_products={delete_multiple_products}
            setSelected={setSelected}
            setProducts={setProducts}
          />
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={products.length}
              />
              <TableBody>
                {stableSort(products, getComparator(order, orderBy)).map(
                  (row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell color="primary" padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.name}
                        </TableCell>
                        <TableCell align="right">{row.product_type}</TableCell>
                        <TableCell align="right">
                          {row.cost_unit_price}
                        </TableCell>
                        <TableCell align="right">
                          {row.selling_unit_price}
                        </TableCell>
                        <TableCell align="right">{row.margin}</TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <form style={{ marginTop: 20 }} onSubmit={(e) => Upload(e)}>
            <Button type="submit" fullWidth color="primary" variant="contained">
              Upload products
            </Button>
          </form>
        </Paper>
      )}
    </div>
  );
}

export default connect(null, { transfer_products, get_supplier_products })(
  EnhancedTable
);
