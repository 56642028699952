import React, { useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import TimeOffStepper from "./TimeOffStepper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import { get_time_off_request } from "../../../../actions/personal";
import CustomizeSnackbar from "../../../../components/CustomizeSnackbar";
import RequestInput from "./RequestInput";
import CancelRequest from "./CancelRequest";
import ApprovalStatus from "./ApprovalStatus";
import TimeOffHistoryTable from "../../../StaticPages/MyAccount/TimeOff/TimeOffHistoryTable";

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: 700,
  },
  center: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
  },
  cardheader: {
    paddingLeft: 0,
  },
}));

const TimeOffRequest = ({
  get_time_off_request,
  data,
  current_user,
  fetched,
  processing,
}) => {
  const classes = useStyles();
  let { TMO } = useParams();

  useEffect(() => {
    get_time_off_request(TMO);
  }, [TMO, get_time_off_request]);

  return fetched ? (
    data.requester === current_user ||
    data.approval_status.approvers.includes(current_user) ? (
      <div className={classes.paper}>
        <Typography style={{ marginBottom: 40, display: "flex" }} variant="h3">
          {`${data.TMO}`}
          <CancelRequest TMO={TMO} current_user={current_user} data={data} />
        </Typography>
        <TimeOffStepper data={data} />
        {processing ? (
          <div className={classes.center}>
            <CircularProgress style={{ margin: "auto" }} />
          </div>
        ) : (
          <Fragment>
            <ApprovalStatus TMO={TMO} current_user={current_user} data={data} />

            {data.history && (
              <div style={{ marginTop: 50, marginBottom: 50 }}>
                <TimeOffHistoryTable
                  contract_start_date={data.contract_start_date}
                  rows={data.history.filter((to) => to.TMO !== data.TMO)}
                  full_name={data.requester_full_name}
                />
              </div>
            )}

            <RequestInput current_user={current_user} TMO={TMO} data={data} />
          </Fragment>
        )}
      </div>
    ) : (
      <CustomizeSnackbar
        payload={{
          severity: "error",
          message: "Permission Denied",
        }}
      />
    )
  ) : (
    <div className={classes.center}>
      <CircularProgress style={{ margin: "auto" }} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  fetched: state.personal.fetched,
  processing: state.personal.submitting,
  data: state.personal.data,
  current_user: state.auth.user.username,
});

export default connect(mapStateToProps, { get_time_off_request })(
  TimeOffRequest
);
