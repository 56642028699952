import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";
import {
  delete_product,
  delete_multiple_products,
} from "../../../actions/procedure";
import { Fragment } from "react";
import AddProduct from "./AddProduct";
import EditProduct from "./EditProduct";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "../../../components/MuiDialogTitle";
import CallSplitIcon from "@material-ui/icons/CallSplit";
import TransferSupplier from "./TransferSupplier";
import ProductsToUpload from "./ProductsToUpload";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Product",
  },
  {
    id: "product_type",
    numeric: true,
    disablePadding: false,
    label: "Product Type",
  },
  {
    id: "cost_unit_price",
    numeric: true,
    disablePadding: false,
    label: "Unit cost (QAR)",
  },
  {
    id: "selling_unit_price",
    numeric: true,
    disablePadding: false,
    label: "Unit price (QAR)",
  },
  { id: "margin", numeric: true, disablePadding: false, label: "Margin (%)" },
];

function EnhancedTableHead({
  classes,
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            style={{ fontWeight: "bold" }}
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.primary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = ({
  selected,
  numSelected,
  numProducts,
  supplier,
  delete_product,
  delete_multiple_products,
  setSelected,
  supplier_products,
}) => {
  const classes = useToolbarStyles();
  const [openNewProduct, setOpenNewProduct] = useState(false);
  const [openEditProduct, setOpenEditProduct] = useState(false);
  const [openDeleteProduct, setOpenDeleteProduct] = useState(false);
  const [openTransferSupplier, setOpenTransferSupplier] = useState(false);
  const first = supplier_products.find((product) => product.id === selected[0]);

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
          style={{ textTransform: "capitalize" }}
        >
          {supplier.name}
        </Typography>
      )}

      {numSelected > 0 && (
        <Tooltip title="Change supplier">
          <IconButton onClick={() => setOpenTransferSupplier(true)}>
            <CallSplitIcon />
          </IconButton>
        </Tooltip>
      )}

      {numSelected > 0 ? (
        numSelected === 1 ? (
          <div style={{ display: "flex" }}>
            <Tooltip title="Edit product">
              <IconButton onClick={() => setOpenEditProduct(!openEditProduct)}>
                <CreateIcon />
              </IconButton>
            </Tooltip>
            {first && (
              <EditProduct
                open={openEditProduct}
                setOpen={setOpenEditProduct}
                product={first}
                supplier_pk={supplier.id}
                setSelected={setSelected}
              />
            )}
            <Tooltip title="Delete selected product">
              <IconButton onClick={() => setOpenDeleteProduct(true)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        ) : (
          <Tooltip
            title={
              numSelected !== numProducts
                ? "Delete selected products"
                : "Delete all products"
            }
          >
            <IconButton onClick={() => setOpenDeleteProduct(true)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )
      ) : (
        <div style={{ display: "flex" }}>
          <Tooltip title="Add Product">
            <IconButton onClick={() => setOpenNewProduct(!openNewProduct)}>
              <AddIcon />
            </IconButton>
          </Tooltip>
          <AddProduct
            open={openNewProduct}
            setOpen={setOpenNewProduct}
            supplier_pk={supplier.id}
          />
        </div>
      )}

      {openTransferSupplier && (
        <TransferSupplier
          openTransferSupplier={openTransferSupplier}
          setOpenTransferSupplier={setOpenTransferSupplier}
          selected_products={supplier_products.filter((product) =>
            selected.includes(product.id)
          )}
          current_supplier_id={supplier.id}
        />
      )}
      <Dialog
        open={openDeleteProduct}
        onClose={() => setOpenDeleteProduct(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle
          style={{ cursor: "move" }}
          id="draggable-dialog-title"
          onClose={() => setOpenDeleteProduct(false)}
        >
          {numSelected > 1
            ? numSelected !== numProducts
              ? "Are you sure that you would like to delete these products? This action cannot be undone."
              : "Are you sure that you would like to delete all of this supplier's products? This action cannot be undone."
            : "Are you sure that you would like to delete this product? This action cannot be undone."}
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => setOpenDeleteProduct(false)}
            type="submit"
            fullWidth
            color="primary"
            variant="outlined"
            style={{
              borderRadius: 24,
              fontWeight: "bold",
            }}
          >
            No, go back
          </Button>
          <Button
            onClick={() => {
              numSelected > 1
                ? numSelected !== numProducts
                  ? delete_multiple_products(0, selected)
                  : delete_multiple_products(supplier.id)
                : delete_product(selected[0]);
              setSelected([]);
              setOpenDeleteProduct(false);
            }}
            type="submit"
            fullWidth
            color="primary"
            variant="contained"
            style={{ borderRadius: 24 }}
          >
            Yes, I'm sure
          </Button>
        </DialogActions>
      </Dialog>
    </Toolbar>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },

  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

function EnhancedTable({
  suppliers,
  supplier_products,
  supplier,
  delete_product,
  delete_multiple_products,
  processing,
}) {
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(1);
  const [rowValues, setRowValues] = useState([]);

  useEffect(() => {
    setRowValues([
      1,
      5 < supplier_products.length && 5,
      10 < supplier_products.length && 10,
      25 < supplier_products.length && 25,
      50 < supplier_products.length && 50,
      100 < supplier_products.length && 100,
      supplier_products.length,
    ]);
    setRowsPerPage(supplier_products.length > 5 ? 5 : supplier_products.length);
    setSelected([]);
  }, [setSelected, supplier, setRowsPerPage, supplier_products, suppliers]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = supplier_products.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, supplier_products.length - page * rowsPerPage);

  return (
    supplier !== null && (
      <div className={classes.root}>
        <ProductsToUpload supplier_pk={supplier.id} />
        <Paper className={classes.paper}>
          <EnhancedTableToolbar
            selected={selected}
            supplier_products={supplier_products}
            numSelected={selected.length}
            numProducts={supplier_products.length}
            supplier={supplier}
            suppliers={suppliers}
            delete_product={delete_product}
            delete_multiple_products={delete_multiple_products}
            setSelected={setSelected}
          />
          <Fragment>
            {processing && <LinearProgress />}
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size="small"
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={supplier_products.length}
                />
                <TableBody>
                  {stableSort(supplier_products, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.id)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          <TableCell color="primary" padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row.name}
                          </TableCell>
                          <TableCell align="right">
                            {row.product_type}
                          </TableCell>
                          <TableCell align="right">
                            {row.cost_unit_price.toFixed(2)}
                          </TableCell>
                          <TableCell align="right">
                            {row.selling_unit_price &&
                              row.selling_unit_price.toFixed(2)}
                          </TableCell>
                          <TableCell align="right">
                            {row.margin && row.margin.toFixed(2)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 33 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={rowValues.filter((value) => value)}
              component="div"
              count={supplier_products.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Fragment>
        </Paper>
      </div>
    )
  );
}

const mapStateToProps = (state) => ({
  processing: state.procedures.processing,
  suppliers: state.procedures.suppliers,
  supplier_products: state.procedures.supplier_products,
});

export default connect(mapStateToProps, {
  delete_product,
  delete_multiple_products,
})(EnhancedTable);
