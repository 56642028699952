import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { loadQuarter } from "../../../actions/milestone";
import QuarterMonth from "./QuarterMonth";
import SelectedQuarterMonth from "./SelectedQuarterMonth";

function Quarter({ loadQuarter, quarter }) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [selectedQuarterMonth, setSelectedQuarterMonth] = React.useState(0);

  React.useEffect(() => {
    setIsLoading(true);
    loadQuarter().then((payload) => {
      const selectedIndex = payload?.months.findLastIndex(
        (month) => new Date() > new Date(month.month_start_date)
      );
      setSelectedQuarterMonth(selectedIndex === -1 ? 0 : selectedIndex);
      setIsLoading(false);
    });
  }, [loadQuarter]);

  if (!Boolean(quarter) || isLoading) {
    return <div style={{ minHeight: 20 }} />;
  }

  const { months = [] } = quarter;

  if (months.length === 0) {
    return <div style={{ minHeight: 20 }} />;
  }

  const selectedMonth = months[selectedQuarterMonth];

  return (
    <Grid container spacing={2} style={{ marginBottom: 80 }}>
      <Grid item xs={12}>
        <Typography variant="h4" style={{ fontWeight: "bold" }}>
          {"Company objectives"}
        </Typography>
      </Grid>

      {months.map((quarter_month, index) => (
        <QuarterMonth
          quarter_month={quarter_month}
          key={index}
          index={index}
          onSelectMonth={() => setSelectedQuarterMonth(index)}
          isSelected={index === selectedQuarterMonth}
        />
      ))}

      <Grid item xs={12}>
        <SelectedQuarterMonth quarter_month={selectedMonth} />
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  quarter: state.milestone.quarter,
});

export default connect(mapStateToProps, { loadQuarter })(Quarter);
