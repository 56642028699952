import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { NavLink } from "react-router-dom";

export default function TimeOffHistoryTable({
  rows,
  contract_start_date,
  hide_title,
  full_name = "Rimadster",
}) {
  const current_date = new Date();
  var start = new Date(contract_start_date);
  start.setFullYear(current_date.getFullYear());
  start > current_date && start.setFullYear(current_date.getFullYear() - 1);
  var end = new Date(start);
  end.setFullYear(start.getFullYear() + 1);

  const time_offs = contract_start_date
    ? rows.filter(
        (time_off) =>
          new Date(time_off.date_start) >= start &&
          new Date(time_off.date_start) < end
      )
    : rows;

  return (
    <div>
      {!hide_title && (
        <Typography
          style={{
            marginBottom: 20,
            fontWeight: "bold",
            alignSelf: "start",
          }}
          variant="h5"
        >
          Time off History
        </Typography>
      )}
      {time_offs.length > 0 ? (
        <Table>
          <TableHead>
            <TableRow>
              {!hide_title && (
                <TableCell style={{ paddingLeft: 0, color: "#A2A2A2" }}>
                  Request number
                </TableCell>
              )}
              <TableCell style={{ color: "#A2A2A2" }}>Type</TableCell>
              <TableCell style={{ color: "#A2A2A2" }}>Status</TableCell>
              <TableCell style={{ paddingRight: 0, color: "#A2A2A2" }}>
                # Days
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {time_offs.map((row) => (
              <TableRow key={row.TMO}>
                {!hide_title && (
                  <TableCell style={{ paddingLeft: 0, fontWeight: "bold" }}>
                    <NavLink
                      to={{
                        pathname: `/app/forms/personal/time-off/${row.TMO}/`,
                      }}
                    >
                      {row.TMO}
                    </NavLink>
                  </TableCell>
                )}
                <TableCell>{row.time_off_type}</TableCell>
                <TableCell>
                  {row.status.replace(/\b\w/g, (l) => l.toUpperCase())}
                </TableCell>
                <TableCell style={{ paddingRight: 0 }}>
                  {row.days_off}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <div>
          <Typography variant="subtitle2" color="textSecondary">
            {full_name} has no previous time offs{" "}
            {contract_start_date &&
              `since ${start.toLocaleDateString("en-GB")}`}
          </Typography>
        </div>
      )}
    </div>
  );
}
