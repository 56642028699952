import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import { FormControl, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { update_procurement_product } from "../../../actions/procurement";
import { connect } from "react-redux";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";

import DialogTitle from "../../../components/MuiDialogTitle";

const useStyles = makeStyles((theme) => ({
  number: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none",
    },
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function EditProduct({ open, setOpen, order, update_procurement_product }) {
  const classes = useStyles();
  const handleClose = () => {
    setOpen(false);
  };

  const [formData, setFormData] = useState({
    id: order.pk,
    received_quantity: order.received_quantity || order.requested_quantity,
    unfit_quantity: order.unfit_quantity || 0,
    reason_for_unfit: order.reason_for_unfit || "",
    delivery_date: order.delivery_date || new Date(),
    responsible_staff: order.responsible_staff,
  });

  const handleChange = (prop) => (event) => {
    setFormData({
      ...formData,
      [prop]: event.target.value,
    });
  };

  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      delivery_date: date,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    update_procurement_product(formData);
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        fullWidth
      >
        <DialogTitle
          style={{ cursor: "move" }}
          id="draggable-dialog-title"
          onClose={handleClose}
        >
          Update order information
        </DialogTitle>
        <form onSubmit={(e) => onSubmit(e)}>
          <DialogContent>
            <FormControl variant="outlined" fullWidth required>
              <InputLabel>Received quantity</InputLabel>
              <OutlinedInput
                label="Received quantity *"
                type="number"
                value={formData.received_quantity}
                onChange={handleChange("received_quantity")}
                className={classes.number}
                inputProps={{
                  pattern: "[0-9]*",
                  min: 0,
                  max: order.requested_quantity - formData.unfit_quantity,
                  step: 1,
                }}
              />
            </FormControl>
            <FormControl variant="outlined" fullWidth required>
              <InputLabel>Unfit quantity</InputLabel>
              <OutlinedInput
                label="Unfit quantity*"
                type="number"
                value={formData.unfit_quantity}
                onChange={handleChange("unfit_quantity")}
                className={classes.number}
                inputProps={{
                  pattern: "[0-9]*",
                  min: 0,
                  max: order.requested_quantity - formData.received_quantity,
                  step: 1,
                }}
              />
            </FormControl>
            <FormControl variant="outlined" fullWidth>
              <TextField
                required={formData.unfit_quantity > 0}
                label="Reason for unfit"
                variant="outlined"
                multiline
                value={formData.reason_for_unfit}
                onChange={handleChange("reason_for_unfit")}
              />
            </FormControl>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                fullWidth
                required
                inputVariant="outlined"
                label="Delivery date"
                format="dd / MM / yyyy"
                value={formData.delivery_date || null}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                minDate={new Date()}
              />
            </MuiPickersUtilsProvider>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              fullWidth
              color="primary"
              variant="outlined"
              style={{ borderRadius: 24 }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              fullWidth
              color="primary"
              variant="contained"
              style={{ borderRadius: 24 }}
            >
              Update
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

// const mapStateToProps = (state) => ({
//   processing: state.procedures.processing,
// });

export default connect(null, { update_procurement_product })(EditProduct);
