import React from "react";
import {
  Collapse,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  TableCell,
  TableContainer,
  Typography,
  TableRow,
  Table,
  Avatar,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { connect } from "react-redux";
import { loadMailingList } from "../../../actions/rimadsters";
import CustomCircularProgress from "../../../components/CustomCircularProgress";

const MailingGroup = ({ id, handleClose, loadMailingList }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [data, setData] = React.useState(null);
  const isOpen = Boolean(id);

  React.useEffect(() => {
    setIsLoading(true);
    if (id) {
      loadMailingList({ id }).then((payload) => {
        setIsLoading(false);
        setData(payload);
      });
    }
  }, [id, loadMailingList]);

  const { name, description, members = [] } = data || {};
  const hasNoMembers = members.length === 0;

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="sm">
      <div>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>

      <DialogContent style={{ padding: "24px 48px" }}>
        <CustomCircularProgress open={isLoading} />
        <Collapse in={Boolean(name)}>
          <div>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h5" style={{ fontWeight: "bold" }}>
                  {name}
                </Typography>
                <Typography>{description}</Typography>
              </Grid>
              <Grid item xs={12}>
                {hasNoMembers ? (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ fontWeight: "bold" }}
                  >
                    {"There are no members in this mailing list."}
                  </Typography>
                ) : (
                  <Typography variant="body2" style={{ fontWeight: "bold" }}>
                    {"Here is the list of all aveyators in this email group"}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                {!hasNoMembers && (
                  <TableContainer style={{ borderTop: "1px solid #e2e2e2" }}>
                    <Table>
                      {members.map(({ id, full_name, email, picture }) => (
                        <TableRow key={id} style={{ height: "80px" }}>
                          <TableCell>
                            <Avatar src={picture} alt={full_name}></Avatar>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2">{full_name}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2">{email}</Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </Table>
                  </TableContainer>
                )}
              </Grid>
            </Grid>
          </div>
        </Collapse>
      </DialogContent>
    </Dialog>
  );
};

export default connect(null, { loadMailingList })(MailingGroup);
