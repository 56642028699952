import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";
import {
  delete_account,
  delete_multiple_accounts,
} from "../../../actions/procedure";
import { Fragment } from "react";
import AddWebAppAccount from "./AddWebAppAccount";
import EditWebAppAccount from "./EditWebAppAccount";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";

import DialogTitle from "../../../components/MuiDialogTitle";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "username",
    numeric: false,
    disablePadding: true,
    label: "Username",
  },
  {
    id: "password",
    numeric: true,
    disablePadding: false,
    label: "Password",
  },
  {
    id: "account_type",
    numeric: true,
    disablePadding: false,
    label: "Account type",
  },
  { id: "url", numeric: true, disablePadding: false, label: "URL" },
  {
    id: "backup_email",
    numeric: true,
    disablePadding: false,
    label: "Backup email",
  },
  {
    id: "phone_number",
    numeric: true,
    disablePadding: false,
    label: "Phone number",
  },
];

function EnhancedTableHead({
  classes,
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            style={{ fontWeight: "bold" }}
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.primary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = ({
  selected,
  numSelected,
  numAccounts,
  webapp,
  delete_account,
  delete_multiple_accounts,
  setSelected,
  webapp_accounts,
}) => {
  const classes = useToolbarStyles();
  const [openNewWebappAccount, setOpenNewWebappAccount] = useState(false);
  const [openEditWebappAccount, setOpenEditWebappAccount] = useState(false);
  const [openDeleteAccount, setOpenDeleteAccount] = useState(false);

  const first = webapp_accounts.find((account) => account.id === selected[0]);

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
          style={{ textTransform: "capitalize" }}
        >
          {webapp.name}
        </Typography>
      )}

      {numSelected > 0 ? (
        numSelected === 1 ? (
          <div style={{ display: "flex" }}>
            <Tooltip title="Edit webapp account">
              <IconButton
                onClick={() => setOpenEditWebappAccount(!openEditWebappAccount)}
              >
                <CreateIcon />
              </IconButton>
            </Tooltip>
            {first && (
              <EditWebAppAccount
                open={openEditWebappAccount}
                setOpen={setOpenEditWebappAccount}
                account={first}
                webapp_pk={webapp.id}
                setSelected={setSelected}
                setAccount={() => {}}
              />
            )}
            <Tooltip title="Delete selected webapp account">
              <IconButton onClick={() => setOpenDeleteAccount(true)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        ) : (
          <Tooltip
            title={
              numSelected !== numAccounts
                ? "Delete selected accounts"
                : "Delete all accounts"
            }
          >
            <IconButton onClick={() => setOpenDeleteAccount(true)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )
      ) : (
        <div style={{ display: "flex" }}>
          <Tooltip title="Add account">
            <IconButton
              onClick={() => setOpenNewWebappAccount(!openNewWebappAccount)}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
          <AddWebAppAccount
            open={openNewWebappAccount}
            setOpen={setOpenNewWebappAccount}
            webapp_pk={webapp.id}
          />
        </div>
      )}

      <Dialog
        open={openDeleteAccount}
        onClose={() => setOpenDeleteAccount(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle onClose={() => setOpenDeleteAccount(false)}>
          {numSelected > 1
            ? numSelected !== numAccounts
              ? "Are you sure that you would like to delete these online accounts? This action cannot be undone."
              : "Are you sure that you would like to delete all of this website / app's online user accounts? This action cannot be undone."
            : "Are you sure that you would like to delete this online user account? This action cannot be undone."}
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => setOpenDeleteAccount(false)}
            type="submit"
            fullWidth
            color="primary"
            variant="outlined"
            style={{
              borderRadius: 24,
              fontWeight: "bold",
            }}
          >
            No, go back
          </Button>
          <Button
            onClick={() => {
              numSelected > 1
                ? numSelected !== numAccounts
                  ? delete_multiple_accounts(0, selected)
                  : delete_multiple_accounts(webapp.id)
                : delete_account(selected[0]);
              setSelected([]);
              setOpenDeleteAccount(false);
            }}
            type="submit"
            fullWidth
            color="primary"
            variant="contained"
            style={{ borderRadius: 24 }}
          >
            Yes, I'm sure
          </Button>
        </DialogActions>
      </Dialog>
    </Toolbar>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },

  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

function EnhancedTable({
  webapps,
  webapp_accounts,
  webapp,
  delete_webapp,
  delete_account,
  delete_multiple_accounts,
}) {
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(1);
  const [rowValues, setRowValues] = useState([]);

  useEffect(() => {
    setRowValues([
      1,
      5 < webapp_accounts.length && 5,
      10 < webapp_accounts.length && 10,
      25 < webapp_accounts.length && 25,
      50 < webapp_accounts.length && 50,
      100 < webapp_accounts.length && 100,
      webapp_accounts.length,
    ]);
    setRowsPerPage(webapp_accounts.length > 5 ? 5 : webapp_accounts.length);
    setSelected([]);
  }, [setSelected, webapp, setRowsPerPage, webapp_accounts, webapps]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = webapp_accounts.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, webapp_accounts.length - page * rowsPerPage);

  return (
    webapp !== null && (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <EnhancedTableToolbar
            selected={selected}
            webapp_accounts={webapp_accounts}
            numSelected={selected.length}
            numAccounts={webapp_accounts.length}
            webapp={webapp}
            delete_webapp={delete_webapp}
            delete_account={delete_account}
            delete_multiple_accounts={delete_multiple_accounts}
            setSelected={setSelected}
          />

          <Fragment>
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size="small"
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={webapp_accounts.length}
                />
                <TableBody>
                  {stableSort(webapp_accounts, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.id)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          <TableCell color="primary" padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row.username}
                          </TableCell>
                          <TableCell align="right">{row.password}</TableCell>
                          <TableCell align="right">
                            {row.account_type}
                          </TableCell>
                          <TableCell align="right">{row.url}</TableCell>
                          <TableCell align="right">
                            {row.backup_email}
                          </TableCell>
                          <TableCell align="right">
                            {row.phone_number}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 33 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={rowValues.filter((value) => value)}
              component="div"
              count={webapp_accounts.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Fragment>
        </Paper>
      </div>
    )
  );
}

const mapStateToProps = (state) => ({
  webapps: state.procedures.webapps,
  webapp_accounts: state.procedures.webapp_accounts,
});

export default connect(mapStateToProps, {
  delete_account,
  delete_multiple_accounts,
})(EnhancedTable);
