import React, { Fragment, useEffect, useState } from "react";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import AddIcon from "@material-ui/icons/Add";
import CreateIcon from "@material-ui/icons/Create";
import EditProcurementProduct from "./EditProcurementProduct";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import LinearProgress from "@material-ui/core/LinearProgress";
import Draggable from "react-draggable";
import InputAdornment from "@material-ui/core/InputAdornment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import RefreshIcon from "@material-ui/icons/Refresh";
import { CSVLink } from "react-csv";
import GetAppIcon from "@material-ui/icons/GetApp";

import {
  get_supplier_products,
  clear_supplier_products,
  remove_product,
  add_back_product,
  add_back_products,
} from "../../../actions/procedure";
import AddSupplier from "../Suppliers/AddSupplier";
import AddProduct from "../Suppliers/AddProduct";
import EditSupplier from "../Suppliers/EditSupplier";

import DividerWithText from "../../../components/DividerWithCenteredText";
import DialogTitle from "../../../components/MuiDialogTitle";
import { TableContainer } from "@material-ui/core";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Product",
  },
  {
    id: "product_type",
    numeric: true,
    disablePadding: false,
    label: "Product type",
  },
  {
    id: "cost_unit_price",
    numeric: true,
    disablePadding: false,
    label: "Total Cost (QAR)",
  },
  {
    id: "selling_unit_price",
    numeric: true,
    disablePadding: false,
    label: "Total Retail (QAR)",
  },
  { id: "margin", numeric: true, disablePadding: false, label: "Margin (%)" },
  {
    id: "requested_quantity",
    numeric: true,
    disablePadding: false,
    label: "Quantity (#)",
  },
];

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },

  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  number: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
      {
        display: "none",
      },
  },
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.primary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
  dividerFullWidth: {
    marginTop: theme.spacing(3),
  },
}));

const headers = [
  {
    key: "name",
    label: "Product",
  },
  {
    key: "cost_unit_price",
    label: "Cost (QAR)",
  },
  {
    key: "selling_unit_price",
    label: "Retail (QAR)",
  },
  {
    key: "requested_quantity",
    label: "Quantity",
  },
];

function EnhancedTable({
  suppliers,
  allSupplierProducts,
  supplier_products,
  formData,
  setFormData,
  get_supplier_products,
  clear_supplier_products,
  remove_product,
  add_back_product,
  add_back_products,
  setTotalProductPrice,
  setNext,
  processingAddingProduct,
  IRN,
}) {
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [selected, setSelected] = useState([]);
  const [openProduct, setOpenProduct] = useState(false);
  const [activeProduct, setActiveProduct] = useState(null);
  const [activeQuantity, setAtiveQuantity] = useState("");
  const [openEditProduct, setOpenEditProduct] = useState(false);

  const [openNewSupplier, setOpenNewSupplier] = useState(false);
  const [openNewProduct, setOpenNewProduct] = useState(false);
  const [openEditSupplier, setOpenEditSupplier] = useState(false);
  const [openRemoveProduct, setOpenRemoveProduct] = useState(false);

  const numSelected = selected.length;
  const numProducts = supplier_products.length;
  const totalPrice = supplier_products.reduce(
    (currentTotalPrice, product) =>
      currentTotalPrice + product.requested_quantity * product.cost_unit_price,
    0
  );

  useEffect(() => {
    setTotalProductPrice(totalPrice);
  }, [setTotalProductPrice, totalPrice]);

  useEffect(() => {
    setSelected([]);
  }, [setSelected, formData.supplier]);

  const handleSupplierChange = (supplier) => {
    if (supplier !== null) {
      get_supplier_products(supplier.id);
    } else {
      clear_supplier_products();
    }
    setAtiveQuantity("");
    setActiveProduct(null);
    setFormData({
      ...formData,
      supplier: supplier,
      products: {},
    });
    setNext(false);
  };
  const removeProduct = (productId) => {
    const temp = formData.products;
    add_back_product({
      product: temp[productId],
    });
    delete temp[productId];
    setFormData({
      ...formData,
      products: { ...temp },
    });
    setSelected([]);
    Object.keys(temp).length === 0 && setNext(false);
  };
  const removeProducts = (productIds) => {
    const temp = formData.products;
    add_back_products({
      products: productIds.map((productId) => formData.products[productId]),
    });
    productIds.forEach((productId) => {
      delete temp[productId];
    });
    setFormData({
      ...formData,
      products: { ...temp },
    });
    setSelected([]);
  };
  const removeAllProducts = (productIds) => {
    add_back_products({
      products: productIds.map((productId) => formData.products[productId]),
    });
    setFormData({
      ...formData,
      products: {},
    });
    setSelected([]);
    setNext(false);
  };
  const handleProductChange = (product) => {
    setActiveProduct(product);
  };
  const handleQuantityChange = (e) => {
    setAtiveQuantity(e.target.value ? parseInt(e.target.value) : "");
  };

  const registerProduct = (editedProduct, newQuantity) => {
    if (editedProduct) {
      if (newQuantity) {
        setFormData({
          ...formData,
          products: {
            ...formData.products,
            [editedProduct.id]: {
              ...editedProduct,
              requested_quantity: newQuantity,
            },
          },
        });
        remove_product(editedProduct.id);
      } else {
        var temp = formData.products;
        delete temp[activeProduct.id];

        setFormData({
          ...formData,
          products: { ...temp, [editedProduct.id]: editedProduct },
        });

        remove_product(activeProduct.id);
        setAtiveQuantity("");
        setActiveProduct(null);
        setOpenProduct(false);
      }
    } else {
      setFormData({
        ...formData,
        products: {
          ...formData.products,
          [activeProduct.id]: {
            ...activeProduct,
            requested_quantity: activeQuantity,
          },
        },
      });
      remove_product(activeProduct.id);
      setAtiveQuantity("");
      setActiveProduct(null);
      setOpenProduct(false);
    }
    setNext(true);
  };
  const handleCloseDialog = () => {
    setOpenProduct(false);
    setActiveProduct(null);
    setAtiveQuantity("");
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = supplier_products.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleClickRow = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === numSelected - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const createSortHandler = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const isSelected = (id) => selected.indexOf(id) !== -1;

  const onSubmit = (e) => {
    e.preventDefault();
    registerProduct();
    setNext(true);
  };

  const csvReport = {
    data: Object.values(formData.products),
    headers: headers,
    filename: formData.supplier && `${formData.supplier.name}_${IRN || ""}`,
  };

  return (
    <div>
      <div style={{ display: "flex" }}>
        <Autocomplete
          required
          options={suppliers}
          getOptionLabel={(option) =>
            `${option.name} (${option.supplier_type})`
          }
          onChange={(event, newValue) => {
            handleSupplierChange(newValue);
          }}
          getOptionSelected={(option, value) =>
            option.id === value.id ? option : null
          }
          fullWidth
          value={formData.supplier}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              InputLabelProps={{ shrink: true }}
              label="Supplier"
              variant="outlined"
              placeholder="Search for supplier"
            />
          )}
        />
        {formData.supplier !== null ? (
          <div>
            <Tooltip title="Edit Supplier">
              <IconButton
                onClick={() => setOpenEditSupplier(!openEditSupplier)}
              >
                <CreateIcon />
              </IconButton>
            </Tooltip>
            <EditSupplier
              open={openEditSupplier}
              setOpen={setOpenEditSupplier}
              supplier={formData.supplier}
              setSupplier={(supplier) =>
                setFormData({ ...formData, supplier: supplier })
              }
            />
          </div>
        ) : (
          <div>
            <Tooltip title="Create a new supplier">
              <IconButton onClick={() => setOpenNewSupplier(!openNewSupplier)}>
                <AddIcon />
              </IconButton>
            </Tooltip>
            <AddSupplier
              open={openNewSupplier}
              setOpen={setOpenNewSupplier}
              setSupplier={(supplier) =>
                setFormData({ ...formData, supplier: supplier })
              }
            />
          </div>
        )}
      </div>
      <TableContainer component={Paper}>
        {processingAddingProduct && <LinearProgress />}
        {formData.supplier !== null && (
          <Toolbar
            className={clsx(classes.root, {
              [classes.highlight]: numSelected > 0,
            })}
          >
            <Typography
              className={classes.title}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {numSelected > 0 ? `${numSelected} selected` : "Add a product"}
            </Typography>

            {numSelected > 0 ? (
              <div>
                {numSelected === 1 ? (
                  <div style={{ display: "flex" }}>
                    <Tooltip title="Edit product">
                      <IconButton
                        onClick={() => setOpenEditProduct(!openEditProduct)}
                      >
                        <CreateIcon />
                      </IconButton>
                    </Tooltip>
                    {openEditProduct && (
                      <EditProcurementProduct
                        supplier_pk={formData.supplier.id}
                        requested_quantity={activeQuantity}
                        setProduct={setActiveProduct}
                        activeQuantity={activeQuantity}
                        open={openEditProduct}
                        setOpen={setOpenEditProduct}
                        product={supplier_products.find(
                          (product) => product.id === selected[0]
                        )}
                        setSelected={setSelected}
                        registerProduct={registerProduct}
                      />
                    )}
                    <Tooltip title="Remove selected product">
                      <IconButton
                        onClick={
                          () => setOpenRemoveProduct(true)
                          //{removeProduct(selected[0])}
                        }
                      >
                        <RemoveCircleIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                ) : (
                  <Tooltip
                    title={
                      numSelected !== numProducts
                        ? "Remove selected products"
                        : "Remove all products"
                    }
                  >
                    <IconButton onClick={() => setOpenRemoveProduct(true)}>
                      <RemoveCircleIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <Dialog
                  open={openRemoveProduct}
                  onClose={() => setOpenRemoveProduct(false)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth
                >
                  <DialogTitle onClose={() => setOpenRemoveProduct(false)}>
                    {numSelected > 1
                      ? numSelected !== numProducts
                        ? "Are you sure that you would like to remove these products?"
                        : "Are you sure that you would like to remove all of these products?"
                      : "Are you sure that you would like to remove this product?"}
                  </DialogTitle>
                  <DialogActions style={{ padding: 24 }}>
                    <Button
                      onClick={() => setOpenRemoveProduct(false)}
                      type="submit"
                      fullWidth
                      color="primary"
                      variant="outlined"
                      style={{
                        borderRadius: 24,
                        fontWeight: "bold",
                      }}
                    >
                      No, go back
                    </Button>
                    <Button
                      onClick={() => {
                        numSelected > 1
                          ? numSelected !== numProducts
                            ? removeProducts(selected)
                            : removeAllProducts(selected)
                          : removeProduct(selected[0]);
                        setSelected([]);
                        setOpenRemoveProduct(false);
                      }}
                      type="submit"
                      fullWidth
                      color="primary"
                      variant="contained"
                      style={{ borderRadius: 24 }}
                    >
                      Yes, I'm sure
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            ) : (
              <div style={{ display: "flex" }}>
                <Tooltip title="Export table">
                  <CSVLink {...csvReport}>
                    <IconButton>
                      <GetAppIcon />
                    </IconButton>
                  </CSVLink>
                </Tooltip>
                <Tooltip title="Add a product">
                  <IconButton onClick={() => setOpenProduct(!openProduct)}>
                    <AddIcon />
                  </IconButton>
                </Tooltip>
                {openProduct &&
                  (openNewProduct ? (
                    <AddProduct
                      open={openNewProduct}
                      setOpen={setOpenNewProduct}
                      supplier_pk={formData.supplier.id}
                      procurementFormProduct={true}
                      registerProduct={registerProduct}
                      setOpenProduct={setOpenProduct}
                    />
                  ) : (
                    <Dialog
                      open={openProduct}
                      onClose={handleCloseDialog}
                      PaperComponent={PaperComponent}
                      aria-labelledby="draggable-dialog-title"
                      fullWidth
                    >
                      <DialogTitle
                        style={{ cursor: "move" }}
                        id="draggable-dialog-title"
                        onClose={handleCloseDialog}
                      >
                        {activeProduct
                          ? activeProduct.name
                          : "Procure an existing product"}
                      </DialogTitle>

                      <form onSubmit={(e) => onSubmit(e)}>
                        <DialogContent>
                          {activeProduct ? (
                            <Fragment>
                              <FormControl fullWidth>
                                <InputLabel>Product type</InputLabel>
                                <Input
                                  disableUnderline={true}
                                  value={activeProduct.product_type}
                                  inputProps={{ readOnly: true }}
                                />
                              </FormControl>
                              <FormControl fullWidth>
                                <InputLabel>
                                  {activeQuantity
                                    ? "Total Cost"
                                    : "Cost / unit"}
                                </InputLabel>
                                <Input
                                  disableUnderline={true}
                                  type="number"
                                  value={
                                    activeQuantity
                                      ? (
                                          activeProduct.cost_unit_price *
                                          activeQuantity
                                        ).toFixed(2)
                                      : activeProduct.cost_unit_price.toFixed(2)
                                  }
                                  startAdornment={
                                    <InputAdornment position="start">
                                      QAR
                                    </InputAdornment>
                                  }
                                  className={classes.number}
                                  inputProps={{ readOnly: true }}
                                />
                              </FormControl>
                              <FormControl fullWidth>
                                <InputLabel>
                                  {activeQuantity
                                    ? "Total Retail"
                                    : "Retail / unit"}
                                </InputLabel>
                                <Input
                                  disableUnderline={true}
                                  type="number"
                                  value={
                                    activeQuantity > 0
                                      ? (
                                          activeProduct.selling_unit_price *
                                          activeQuantity
                                        ).toFixed(2)
                                      : activeProduct.selling_unit_price.toFixed(
                                          2
                                        )
                                  }
                                  startAdornment={
                                    <InputAdornment position="start">
                                      QAR
                                    </InputAdornment>
                                  }
                                  className={classes.number}
                                  inputProps={{ readOnly: true }}
                                />
                              </FormControl>
                              <FormControl fullWidth>
                                <InputLabel>Margin</InputLabel>
                                <Input
                                  disableUnderline={true}
                                  type="number"
                                  value={
                                    activeProduct.margin &&
                                    activeProduct.margin.toFixed(2)
                                  }
                                  startAdornment={
                                    <InputAdornment position="start">
                                      %
                                    </InputAdornment>
                                  }
                                  className={classes.number}
                                  inputProps={{ readOnly: true }}
                                />
                              </FormControl>
                              <FormControl
                                fullWidth
                                variant="outlined"
                                required
                              >
                                <InputLabel>Quantity</InputLabel>
                                <OutlinedInput
                                  label="Quantity *"
                                  type="number"
                                  autoFocus
                                  value={activeQuantity}
                                  onChange={handleQuantityChange}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      #
                                    </InputAdornment>
                                  }
                                  className={classes.number}
                                  inputProps={{ min: 1 }}
                                />
                              </FormControl>
                            </Fragment>
                          ) : (
                            <div>
                              <div style={{ display: "flex" }}>
                                <Autocomplete
                                  required
                                  options={allSupplierProducts}
                                  getOptionLabel={(option) => `${option.name}`}
                                  onChange={(event, newValue) => {
                                    handleProductChange(newValue);
                                  }}
                                  fullWidth
                                  value={activeProduct}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      required
                                      InputLabelProps={{ shrink: true }}
                                      label="Product"
                                      variant="outlined"
                                      placeholder="Search for a product that already exists..."
                                    />
                                  )}
                                />
                                <div>
                                  <Tooltip title="Refresh supplier products">
                                    <IconButton
                                      onClick={() =>
                                        get_supplier_products(
                                          formData.supplier.id
                                        )
                                      }
                                    >
                                      <RefreshIcon />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </div>
                              <div>
                                <DividerWithText>
                                  <Typography
                                    style={{
                                      fontSize: 18,
                                      fontWeight: "bold",
                                    }}
                                    color="textSecondary"
                                    variant="caption"
                                  >
                                    OR
                                  </Typography>
                                </DividerWithText>
                              </div>
                            </div>
                          )}
                        </DialogContent>

                        <DialogActions>
                          {activeProduct ? (
                            <Fragment>
                              <Button
                                onClick={() => {
                                  setAtiveQuantity("");
                                  setActiveProduct(null);
                                }}
                                fullWidth
                                color="primary"
                                variant="outlined"
                                style={{ borderRadius: 24 }}
                              >
                                Change product
                              </Button>
                              <Button
                                type="submit"
                                fullWidth
                                color="primary"
                                variant="contained"
                                style={{ borderRadius: 24 }}
                              >
                                Add
                              </Button>
                            </Fragment>
                          ) : (
                            <Button
                              onClick={() => setOpenNewProduct(!openNewProduct)}
                              fullWidth
                              color="secondary"
                              variant="contained"
                              // style={{ borderRadius: 24 }}
                            >
                              Create a new Product
                            </Button>
                          )}
                        </DialogActions>
                      </form>
                    </Dialog>
                  ))}
              </div>
            )}
          </Toolbar>
        )}
        {numProducts > 0 && (
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={numSelected > 0 && numSelected < numProducts}
                    checked={numProducts > 0 && numSelected === numProducts}
                    onChange={handleSelectAllClick}
                  />
                </TableCell>
                {headCells.map((headCell) => (
                  <TableCell
                    style={{ fontWeight: "bold" }}
                    key={headCell.id}
                    align={headCell.numeric ? "right" : "left"}
                    padding={headCell.disablePadding ? "none" : "default"}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={() => createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                      {orderBy === headCell.id ? (
                        <span className={classes.visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(supplier_products, getComparator(order, orderBy))
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClickRow(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell color="primary" padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.name}
                      </TableCell>
                      <TableCell align="right">{row.product_type}</TableCell>
                      <TableCell align="right">
                        {(row.cost_unit_price * row.requested_quantity).toFixed(
                          2
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {(
                          row.selling_unit_price * row.requested_quantity
                        ).toFixed(2)}
                      </TableCell>
                      <TableCell align="right">
                        {row.margin && row.margin.toFixed(2)}
                      </TableCell>
                      <TableCell align="right">
                        {row.requested_quantity}
                      </TableCell>
                    </TableRow>
                  );
                })}
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox style={{ display: "none" }} />
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  padding="none"
                  style={{ fontWeight: "bold" }}
                >
                  Total
                </TableCell>
                <TableCell />
                <TableCell align="right" style={{ fontWeight: "bold" }}>
                  {totalPrice.toFixed(2)}
                </TableCell>
                <TableCell align="right" style={{ fontWeight: "bold" }}>
                  {supplier_products
                    .reduce(
                      (currentTotalPrice, product) =>
                        currentTotalPrice +
                        product.requested_quantity * product.selling_unit_price,
                      0
                    )
                    .toFixed(2)}
                </TableCell>
                <TableCell />
                <TableCell />
              </TableRow>
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </div>
  );
}

const mapStateToProps = (state) => ({
  suppliers: state.procedures.suppliers,
  allSupplierProducts: state.procedures.supplier_products,
  processingAddingProduct: state.procedures.processing,
});

export default connect(mapStateToProps, {
  get_supplier_products,
  clear_supplier_products,
  remove_product,
  add_back_product,
  add_back_products,
})(EnhancedTable);
