import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { edit_product } from "../../../actions/procedure";
import { connect } from "react-redux";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import TextField from "@material-ui/core/TextField";
import DialogTitle from "../../../components/MuiDialogTitle";

const useStyles = makeStyles((theme) => ({
  number: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
      {
        display: "none",
      },
  },
  dividerFullWidth: {
    marginTop: theme.spacing(3),
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function EditProcurementProduct({
  registerProduct,
  open,
  setOpen,
  product,
  setProduct,
  requested_quantity,
  supplier_pk,
  setSelected,
  edit_product,
}) {
  const classes = useStyles();

  const [editingQuantity, setEditingQuantity] = useState(true);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    setProduct(product);
    setFormData({ ...product });
  }, [setProduct, product, setFormData]);

  const handleClose = () => {
    setOpen(false);
    setProduct(null);
  };

  const handleChangeValue = (prop) => (event) => {
    setFormData({
      ...formData,
      [prop]: event.target.value,
    });
  };

  const handleChange = (prop) => (event) => {
    setFormData({
      ...formData,
      [prop]: event.target.value,
      margin:
        prop === "selling_unit_price"
          ? event.target.value > 0
            ? (
                (100 * (event.target.value - formData.cost_unit_price)) /
                event.target.value
              ).toFixed(2)
            : 0
          : formData.selling_unit_price > 0
          ? (
              (100 * (formData.selling_unit_price - event.target.value)) /
              formData.selling_unit_price
            ).toFixed(2)
          : 0,
    });
  };

  const onSubmitEditQuantity = (e) => {
    e.preventDefault();
    registerProduct(formData);
    handleClose();
  };

  const onSubmitEditProduct = (e) => {
    e.preventDefault();
    edit_product(formData, supplier_pk).then((product_pk) => {
      if (product_pk) {
        registerProduct({
          name: formData.name,
          product_type: formData.product_type,
          selling_unit_price: parseFloat(formData.selling_unit_price),
          cost_unit_price: parseFloat(formData.cost_unit_price),
          margin: parseFloat(formData.margin),
          requested_quantity: formData.requested_quantity,
          id: product_pk,
        });
        setSelected([product_pk]);
      }
    });
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth
    >
      <DialogTitle
        style={{ cursor: "move" }}
        id="draggable-dialog-title"
        onClose={handleClose}
      >
        {editingQuantity ? product.name : "Update Product"}
      </DialogTitle>
      {editingQuantity ? (
        <form onSubmit={(e) => onSubmitEditQuantity(e)}>
          <DialogContent>
            <FormControl fullWidth>
              <TextField
                label="Product type"
                value={product.product_type}
                InputProps={{ disableUnderline: true, readOnly: true }}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>
                {requested_quantity ? "Total Cost" : "Cost / unit"}
              </InputLabel>
              <Input
                disableUnderline={true}
                type="number"
                value={
                  requested_quantity
                    ? (product.cost_unit_price * requested_quantity).toFixed(2)
                    : product.cost_unit_price.toFixed(2)
                }
                startAdornment={
                  <InputAdornment position="start">QAR</InputAdornment>
                }
                className={classes.number}
                inputProps={{ readOnly: true }}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>
                {requested_quantity ? "Total Retail" : "Retail / unit"}
              </InputLabel>
              <Input
                disableUnderline={true}
                type="number"
                value={
                  requested_quantity
                    ? (product.selling_unit_price * requested_quantity).toFixed(
                        2
                      )
                    : product.selling_unit_price.toFixed(2)
                }
                startAdornment={
                  <InputAdornment position="start">QAR</InputAdornment>
                }
                className={classes.number}
                inputProps={{ readOnly: true }}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>Margin</InputLabel>
              <Input
                disableUnderline={true}
                type="number"
                value={product.margin}
                startAdornment={
                  <InputAdornment position="start">%</InputAdornment>
                }
                className={classes.number}
                inputProps={{ readOnly: true }}
              />
            </FormControl>
            <FormControl fullWidth variant="outlined" required>
              <InputLabel>Quantity</InputLabel>
              <OutlinedInput
                required
                label="Quantity *"
                type="number"
                autoFocus
                value={formData.requested_quantity}
                onChange={handleChangeValue("requested_quantity")}
                startAdornment={
                  <InputAdornment position="start">#</InputAdornment>
                }
                className={classes.number}
                inputProps={{ min: 1 }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setEditingQuantity(!editingQuantity)}
              fullWidth
              color="primary"
              variant="outlined"
              style={{ borderRadius: 24 }}
            >
              Edit product
            </Button>
            <Button
              type="submit"
              fullWidth
              color="primary"
              variant="contained"
              style={{ borderRadius: 24 }}
              disabled={formData.requested_quantity === requested_quantity}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      ) : (
        <form onSubmit={(e) => onSubmitEditProduct(e)}>
          <DialogContent>
            <FormControl fullWidth required>
              <TextField
                onChange={handleChangeValue("name")}
                value={formData.name}
                autoFocus
                variant="outlined"
                label="Name *"
                type="text"
              />
            </FormControl>
            <FormControl fullWidth variant="outlined" required>
              <InputLabel>Product type</InputLabel>
              <Select
                value={formData.product_type}
                onChange={handleChangeValue("product_type")}
                label="Product type *"
                variant="outlined"
                fullWidth
              >
                <MenuItem value={"internal"}>Internal</MenuItem>
                <MenuItem value={"retail"}>Retail</MenuItem>
                <MenuItem value={"both"}>Both</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="outlined" fullWidth required>
              <InputLabel>Cost / unit</InputLabel>
              <OutlinedInput
                label="Cost / unit *"
                type="number"
                value={formData.cost_unit_price}
                onChange={handleChange("cost_unit_price")}
                startAdornment={
                  <InputAdornment position="start">QAR</InputAdornment>
                }
                className={classes.number}
                inputProps={{ min: 0, step: 0.01 }}
              />
            </FormControl>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Retail / unit</InputLabel>
              <OutlinedInput
                label="Retail / unit *"
                type="number"
                value={formData.selling_unit_price}
                onChange={handleChange("selling_unit_price")}
                startAdornment={
                  <InputAdornment position="start">QAR</InputAdornment>
                }
                className={classes.number}
                inputProps={{ min: 0, step: 0.01 }}
              />
            </FormControl>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Margin</InputLabel>
              <OutlinedInput
                label="Margin"
                type="number"
                value={formData.margin}
                startAdornment={
                  <InputAdornment position="start">%</InputAdornment>
                }
                className={classes.number}
                inputProps={{ readOnly: true, min: 0, step: 0.01 }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions style={{ padding: 24 }}>
            <Button
              onClick={() => setEditingQuantity(!editingQuantity)}
              fullWidth
              color="primary"
              variant="outlined"
              style={{ borderRadius: 24 }}
            >
              Edit quantity
            </Button>
            <Button
              type="submit"
              fullWidth
              color="primary"
              variant="contained"
              style={{ borderRadius: 24 }}
              disabled={
                formData.name === product.name &&
                formData.product_type === product.product_type &&
                parseFloat(formData.selling_unit_price) ===
                  parseFloat(product.selling_unit_price) &&
                parseFloat(formData.cost_unit_price) ===
                  parseFloat(product.cost_unit_price)
              }
            >
              Save
            </Button>
          </DialogActions>
        </form>
      )}
    </Dialog>
  );
}

export default connect(null, { edit_product })(EditProcurementProduct);
