import axios from "axios";
import { SNACKBAR_WRITE } from "./procedureType";

import {
  CLEAR_FORMDATA,
  GET_FORM_DATA_SUCCESS,
  UPDATE_FORM_STATUS_SUCCESS,
  PROCESSING,
} from "./reimbursementTypes";
import { AUTHENTICATED_FAIL } from "./authTypes";

import global from "../global";
const { API_URL } = global;

export const post_reimbursement_request = (data) => async (dispatch) => {
  dispatch({
    type: PROCESSING,
    payload: true,
  });
  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    let formData = new FormData();
    formData.append("expense_for", data.expense_for);
    formData.append("reimbursement_amount", data.reimbursement_amount);
    formData.append(
      "reasons_for_reimbursement",
      data.reasons_for_reimbursement
    );
    formData.append("receipt_image", data.receipt_image);
    formData.append("payment_type", data.payment_type);
    try {
      const response = await axios.post(
        `${API_URL}/reimbursement/`,
        formData,
        config
      );
      dispatch({
        type: SNACKBAR_WRITE,
        payload: response.data,
      });
      dispatch({
        type: PROCESSING,
        payload: false,
      });

      return response.data.RRN;
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "An error has occured! Please try again later.",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const get_reimbursement_request = (RRN) => async (dispatch) => {
  dispatch({
    type: CLEAR_FORMDATA,
  });
  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    try {
      const response = await axios.get(
        `${API_URL}/reimbursement/${RRN}/`,
        config
      );
      dispatch({
        type: GET_FORM_DATA_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "Unable to load request",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const update_reimbursement_request_form =
  (form, RRN) => async (dispatch) => {
    dispatch({
      type: PROCESSING,
      payload: true,
    });
    const access = localStorage.getItem("access");
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: `JWT ${access}`,
        },
      };

      let formData = new FormData();
      formData.append("reimbursement_amount", form.reimbursement_amount);
      formData.append("expense_for", form.expense_for);
      formData.append(
        "reasons_for_reimbursement",
        form.reasons_for_reimbursement
      );
      formData.append("payment_type", form.payment_type);
      formData.append("receipt_image", form.receipt_image);
      try {
        const response = await axios.put(
          `${API_URL}/reimbursement/${RRN}/`,
          formData,
          config
        );
        dispatch({
          type: UPDATE_FORM_STATUS_SUCCESS,
          payload: response.data,
        });
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: response.data.message,
            severity: response.data.severity,
          },
        });
      } catch (error) {
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: "An error has occured! Please try again later.",
            severity: "error",
          },
        });
      }
    } else {
      dispatch({
        type: AUTHENTICATED_FAIL,
      });
    }
  };

export const update_reimbursement_request_status =
  (status, RRN, note) => async (dispatch) => {
    const access = localStorage.getItem("access");
    if (localStorage.getItem("access")) {
      dispatch({
        type: PROCESSING,
        payload: true,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `JWT ${access}`,
        },
      };

      const body = JSON.stringify({ status: status, note: note });

      try {
        const response = await axios.post(
          `${API_URL}/reimbursement/${RRN}/`,
          body,
          config
        );
        dispatch({
          type: UPDATE_FORM_STATUS_SUCCESS,
          payload: response.data,
        });
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: response.data.message,
            severity: response.data.severity,
          },
        });
      } catch (error) {
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: "An error has occured! Please try again later.",
            severity: "error",
          },
        });
      }
    } else {
      dispatch({
        type: AUTHENTICATED_FAIL,
      });
    }
  };

export const submit_reimbursement_payment_details =
  (form, RRN) => async (dispatch) => {
    dispatch({
      type: PROCESSING,
      payload: true,
    });
    const access = localStorage.getItem("access");
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `JWT ${access}`,
        },
      };

      const body = JSON.stringify({
        status: "to collect",
        payment_type: form.payment_type,
        associated_payment_id: form.associated_payment_id,
      });

      try {
        const response = await axios.post(
          `${API_URL}/reimbursement/${RRN}/`,
          body,
          config
        );
        dispatch({
          type: UPDATE_FORM_STATUS_SUCCESS,
          payload: response.data,
        });
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: response.data.message,
            severity: response.data.severity,
          },
        });
      } catch (error) {
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: "An error has occured! Please try again later.",
            severity: "error",
          },
        });
      }
    } else {
      dispatch({
        type: AUTHENTICATED_FAIL,
      });
    }
  };
