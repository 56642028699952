import axios from "axios";
import {
  LOAD_QUARTER,
  LOAD_MILESTONES,
  IS_LOADING_MILESTONES,
} from "./milestoneTypes";
import { AUTHENTICATED_FAIL } from "./authTypes";
import { SNACKBAR_WRITE } from "./procedureType";
import global from "../global";
const { API_URL } = global;

export const loadQuarter = () => async (dispatch) => {
  const access = localStorage.getItem("access");
  if (access) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };
    try {
      const response = await axios.get(
        `${API_URL}/milestones/quarters/current/`,
        config
      );
      dispatch({
        type: LOAD_QUARTER,
        payload: response.data,
      });
      return response.data;
    } catch (error) {
      return null;
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const loadMilestones =
  (index, start_date, end_date) => async (dispatch) => {
    const access = localStorage.getItem("access");
    if (access) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `JWT ${access}`,
        },
      };
      dispatch({
        type: IS_LOADING_MILESTONES,
        payload: true,
      });

      try {
        const response = await axios.get(
          `${API_URL}/milestones/milestones/quarter_month/?date__gte=${start_date}&date__lte=${end_date}`,
          config
        );
        setTimeout(() => {
          dispatch({
            type: LOAD_MILESTONES,
            payload: {
              quarter_month_index: index,
              quarter_month_payload: response.data,
            },
          });
        }, 500);
      } catch (error) {
        dispatch({
          type: IS_LOADING_MILESTONES,
          payload: false,
        });
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: "Unable to load milestone!",
            severity: "error",
          },
        });
      }
    } else {
      dispatch({
        type: AUTHENTICATED_FAIL,
      });
    }
  };
