import React from "react";
import { Avatar, CardActionArea, Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { NavLink } from "react-router-dom";

const CardLink = ({ title, link, disabled }) => {
  return (
    <Card
      style={{
        border: "3px solid #77E0FF",
        borderRadius: 12,
        minWidth: 190,
        width: "min-content",
      }}
      elevation={10}
    >
      <CardActionArea
        component={NavLink}
        to={link}
        disabled={disabled}
        style={{ textDecoration: "none" }}
      >
        <CardContent style={{ padding: 24, minHeight: 160, paddingBottom: 0 }}>
          <Typography variant="h4">{title}</Typography>
        </CardContent>
        <CardActions style={{ padding: 24, paddingTop: 0 }}>
          {disabled ? (
            <Typography variant="body1">Coming Soon</Typography>
          ) : (
            <Avatar
              style={{
                color: "white",
                backgroundColor: "#77E0FF",
                marginLeft: "auto",
              }}
            >
              <ArrowForwardIcon />
            </Avatar>
          )}
        </CardActions>
      </CardActionArea>
    </Card>
  );
};

export default CardLink;
