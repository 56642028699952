import React from "react";
import { Grid, Typography } from "@material-ui/core";
import CardLink from "../../components/CardLink";

const ReimbursementDirectory = () => {
  return (
    <div style={{ width: 655 }}>
      <Typography variant="h3" style={{ paddingBottom: 48 }}>
        Reimbursement
      </Typography>
      <Typography variant="h6" style={{ marginBottom: 36 }}>
        The purpose of this request form is to submit a request for a
        reimbursement when an employee makes a purchase on behalf of the company
      </Typography>
      <Grid
        container
        spacing={4}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid item>
          <CardLink
            title={"Request Form"}
            link="/app/forms/reimbursement/form"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ReimbursementDirectory;
