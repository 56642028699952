import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";

// import CircularProgress from "@material-ui/core/CircularProgress";
import NonEditableInputView from "./NonEditableInputView";
import EditableInputView from "./Form";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
  },
  subheader: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 20,
    marginLeft: 0,
  },
  card: {
    minWidth: 200,
    position: "fixed",
    top: "50%",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
  },
}));

const ProcurementRequest = ({ current_user, IRN, data }) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    request_type: "",
    ordering_for: "",
    delivery_destination: null,
    expected_delivery_cost: "",
    expected_delivery_date: null,
    expected_receiver_staff: [],
    supplier: null,
    products: {},
    payment_type: "",
    payments: {},
  });

  const [edit, setEdit] = useState(false);

  useEffect(() => {
    setFormData({
      approval_status: data.approval_status.status,
      request_type: data.request_type,
      ordering_for: data.ordering_for,
      delivery_destination: data.delivery_destination,
      expected_delivery_cost: data.expected_delivery_cost
        ? parseFloat(data.expected_delivery_cost)
        : null,
      expected_delivery_date: data.expected_delivery_date,
      expected_receiver_staff: data.expected_receiver_staff,
      supplier: data.supplier,
      products: data.inventory_request_products.reduce(function (result, item) {
        var temp = { ...item };
        delete temp["product"];
        result[item.product.id] = { ...temp, ...item["product"], pk: item.id };
        return result;
      }, {}),
      payment_type:
        data.inventory_request_payments[0].purchase_type === "physical purchase"
          ? "offline"
          : "online",
      payments:
        data.inventory_request_payments[0].purchase_type === "physical purchase"
          ? data.inventory_request_payments.reduce(function (result, payment) {
              var key = new Date(payment.payment_date).toLocaleDateString(
                "en-GB"
              );
              const temp = payment.accounting_status;
              result[key] = { ...temp, ...payment };
              return result;
            }, {})
          : {
              ...data.inventory_request_payments[0],
              ...data.inventory_request_payments[0].accounting_status,
            },
    });
  }, [data, setFormData]);

  const AllowedToEdit = () => {
    return (
      (["pending", "returned"].includes(data.approval_status.status) &&
        current_user === data.requester) ||
      (["approved"].includes(data.approval_status.status) &&
        data.approval_status.approvers.includes(current_user) &&
        !data.inventory_request_payments
          .map((payment) => payment.accounting_status.status)
          .some((status) => status === "received"))
    );
  };

  return (
    <div className={classes.paper}>
      {data.approval_status.note && (
        <FormControl fullWidth>
          <TextField
            label="Approver's note"
            multiline
            InputProps={{ disableUnderline: true, readOnly: true }}
            value={data.approval_status.note}
          />
        </FormControl>
      )}
      <div>
        <Typography
          style={{ marginBottom: 20, fontWeight: "bold", alignSelf: "start" }}
          variant="h5"
        >
          Procurement information
          {AllowedToEdit() && (
            <Button
              color="primary"
              style={{ fontWeight: "bold" }}
              onClick={() => {
                setEdit(!edit);
                // setEdited(false);
                setFormData({
                  approval_status: data.approval_status.status,
                  request_type: data.request_type,
                  ordering_for: data.ordering_for,
                  delivery_destination: data.delivery_destination,
                  expected_delivery_cost: data.expected_delivery_cost
                    ? parseFloat(data.expected_delivery_cost)
                    : null,
                  expected_delivery_date: data.expected_delivery_date,
                  expected_receiver_staff: data.expected_receiver_staff,
                  supplier: data.supplier,
                  products: data.inventory_request_products.reduce(function (
                    result,
                    item
                  ) {
                    var temp = { ...item };
                    delete temp["product"];
                    result[item.product.id] = {
                      ...temp,
                      ...item["product"],
                      pk: item.id,
                    };
                    return result;
                  },
                  {}),
                  payment_type:
                    data.inventory_request_payments[0].purchase_type ===
                    "physical purchase"
                      ? "offline"
                      : "online",
                  payments:
                    data.inventory_request_payments[0].purchase_type ===
                    "physical purchase"
                      ? data.inventory_request_payments.reduce(function (
                          result,
                          payment
                        ) {
                          var key = new Date(
                            payment.payment_date
                          ).toLocaleDateString("en-GB");
                          const temp = payment.accounting_status;
                          // delete payment.accounting_status;
                          result[key] = { ...temp, ...payment };
                          return result;
                        },
                        {})
                      : {
                          ...data.inventory_request_payments[0],
                          ...data.inventory_request_payments[0]
                            .accounting_status,
                        },
                });
              }}
            >
              {edit ? "Cancel" : "Edit"}
            </Button>
          )}
        </Typography>

        <FormControl fullWidth>
          <TextField
            label="Requester"
            InputProps={{ disableUnderline: true, readOnly: true }}
            value={data.requester_full_name}
          />
        </FormControl>
      </div>
      {!AllowedToEdit() || !edit ? (
        <NonEditableInputView data={formData} IRN={IRN} />
      ) : (
        <EditableInputView data={formData} IRN={IRN} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  current_user: state.auth.user.username,
  submitting: state.procurement.submitting,
  data: state.procurement.data,
});

export default connect(mapStateToProps, {})(ProcurementRequest);
