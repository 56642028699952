import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

// import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Typography from "@material-ui/core/Typography";

import { update_unpaid_campaign_request } from "../../../../actions/marketing_expenses";

import { Fragment } from "react";

const categories = [
  "Discount",
  "Rebate",
  "Voucher",
  "Reward scheme",
  "Bundling",
];

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: "flex",
//     flexWrap: "wrap",
//   },
//   paper: {
//     marginTop: theme.spacing(8),
//     display: "flex",
//     flexDirection: "column",
//   },
//   withoutLabel: {
//     marginTop: theme.spacing(3),
//   },
//   textField: {
//     width: "25ch",
//   },
//   center: {
//     display: "flex",
//     justifyContent: "center",
//     height: "100%",
//   },
//   number: {
//     "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
//       display: "none",
//     },
//   },
// }));

const RequestInput = ({
  data,
  current_user,
  UCN,
  update_unpaid_campaign_request,
}) => {
  // const classes = useStyles();
  const [formData, setFormData] = useState(null);

  const [edit, setEdit] = useState(false);
  const [edited, setEdited] = useState(false);

  useEffect(() => {
    setFormData(data);
    setEdited(false);
  }, [setFormData, data, edit]);

  const handleDateChange = (date, prop) => {
    setFormData({ ...formData, [prop]: date });
    setEdited(true);
  };
  const handleCategoryChange = (category) => {
    setFormData({ ...formData, category: category || null });
    setEdited(true);
  };
  const handleChange = (prop) => (event) => {
    setFormData({ ...formData, [prop]: event.target.value });
    setEdited(true);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    update_unpaid_campaign_request(formData, UCN);
    setEdit(false);
    setEdited(false);
  };

  const AllowedToEdit = () => {
    return (
      ["pending", "returned"].includes(data.approval_status.status) &&
      current_user === data.requester
    );
  };

  return (
    formData && (
      <Fragment>
        {data.approval_status.note && (
          <FormControl fullWidth>
            <TextField
              label="Approver's note"
              multiline
              InputProps={{ disableUnderline: true, readOnly: true }}
              value={data.approval_status.note}
            />
          </FormControl>
        )}

        <Typography
          style={{ marginBottom: 20, fontWeight: "bold", alignSelf: "start" }}
          variant="h5"
        >
          Unpaid campaign information
          {AllowedToEdit() && (
            <Button
              color="primary"
              style={{ fontWeight: "bold" }}
              onClick={() => {
                setEdit(!edit);
              }}
            >
              {edit ? "Cancel" : "Edit"}
            </Button>
          )}
        </Typography>

        {AllowedToEdit() && edit ? (
          <form onSubmit={(e) => onSubmit(e)}>
            <FormControl fullWidth>
              <TextField
                required
                label="Campaign name"
                onChange={handleChange("campaign_name")}
                value={formData.campaign_name}
                variant="outlined"
              />
            </FormControl>
            <Autocomplete
              required
              options={categories}
              getOptionLabel={(option) => option}
              onInputChange={(event, newValue) => {
                handleCategoryChange(newValue);
              }}
              getOptionSelected={(option, value) =>
                option === value ? option : value
              }
              value={formData.category}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  InputLabelProps={{ shrink: true }}
                  label="Category"
                  variant="outlined"
                />
              )}
            />
            <FormControl fullWidth>
              <TextField
                required
                multiline
                label="Qualitative justification"
                onChange={handleChange("qualitative_justification")}
                value={formData.qualitative_justification}
                variant="outlined"
              />
            </FormControl>
            {/* <FormControl fullWidth variant="outlined" required>
              <InputLabel htmlFor="outlined-adornment-amount">
                Expected cost
              </InputLabel>
              <OutlinedInput
                label="Expected cost *"
                type="number"
                value={formData.expected_cost}
                onChange={handleChange("expected_cost")}
                startAdornment={
                  <InputAdornment position="start">QAR</InputAdornment>
                }
                className={classes.number}
                inputProps={{ min: 0, step: 0.01 }}
              />
            </FormControl>
            <FormControl fullWidth variant="outlined" required>
              <InputLabel htmlFor="outlined-adornment-amount">
                Expected ROI
              </InputLabel>
              <OutlinedInput
                label="Expected ROI *"
                type="number"
                value={formData.expected_ROI}
                onChange={handleChange("expected_ROI")}
                startAdornment={
                  <InputAdornment position="start">QAR</InputAdornment>
                }
                className={classes.number}
                inputProps={{ min: 0, step: 0.01 }}
              />
            </FormControl> */}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                fullWidth
                required
                inputVariant="outlined"
                label="Start date"
                format="dd / MM / yyyy"
                value={formData.start_date || null}
                onChange={(date) => handleDateChange(date, "start_date")}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                minDate={new Date()}
              />
            </MuiPickersUtilsProvider>
            {formData.start_date && (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  required
                  inputVariant="outlined"
                  label="End date"
                  format="dd / MM / yyyy"
                  value={formData.end_date || null}
                  onChange={(date) => handleDateChange(date, "end_date")}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  minDate={formData.start_date}
                />
              </MuiPickersUtilsProvider>
            )}
            <FormControl fullWidth>
              <Button
                disabled={
                  (formData.start_date !== null
                    ? formData.start_date.toString() === "Invalid Date"
                    : true) ||
                  (formData.end_date !== null
                    ? formData.end_date.toString() === "Invalid Date"
                    : false) ||
                  !edited ||
                  (formData.end_date !== null &&
                    formData.start_date > formData.end_date)
                }
                variant="contained"
                color="primary"
                type="submit"
              >
                Submit
              </Button>
            </FormControl>
          </form>
        ) : (
          <div>
            <FormControl fullWidth>
              <TextField
                label="Requester"
                value={data.requester_full_name}
                InputProps={{ disableUnderline: true, readOnly: true }}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                label="Campaign name"
                value={formData.campaign_name}
                InputProps={{ disableUnderline: true, readOnly: true }}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                label="Category"
                value={formData.category}
                InputProps={{ disableUnderline: true, readOnly: true }}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                multiline
                label="Qualitative justification"
                value={formData.qualitative_justification}
                InputProps={{ disableUnderline: true, readOnly: true }}
              />
            </FormControl>
            {formData.expected_cost && (
              <FormControl fullWidth>
                <TextField
                  label="Expected cost"
                  value={`${formData.expected_cost} QAR`}
                  InputProps={{ disableUnderline: true, readOnly: true }}
                />
              </FormControl>
            )}
            {formData.expected_ROI && (
              <FormControl fullWidth>
                <TextField
                  label="Expected ROI"
                  value={formData.expected_ROI}
                  InputProps={{ disableUnderline: true, readOnly: true }}
                  multiline
                />
              </FormControl>
            )}
            <FormControl fullWidth>
              <TextField
                label="Start date"
                value={new Date(formData.start_date).toLocaleDateString(
                  "en-GB"
                )}
                InputProps={{ disableUnderline: true, readOnly: true }}
              />
            </FormControl>
            {formData.end_date && (
              <FormControl fullWidth>
                <TextField
                  label="End date"
                  value={new Date(formData.end_date).toLocaleDateString(
                    "en-GB"
                  )}
                  InputProps={{ disableUnderline: true, readOnly: true }}
                />
              </FormControl>
            )}
          </div>
        )}
      </Fragment>
    )
  );
};

export default connect(null, {
  update_unpaid_campaign_request,
})(RequestInput);
