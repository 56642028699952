import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  InputAdornment,
  TextField,
  Button,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import {
  post_operation_expense_request,
  update_operation_expense_request_form,
} from "../../../../actions/operation_expenses";
import { Redirect } from "react-router";

// import InputExpenses from "./InputExpenses";
import Poppup from "../../../../components/Poppup";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 600,
  },
  center: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
  },
  number: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
      {
        display: "none",
      },
  },
}));

const OperationExpenseRequest = ({
  submitting,
  post_operation_expense_request,
  update_operation_expense_request_form,
  OERN,
  data,
}) => {
  const classes = useStyles();

  const [formData, setFormData] = useState({
    payment_start_date: null,
    payment_end_date: null,
    cash_requested_amount: "",
    cash_justification: "",
    credit_requested_amount: "",
    credit_justification: "",
  });

  useEffect(() => {
    data && setFormData(data);
  }, [data]);

  const handleChange = (prop) => (event) => {
    setFormData({ ...formData, [prop]: event.target.value });
  };
  const handleDateChange = (date, prop) => {
    setFormData({ ...formData, [prop]: date });
  };

  const [redirectingOERN, setRedirectingOERN] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    if (OERN) {
      update_operation_expense_request_form(formData, OERN);
    } else {
      post_operation_expense_request(formData).then((OERN) =>
        setRedirectingOERN(OERN)
      );
    }
  };

  return redirectingOERN ? (
    <Redirect
      to={`/app/forms/operation-expenses/operation-expense/form/${redirectingOERN}/`}
    />
  ) : (
    <div className={classes.paper}>
      {!OERN && (
        <Typography style={{ marginBottom: 40 }} variant="h3">
          Operation Expenses
          <Poppup
            content={
              <Typography variant="body1">
                The purpose of this request form is to enable the operations
                team request the total cash and credit amount which they
                forecast that they need aver a specified time period.
              </Typography>
            }
          />
        </Typography>
      )}
      {submitting ? (
        <div className={classes.center}>
          <CircularProgress style={{ margin: "auto" }} />
        </div>
      ) : (
        <div>
          <form style={{ width: "100%" }} onSubmit={(e) => onSubmit(e)}>
            <Typography style={{ marginBottom: 40 }} variant="h6">
              Date or period
            </Typography>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                fullWidth
                required
                inputVariant="outlined"
                label="Start date"
                format="dd / MM / yyyy"
                value={formData.payment_start_date || null}
                onChange={(date) =>
                  handleDateChange(date, "payment_start_date")
                }
                minDate={new Date()}
              />
            </MuiPickersUtilsProvider>
            {formData.payment_start_date && (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  inputVariant="outlined"
                  label="End date"
                  format="dd / MM / yyyy"
                  value={formData.payment_end_date || null}
                  onChange={(date) =>
                    handleDateChange(date, "payment_end_date")
                  }
                  minDate={formData.payment_start_date}
                />
              </MuiPickersUtilsProvider>
            )}

            <Typography style={{ marginBottom: 40 }} variant="h6">
              Cash
            </Typography>

            <FormControl fullWidth variant="outlined">
              <TextField
                label="Cash Amount"
                onChange={handleChange("cash_requested_amount")}
                value={formData.cash_requested_amount}
                variant="outlined"
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">QAR</InputAdornment>
                  ),
                }}
                className={classes.number}
                inputProps={{ min: 0, step: 0.01 }}
              />
            </FormControl>
            <FormControl fullWidth variant="outlined">
              <TextField
                required={formData.cash_requested_amount !== ""}
                label="Cash justification"
                multiline
                onChange={handleChange("cash_justification")}
                value={formData.cash_justification}
                variant="outlined"
              />
            </FormControl>

            <Typography style={{ marginBottom: 40 }} variant="h6">
              Credit
            </Typography>

            <FormControl fullWidth variant="outlined">
              <TextField
                label="Credit Amount"
                onChange={handleChange("credit_requested_amount")}
                value={formData.credit_requested_amount}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">QAR</InputAdornment>
                  ),
                }}
                className={classes.number}
                inputProps={{ min: 0, step: 0.01 }}
              />
            </FormControl>
            <FormControl fullWidth variant="outlined">
              <TextField
                required={formData.credit_requested_amount !== ""}
                label="Credit justification"
                multiline
                onChange={handleChange("credit_justification")}
                value={formData.credit_justification}
                variant="outlined"
              />
            </FormControl>

            <FormControl fullWidth>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={
                  formData.credit_requested_amount === "" &&
                  formData.cash_justification === ""
                }
              >
                {OERN ? "Save" : "Submit"}
              </Button>
            </FormControl>
          </form>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  submitting: state.operation_expenses.submitting,
});

export default connect(mapStateToProps, {
  post_operation_expense_request,
  update_operation_expense_request_form,
})(OperationExpenseRequest);
