import React, { useEffect, useState } from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { Fragment } from "react";
import { Typography } from "@material-ui/core";

function ReimbursementStepper({ data }) {
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    if (data.approval_status.status === "approved") setActiveStep(3);
    else if (["rejected", "cancelled"].includes(data.approval_status.status))
      setActiveStep(2);
    else if (data.accounting_status.status === "pending") setActiveStep(1);
    else if ("pending" === data.approval_status.status) setActiveStep(2);
    else if (data.approval_status.status === "returned") setActiveStep(0);
  }, [
    data.approval_status.status,
    data.accounting_status.status,
    setActiveStep,
  ]);

  function getSteps() {
    return (
      (data.approval_status.status === "cancelled" && [
        ["Requested", data.created_at],
        ["Cancelled", data.approval_status.date_approved_or_cancelled],
      ]) ||
      (data.approval_status.status === "rejected" && [
        ["Requested", data.created_at],
        ["Rejected", data.approval_status.date_approved_or_cancelled],
      ]) ||
      (data.approval_status.status === "returned" && [
        ["Returned", data.created_at],
        [
          data.accounting_status.status === "pending" ? "Account" : "Accounted",
          data.accounting_status.accounted_at || "-",
        ],
        ["Approved", "-"],
      ]) || [
        ["Requested", data.created_at],
        [
          data.accounting_status.status === "pending" ? "Account" : "Accounted",
          data.accounting_status.accounted_at || "-",
        ],
        [
          data.approval_status.date_approved_or_cancelled
            ? "Approved"
            : "Approve",
          data.approval_status.date_approved_or_cancelled || "-",
        ],
      ]
    );
  }

  return (
    <div style={{ width: "100%", textAlign: "center", marginBottom: 40 }}>
      <Stepper activeStep={activeStep} alternativeLabel style={{ padding: 0 }}>
        {getSteps().map((label) => (
          <Step key={label[0]}>
            <StepLabel>
              <Fragment>
                {label[0]}
                <Typography style={{ color: "#949494", fontSize: 10 }}>
                  {label[1]}
                </Typography>
              </Fragment>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}

export default ReimbursementStepper;
