import React, {useState, useEffect} from "react";
import useInterval from "react-useinterval";
import clsx from "clsx";
import {NavLink} from "react-router-dom";

import NavDrawer from "./HomePage/NavDrawer";
import NavRoutes from "./HomePage/NavRoutes";
import NavNotifications from "./HomePage/NavNotifications";

import AveyLogo from "../aveyLogo";
import {
  AppBar,
  Toolbar,
  Snackbar,
  Badge,
  SwipeableDrawer,
  Drawer,
  Fab,
  makeStyles,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MenuIcon from "@material-ui/icons/Menu";

import {connect} from "react-redux";
import {remove_snackbar} from "../actions/procedure";
import {load_notifications} from "../actions/auth";

import global from "../global";

const {REFRESH_NOTIFICATIONS_RATE} = global;

const drawerWidth = 320;
const navbarHeight = 80;
const navbarTop = 100;

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    padding: 0,
    margin: 0,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    display: "none",
    [theme.breakpoints.up("lg")]: {
      display: "flex",
    },
  },
  swipeabledrawer: {
    width: drawerWidth,
    // flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  rightBorder: {
    width: drawerWidth,
    borderRight: `2px solid rgba(119, 224, 255, .2)`,
  },
  grow: {
    flexGrow: 1,
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  snackbar: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  customBadge: {
    backgroundColor: "white",
    color: "#77E0FF",
  },
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  content: {
    padding: 20,
    paddingTop: 30,
    paddingBottom: 90,
    [theme.breakpoints.up("sm")]: {
      padding: 40,
      paddingTop: 50,
      paddingBottom: 90,
    },
    [theme.breakpoints.up("md")]: {
      padding: 80,
      paddingTop: 90,
      paddingBottom: 180,
    },
    [theme.breakpoints.up("lg")]: {
      paddingRight: 60,
      paddingLeft: drawerWidth + 60,
      paddingTop: navbarTop,
      paddingBottom: 240,
    },
  },
  toolbar: {
    minHeight: navbarHeight,
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 32,
      paddingRight: 32,
    },
  },
  noMarginRight: {
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  marginRight: {
    [theme.breakpoints.up("sm")]: {
      marginRight: drawerWidth,
    },
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  containerWidth: {
    maxWidth: "100%",
    [theme.breakpoints.up("md")]: {
      maxWidth: 920,
    },
  },
}));

const Navbar = ({
                  message,
                  severity,
                  current_user,
                  remove_snackbar,
                  load_notifications,
                }) => {
  const classes = useStyles();
  const [ref, setRef] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);

  useEffect(() => {
    load_notifications(1);
  }, [load_notifications]);
  const fetchNotifications = () => {
    load_notifications(1);
  };
  useInterval(fetchNotifications, REFRESH_NOTIFICATIONS_RATE);

  const toggleNavNotifications = () => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenNotification(!openNotification);
  };
  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    remove_snackbar();
  };
  const handleNavDrawerClick = () => {
    setOpenMenu(false);
    ref.scrollIntoView({behavior: "smooth"});
  };

  return (
    <div ref={(ref) => setRef(ref)}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.sectionMobile}>
            <Fab
              onClick={() => setOpenMenu(!openMenu)}
              style={{color: "white", backgroundColor: "#77E0FF"}}
            >
              <MenuIcon/>
            </Fab>
          </div>
          <Fab variant="extended" style={{backgroundColor: "transparent"}}>
            <NavLink to="">
              <AveyLogo fill="tertiary"/>
            </NavLink>
          </Fab>
          <div className={classes.grow}/>
          <Fab
            component="span"
            style={{color: "white", backgroundColor: "#77E0FF"}}
            className={clsx(classes.noMarginRight, {
              [classes.marginRight]: openNotification,
            })}
            onClick={() => setOpenNotification(!openNotification)}
          >
            <Badge
              classes={{badge: classes.customBadge}}
              badgeContent={
                current_user.notifications.filter((notification) => {
                  return !notification.read;
                }).length
              }
            >
              <NotificationsIcon/>
            </Badge>
          </Fab>
        </Toolbar>
      </AppBar>
      <SwipeableDrawer
        anchor="left"
        open={openMenu}
        onOpen={() => setOpenMenu(!openMenu)}
        onClose={() => setOpenMenu(!openMenu)}
        classes={{paper: classes.drawerPaper}}
      >
        <NavDrawer handleNavDrawerClick={handleNavDrawerClick}/>
      </SwipeableDrawer>
      <Drawer
        anchor="left"
        variant="permanent"
        className={classes.drawer}
        classes={{paper: classes.rightBorder}}
      >
        <Toolbar className={classes.toolbar}/>
        <NavDrawer handleNavDrawerClick={handleNavDrawerClick}/>
      </Drawer>
      <SwipeableDrawer
        BackdropProps={{invisible: true}}
        anchor="right"
        open={openNotification}
        onClose={toggleNavNotifications()}
        onOpen={toggleNavNotifications()}
        classes={{paper: classes.swipeabledrawer}}
      >
        <NavNotifications
          toggleNavNotifications={toggleNavNotifications()}
          navbarHeight={navbarHeight}
        />
      </SwipeableDrawer>
      <main className={classes.content}>
        <NavRoutes navbarHeight={navbarHeight}/>
        <div className={classes.snackbar}>
          {message && (
            <Snackbar
              open={true}
              autoHideDuration={8000}
              onClose={handleAlertClose}
            >
              <MuiAlert
                onClose={handleAlertClose}
                elevation={6}
                variant="filled"
                severity={severity}
              >
                {message}
              </MuiAlert>
            </Snackbar>
          )}
        </div>
      </main>
    </div>
  );
};

const mapStateToProps = (state) => ({
  current_user: state.auth.user,
  message: state.procedures.message,
  severity: state.procedures.severity,
});

export default connect(mapStateToProps, {
  remove_snackbar,
  load_notifications,
})(Navbar);
