import React, {Fragment, useEffect, useState} from "react";
import clsx from "clsx";
import {lighten, makeStyles} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import CreateIcon from "@material-ui/icons/Create";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Draggable from "react-draggable";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DialogTitle from "../../../../components/MuiDialogTitle";
import {Avatar, LinearProgress, TextField} from "@material-ui/core";
import ImageUpload from "../../ImageUpload";
import ZoomOutMapIcon from "@material-ui/icons/ZoomOutMap";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import {connect} from "react-redux";
import {
  post_input_expense,
  delete_input_expense,
  update_input_expense,
} from "../../../../actions/operation_expenses";

let categories = [
  "Delivery fees",
  "Vehicle maintenance",
  "Gas expense",
  "Telephone expense",
  "Office supplies",
  "Cleaning service",
  "Traffic",
  "Daily Avey carrier wages",
];

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const TableRowWithDialog = ({
                              handleClickRow,
                              row,
                              isItemSelected,
                              AllowedToEdit,
                            }) => {
  const classes = useStyles();
  const [openReceipt, setOpenReceipt] = useState(false);
  const isPDF =
    row.receipt_image.substring(row.receipt_image.lastIndexOf(".") + 1) ===
    "pdf";
  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
      selected={isItemSelected}
    >
      {AllowedToEdit && (
        <TableCell
          color="primary"
          padding="checkbox"
          onClick={(event) => handleClickRow(event, row.id)}
        >
          <Checkbox color="primary" checked={isItemSelected}/>
        </TableCell>
      )}
      <TableCell
        component="th"
        scope="row"
        padding={AllowedToEdit ? "none" : "default"}
      >
        {row.id}
      </TableCell>
      <TableCell>
        {new Date(row.submission_date).toLocaleDateString("en-GB")}
      </TableCell>
      <TableCell>{row.category}</TableCell>
      <TableCell>{parseFloat(row.amount_paid).toFixed(2)}</TableCell>
      <TableCell>{row.payment_type}</TableCell>
      <TableCell onClick={() => !isPDF && setOpenReceipt(true)}>
        {isPDF ? (
          <a
            href={row.receipt_image}
            style={{color: "#77E0FF", fontWeight: "bold"}}
            target="_blank"
            rel="noreferrer"
          >
            PDF
          </a>
        ) : (
          <Fragment>
            <Avatar
              alt="receipt"
              src={row.receipt_image}
              className={classes.largeAvatar}
            />
            <Avatar className={classes.opacityOnHover}>
              <ZoomOutMapIcon className={classes.largeIcon}/>
            </Avatar>
          </Fragment>
        )}
      </TableCell>
      <Dialog open={openReceipt} onClose={() => setOpenReceipt(false)}>
        <img
          alt="receipt"
          src={row.receipt_image}
          style={{height: "100%", maxWidth: "100%"}}
        />
      </Dialog>
    </TableRow>
  );
};

const headCells = [
  {
    id: "id",
    disablePadding: true,
    label: "id",
  },
  {
    id: "submission_date",
    disablePadding: false,
    label: "Date Submitted",
  },
  {
    id: "category",
    disablePadding: false,
    label: "Category",
  },
  {
    id: "amount_paid",
    disablePadding: false,
    label: "Payment Amount",
  },
  {
    id: "payment_type",
    disablePadding: false,
    label: "Payment Type",
  },
  {
    id: "receipt_image",
    disablePadding: false,
    label: "Receipt",
  },
];

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    marginBottom: theme.spacing(3),
  },

  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  number: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
      {
        display: "none",
      },
  },
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.primary.main,
        backgroundColor: lighten(theme.palette.primary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.primary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
  largeAvatar: {
    position: "absolute",
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 4px 0px, rgb(145 158 171 / 24%) 0px 24px 48px 0px",
  },
  opacityOnHover: {
    display: "flex",
    flexDirection: "column",
    opacity: 0,
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 4px 0px, rgb(145 158 171 / 24%) 0px 24px 48px 0px",
    "&:hover": {
      cursor: "pointer",
      opacity: 1,
      transition: "opacity .2s ease",
    },
  },
}));

function EnhancedTable({
                         OERN,
                         data,
                         post_input_expense,
                         delete_input_expense,
                         update_input_expense,
                         submitting_oe_ie,
                       }) {
  const classes = useStyles();
  const [input_expenses, setInputExpenses] = useState(
    data ? data.input_expenses : []
  );
  const AllowedToEdit = data && data.amount_returned === null;

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [selected, setSelected] = useState([]);
  const [openNewInputExpense, setOpenNewInputExpense] = useState(false);
  const [openEditInputExpense, setOpenEditInputExpense] = useState(false);
  const [activeInputExpense, setActiveInputExpense] = useState({
    category: null,
    amount_paid: "",
    payment_type: "",
    receipt_image: null,
    submission_date: null,
  });
  const [editInputExpense, setEditInputExpense] = useState({
    id: null,
    category: null,
    amount_paid: "",
    payment_type: "",
    receipt_image: null,
    submission_date: null,
  });
  const [openRemoveInputExpense, setOpenRemoveInputExpense] = useState(false);

  useEffect(() => {
    data ? setInputExpenses(data["input_expenses"]) : setInputExpenses([]);
    setSelected([]);
  }, [data]);

  const numSelected = selected.length;
  const numInputExpenses = input_expenses.length;
  const totalPrice =
    selected.length > 0
      ? input_expenses
        .filter((expense) => selected.includes(expense.id))
        .reduce(
          (setTotalPaymentPrice, expense) =>
            setTotalPaymentPrice + parseFloat(expense.amount_paid),
          0
        )
      : input_expenses.reduce(
        (setTotalPaymentPrice, expense) =>
          setTotalPaymentPrice + parseFloat(expense.amount_paid),
        0
      );

  const remainingCashAmount =
    data &&
    data.cash_requested_amount -
    input_expenses
      .filter((expense) => expense.payment_type === "Cash")
      .reduce(
        (setTotalPaymentPrice, expense) =>
          setTotalPaymentPrice + parseFloat(expense.amount_paid),
        0
      );

  const remainingCreditAmount =
    data &&
    data.credit_requested_amount -
    input_expenses
      .filter((expense) => expense.payment_type === "Credit card")
      .reduce(
        (setTotalPaymentPrice, expense) =>
          setTotalPaymentPrice + parseFloat(expense.amount_paid),
        0
      );

  const first =
    input_expenses.length > 0 &&
    input_expenses.find((input_expenses) => input_expenses.id === selected[0]);

  const openEditInputExpenseDialog = () => {
    setEditInputExpense({
      id: first.id,
      category: first.category,
      amount_paid: first.amount_paid,
      payment_type: first.payment_type,
      receipt_image: first.receipt_image,
      submission_date: new Date(first.submission_date),
    });
    setOpenEditInputExpense(true);
  };
  const removeInputExpenses = (selected) => {
    delete_input_expense(OERN, selected);
  };
  const handleActiveInputExpenseChange = (prop) => (event) => {
    setActiveInputExpense({
      ...activeInputExpense,
      [prop]: event.target.value,
    });
  };

  const handleActiveCategoryChange = (category) => {
    setActiveInputExpense({
      ...activeInputExpense,
      category: category || null,
    });
  };

  const handleActiveDateChange = (date) => {
    setActiveInputExpense({
      ...activeInputExpense,
      submission_date: date || null,
    });
  };

  const handleEditDateChange = (date) => {
    setEditInputExpense({
      ...editInputExpense,
      submission_date: date || null,
    });
  };

  const handleEditCategoryChange = (category) => {
    setEditInputExpense({...editInputExpense, category: category || null});
  };

  const handleCloseDialog = () => {
    setOpenNewInputExpense(false);
    setActiveInputExpense({
      category: null,
      amount_paid: "",
      payment_type: "",
      receipt_image: null,
      submission_date: null,
    });
  };
  const handleEditCloseDialog = () => {
    setOpenEditInputExpense(false);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = input_expenses.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleClickRow = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === numSelected - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const createSortHandler = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const isSelected = (id) => selected.indexOf(id) !== -1;

  const onSubmit = (e) => {
    e.preventDefault();
    post_input_expense(OERN, activeInputExpense);
    setActiveInputExpense({
      category: null,
      amount_paid: "",
      payment_type: "",
      receipt_image: null,
    });
    setOpenNewInputExpense(false);
  };

  const onSubmitEdit = (e) => {
    e.preventDefault();
    update_input_expense(OERN, editInputExpense).then(() =>
      setSelected([editInputExpense.id])
    );
    setOpenEditInputExpense(false);
  };

  const handleEditInputExpenseChange = (prop) => (event) => {
    setEditInputExpense({
      ...editInputExpense,
      [prop]: event.target.value,
    });
  };

  return (
    data && (
      <div>
        <Paper className={classes.paper}>
          {AllowedToEdit && (
            <Toolbar
              className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
              })}
            >
              <Typography
                className={classes.title}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {numSelected > 0 ? `${numSelected} selected` : "Add an expense"}
              </Typography>

              {numSelected > 0 ? (
                numSelected === 1 ? (
                  <div style={{display: "flex"}}>
                    <Tooltip title="Edit expense">
                      <IconButton onClick={() => openEditInputExpenseDialog()}>
                        <CreateIcon/>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Remove selected expense">
                      <IconButton
                        onClick={() => {
                          setOpenRemoveInputExpense(true);
                        }}
                      >
                        <DeleteIcon/>
                      </IconButton>
                    </Tooltip>
                  </div>
                ) : numSelected !== numInputExpenses ? (
                  <Tooltip title="Remove selected expenses">
                    <IconButton onClick={() => setOpenRemoveInputExpense(true)}>
                      <DeleteIcon/>
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Remove all expenses">
                    <IconButton onClick={() => setOpenRemoveInputExpense(true)}>
                      <DeleteIcon/>
                    </IconButton>
                  </Tooltip>
                )
              ) : (
                <div style={{display: "flex"}}>
                  <Tooltip title="Add an expense">
                    <IconButton
                      onClick={() =>
                        setOpenNewInputExpense(!openNewInputExpense)
                      }
                    >
                      <AddIcon/>
                    </IconButton>
                  </Tooltip>
                </div>
              )}
            </Toolbar>
          )}
          {submitting_oe_ie && <LinearProgress/>}
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                {AllowedToEdit && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      indeterminate={
                        numSelected > 0 && numSelected < numInputExpenses
                      }
                      checked={
                        numInputExpenses > 0 && numSelected === numInputExpenses
                      }
                      onChange={handleSelectAllClick}
                    />
                  </TableCell>
                )}
                {headCells.map((headCell) => (
                  <TableCell
                    style={{fontWeight: "bold"}}
                    key={headCell.id}
                    padding={
                      headCell.disablePadding && AllowedToEdit
                        ? "none"
                        : "default"
                    }
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={() => createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                      {orderBy === headCell.id ? (
                        <span className={classes.visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(input_expenses, getComparator(order, orderBy)).map(
                (row, index) => {
                  const isItemSelected = isSelected(row.id);
                  return (
                    <TableRowWithDialog
                      key={index}
                      handleClickRow={handleClickRow}
                      row={row}
                      isItemSelected={isItemSelected}
                      AllowedToEdit={AllowedToEdit}
                    />
                  );
                }
              )}
              {totalPrice > 0 && (
                <TableRow>
                  <TableCell colSpan={4} style={{fontWeight: "bold"}}>
                    IE total
                  </TableCell>
                  <TableCell colSpan={3} style={{fontWeight: "bold"}}>
                    {totalPrice.toFixed(2)}
                  </TableCell>
                </TableRow>
              )}

              {data.cash_requested_amount && (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Typography variant="body2" style={{fontWeight: "bold"}}>
                      OE cash total
                    </Typography>
                    {remainingCashAmount !== 0 && (
                      <Typography variant="body2">Remaining Cash</Typography>
                    )}
                  </TableCell>
                  <TableCell colSpan={3}>
                    <Typography variant="body2" style={{fontWeight: "bold"}}>
                      {data.cash_requested_amount.toFixed(2)}
                    </Typography>
                    {remainingCashAmount !== 0 && (
                      <Typography variant="body2">
                        {remainingCashAmount.toFixed(2)}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              )}

              {data.credit_requested_amount && (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Typography variant="body2" style={{fontWeight: "bold"}}>
                      OE credit total
                    </Typography>
                    {remainingCreditAmount !== 0 && (
                      <Typography variant="body2">Remaining Credit</Typography>
                    )}
                  </TableCell>
                  <TableCell colSpan={3}>
                    <Typography variant="body2" style={{fontWeight: "bold"}}>
                      {data.credit_requested_amount.toFixed(2)}
                    </Typography>
                    {remainingCreditAmount !== 0 && (
                      <Typography variant="body2">
                        {remainingCreditAmount.toFixed(2)}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Paper>

        {numSelected > 0 && (
          <Dialog
            open={openRemoveInputExpense}
            onClose={() => setOpenRemoveInputExpense(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
          >
            <DialogTitle
              style={{cursor: "move"}}
              id="draggable-dialog-title"
              onClose={() => setOpenRemoveInputExpense(false)}
            >
              {numSelected > 1
                ? numSelected !== numInputExpenses
                  ? "Are you sure that you would like to remove these expenses?"
                  : "Are you sure that you would like to remove all of these expenses?"
                : "Are you sure that you would like to remove this expense?"}
            </DialogTitle>
            <DialogActions>
              <Button
                onClick={() => setOpenRemoveInputExpense(false)}
                type="submit"
                fullWidth
                color="primary"
                variant="outlined"
                style={{
                  borderRadius: 24,
                  fontWeight: "bold",
                }}
              >
                No, go back
              </Button>
              <Button
                onClick={() => {
                  removeInputExpenses(selected);
                  setSelected([]);
                  setOpenRemoveInputExpense(false);
                }}
                type="submit"
                fullWidth
                color="primary"
                variant="contained"
                style={{borderRadius: 24}}
              >
                Yes, I'm sure
              </Button>
            </DialogActions>
          </Dialog>
        )}

        {openNewInputExpense && (
          <Dialog
            open={openNewInputExpense}
            onClose={handleCloseDialog}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            fullWidth
          >
            <form onSubmit={(e) => onSubmit(e)}>
              <DialogTitle
                style={{cursor: "move"}}
                id="draggable-dialog-title"
                onClose={handleCloseDialog}
              >
                Add an expense
              </DialogTitle>
              <DialogContent>
                <Autocomplete
                  required
                  options={categories}
                  getOptionLabel={(option) => option}
                  onInputChange={(event, newValue) => {
                    handleActiveCategoryChange(newValue);
                  }}
                  getOptionSelected={(option, value) =>
                    option === value ? option : value
                  }
                  value={activeInputExpense.category}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      InputLabelProps={{shrink: true}}
                      label="Category"
                      variant="outlined"
                      placeholder="Type in a new category or select from this list"
                    />
                  )}
                />
                <FormControl fullWidth variant="outlined" required>
                  <InputLabel>Amount paid</InputLabel>
                  <OutlinedInput
                    label="Amount paid *"
                    type="number"
                    value={activeInputExpense.amount_paid}
                    onChange={handleActiveInputExpenseChange("amount_paid")}
                    startAdornment={
                      <InputAdornment position="start">QAR</InputAdornment>
                    }
                    className={classes.number}
                    inputProps={{min: 0, step: 0.01}}
                  />
                </FormControl>
                <FormControl fullWidth variant="outlined" required>
                  <InputLabel>Payment type</InputLabel>
                  <Select
                    value={activeInputExpense.payment_type}
                    onChange={handleActiveInputExpenseChange("payment_type")}
                    label="Payment type *"
                  >
                    <MenuItem value={"Cash"}>Cash</MenuItem>
                    <MenuItem value={"Credit card"}>Credit card</MenuItem>
                  </Select>
                </FormControl>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    fullWidth
                    required
                    inputVariant="outlined"
                    label="Submission date"
                    format="dd / MM / yyyy"
                    value={activeInputExpense.submission_date || null}
                    onChange={(date) => handleActiveDateChange(date)}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
                <ImageUpload
                  allowedToEdit={true}
                  passReceipt={(file) =>
                    setActiveInputExpense({
                      ...activeInputExpense,
                      receipt_image: file,
                    })
                  }
                  receipt_image={activeInputExpense.receipt_image || null}
                />
              </DialogContent>
              <DialogActions style={{padding: 24}}>
                <Fragment>
                  <Button
                    onClick={handleCloseDialog}
                    fullWidth
                    color="primary"
                    variant="outlined"
                    style={{borderRadius: 24}}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={
                      (activeInputExpense.payment_type === "Cash" &&
                        remainingCashAmount.toFixed(2) <
                        parseFloat(activeInputExpense.amount_paid)) ||
                      (activeInputExpense.payment_type === "Credit card" &&
                        remainingCreditAmount.toFixed(2) <
                        parseFloat(activeInputExpense.amount_paid))
                    }
                    type="submit"
                    fullWidth
                    color="primary"
                    variant="contained"
                    style={{borderRadius: 24}}
                  >
                    Add
                  </Button>
                </Fragment>
              </DialogActions>
            </form>
          </Dialog>
        )}
        {openEditInputExpense && (
          <Dialog
            open={openEditInputExpense}
            onClose={handleEditCloseDialog}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            fullWidth
          >
            <form onSubmit={(e) => onSubmitEdit(e)}>
              <DialogTitle
                style={{cursor: "move"}}
                id="draggable-dialog-title"
                onClose={handleEditCloseDialog}
              >
                Edit Expense
              </DialogTitle>
              <DialogContent>
                <Autocomplete
                  required
                  options={categories}
                  getOptionLabel={(option) => option}
                  onInputChange={(event, newValue) => {
                    handleEditCategoryChange(newValue);
                  }}
                  getOptionSelected={(option, value) =>
                    option === value ? option : value
                  }
                  value={editInputExpense.category}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      InputLabelProps={{shrink: true}}
                      label="Category"
                      variant="outlined"
                      placeholder="Type in a new category or select from this list"
                    />
                  )}
                />
                <FormControl fullWidth variant="outlined" required>
                  <InputLabel>Amount paid</InputLabel>
                  <OutlinedInput
                    label="Amount paid *"
                    type="number"
                    value={editInputExpense.amount_paid}
                    onChange={handleEditInputExpenseChange("amount_paid")}
                    startAdornment={
                      <InputAdornment position="start">QAR</InputAdornment>
                    }
                    className={classes.number}
                    inputProps={{min: 0, step: 0.01}}
                  />
                </FormControl>
                <FormControl fullWidth variant="outlined" required>
                  <InputLabel>Payment type</InputLabel>
                  <Select
                    value={editInputExpense.payment_type}
                    onChange={handleEditInputExpenseChange("payment_type")}
                    label="Payment type *"
                  >
                    <MenuItem value={"Cash"}>Cash</MenuItem>
                    <MenuItem value={"Credit card"}>Credit card</MenuItem>
                  </Select>
                </FormControl>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    fullWidth
                    required
                    inputVariant="outlined"
                    label="Submission date"
                    format="dd / MM / yyyy"
                    value={editInputExpense.submission_date || null}
                    onChange={(date) => handleEditDateChange(date)}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleEditCloseDialog}
                  fullWidth
                  color="primary"
                  variant="outlined"
                  style={{borderRadius: 24}}
                >
                  Cancel
                </Button>
                <Button
                  disabled={
                    (editInputExpense.payment_type === "Cash" &&
                      remainingCashAmount + parseFloat(first.amount_paid) <
                      parseFloat(editInputExpense.amount_paid)) ||
                    (editInputExpense.payment_type === "Credit card" &&
                      remainingCreditAmount + parseFloat(first.amount_paid) <
                      parseFloat(editInputExpense.amount_paid))
                  }
                  type="submit"
                  fullWidth
                  color="primary"
                  variant="contained"
                  style={{borderRadius: 24}}
                >
                  Edit
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        )}
      </div>
    )
  );
}

const mapStateToProps = (state) => ({
  data: state.operation_expenses.data,
  submitting_oe_ie: state.operation_expenses.submitting_oe_ie,
});

export default connect(mapStateToProps, {
  post_input_expense,
  delete_input_expense,
  update_input_expense,
})(EnhancedTable);
