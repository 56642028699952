import React from "react";
import {Card, Typography} from "@material-ui/core";
import YouTube from "react-youtube";

const Introduction = () => {
  const opts = {
    height: 360,
    width: "100%",
    playerVars: {autoplay: 0},
  };

  const mdspace = <div style={{padding: 24, width: "100%"}}/>;
  const smspace = <div style={{padding: 8, width: "100%"}}/>;

  return (
    <div style={{maxWidth: 600}}>
      <Typography variant="h4" style={{color: "#77E0FF"}}>
        Welcome to the Avey portal!
      </Typography>
      {smspace}
      <Typography variant="h3">
        Here you will find Avey's most valuable treasure.
      </Typography>
      {mdspace}
      <Typography variant="body1">
        We, at Avey, dedicate ourselves to four core values, namely, passion,
        grit, creativity, and customer-centrality.
      </Typography>
      {smspace}
      <Typography variant="body1">
        With these values, we come together to achieve our vision to offer the
        world an innovative healthcare solution that changes lives. We intend to
        do so through the Avey end-to-end ecosystem that escorts and relieves
        patients from the moment they feel unwell until they get fully cured.
      </Typography>
      {smspace}
      <Typography variant="body1">
        More importantly, we are able to achieve our vision with our most
        important asset - you!
        <b>
          {" "}
          We are proud of the valuable contribution the Avey team makes on a
          daily basis.{" "}
        </b>
        For this, we have dedicated a portal to help Aveyators feel ready to
        make an impact.
      </Typography>
      {smspace}
      <Typography variant="body1">
        In case of any further assistance, please don’t hesitate to reach out to{" "}
        <b>hr@rimads.ai</b>. We are all here to make you feel comfortable and
        enabled.
      </Typography>
      {smspace}
      <Typography variant="body1">
        Let’s have fun changing the world together!
      </Typography>
      {mdspace}
      <Card elevation={8} style={{height: "360px"}}>
        <YouTube videoId="wIWuDIayBqY" opts={opts}/>
      </Card>
    </div>
  );
};

export default Introduction;
