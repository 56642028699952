import React from "react";
import { Grid, Typography } from "@material-ui/core";
import CardLink from "../../components/CardLink";

const RecordDirectory = () => {
  return (
    <div>
      <Typography
        style={{
          fontWeight: "bold",
          fontSize: 40,
          color: "black",
          paddingBottom: 48,
        }}
      >
        Records
      </Typography>
      <Typography style={{ fontSize: 20, marginBottom: 36 }}>
        Choose between the available records
      </Typography>
      <Grid
        container
        spacing={4}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid item>
          <CardLink title={"Suppliers"} link="/app/records/suppliers" />
        </Grid>
        <Grid item>
          <CardLink title={"Online Accounts"} link="/app/records/webapps" />
        </Grid>
      </Grid>
    </div>
  );
};

export default RecordDirectory;
