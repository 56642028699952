import React from "react";
import {
  Typography,
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import CustomNavLinkCard from "../../../CustomNavLinkCard";
import pluralize from "pluralize";

const variantPropsMapper = {
  supervisor: {
    endpoint: "weekly-team-evaluations",
  },
  supervisee: {
    endpoint: "weekly-team-evaluations",
  },
  manager: {
    endpoint: "history",
  },
};

export default function WeeklyKpisTeamcard({ department_team, variant }) {
  const {
    // created_at, // "2023-01-23T01:59:13.479789+03:00"
    // modified_at, // "2023-01-23T01:59:21.204011+03:00"
    // department, // "engineering"
    // supervisor, // 1
    // name, // "Engineering"
    id, // 1,=
    slug, // "ENGINEERING_01"
    supervisees, // [1]
    num_missing_evals,
  } = department_team;

  const { endpoint } = variantPropsMapper[variant];
  const to = `/app/forms/team-performance/teams/${id.toString()}/${endpoint}`;
  const numMembersStr = `You +${pluralize("member", supervisees.length, true)}`;
  const numMissingEvaluationsStr = pluralize(
    "missing evaluation",
    num_missing_evals,
    true
  );

  return (
    <CustomNavLinkCard to={to} style={{ padding: 20 }}>
      <ListItem disableGutters style={{ padding: 0 }}>
        <ListItemAvatar style={{ marginRight: 12 }}>
          <Avatar variant="square" style={{ width: 56, height: 56 }} />
        </ListItemAvatar>
        <ListItemText disableTypography>
          <Typography
            component="div"
            variant="body2"
            style={{ fontWeight: "bold" }}
          >
            {slug}
          </Typography>
          <Typography variant="caption" component="div">
            {numMembersStr}
          </Typography>
          {num_missing_evals > 0 && (
            <Typography
              variant="caption"
              component="div"
              color="primary"
              style={{ fontWeight: "bold" }}
            >
              {numMissingEvaluationsStr}
            </Typography>
          )}
        </ListItemText>
      </ListItem>
    </CustomNavLinkCard>
  );
}
