import React, { Fragment } from "react";
import { TextField, Grid } from "@material-ui/core";

const EmergencyContactContent = ({ contact }) => {
  return (
    <Fragment>
      <Grid item xs={12}>
        <TextField
          label="Name"
          value={contact.name}
          InputProps={{
            disableUnderline: true,
            readOnly: true,
          }}
          fullWidth
          style={{ marginBottom: 12 }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="standard"
          label="Relationship"
          value={contact.relationship}
          InputProps={{
            disableUnderline: true,
            readOnly: true,
          }}
          fullWidth
          style={{ marginBottom: 12 }}
        />
      </Grid>
      <Grid item xs={12} md={contact.email ? 6 : 12}>
        <TextField
          variant="standard"
          label="Phone number (with prefix)"
          value={contact.phone_number}
          InputProps={{
            disableUnderline: true,
            readOnly: true,
          }}
          fullWidth
          style={{ marginBottom: 12 }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          variant="standard"
          label="Email"
          value={contact.email || "-"}
          InputProps={{
            disableUnderline: true,
            readOnly: true,
          }}
          fullWidth
          style={{ marginBottom: 12 }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          variant="standard"
          label="Country of residence"
          value={contact.country}
          InputProps={{
            disableUnderline: true,
            readOnly: true,
          }}
          fullWidth
          style={{ marginBottom: 12 }}
        />
      </Grid>
    </Fragment>
  );
};

export default EmergencyContactContent;
