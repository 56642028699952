import * as React from "react";
import { Grid, Typography, Slider, TextField } from "@material-ui/core";

export default function SliderWithInputField({
  name = "",
  label = "",
  value = 0,
  setValue,
  inputProps = { step: 5, min: 0, max: 100 },
  endAdornment = "%",
  required = false,
}) {
  const step = inputProps?.step || 5;
  const min = inputProps?.min || 0;
  const max = inputProps?.max || 100;

  const handleSliderChange = (e, newValue) =>
    setValue({ target: { value: newValue, name: name } });

  const handleInputChange = (e) => {
    const newValue = e.target.value === "" ? "" : Number(e.target.value);
    setValue({ target: { value: newValue, name: name } });
  };

  const handleBlur = () => {
    if (value <= min) {
      setValue({ target: { value: min, name: name } });
    } else if (value > max) {
      setValue({ target: { value: max, name: name } });
    }
  };

  return (
    <React.Fragment>
      <Typography id="input-slider" variant="body2" gutterBottom>
        {label}
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={9}>
          <Slider
            required={required}
            min={min}
            max={max}
            value={typeof value === "number" ? value : min}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
            step={step}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            required={required}
            variant="outlined"
            value={value}
            size="small"
            onChange={handleInputChange}
            onBlur={handleBlur}
            fullWidth
            InputProps={{
              endAdornment: (
                <Typography
                  style={{ alignItems: "baseline", whiteSpace: "nowrap" }}
                  variant="caption"
                  component="div"
                >
                  {endAdornment}
                </Typography>
              ),
            }}
            inputProps={{
              type: "number",
              "aria-labelledby": "input-slider",
              ...inputProps,
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
