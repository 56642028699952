import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import CardLink from "../../components/CardLink";
import api from "../Analytics/api";
import CircularProgress from "@material-ui/core/CircularProgress";

const perspectives = api.perspectives;

const AnalyticsDirectory = () => {
  const [pers, setPerspectives] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    perspectives()
      .then((res) => {
        setPerspectives(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setPerspectives([]);
        setIsLoading(false);
      });
  }, []);

  return (
    <div style={{ width: 655 }}>
      <Typography variant="h3" style={{ paddingBottom: 48 }}>
        Analytics
      </Typography>
      <Typography variant="h6" style={{ marginBottom: 36 }}>
        Choose between the available perspectives.
      </Typography>
      <Grid
        container
        spacing={3}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          pers.map((perspective) => (
            <Grid item>
              <CardLink
                title={perspective.name}
                link={`/app/analytics/${perspective.name.toLowerCase()}/`}
              />
            </Grid>
          ))
        )}
      </Grid>
    </div>
  );
};

export default AnalyticsDirectory;
