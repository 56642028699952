import React, { Fragment } from "react";
import { Typography, Card, CardContent } from "@material-ui/core";

const CardWithTotals = ({ children, title, subtitle }) => {
  return (
    <Fragment>
      <Typography
        style={{ fontWeight: "bold", alignSelf: "start" }}
        variant="h5"
      >
        {title}
      </Typography>
      <Typography
        style={{ fontWeight: "bold", alignSelf: "start" }}
        color="textSecondary"
        variant="subtitle1"
      >
        {subtitle}
      </Typography>
      <Card elevation={8} style={{ marginTop: 20, marginBottom: 48 }}>
        <CardContent style={{ paddingBottom: 12 }}>{children}</CardContent>
      </Card>
    </Fragment>
  );
};

export default CardWithTotals;
