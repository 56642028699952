import React from "react";
import { Grid, Typography } from "@material-ui/core";
import CustomNavLinkCard from "../../CustomNavLinkCard";

export default function WeeklyKpisDepartmentTeamTab({ to, text }) {
  return (
    <Grid item>
      <CustomNavLinkCard to={to}>
        <Typography variant="body2" style={{ fontWeight: "bold" }}>
          {text}
        </Typography>
      </CustomNavLinkCard>
    </Grid>
  );
}
