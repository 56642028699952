import axios from "axios";
import { AUTHENTICATED_FAIL } from "./authTypes";
import { SNACKBAR_WRITE } from "./procedureType";

import global from "../global";
const { API_URL } = global;

export const load_department = (name) => async (dispatch) => {
  const access = localStorage.getItem("access");
  if (access) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    try {
      const response = await axios.get(
        `${API_URL}/departments/departments/${name}/`,
        config
      );

      return response.data;
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "Unable to load department!",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};
