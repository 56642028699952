export const WEEKLY_KPIS_LOAD_DEPARTMENT_TEAM =
  "WEEKLY_KPIS_LOAD_DEPARTMENT_TEAM";
export const WEEKLY_KPIS_RESET_DEPARTMENT_TEAM =
  "WEEKLY_KPIS_RESET_DEPARTMENT_TEAM";
export const WEEKLY_KPIS_LOAD_ACTIVE_EVALUATIONS =
  "WEEKLY_KPIS_LOAD_ACTIVE_EVALUATIONS";
export const WEEKLY_KPIS_LOAD_PAST_EVALUATIONS =
  "WEEKLY_KPIS_LOAD_PAST_EVALUATIONS";
export const WEEKLY_KPIS_UPDATE_ACTIVE_EVALUATION =
  "WEEKLY_KPIS_UPDATE_ACTIVE_EVALUATION";
export const WEEKLY_KPIS_SELECT_TAGS = "WEEKLY_KPIS_SELECT_TAGS";
export const WEEKLY_KPIS_SELECT_DURATION = "WEEKLY_KPIS_SELECT_DURATION";
