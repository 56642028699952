import React from "react";
import { Grid, Typography } from "@material-ui/core";
import CardLink from "../../components/CardLink";

const OperationExpensesDirectory = () => {
  return (
    <div style={{ width: 655 }}>
      <Typography variant="h3" style={{ paddingBottom: 48 }}>
        Operation Expenses
      </Typography>
      <Typography variant="h6" style={{ marginBottom: 36 }}>
        This module is built to be used primarily by the logistics team to
        facilitate work between the logistics team and the accounting team.
      </Typography>
      <Grid
        container
        spacing={4}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid item>
          <CardLink
            title={"Operation Expense"}
            link="/app/forms/operation-expenses/operation-expense/form"
          />
        </Grid>
        <Grid item>
          <CardLink
            title={"Input Expense"}
            link="/app/forms/operation-expenses/input-expense"
          />
        </Grid>
        <Grid item>
          <CardLink
            title={"Return Cash"}
            link="/app/forms/operation-expenses/return-cash/form"
          />
        </Grid>
        <Grid item>
          <CardLink
            title={"Sales Closing"}
            link="/app/forms/sales-closing"
            disabled={true}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default OperationExpensesDirectory;
