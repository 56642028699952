import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

const styles = () => ({
  root: {
    display: "flex",
    padding: 24,
    paddingBottom: 12,
  },
  grow: {
    flexGrow: 1,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography style={{ fontWeight: "bold", margin: "auto" }} variant="h6">
        {children}
      </Typography>
      <div className={classes.grow} />
      <IconButton
        aria-label="close"
        onClick={onClose}
        style={{ margin: "auto" }}
      >
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
});

export default DialogTitle;
