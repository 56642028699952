import {
  CLEAR_FORMDATA,
  GET_FORMDATA,
  UPDATE_FORM_STATUS,
  PROCESSING,
  PROCESSING_OE_IE,
  FETCHED_ALL_OE,
  NEW_OE_IE,
  EDIT_OE_IE,
  DELETE_OE_IE,
  GET_RCRN,
  CLEAR_RCRN_DATA,
  PROCESSING_RCRN,
  UPDATING_RCRN,
} from "../actions/operationTypes";

const initialState = {
  fetched_all_OE: false,
  fetched: false,
  submitting: false,
  data: null,
  submitting_oe_ie: false,
  fetched_RCRN: false,
  submitting_RCRN: false,
  RCRN_data: null,
};

export default function operation_expenses(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case UPDATING_RCRN:
      return {
        ...state,
        RCRN_data: {
          ...state.RCRN_data,
          operation_expense_requests:
            state.RCRN_data.operation_expense_requests.map(
              (operation_expense_request) =>
                operation_expense_request.OERN !== payload.OERN
                  ? operation_expense_request
                  : payload
            ),
        },
      };

    case PROCESSING_RCRN:
      return {
        ...state,
        submitting_RCRN: payload,
      };

    case CLEAR_RCRN_DATA:
      return {
        ...state,
        fetched_RCRN: false,
        RCRN_data: null,
      };

    case GET_RCRN:
      return {
        ...state,
        RCRN_data: payload,
        fetched_RCRN: true,
      };

    case DELETE_OE_IE:
      return {
        ...state,
        data: {
          ...state.data,
          input_expenses: state.data.input_expenses.filter(
            (input_expense) => !payload.includes(input_expense.id)
          ),
        },
      };

    case EDIT_OE_IE:
      return {
        ...state,
        data: {
          ...state.data,
          input_expenses: state.data.input_expenses.map((input_expense) =>
            input_expense.id !== payload.id ? input_expense : payload
          ),
        },
      };

    case NEW_OE_IE:
      return {
        ...state,
        data: {
          ...state.data,
          input_expenses: state.data.input_expenses.concat(payload),
        },
      };

    case FETCHED_ALL_OE:
      return {
        ...state,
        fetched_all_OE: payload,
      };

    case PROCESSING_OE_IE:
      return {
        ...state,
        submitting_oe_ie: payload,
      };

    case GET_FORMDATA:
      return {
        ...state,
        data: payload,
        fetched: true,
      };

    case UPDATE_FORM_STATUS:
      return {
        ...state,
        submitting: false,
        data: payload.formData,
      };

    case CLEAR_FORMDATA:
      return {
        ...state,
        fetched: false,
        data: null,
      };

    case PROCESSING:
      return {
        ...state,
        submitting: payload,
      };

    default:
      return state;
  }
}
