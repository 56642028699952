import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { MenuItem, Select } from "@material-ui/core";
import { transfer_products } from "../../../actions/procedure";
import Draggable from "react-draggable";
import Autocomplete from "@material-ui/lab/Autocomplete";
import HistoryIcon from "@material-ui/icons/History";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Product",
  },
  {
    id: "product_type",
    numeric: true,
    disablePadding: false,
    label: "Product Type",
  },
  {
    id: "cost_unit_price",
    numeric: true,
    disablePadding: false,
    label: "Unit cost (QAR)",
  },
  {
    id: "selling_unit_price",
    numeric: true,
    disablePadding: false,
    label: "Unit price (QAR)",
  },
  { id: "margin", numeric: true, disablePadding: false, label: "Margin (%)" },
  { id: "edited", numeric: true, disablePadding: false, label: "Edited" },
];

function EnhancedTableHead({ classes, order, orderBy, onRequestSort }) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            style={{ fontWeight: "bold" }}
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
  },

  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  number: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
      {
        display: "none",
      },
  },
}));

function EnhancedTable({
  suppliers,
  selected_products,
  transfer_products,
  openTransferSupplier,
  setOpenTransferSupplier,
  current_supplier_id,
}) {
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [editableProducts, setEditableProducts] = useState(selected_products);
  const [transferSupplier, setTransferSupplier] = useState(null);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const revertToOriginal = (id) => {
    setEditableProducts(
      editableProducts.map((product) =>
        product.id === id
          ? selected_products.find((product) => product.id === id)
          : product
      )
    );
  };

  const handleChangeValue = (id, prop) => (event) => {
    setEditableProducts(
      editableProducts.map((product) =>
        product.id === id
          ? {
              ...product,
              [prop]: event.target.value,
            }
          : product
      )
    );
  };

  const handleChange = (id, prop) => (event) => {
    setEditableProducts(
      editableProducts.map((product) =>
        product.id === id
          ? {
              ...product,
              [prop]: event.target.value,
              margin:
                prop === "selling_unit_price"
                  ? event.target.value > 0
                    ? (
                        (100 * (event.target.value - product.cost_unit_price)) /
                        event.target.value
                      ).toFixed(2)
                    : 0
                  : product.selling_unit_price > 0
                  ? (
                      (100 *
                        (product.selling_unit_price - event.target.value)) /
                      product.selling_unit_price
                    ).toFixed(2)
                  : 0,
            }
          : product
      )
    );
  };

  const onSubmit = (e) => {
    e.preventDefault();
    transfer_products(transferSupplier.id, editableProducts);
    setOpenTransferSupplier(false);
  };

  return (
    <Dialog
      open={openTransferSupplier}
      onClose={() => setOpenTransferSupplier(false)}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth
      maxWidth="md"
    >
      {/* <DialogTitle
        style={{ cursor: "move" }}
        id="draggable-dialog-title"
        onClose={() => setOpenTransferSupplier(false)}
      /> */}
      <form onSubmit={(e) => onSubmit(e)}>
        <DialogContent>
          <Typography variant="h6" style={{ marginBottom: 20 }}>
            Transfer to
          </Typography>
          <Autocomplete
            id="combo-box-demo"
            style={{ marginBottom: 20 }}
            options={suppliers.filter(
              (supplierAuto) => supplierAuto.id !== current_supplier_id
            )}
            getOptionLabel={(option) =>
              `${option.name} (${option.supplier_type})`
            }
            value={transferSupplier}
            getOptionSelected={(option, value) =>
              option.id === value.id ? option : null
            }
            onChange={(event, newValue) => {
              setTransferSupplier(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                label="Supplier"
                variant="outlined"
                placeholder="Search for supplier"
              />
            )}
          />
          <Typography variant="h6" style={{ marginBottom: 20 }}>
            Products
          </Typography>
          <Paper className={classes.paper} elevation={24}>
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size="small"
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />

                <TableBody>
                  {stableSort(
                    editableProducts,
                    getComparator(order, orderBy)
                  ).map((row, index) => {
                    const labelId = `transfer-table-checkbox-${index}`;
                    const original = selected_products.find(
                      (product) => product.id === row.id
                    );

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="default"
                        >
                          <TextField
                            required
                            onChange={handleChangeValue(row.id, "name")}
                            value={row.name}
                            variant="outlined"
                            type="text"
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Select
                            fullWidth
                            value={row.product_type}
                            onChange={handleChangeValue(row.id, "product_type")}
                            variant="outlined"
                          >
                            <MenuItem value={"internal"}>Internal</MenuItem>
                            <MenuItem value={"retail"}>Retail</MenuItem>
                            <MenuItem value={"both"}>Both</MenuItem>
                          </Select>
                        </TableCell>
                        <TableCell align="right">
                          <TextField
                            required
                            onChange={handleChange(row.id, "cost_unit_price")}
                            value={row.cost_unit_price}
                            variant="outlined"
                            type="number"
                            className={classes.number}
                            inputProps={{
                              min: 0,
                              step: 0.01,
                              style: { textAlign: "right" },
                            }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <TextField
                            required
                            onChange={handleChange(
                              row.id,
                              "selling_unit_price"
                            )}
                            value={row.selling_unit_price}
                            variant="outlined"
                            type="number"
                            className={classes.number}
                            inputProps={{
                              min: 0,
                              step: 0.01,
                              style: { textAlign: "right" },
                            }}
                          />
                        </TableCell>
                        <TableCell align="right">{row.margin}</TableCell>
                        <TableCell align="right">
                          {original.name === row.name &&
                          original.product_type === row.product_type &&
                          original.selling_unit_price ===
                            (row.product_type &&
                              parseFloat(row.selling_unit_price)) &&
                          original.cost_unit_price ===
                            (row.product_type &&
                              parseFloat(row.cost_unit_price)) ? (
                            "False"
                          ) : (
                            <IconButton
                              onClick={() => revertToOriginal(row.id)}
                            >
                              <HistoryIcon />
                            </IconButton>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenTransferSupplier(false)}
            fullWidth
            color="primary"
            variant="outlined"
            style={{
              borderRadius: 24,
              fontWeight: "bold",
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={!transferSupplier}
            type="submit"
            fullWidth
            color="primary"
            variant="contained"
            style={{ borderRadius: 24 }}
          >
            Duplicate products
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  suppliers: state.procedures.suppliers,
});

export default connect(mapStateToProps, { transfer_products })(EnhancedTable);
