import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import CustomSkeleton from "./CustomSkeleton";

export default function CustomInfiniteScroll({
  loadNext = () => {},
  numResults = 0,
  next,
  skeletonProps = {
    variant: "default",
  },
}) {
  return (
    <InfiniteScroll
      dataLength={numResults}
      next={loadNext}
      hasMore={Boolean(next)}
      loader={
        <div>
          <CustomSkeleton {...skeletonProps} />
        </div>
      }
    />
  );
}
