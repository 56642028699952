import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import Metric from "./Metric";
import api from "./api";

const metricsInPerspective = api.metricsInPerspective;

const Perspective = ({ name }) => {
  const [metrics, setMetrics] = useState([]);
  useEffect(() => {
    metricsInPerspective(name).then((res) => {
      setMetrics(res ? res.data : []);
    });
  }, [name]);

  return (
    <div>
      <Typography variant="h3" style={{ paddingBottom: 40 }}>
        {name}
      </Typography>
      {metrics.map((metric) => (
        <Metric metric={metric} key={metric.id} />
      ))}
    </div>
  );
};

export default Perspective;
