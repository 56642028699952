import React from "react";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import {
  weeklyKpisCompanyMetric,
  weeklyKpisDepartmentTeamMetric,
} from "../../../actions/weeklyKpis";
import DepartmentTeamEvaluationMetric from "./DepartmentTeamEvaluationMetric";
import WeeklyKpisFilters from "./WeeklyKpisFilters";

const DepartmentTeamEvaluationMetrics = ({
  weeklyKpisCompanyMetric,
  weeklyKpisDepartmentTeamMetric,
  scope = "department_team",
  name = null,
  num_workdays = 7,
}) => {
  const scopeMapper = {
    company: {
      title: name || "Company-wide",
      loadMetrics: weeklyKpisCompanyMetric,
      AcceptableEngines: ["Departments", "Department teams", "Employees"],
    },
    department_team: {
      title: name || "Team-wide",
      loadMetrics: weeklyKpisDepartmentTeamMetric,
      AcceptableEngines: ["Employees"],
    },
  };

  const { title, loadMetrics, AcceptableEngines } = scopeMapper[scope];

  const filters = React.useMemo(
    () => <WeeklyKpisFilters AcceptableEngines={AcceptableEngines} />,
    [AcceptableEngines]
  );

  if (!["company", "department_team"].includes(scope)) {
    return null;
  }

  return (
    <Grid container spacing={3}>
      {filters}
      <Grid item xs={12}>
        <DepartmentTeamEvaluationMetric
          metric="utilization_percent_from"
          title={`${title} Utilization Score`}
          yAxisLabel="Percent %"
          denominator={100}
          stepSize={10}
          loadMetrics={loadMetrics}
        />
      </Grid>
      <Grid item xs={12}>
        <DepartmentTeamEvaluationMetric
          metric="performance_level_percent_from"
          title={`${title} Performance Score`}
          yAxisLabel="Percent %"
          denominator={100}
          stepSize={10}
          loadMetrics={loadMetrics}
        />
      </Grid>
      <Grid item xs={12}>
        <DepartmentTeamEvaluationMetric
          metric="num_days_physical_attendance_from"
          title={`${title} Attendance Score`}
          yAxisLabel={`Days / ${num_workdays}`}
          denominator={num_workdays}
          stepSize={1}
          loadMetrics={loadMetrics}
        />
      </Grid>
    </Grid>
  );
};

export default connect(null, {
  weeklyKpisCompanyMetric,
  weeklyKpisDepartmentTeamMetric,
})(DepartmentTeamEvaluationMetrics);
