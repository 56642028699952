import React from "react";
import { connect } from "react-redux";
import { loadMailingLists } from "../../../actions/rimadsters";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import CustomCircularProgress from "../../../components/CustomCircularProgress";
import CustomInfiniteScroll from "../../../components/CustomInfiniteScroll";
import Grid from "@material-ui/core/Grid";
import MailingGroup from "./MailingGroup";

const MailingLists = ({ loadMailingLists }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [data, setData] = React.useState({
    results: [],
    page: 1,
    next: null,
    count: 0,
  });
  const [objectId, setObjectId] = React.useState(null);

  const handleObjectId = (id) => setObjectId(id);

  React.useEffect(() => {
    setIsLoading(true);
    loadMailingLists({ page: 1 }).then((payload) => {
      setIsLoading(false);
      payload && setData(payload);
    });
  }, [loadMailingLists]);

  const { results, next } = data;
  const noMailingLists = results.length === 0;

  return (
    <div>
      <MailingGroup id={objectId} handleClose={() => handleObjectId(null)} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Typography variant="h3">{"Mailing lists"}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">
            {noMailingLists
              ? "There are currently no mailing lists available"
              : "Here is the list of all Avey Groups in alphabetical order."}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <CustomCircularProgress open={isLoading} />
          {!noMailingLists && (
            <>
              <TableContainer style={{ borderTop: "1px solid #e2e2e2" }}>
                <Table>
                  <TableBody>
                    {results
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map(({ id, name, description }) => {
                        return (
                          <TableRow
                            key={id}
                            style={{ height: "100px", cursor: "pointer" }}
                            hover
                            onClick={() => handleObjectId(id)}
                          >
                            <TableCell>
                              <Typography
                                variant="body2"
                                style={{
                                  fontWeight: "bold",
                                  textDecoration: "underline",
                                }}
                              >
                                {name}
                              </Typography>
                            </TableCell>
                            <TableCell style={{ maxWidth: "220px" }}>
                              <Typography variant="body2">
                                {description}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <CustomInfiniteScroll
                numResults={results.length}
                next={next}
                loadNext={() => {
                  loadMailingLists({ page_number: next }).then((payload) => {
                    if (payload) {
                      const { results: newResults, ...other } = payload;
                      setData({
                        results: [...results, ...newResults],
                        ...other,
                      });
                    }
                  });
                }}
                skeletonProps={{ variant: "table", count: 2, spacing: 1 }}
              />
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default connect(null, { loadMailingLists })(MailingLists);
