import React, { Fragment, useState } from "react";
import { Typography, Popover, IconButton } from "@material-ui/core";
import { Info as InfoIcon } from "@material-ui/icons";

const InfoPopover = ({ info }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);

  return (
    <Fragment>
      <IconButton color="primary" onClick={handleClick}>
        <InfoIcon />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography style={{ padding: 16, maxWidth: 500 }}>{info}</Typography>
      </Popover>
    </Fragment>
  );
};

export default InfoPopover;
