import { useEffect } from "react";
import { connect } from "react-redux";
import { custom_snackbar } from "../actions/procedure";
import { Redirect } from "react-router-dom";

const CustomizeSnackbar = ({ payload, custom_snackbar }) => {
  useEffect(() => {
    custom_snackbar(payload);
  });

  return <Redirect to="/app" />;
};

export default connect(null, { custom_snackbar })(CustomizeSnackbar);
