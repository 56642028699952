import React, { useState } from "react";
import { connect } from "react-redux";

import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "../../../../components/MuiDialogTitle";
import { TextField, InputAdornment, makeStyles } from "@material-ui/core";

import { update_unpaid_campaign_request_accounted_by } from "../../../../actions/marketing_expenses";

const useStyles = makeStyles((theme) => ({
  number: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
      {
        display: "none",
      },
  },
}));

const PaymentInformation = ({
  UCN,
  data,
  current_user,
  update_unpaid_campaign_request_accounted_by,
}) => {
  const classes = useStyles();
  const [paymentData, setPaymentData] = useState({
    expected_cost: "",
    expected_ROI: "",
  });

  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    setOpen(!open);
  };

  const handleChange = (prop) => (event) => {
    setPaymentData({ ...paymentData, [prop]: event.target.value });
  };

  return (
    <div>
      {data.accounting_status.accountants.includes(current_user) &&
        data.accounting_status.status === "pending" && (
          <form
            style={{ width: "100%", marginBottom: 40 }}
            onSubmit={(e) => onSubmit(e)}
          >
            <Typography
              style={{
                marginBottom: 20,
                fontWeight: "bold",
                alignSelf: "start",
              }}
              variant="h5"
            >
              Accounting information
            </Typography>

            <FormControl
              fullWidth
              variant="outlined"
              style={{ marginBottom: 20 }}
            >
              <TextField
                required
                value={paymentData.expected_cost}
                label="Expected cost"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">QAR</InputAdornment>
                  ),
                }}
                onChange={handleChange("expected_cost")}
                type="number"
                className={classes.number}
                inputProps={{ min: 0, step: 0.01 }}
              />
            </FormControl>
            <FormControl
              fullWidth
              variant="outlined"
              style={{ marginBottom: 20 }}
            >
              <TextField
                required
                value={paymentData.expected_ROI}
                label="Expected ROI"
                variant="outlined"
                onChange={handleChange("expected_ROI")}
                multiline
              />
            </FormControl>
            <Button fullWidth variant="contained" color="primary" type="submit">
              Submit
            </Button>
          </form>
        )}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle
          style={{ cursor: "move" }}
          id="draggable-dialog-title"
          onClose={() => handleClick(null)}
        >
          Are you sure? This action cannot be undone.
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => handleClick(null)}
            type="submit"
            fullWidth
            color="primary"
            variant="outlined"
            style={{
              borderRadius: 24,
              fontWeight: "bold",
            }}
          >
            No, go back
          </Button>

          <Button
            onClick={() => {
              update_unpaid_campaign_request_accounted_by(paymentData, UCN);
              handleClick(null);
            }}
            type="submit"
            fullWidth
            color="primary"
            variant="contained"
            style={{ borderRadius: 24 }}
          >
            Yes, I'm sure
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default connect(null, {
  update_unpaid_campaign_request_accounted_by,
})(PaymentInformation);
