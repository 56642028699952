import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  load_emergency_contacts,
  create_emergency_contact,
} from "../../../../actions/auth";
import {
  Typography,
  TextField,
  Grid,
  DialogContent,
  DialogActions,
  Backdrop,
  CircularProgress,
  Button,
} from "@material-ui/core";
import EmergencyContact from "./EmergencyContact";
import DraggableDialog from "../../../../components/DraggableDialog";
import DialogTitle from "../../../../components/MuiDialogTitle";

const EmergencyContacts = ({
  user,
  emergency_contacts,
  load_emergency_contacts,
  create_emergency_contact,
}) => {
  const [formData, setFormData] = useState({
    employee: user.id,
    name: "",
    relationship: "",
    phone_number: "",
    email: "",
    country: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [createMode, setCreateMode] = useState(false);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    load_emergency_contacts(user.id).then(() => setIsLoading(false));
  }, [load_emergency_contacts, user.id]);

  useEffect(() => {
    if (createMode) {
      setFormData({
        employee: user.id,
        name: "",
        relationship: "",
        phone_number: "",
        email: "",
        country: "",
      });
    }
  }, [user.id, createMode]);

  const handleClick = () => setCreateMode(!createMode);
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    setProcessing(true);
    create_emergency_contact(formData).then((created) => {
      setProcessing(false);
      created && setCreateMode(false);
    });
  };

  return (
    <div>
      <div style={{ display: "flex" }}>
        <Typography
          variant="h6"
          style={{ fontWeight: "bold", paddingBottom: 20 }}
        >
          Emergency contacts
        </Typography>
        <div style={{ flexGrow: 1 }} />
        {isLoading ? (
          <CircularProgress size={20} />
        ) : (
          <Typography
            variant="button"
            style={{ fontSize: 16, cursor: "pointer" }}
            color="primary"
            onClick={() => handleClick()}
          >
            Add contact
          </Typography>
        )}
      </div>
      {emergency_contacts.length > 0 ? (
        emergency_contacts.map((contact) => (
          <EmergencyContact
            key={contact.id}
            contact={contact}
            editable={true}
          />
        ))
      ) : (
        <Typography variant="body1">
          Provide us with at least 2 emergency contacts.
        </Typography>
      )}
      <DraggableDialog open={createMode} onClose={handleClick} fullWidth>
        <DialogTitle onClose={handleClick}>New Emergency Contact</DialogTitle>
        <Backdrop style={{ position: "absolute", zIndex: 1 }} open={processing}>
          <CircularProgress style={{ margin: "auto" }} />
        </Backdrop>
        <form onSubmit={(e) => onSubmit(e)}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Name"
                  name="name"
                  value={formData.name}
                  onChange={(e) => onChange(e)}
                  fullWidth
                  style={{ marginBottom: 12 }}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Relationship"
                  name="relationship"
                  value={formData.relationship}
                  onChange={(e) => onChange(e)}
                  fullWidth
                  style={{ marginBottom: 12 }}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  variant="outlined"
                  label="Phone number (with prefix)"
                  name="phone_number"
                  value={formData.phone_number}
                  onChange={(e) => onChange(e)}
                  fullWidth
                  style={{ marginBottom: 12 }}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  variant="outlined"
                  label="Email"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={(e) => onChange(e)}
                  fullWidth
                  style={{ marginBottom: 12 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Country of residence"
                  name="country"
                  value={formData.country}
                  onChange={(e) => onChange(e)}
                  fullWidth
                  style={{ marginBottom: 12 }}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={() => handleClick()}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  color="secondary"
                  variant="contained"
                  type="submit"
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </DraggableDialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  emergency_contacts: state.auth.emergency_contacts,
});

export default connect(mapStateToProps, {
  load_emergency_contacts,
  create_emergency_contact,
})(EmergencyContacts);
