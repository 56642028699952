import React from "react";
import { Route } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";
import LoadWeeklyKpisTeams from "./LoadWeeklyKpisTeams";
import WeeklyKpisDepartmentTeamTab from "../WeeklyKpisTeam/WeeklyKpisDepartmentTeamTab";
import DepartmentTeamEvaluationMetrics from "../WeeklyKpisTeam/DepartmentTeamEvaluationMetrics";
import { connect } from "react-redux";

const WeeklyKpisTeams = ({ user }) => {
  const { groups = [] } = user;
  const isManger = groups.includes("manager");

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h3" style={{ fontWeight: "bold" }}>
          {"Performance evaluation system"}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <WeeklyKpisDepartmentTeamTab
            to={"/app/forms/department-teams/your-teams"}
            text="Teams you're in"
          />
          <WeeklyKpisDepartmentTeamTab
            to={"/app/forms/department-teams/teams-you-supervise"}
            text="Teams you supervise"
          />
          {isManger && (
            <React.Fragment>
              <WeeklyKpisDepartmentTeamTab
                to={"/app/forms/department-teams/teams-you-manage"}
                text="Teams you manage"
              />
              <WeeklyKpisDepartmentTeamTab
                to={"/app/forms/department-teams/metrics"}
                text="Metrics"
              />
            </React.Fragment>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Route
          exact
          path={[
            "/app/forms/department-teams",
            "/app/forms/department-teams/your-teams",
            "/app/forms/department-teams/your-teams/*",
          ]}
          render={() => <LoadWeeklyKpisTeams variant="supervisee" />}
        />
        <Route
          exact
          path={["/app/forms/department-teams/teams-you-supervise"]}
          render={() => <LoadWeeklyKpisTeams variant="supervisor" />}
        />

        {isManger && (
          <React.Fragment>
            <Route
              exact
              path={["/app/forms/department-teams/teams-you-manage"]}
              render={() => <LoadWeeklyKpisTeams variant="manager" />}
            />
            <Route
              exact
              path={["/app/forms/department-teams/metrics"]}
              component={() => (
                <DepartmentTeamEvaluationMetrics
                  scope="company"
                  num_workdays={7}
                />
              )}
            />
          </React.Fragment>
        )}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(WeeklyKpisTeams);
