import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import { FormControl } from "@material-ui/core";
import { add_webapp } from "../../../actions/procedure";
import { connect } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";

import DialogTitle from "../../../components/MuiDialogTitle";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function AddWebApp({ open, setOpen, add_webapp, groups }) {
  const handleClose = () => {
    setOpen(false);
    setFormData({
      name: "",
      manager_groups: [],
    });
  };

  const [formData, setFormData] = useState({
    name: "",
    manager_groups: [],
  });

  const handleChange = (prop) => (event) => {
    setFormData({ ...formData, [prop]: event.target.value });
  };

  const handleAccountManagersChange = (manager_groups) => {
    setFormData({
      ...formData,
      manager_groups: manager_groups,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    add_webapp(formData);
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        fullWidth
      >
        <DialogTitle
          style={{ cursor: "move" }}
          id="draggable-dialog-title"
          onClose={handleClose}
        >
          Add a new website / app
        </DialogTitle>
        <form onSubmit={(e) => onSubmit(e)}>
          <DialogContent>
            <FormControl fullWidth>
              <TextField
                required
                onChange={handleChange("name")}
                value={formData.name}
                autoFocus
                variant="outlined"
                label="Name"
                type="text"
              />
            </FormControl>
            <Autocomplete
              multiple
              id="tags-outlined"
              options={groups}
              getOptionLabel={(option) => option}
              value={formData.manager_groups}
              onChange={(event, newValue) => {
                handleAccountManagersChange(newValue);
              }}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Website / App account managers"
                  placeholder="Search for groups"
                />
              )}
            />
          </DialogContent>
          <DialogActions style={{ padding: 24 }}>
            <Button
              onClick={handleClose}
              fullWidth
              color="primary"
              variant="outlined"
              style={{ borderRadius: 24 }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              fullWidth
              color="primary"
              variant="contained"
              style={{ borderRadius: 24 }}
            >
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  processing: state.procedures.processing,
  groups: state.procedures.groups,
});

export default connect(mapStateToProps, { add_webapp })(AddWebApp);
