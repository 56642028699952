import React from "react";

export default function QuarterMonthProgress({
                                               percentageCompleted,
                                               start_date,
                                             }) {
  const isGrey = new Date() < new Date(start_date);
  const bgColor1 = isGrey ? "#949494" : "#77E0FF";
  const bgColor2 = isGrey ? "#C2C2C2" : "rgba(119, 224, 255, 0.4)";

  return (
    <React.Fragment>
      <div
        style={{
          zIndex: 1,
          position: "absolute",
          backgroundColor: bgColor2,
          width: "100%",
          height: "100%",
          transition: "all 0.3s ease",
        }}
      />
      <div
        style={{
          zIndex: 1,
          position: "absolute",
          backgroundColor: bgColor1,
          width: percentageCompleted,
          height: "100%",
          transition: "all 0.3s ease",
        }}
      />
    </React.Fragment>
  );
}
