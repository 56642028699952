import React, { useState } from "react";
import InfoIcon from "@material-ui/icons/Info";
import { Card, CardContent, Collapse, IconButton } from "@material-ui/core";

export default function PopperPopupState({ content }) {
  const [toggle, setToggle] = useState(false);

  return (
    <span>
      <IconButton onClick={() => setToggle(!toggle)}>
        <InfoIcon color="primary" />
      </IconButton>
      <Collapse in={toggle}>
        <Card>
          <CardContent style={{ padding: 12 }}>{content}</CardContent>
        </Card>
      </Collapse>
    </span>
  );
}
