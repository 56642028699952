import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import { update_marketing_expense_request_status } from "../../../../actions/marketing_expenses";
import {
  OutlinedInput,
  TextField,
  InputAdornment,
  InputLabel,
  FormControl,
  Dialog,
  DialogActions,
  Button,
  Typography,
} from "@material-ui/core";
import DialogTitle from "../../../../components/MuiDialogTitle";
import CardWithTotals from "../../../../components/CardWithTotals";

const ApprovalStatus = ({
  MERN,
  current_user,
  data,
  update_marketing_expense_request_status,
}) => {
  const [note, setNote] = useState("");
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState(null);

  useEffect(() => {
    setNote(data.approval_status.note || "");
  }, [setNote, data.approval_status.note]);

  const handleChange = (prop) => (event) => {
    setNote(event.target.value);
  };

  const handleClick = (action) => {
    setOpen(!open);
    action !== null && setAction(action);
  };

  return (
    data.approval_status.status !== "cancelled" &&
    data.approval_status.approvers.includes(current_user) &&
    data.approval_status.status === "pending" && (
      <div style={{ width: "100%" }}>
        <CardWithTotals
          title="Quick stats"
          subtitle={`Amounts approved in the last
          ${parseInt(
            (new Date() -
              new Date(new Date().getFullYear(), new Date().getMonth(), 0)) /
              (1000 * 60 * 60 * 24),
            10
          )}
          days`}
        >
          <FormControl fullWidth>
            <TextField
              label="Total MERN"
              value={data.totals["total_MERN"]}
              InputProps={{
                disableUnderline: true,
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">QAR</InputAdornment>
                ),
              }}
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              label="Total UCN"
              value={data.totals["total_UCN"]}
              InputProps={{
                disableUnderline: true,
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">QAR</InputAdornment>
                ),
              }}
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              label="Total Marketing Supplies IRN"
              value={data.totals["total_IRN"]}
              InputProps={{
                disableUnderline: true,
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">QAR</InputAdornment>
                ),
              }}
            />
          </FormControl>
          <FormControl fullWidth style={{ marginBottom: 0 }}>
            <TextField
              style={{ marginBottom: 0 }}
              label="Total MS IRN + MERN"
              value={data.totals["total_IRN"] + data.totals["total_MERN"]}
              InputProps={{
                disableUnderline: true,
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">QAR</InputAdornment>
                ),
              }}
            />
          </FormControl>
        </CardWithTotals>
        <Typography
          style={{ marginBottom: 20, fontWeight: "bold", alignSelf: "start" }}
          variant="h5"
        >
          Proceed with the request
        </Typography>
        <FormControl fullWidth variant="outlined" style={{ marginBottom: 20 }}>
          {action === "approve" || action === null ? (
            <div>
              <InputLabel>Note</InputLabel>
              <OutlinedInput
                label="Note"
                fullWidth
                onChange={handleChange()}
                value={note}
              />
            </div>
          ) : (
            <div>
              <InputLabel required>Note</InputLabel>
              <OutlinedInput
                required
                fullWidth
                label="Note"
                onChange={handleChange()}
                value={note}
              />
            </div>
          )}
        </FormControl>
        <div style={{ marginBottom: 64, textAlign: "center", display: "flex" }}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => handleClick("approve")}
          >
            Approve
          </Button>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            onClick={() => handleClick("reject")}
            style={{ marginRight: 10, marginLeft: 10 }}
          >
            Reject
          </Button>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            onClick={() => handleClick("return")}
          >
            Send back
          </Button>
        </div>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
        >
          <DialogTitle
            style={{ cursor: "move" }}
            id="draggable-dialog-title"
            onClose={() => handleClick(null)}
          >
            {(action === "approve" &&
              "Are you sure that you want to approve this procurement request? This action cannot be undone.") ||
              (action === "reject" &&
                (note === ""
                  ? "Please provide a reason to the requester."
                  : "Are you sure that you want to reject this procurement request? This action cannot be undone.")) ||
              (action === "return" &&
                (note === ""
                  ? "Please provide a reason to the requester."
                  : "Are you sure that you want to return this procurement request? This action cannot be undone."))}
          </DialogTitle>
          <DialogActions>
            {["reject", "return"].includes(action) && note === "" ? (
              <Button
                onClick={() => handleClick(null)}
                type="submit"
                fullWidth
                color="primary"
                variant="contained"
                style={{ borderRadius: 24 }}
              >
                OK
              </Button>
            ) : (
              <Fragment>
                <Button
                  onClick={() => handleClick(null)}
                  type="submit"
                  fullWidth
                  color="primary"
                  variant="outlined"
                  style={{ borderRadius: 24 }}
                >
                  No, go back
                </Button>
                <Button
                  onClick={() => {
                    update_marketing_expense_request_status(action, MERN, note);
                    handleClick(null);
                  }}
                  type="submit"
                  fullWidth
                  color="primary"
                  variant="contained"
                  style={{ borderRadius: 24 }}
                >
                  {(action === "approve" && "Yes, approve it") ||
                    (action === "reject" && "Yes, reject it") ||
                    (action === "return" && "Yes, return it")}
                </Button>
              </Fragment>
            )}
          </DialogActions>
        </Dialog>
      </div>
    )
  );
};

export default connect(null, { update_marketing_expense_request_status })(
  ApprovalStatus
);
