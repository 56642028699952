import {
  CLEAR_TIME_OFF_FORMDATA,
  GET_TIME_OFF_FORMDATA,
  UPDATE_TIME_OFF_FORMSTATUS,
  PROCESSING_TIME_OFF_REQUEST,
} from "../actions/personalTypes";

const initialState = {
  fetched: false,
  submitting: false,
  data: null,
};

export default function personal(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_TIME_OFF_FORMDATA:
      return {
        ...state,
        data: payload,
        fetched: true,
      };

    case UPDATE_TIME_OFF_FORMSTATUS:
      return {
        ...state,
        submitting: false,
        data: payload.formData,
      };

    case CLEAR_TIME_OFF_FORMDATA:
      return {
        ...state,
        fetched: false,
        data: null,
      };

    case PROCESSING_TIME_OFF_REQUEST:
      return {
        ...state,
        submitting: payload,
      };

    default:
      return state;
  }
}
