import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Typography,
  Button,
  LinearProgress,
  Toolbar,
  Tooltip,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  makeStyles,
  InputAdornment,
  TextField,
  Grid,
} from "@material-ui/core";
import { connect } from "react-redux";
import { load_analytics_table } from "../../../actions/auth";
import { CSVLink } from "react-csv";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import FilterListIcon from "@material-ui/icons/FilterList";
import DialogTitle from "../../../components/MuiDialogTitle";
import GetAppIcon from "@material-ui/icons/GetApp";
import Draggable from "react-draggable";
import Autocomplete from "@material-ui/lab/Autocomplete";

const RequestsMapping = {
  "Inventory socking": "IRN",
  "Marketing expenses": "MERN",
  "Unpaid campaigns": "UCN",
  Reimbursement: "RRN",
  Operations: "OERN",
};

const PaymentMethodsOptions = [
  "Cash",
  "Credit card",
  "Debit card",
  "Cheque",
  "Paypal",
  "Bank transfer",
  "Credit application",
];

const StatusOptions = ["pending", "to collect", "received"];

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "requested_at",
    label: "Requested At",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "RN",
    label: "Request Number",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "amount",
    label: "Amount (QAR)",
    numeric: true,
    disablePadding: false,
  },
  {
    id: "payment_type",
    label: "Payment Method",
    numeric: true,
    disablePadding: false,
  },
  {
    id: "date_received",
    label: "Date Received",
    numeric: true,
    disablePadding: false,
  },
  {
    id: "status",
    label: "Status",
    numeric: true,
    disablePadding: false,
  },
];

const headers = headCells.map((header) => ({
  label: header.label,
  key: header.id,
}));

function EnhancedTableHead({ classes, order, orderBy, onRequestSort }) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            style={{ fontWeight: "bold" }}
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  number: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
      {
        display: "none",
      },
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
}));

function EnhancedTable({ load_analytics_table }) {
  const classes = useStyles();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("requested_at");

  const [useEffectLoaded, setUseEffectLoaded] = useState(true);
  const [is_loading, setIsLoading] = useState(false);
  const [page_number, setPageNumber] = useState(1);
  const [interval, setInterval] = useState({
    start_date: null,
    end_date: null,
  });
  const [analytics_content, setAnalyticsContent] = useState([]);
  const [openRangeDialog, setOpenRangeDialog] = useState(false);

  const [filter, setFilter] = useState({
    amount: {
      label: "amount",
      equality_type: "range",
      comparisons: [null, null],
    },
    payment_type: {
      label: "payment method",
      equality_type: "includes",
      comparisons: PaymentMethodsOptions,
    },
    status: {
      label: "status",
      equality_type: "includes",
      comparisons: StatusOptions,
    },
    RN: {
      label: "Request number",
      equality_type: "starts_with",
      comparisons: Object.keys(RequestsMapping),
    },
  });

  const [openFilterDialog, setOpenFilterDialog] = useState(false);

  useEffect(() => {
    load_analytics_table(1, interval).then((data) => {
      setPageNumber(data.page_number);
      setAnalyticsContent(data.statuses);
      setUseEffectLoaded(false);
    });
  }, [load_analytics_table, interval]);

  const Retrieve = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setOpenRangeDialog(false);
    load_analytics_table(1, interval).then((data) => {
      setPageNumber(data.page_number);
      setAnalyticsContent(data.statuses);
      setIsLoading(false);
    });
  };

  const LoadNextPage = (e) => {
    e.preventDefault();
    setIsLoading(true);
    load_analytics_table(page_number, interval).then((data) => {
      setPageNumber(data.page_number);
      setAnalyticsContent(analytics_content.concat(data.statuses));
      setIsLoading(false);
    });
  };

  const handleDateChange = (date, prop) => {
    setInterval({ ...interval, [prop]: date });
  };

  const handleAmountChange = (amountId) => (event) => {
    var temp = filter.amount.comparisons;
    temp[amountId] = event.target.value;
    setFilter({
      ...filter,
      amount: {
        ...filter["amount"],
        comparisons: temp,
      },
    });
  };

  const handleAutocompleteChange = (value, autocompleteId) => {
    setFilter({
      ...filter,
      [autocompleteId]: {
        ...filter[autocompleteId],
        comparisons: value,
      },
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const csvReport = {
    data: analytics_content,
    headers: headers,
    filename: `Portal_Analytics_${new Date().toLocaleDateString()}`,
  };

  return (
    <div>
      <Typography variant="h3" style={{ marginBottom: 40 }}>
        Request Analytics
      </Typography>

      <Dialog
        open={openRangeDialog}
        onClose={() => setOpenRangeDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperComponent={PaperComponent}
        hideBackdrop
      >
        <DialogTitle
          style={{ cursor: "move" }}
          id="draggable-dialog-title"
          onClose={() => setOpenRangeDialog(false)}
        >
          Select a range for the requests
        </DialogTitle>

        <form onSubmit={(e) => Retrieve(e)}>
          <DialogContent></DialogContent>
          <DialogActions>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Retrieve
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog
        open={openFilterDialog}
        onClose={() => setOpenFilterDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperComponent={PaperComponent}
        style={{ width: "100%" }}
        maxWidth="sm"
        hideBackdrop
      >
        <DialogTitle
          style={{ cursor: "move" }}
          id="draggable-dialog-title"
          onClose={() => setOpenFilterDialog(false)}
        >
          Select your filters
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  required
                  inputVariant="outlined"
                  label="Start date"
                  format="dd / MM / yyyy"
                  value={interval.start_date || null}
                  onChange={(date) => handleDateChange(date, "start_date")}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  required
                  inputVariant="outlined"
                  label="End date"
                  format="dd / MM / yyyy"
                  value={interval.end_date || null}
                  onChange={(date) => handleDateChange(date, "end_date")}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  disabled={interval.start_date === null}
                  minDate={interval.start_date}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Greater than"
                variant="outlined"
                value={filter.amount.comparisons[0]}
                onChange={handleAmountChange(0)}
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">QAR</InputAdornment>
                  ),
                }}
                className={classes.number}
                inputProps={{ min: 0, step: 0.01 }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Less than"
                variant="outlined"
                value={filter.amount.comparisons[1]}
                onChange={handleAmountChange(1)}
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">QAR</InputAdornment>
                  ),
                }}
                className={classes.number}
                inputProps={{ min: 0, step: 0.01 }}
              />
            </Grid>
          </Grid>
          <Autocomplete
            required
            multiple
            options={StatusOptions}
            getOptionLabel={(option) => option}
            onChange={(event, newValue) => {
              handleAutocompleteChange(newValue, "status");
            }}
            getOptionSelected={(option, value) =>
              option === value ? option : null
            }
            fullWidth
            filterSelectedOptions
            value={filter.status.comparisons}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                InputLabelProps={{ shrink: true }}
                label="Payment method"
                variant="outlined"
              />
            )}
          />
          <Autocomplete
            required
            multiple
            options={PaymentMethodsOptions}
            getOptionLabel={(option) => option}
            onChange={(event, newValue) => {
              handleAutocompleteChange(newValue, "payment_type");
            }}
            getOptionSelected={(option, value) =>
              option === value ? option : null
            }
            fullWidth
            filterSelectedOptions
            value={filter.payment_type.comparisons}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                InputLabelProps={{ shrink: true }}
                label="Payment method"
                variant="outlined"
              />
            )}
          />
          <Autocomplete
            required
            multiple
            options={Object.keys(RequestsMapping)}
            getOptionLabel={(option) => option}
            onChange={(event, newValue) => {
              handleAutocompleteChange(newValue, "RN");
            }}
            getOptionSelected={(option, value) =>
              option === value ? option : null
            }
            fullWidth
            filterSelectedOptions
            value={filter.RN.comparisons}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                InputLabelProps={{ shrink: true }}
                label="Payment method"
                variant="outlined"
              />
            )}
          />
        </DialogContent>
      </Dialog>

      <TableContainer className={classes.paper} component={Paper} elevation={8}>
        {is_loading && <LinearProgress />}
        {analytics_content.length > 0 && (
          <Toolbar className={classes.toolbar}>
            <Typography
              style={{ flex: "1 1 100%" }}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {(interval.start_date &&
                interval.start_date.toLocaleDateString()) ||
                (interval.end_date && interval.end_date.toLocaleDateString()) ||
                (interval.end_date && interval.start_date.toLocaleDateString())}
            </Typography>

            <div style={{ display: "flex" }}>
              <Tooltip title="Export table">
                <CSVLink {...csvReport}>
                  <IconButton>
                    <GetAppIcon />
                  </IconButton>
                </CSVLink>
              </Tooltip>
              <Tooltip title="Select filters">
                <IconButton onClick={() => setOpenFilterDialog(true)}>
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
            </div>
          </Toolbar>
        )}
        {useEffectLoaded && <LinearProgress />}
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="small"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />

          <TableBody>
            {stableSort(analytics_content, getComparator(order, orderBy))
              .filter((row) =>
                Object.entries(filter)
                  .map((filter_type) => {
                    const k = filter_type[0];
                    const equality = filter_type[1].equality_type;
                    const comparisons = filter_type[1].comparisons;
                    if (equality === "includes") {
                      return comparisons.length > 0
                        ? comparisons.includes(row[k])
                        : true;
                    } else if (equality === "range") {
                      return (
                        (!comparisons[0] && row[k] <= comparisons[1]) ||
                        (!comparisons[1] && row[k] >= comparisons[0]) ||
                        (comparisons[0] &&
                          comparisons[1] &&
                          row[k] <= comparisons[1] &&
                          row[k] >= comparisons[0])
                      );
                    } else if (equality === "starts_with") {
                      return comparisons.length > 0
                        ? comparisons
                            .map((comparison) =>
                              row[k].startsWith(RequestsMapping[comparison])
                            )
                            .reduce((swbool, bool) => swbool || bool, false)
                        : true;
                    }
                    return true;
                  })
                  .reduce((sumOfBools, bool) => sumOfBools && bool, true)
              )
              .map((row, index) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.RN}
                  selected={!row.date_received}
                >
                  <TableCell component="th" scope="row" padding="default">
                    {new Date(row.requested_at).toLocaleDateString()}
                  </TableCell>
                  <TableCell>{row.RN}</TableCell>
                  <TableCell align="right">{row.amount}</TableCell>
                  <TableCell align="right">{row.payment_type}</TableCell>
                  <TableCell align="right">
                    {row.date_received &&
                      new Date(row.date_received).toLocaleDateString()}
                  </TableCell>
                  <TableCell align="right">{row.status}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {is_loading && <LinearProgress />}
      </TableContainer>

      <div style={{ display: "flex", marginTop: 20 }}>
        <div style={{ flexGrow: 1 }} />
        {page_number !== 0 && (
          <Button color="primary" onClick={LoadNextPage}>
            Load more
          </Button>
        )}
      </div>
    </div>
  );
}

export default connect(null, { load_analytics_table })(EnhancedTable);
