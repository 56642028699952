import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import ProcurementStepper from "./ProcurementStepper";
import ApprovalStatus from "./ApprovalStatus";
import AccountingStatus from "./AccountingStatus";
import RequestInput from "./RequestInput";
import CustomizeSnackbar from "../../../components/CustomizeSnackbar";

import { get_procurement_request } from "../../../actions/procurement";

const useStyles = makeStyles((theme) => ({
  paper: {
    flexDirection: "column",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
  },
}));

const ProcurementView = ({
  get_procurement_request,
  current_user,
  submitting,
  data,
}) => {
  const classes = useStyles();
  let { IRN } = useParams();
  const [activeStepper, setActiveStepper] = useState(0);

  useEffect(() => {
    get_procurement_request(IRN);
  }, [IRN, get_procurement_request]);

  return data !== null && !submitting ? (
    data.requester === current_user ||
    data.expected_receiver_staff
      .map((staff) => staff.username)
      .includes(current_user) ||
    data.approval_status.approvers.includes(current_user) ||
    data.inventory_request_payments[0].accounting_status.accountants.includes(
      current_user
    ) ? (
      <div>
        <ProcurementStepper
          procurementFormData={data}
          activeStepper={activeStepper}
          setActiveStepper={setActiveStepper}
          current_user={current_user}
        />
        <Fragment>
          <ApprovalStatus IRN={IRN} current_user={current_user} data={data} />
          <AccountingStatus
            IRN={IRN}
            current_user={current_user}
            data={data}
            activeStepper={activeStepper}
          />
        </Fragment>
        <RequestInput IRN={IRN} current_user={current_user} />
      </div>
    ) : (
      <CustomizeSnackbar
        payload={{
          severity: "error",
          message: "Permission Denied",
        }}
      />
    )
  ) : (
    <div className={classes.center}>
      <CircularProgress style={{ margin: "auto" }} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  submitting: state.procurement.submitting,
  data: state.procurement.data,
  current_user: state.auth.user.username,
});

export default connect(mapStateToProps, { get_procurement_request })(
  ProcurementView
);
