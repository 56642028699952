import axios from "axios";
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_LOADED_SUCCESS,
  USER_LOADED_FAIL,
  AUTHENTICATED_SUCCESS,
  AUTHENTICATED_FAIL,
  LOGOUT,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAIL,
  PASSWORD_RESET_CONFIRM_SUCCESS,
  PASSWORD_RESET_CONFIRM_FAIL,
  SIGNUP_SUCCESS,
  SIGNUP_FAIL,
  ACTIVATION_SUCCESS,
  ACTIVATION_FAIL,
  LOAD_NOTIFICATION_SUCCESS,
  LOAD_NOTIFICATION_FAILURE,
  MARK_NOTIFICAITON_AS_READ,
  MARK_NOTIFICATIONS_AS_READ,
  CLEAR_NOTIFICATIONS,
  SNACKBAR_DELETE,
  CHANGE_PASSWORD_ERROR,
  PROCESSING_PASSWORD_RESET,
  INCOMING_NOTIFICATION,
  PROCESSING_PROFILE_PICTURE_UPDATE,
  CHANGE_PROFILE_PICTURE,
  UPDATE_USER_INFORMATION,
  LOAD_USER_TIME_OFFS_SUCCESS,
  LOAD_USER_TIME_OFFS_FAILURE,
  LOAD_EMERGENCY_CONTACTS,
  CREATE_EMERGENCY_CONTACT,
  UPDATE_EMERGENCY_CONTACT,
  DELETE_EMERGENCY_CONTACT,
} from "./authTypes";

import { SNACKBAR_WRITE } from "./procedureType";

import { get_reimbursement_request } from "./reimbursement";
import {
  get_unpaid_campaign_request,
  get_marketing_expense_request,
} from "./marketing_expenses";
import { get_time_off_request } from "./personal";
import { get_procurement_request } from "./procurement";

import global from "../global";
const { API_URL } = global;

export const checkAuthenticated = () => async (dispatch) => {
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    const body = JSON.stringify({ token: localStorage.getItem("access") });
    try {
      const response = await axios.post(
        `${API_URL}/auth/jwt/verify/`,
        body,
        config
      );
      if (response.data.code !== "token_not_valid") {
        dispatch({
          type: AUTHENTICATED_SUCCESS,
        });
      } else {
        dispatch({
          type: AUTHENTICATED_FAIL,
        });
      }
    } catch (error) {
      dispatch({
        type: AUTHENTICATED_FAIL,
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const load_user = () => async (dispatch) => {
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };

    try {
      const response = await axios.get(`${API_URL}/auth/users/me/`, config);
      dispatch({
        type: USER_LOADED_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: USER_LOADED_FAIL,
      });
    }
  } else {
    dispatch({
      type: USER_LOADED_FAIL,
    });
  }
};

export const signup =
  (username, email, first_name, last_name, password, re_password) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({
      username,
      email,
      first_name,
      last_name,
      password,
      re_password,
    });

    try {
      const response = await axios.post(`${API_URL}/auth/users/`, body, config);
      dispatch({
        type: SIGNUP_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: SIGNUP_FAIL,
      });
    }
  };

export const verify = (uid, token) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({ uid, token });

  try {
    await axios.post(`${API_URL}/auth/users/activation/`, body, config);
    dispatch({
      type: ACTIVATION_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: ACTIVATION_FAIL,
    });
  }
};

export const login = (username, password) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({ username, password });

  try {
    const response = await axios.post(
      `${API_URL}/auth/jwt/create/`,
      body,
      config
    );
    dispatch({
      type: LOGIN_SUCCESS,
      payload: response.data,
    });
    dispatch(load_user());
  } catch (error) {
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

export const reset_password = (email) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({ email });

  try {
    await axios.post(`${API_URL}/auth/users/reset_password/`, body, config);
    dispatch({
      type: PASSWORD_RESET_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: PASSWORD_RESET_FAIL,
    });
  }
};

export const reset_password_confirm =
  (uid, token, new_password, re_new_password) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({ uid, token, new_password, re_new_password });

    try {
      await axios.post(
        `${API_URL}/auth/users/reset_password_confirm/`,
        body,
        config
      );
      dispatch({
        type: PASSWORD_RESET_CONFIRM_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: PASSWORD_RESET_CONFIRM_FAIL,
      });
    }
  };

export const logout = () => (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
};

export const load_notifications = (pageNumber) => async (dispatch) => {
  pageNumber === 1 &&
    dispatch({
      type: CLEAR_NOTIFICATIONS,
    });

  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    try {
      const response = await axios.get(
        `${API_URL}/accounts/notifications/${pageNumber}/`,
        config
      );

      dispatch({
        type: LOAD_NOTIFICATION_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "Unable to load your notifications!",
          severity: "error",
        },
      });
      dispatch({
        type: LOAD_NOTIFICATION_FAILURE,
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const mark_notification_as_read = (id) => async (dispatch) => {
  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    const body = JSON.stringify({ read: true });

    try {
      await axios.post(`${API_URL}/accounts/notification/${id}/`, body, config);
      dispatch({
        type: MARK_NOTIFICAITON_AS_READ,
        payload: id,
      });
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "Unable to carry out action. Please try again later!",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const mark_notifications_as_read = () => async (dispatch) => {
  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    try {
      await axios.post(`${API_URL}/accounts/notifications/1/`, {}, config);
      dispatch({
        type: MARK_NOTIFICATIONS_AS_READ,
      });
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "Unable to carry out action. Please try again later!",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const remove_snackbar = () => async (dispatch) => {
  dispatch({
    type: SNACKBAR_DELETE,
  });
};

export const update_password =
  (old_password, new_password1, new_password2) => async (dispatch) => {
    dispatch({
      type: PROCESSING_PASSWORD_RESET,
      payload: true,
    });
    const access = localStorage.getItem("access");
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `JWT ${access}`,
        },
      };
      const body = JSON.stringify({
        old_password,
        new_password1,
        new_password2,
      });

      try {
        const response = await axios.post(
          `${API_URL}/accounts/update_password/`,
          body,
          config
        );

        dispatch({
          type: CHANGE_PASSWORD_ERROR,
          payload: response.data,
        });

        !response.data.error &&
          dispatch({
            type: SNACKBAR_WRITE,
            payload: response.data,
          });

        dispatch({
          type: PROCESSING_PASSWORD_RESET,
          payload: false,
        });

        return response.data.error;
      } catch (error) {
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: "An error has occured! Please try again later.",
            severity: "error",
          },
        });
      }
    } else {
      dispatch({
        type: AUTHENTICATED_FAIL,
      });
    }

    dispatch({
      type: PROCESSING_PASSWORD_RESET,
      payload: false,
    });
    return true;
  };

export const incoming_notification =
  (notification, location) => async (dispatch) => {
    dispatch({
      type: INCOMING_NOTIFICATION,
      payload: notification,
    });
    const location_list = location.split("/");

    if (
      notification.notification.link === "reimbursement/form" &&
      location_list.includes(notification.notification.request_number)
    ) {
      dispatch(
        get_reimbursement_request(notification.notification.request_number)
      );
    } else if (
      notification.notification.link === "marketing-expenses/unpaid-form" &&
      location_list.includes(notification.notification.request_number)
    ) {
      dispatch(
        get_unpaid_campaign_request(notification.notification.request_number)
      );
    } else if (
      notification.notification.link === "marketing-expenses/paid-form" &&
      location_list.includes(notification.notification.request_number)
    ) {
      dispatch(
        get_marketing_expense_request(notification.notification.request_number)
      );
    } else if (
      notification.notification.link === "personal/time-off" &&
      location_list.includes(notification.notification.request_number)
    ) {
      dispatch(get_time_off_request(notification.notification.request_number));
    } else if (
      notification.notification.link === "procurement/form" &&
      location_list.includes(notification.notification.request_number)
    ) {
      dispatch(
        get_procurement_request(notification.notification.request_number)
      );
    }
  };

export const update_profile_picture = (profile_picture) => async (dispatch) => {
  dispatch({
    type: PROCESSING_PROFILE_PICTURE_UPDATE,
    payload: true,
  });
  const access = localStorage.getItem("access");
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };
    let formData = new FormData();
    formData.append("picture", profile_picture);
    try {
      const response = await axios.post(
        `${API_URL}/accounts/update_profile_picture/`,
        formData,
        config
      );

      dispatch({
        type: SNACKBAR_WRITE,
        payload: response.data,
      });

      dispatch({
        type: CHANGE_PROFILE_PICTURE,
        payload: response.data.picture,
      });
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "An error has occured! Please try again later.",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }

  dispatch({
    type: PROCESSING_PROFILE_PICTURE_UPDATE,
    payload: false,
  });
};

export const load_analytics_table =
  (pageNumber, interval) => async (dispatch) => {
    const access = localStorage.getItem("access");
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `JWT ${access}`,
        },
      };

      const body = JSON.stringify(interval);
      try {
        const response = await axios.post(
          `${API_URL}/accounts/payment_analytics/${pageNumber}/`,
          body,
          config
        );

        return response.data;
      } catch (error) {
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: "Unable to load table!",
            severity: "error",
          },
        });
      }
    } else {
      dispatch({
        type: AUTHENTICATED_FAIL,
      });
    }
  };

export const update_user_information =
  (get_emails, phone_number) => async (dispatch) => {
    const access = localStorage.getItem("access");
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `JWT ${access}`,
        },
      };

      const body = JSON.stringify({
        get_emails: get_emails,
        phone_number: phone_number,
      });
      try {
        const response = await axios.post(
          `${API_URL}/accounts/update_user_information/`,
          body,
          config
        );

        dispatch({
          type: SNACKBAR_WRITE,
          payload: response.data,
        });

        dispatch({
          type: UPDATE_USER_INFORMATION,
          payload: response.data.user,
        });
      } catch (error) {
        dispatch({
          type: SNACKBAR_WRITE,
          payload: {
            message: "Unable to edit your personal information!",
            severity: "error",
          },
        });
      }
    } else {
      dispatch({
        type: AUTHENTICATED_FAIL,
      });
    }
  };

export const load_time_offs = () => async (dispatch) => {
  const access = localStorage.getItem("access");
  if (access) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    try {
      const response = await axios.get(
        `${API_URL}/miscellaneous/time_offs/annual_time_offs/`,
        config
      );

      dispatch({
        type: LOAD_USER_TIME_OFFS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: LOAD_USER_TIME_OFFS_FAILURE,
      });
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "Unable to load time off!",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const load_emergency_contacts = (id) => async (dispatch) => {
  const access = localStorage.getItem("access");
  if (access) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    try {
      const response = await axios.get(
        `${API_URL}/accounts/emergency_contacts/?employee__id=${id}`,
        config
      );

      dispatch({
        type: LOAD_EMERGENCY_CONTACTS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "Unable to load emegency contacts!",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const create_emergency_contact = (data) => async (dispatch) => {
  const access = localStorage.getItem("access");
  if (access) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    try {
      const response = await axios.post(
        `${API_URL}/accounts/emergency_contacts/`,
        data,
        config
      );

      dispatch({
        type: CREATE_EMERGENCY_CONTACT,
        payload: response.data,
      });
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "Successfully created emegency contact!",
          severity: "success",
        },
      });
      return true;
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "Unable to create emegency contact!",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const update_emergency_contact = (data) => async (dispatch) => {
  const access = localStorage.getItem("access");
  if (access) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    try {
      const response = await axios.put(
        `${API_URL}/accounts/emergency_contacts/${data.id}/`,
        data,
        config
      );

      dispatch({
        type: UPDATE_EMERGENCY_CONTACT,
        payload: response.data,
      });
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "Successfully updated emegency contact!",
          severity: "success",
        },
      });
      return true;
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "Unable to update emegency contact!",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const delete_emergency_contact = (id) => async (dispatch) => {
  const access = localStorage.getItem("access");
  if (access) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `JWT ${access}`,
      },
    };

    try {
      await axios.delete(
        `${API_URL}/accounts/emergency_contacts/${id}/`,
        config
      );

      dispatch({
        type: DELETE_EMERGENCY_CONTACT,
        payload: id,
      });
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "Successfully deleted emegency contact!",
          severity: "success",
        },
      });
      return true;
    } catch (error) {
      dispatch({
        type: SNACKBAR_WRITE,
        payload: {
          message: "Unable to update emegency contact!",
          severity: "error",
        },
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};
